import React from "react";

const UpdateVehicleBtn = (props) => {
  const handleOnClick = () => {
    props.context.componentParent.onClickUpdateAssignVehicle(props, true);
  };

  return (
    <div>
      <button
        type="button"
        className="btn btn-secondary grid-btn"
        onClick={handleOnClick}
      >
        Update Vehicle
      </button>
    </div>
  );
};

export default UpdateVehicleBtn;
