import React, { Component } from "react";
// import Level2 from "./level2";
// import ScrollBar from "./slider";
import ScrollBar from "../optirun-admin/slider";
import PWConfigL2 from "../optirun-admin/plantWConfigL2";

export default class FPWConfigL1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      click: true,
    };
  }
  componentDidMount = () => {
    // var data = this.props.data
    // var keys = Object.keys(data)
    // console.log(data,'hello')
  };
  changeHandler = async (item, event) => {
    var data = this.props.data;
    var keys = Object.keys(data);
    if (data[item].value === 0) {
      data[item].value = 1;
      if (data[item].type == "radio") {
        keys.map((key) => {
          if (
            data[item].id === data[key].id &&
            data[item].name !== data[key].name
          ) {
            data[key].value = 0;
          }
        });
      }
    } else {
      data[item].value = 0;
    }
    await this.setState((prevState) => {
      return { click: !prevState.click };
    });
  };

  render() {
    var data = this.props.data;
    var keys = Object.keys(data);
    if (this.props.screen !== "summary") {
      if (data["Use Dynamic Planning Window"] !== undefined) {
        if (data["Use Dynamic Planning Window"].value === 1) {
          data["Number of Days to Use Planning Window"].disabled = true;
          data["Number of Days to Use Planning Window"].value = 0;
          // console.log(data["Number of Days to Use Planning Window"], "ll");
        } else {
          var userType = localStorage.getItem("user_type") === "admin";
          if (userType) {
            data["Number of Days to Use Planning Window"].disabled = false;
            if (data["Number of Days to Use Planning Window"].value === 0) {
              data["Number of Days to Use Planning Window"].value = 1;
            }
          }
        }
      }
      if (data["Auto Select Vehicles"] !== undefined) {
        if (data["Auto Select Vehicles"].value === 1) {
          data["IN416"].disabled = true;
          data["IN436"].disabled = true;
          data["IN417"].disabled = true;
        } else {
          data["IN416"].disabled = false;
          data["IN436"].disabled = false;
          data["IN417"].disabled = false;
        }
      }
      if (data["Optimize Distance for Planning"] !== undefined) {
        if (
          data["Optimize Distance for Planning"].value === 0 &&
          data["Optimise Time for Planning"].value === 0
        ) {
          data["Optimize Distance for Planning"].value = 1;
        }
      }
      if (data["Use Volume for Vehicle Fill Rate (VFR)"] !== undefined) {
        if (
          data["Use Volume for Vehicle Fill Rate (VFR)"].value === 0 &&
          data["Use Weight for Vehicle Fill Rate (VFR)"].value === 0
        ) {
          data["Use Weight for Vehicle Fill Rate (VFR)"].value = 1;
        }
      }
    }

    // console.log(data,'hello')
    return (
      <div
        className="col-md-12 mr-0"
        style={{ display: "block", marginRight: "0" }}
      >
        <div className="row mt-30p">
          {keys.map((item, idx) => {
            if (
              item !== "type" &&
              item !== "location" &&
              item !== "value" &&
              item !== "disabled" &&
              item !== "name" &&
              item !== "id"
            ) {
              if (data[item]?.type !== "number") {
                if(item == "Vehicle Availability"){
                  return (
                    <div
                      key={idx}
                      className={`col-sm-${this.props.colLength} mr-0  pr-0 pt-10p`}
                      id={item}
                    >
                      <label
                        className=""
                        style={{ color: "black", fontSize: "10", fontWeight:700 }}
                      >
                        {/* bg-primary */}
                        <input
                          checked={data[item].value === 1}
                          type={data[item].type}
                          disabled={data[item].disabled}
                          id={data[item].name}
                          name={data[item].id}
                          onClick={(event) => {
                            this.changeHandler(item, event);
                          }}
                        />
                        &nbsp;&nbsp;{item}
                      </label>
                      <div>
                        <PWConfigL2
                          name={item}
                          data={data[item]}
                          status={data[item].value}
                        />
                      </div>
                    </div>
                  );
              }else if(item != "Auto Select Vehicles"){
                return (
                  <div
                    key={idx}
                    className={`col-sm-${this.props.colLength} mr-0  pr-0`}
                    id={item}
                  >
                    <label
                      className=""
                      style={{ color: "black", fontSize: "10" }}
                    >
                      {/* bg-primary */}
                      <input
                        checked={data[item].value === 1}
                        type={data[item].type}
                        disabled={data[item].disabled}
                        id={data[item].name}
                        name={data[item].id}
                        onClick={(event) => {
                          this.changeHandler(item, event);
                        }}
                      />
                      &nbsp;&nbsp;{item}
                    </label>
                    <div>
                      <PWConfigL2
                        name={item}
                        data={data[item]}
                        status={data[item].value}
                      />
                    </div>
                  </div>
                );
              }         
              else{
                return (
                  <div
                    key={idx}
                    className='col-sm-12 pl-25p'
                    id={item}
                  >
                    <label
                      className=""
                      style={{ color: "black", fontSize: "10" }}
                    >
                      {/* bg-primary */}
                      <input
                        checked={data[item].value === 1}
                        type={data[item].type}
                        disabled={data[item].disabled}
                        id={data[item].name}
                        name={data[item].id}
                        onClick={(event) => {
                          this.changeHandler(item, event);
                        }}
                      />
                      &nbsp;&nbsp;{item}
                    </label>
                    <div>
                      <PWConfigL2
                        name={item}
                        data={data[item]}
                        status={data[item].value}
                      />
                    </div>
                  </div>
                );
              }
              } else {
                return (
                  <div className="col-sm-12">
                    <ScrollBar data={data[item]} name={item} />
                  </div>
                );
              }
            }
          })}
        </div>
      </div>
    );
  }
}

// export default Level1
