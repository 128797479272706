import React, { Component } from "react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import redirectURL from "../../redirectURL";
import CSVFileValidator from "csv-file-validator";
import $ from "jquery";
import { verifyext } from "../../common/verifyext";
import GridButton from "../gridButton";
import * as XLSX from "xlsx";
import SweetAlert from "react-bootstrap-sweetalert";
var moment = require("moment");

let loc_code = "";

export default class UploadData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderNumbersList: [],
      showSlideBlockUpload: "sidebarcls",
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 100,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        GridButton: GridButton,
      },
      rowData: [],
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 1000,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      droppedList: [],
      processErrShow: "",
      loadshow: "show-n",
      overly: "show-n",
      loadFile: "",
      location: this.props.location,
      transactionId: this.props.transaction_id,
      inputXlFile: false,
      dropLocationCodes: [],
      plantCodes: [],
      show: false,
      basicTitle: "",
      basicType: "default",
      invoicingDate: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.orderNumbersList !== this.props.orderNumbersList) {
      this.setState({
        orderNumbersList: this.props.orderNumbersList,
      });
    }
    if (prevProps.invoicingDate !== this.props.invoicingDate) {
      this.setState({
        invoicingDate: this.props.invoicingDate,
      });
    }
    // console.log("first ivo44", this.props.invoicingDate);
  }
  componentDidMount() {
    // console.log("first ivo", this.props.invoicingDate);
    this.setState({
      orderNumbersList: this.props.orderNumbersList,
      invoicingDate: this.props.invoicingDate,
    });
  }

  onClickShowUpload = () => {
    this.setState({
      showSlideBlockUpload: "slide25",
      overly: "show-m",
      location: this.props.location,
    });
    var params = {
      data_type: 2,
      location_code: this.props.location.value,
    };
    this.boundTypeLocations({ data_type: 2 });
    // console.log(this.props.location,'loc')
    var dropLocationCodes = [];
    redirectURL.post("master/getBoundLocations", params).then((response) => {
      // console.log("response", response.data, loc_code);
      response.data.boundLocations.map((item) => {
        if (item.location_type === 2) {
          dropLocationCodes.push(item.location_code);
        }
        return "";
      });
      this.setState({ dropLocationCodes });
    });
  };

  hideSlideBlock = () => {
    this.setState({
      overlayBlock: "show-n",
      showSlideBlockUpload: "",
      loadFile: "",
      loadshow: "show-n",
      overly: "show-n",
      inputXlFile: true,
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
    document.getElementById("inValidDataInfo").innerHTML = "";
  };
  fixProcess = () => {
    this.setState({
      processErrMsg: "",
      processErrShow: 0,
      loadFile: "",
      clickOnsave: false,
    });
    $("#uploadFile").val("");
    $("#notify_email").val("");
    //document.getElementById("upform").reset();
  };

  csvFileValidationsForInputFile = (csvFileData) => {
    var orderNumbersList = this.state.orderNumbersList;
    var { plantCodes } = this.state;
    const config = {
      headers: [
        {
          name: "Pickup Location",
          inputName: "Pickup Location",
          required: true,
          validate: function (code) {
            return isPickUPCodeValid(code);
          },
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Drop Location",
          inputName: "Drop Location",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
          validate: function (code) {
            return isDropCodeValid(code, plantCodes);
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Order Number",
          inputName: "Order Number",
          required: true,
          unique: true,
          uniqueError: function (headerName) {
            return `${headerName} is not unique`;
          },
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
          validate: function (order_number) {
            return validateOrderNumber(order_number, orderNumbersList);
          },
          validateError: function (headerName) {
            return ` ${headerName} already exists !!`;
          },
        },
        {
          name: "Invoicing Date",
          inputName: "Invoicing Date",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
          validate: function (date) {
            return isDateFormatValid(date);
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column valid pattern is M/D/YYYY`;
          },
        },

        {
          name: "Quantity",
          inputName: "Quantity",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
          validate: function (quantity) {
            return parseFloat(quantity) < 1000;
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column s/b less than 1000`;
          },
        },
        {
          name: "Demand",
          inputName: "Demand",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
          validate: function (demand) {
            return parseFloat(demand) < 100;
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column s/b less than 100`;
          },
        },
        {
          name: "Weight",
          inputName: "Weight",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
          validate: function (weight) {
            return isValidWeight(weight);
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column s/b less than 50000`;
          },
        },
        {
          name: "Dealer Available Time (From 00:00)",
          inputName: "Dealer Available Time (From 00:00)",
          required: false,
        },
        {
          name: "Dealer Available Time (To 23:59)",
          inputName: "Dealer Available Time (To 23:59)",
          required: false,
        },
        {
          name: "Delivery Date",
          inputName: "Delivery Date",
          // required: true,
          // requiredError: function (headerName, rowNumber, columnNumber) {
          //     return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
          // }
          validate: function (date) {
            return isDateFormatValid(date);
          },
          validateError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Product Code",
          inputName: "product_code",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
      ],
    };
    // var data =
    CSVFileValidator(csvFileData, config)
      .then((csvData) => {
        //commented as per customer
        var dealers = [];
        const invoicingDateIndex = config.headers.findIndex(
          (header) => header.name === "Invoicing Date"
        );
        const deliveryDateIndex = config.headers.findIndex(
          (header) => header.name === "Delivery Date"
        );
        var invocDates = [];
        if (invoicingDateIndex !== -1 && deliveryDateIndex !== -1) {
          // Assuming 'csvData' is an array of objects representing CSV rows
          csvData.data.forEach((row, idx) => {
            dealers.push(row["Drop Location"]);
            const invoicingDate = row["Invoicing Date"];
            const deliveryDate = row["Delivery Date"];
            if (!invocDates.includes(invoicingDate)) {
              invocDates.push(invoicingDate);
            }
            var parseInvoiceD = moment(invoicingDate, "M/D/YYYY");
            var parseOrderD = moment(deliveryDate, "M/D/YYYY");
            const formattedInVdate = parseInvoiceD.format("M/D/YYYY");
            var formattedOdD = parseOrderD.format("M/D/YYYY");
            const minDate = moment().subtract(30, "days");
            const maxDate = moment().add(30, "days");
            const isInRange = moment(formattedInVdate, "M/D/YYYY").isBetween(
              minDate,
              maxDate,
              null,
              "[]"
            );
            const isDeliverRange = moment(formattedOdD, "M/D/YYYY").isBetween(
              minDate,
              maxDate,
              null,
              "[]"
            );
            // console.log(isInRange,'dfji')
            if (new Date(formattedInVdate) && new Date(formattedOdD)) {
              if (new Date(formattedInVdate) > new Date(formattedOdD)) {
                csvData.inValidData.push({
                  columnIndex: "4,10",
                  message: "Invoice date should be less than delivery date",
                  rowIndex: idx + 2,
                });
              }
            }
            // if (!isInRange) {
            //   csvData.inValidData.push({
            //     columnIndex: 4,
            //     message:
            //       "Invoice date is out of range (less than or greater than 1 Month)",
            //     rowIndex: idx + 2,
            //   });
            // }
            // if (!isDeliverRange) {
            //   csvData.inValidData.push({
            //     columnIndex: 10,
            //     message:
            //       "Delivery date is out of range (less than or greater than 1 Month)",
            //     rowIndex: idx + 2,
            //   });
            // }
          });
          if (invocDates.length > 1) {
            csvData.inValidData.push({
              message:
                "Please make sure invoice date across input should be same..!!",
            });
            this.setState({
              uploadFile: "",
              show: true,
              basicType: "danger",
              basicTitle:
                "Please make sure invoice date across input should be same..!!",
            });
          }
          console.log(
            "first this.state.invoicingDate",
            moment(invocDates[0], "MM/DD/YYYY")
              .utcOffset("+05:30")
              .format("DD-MM-YYYY"),
            "invoicingDate",
            this.state.invoicingDate
          );
          if (
            invocDates.length === 1 &&
            moment(invocDates[0], "MM/DD/YYYY")
              .utcOffset("+05:30")
              .format("DD-MM-YYYY") !== this.state.invoicingDate
          ) {
            csvData.inValidData.push({
              message:
                "Please make sure invoice date should be same as invoice date in existing order info...!!",
            });
            this.setState({
              uploadFile: "",
              show: true,
              basicType: "danger",
              basicTitle:
                "Please make sure invoice date should be same as invoice date in existing order info...!!",
            });
          }
          if (this.state.dropLocationCodes !== undefined) {
            var check = dealers.some((loc) =>
              this.state.dropLocationCodes.includes(loc)
            );
          }
          if (!check) {
            csvData.inValidData.push({
              message: "All drop locations are invalid, Please check..!!",
            });
            this.setState({
              uploadFile: "",
              show: true,
              basicType: "danger",
              basicTitle: "All drop locations are invalid, Please check..!!",
            });
          }
        }
        document.getElementById("inValidDataInfo").innerHTML = "";
        if (csvData.inValidData.length > 0) {
          $("#uploadFile").val("");
          document.getElementById("bulkUploadBtn").type = "button";
          document.getElementById("bulkUploadBtn").classList.remove("btn-info");
          document
            .getElementById("bulkUploadBtn")
            .classList.add("btn-secondary");
          let invalidData = csvData.inValidData;
          let element = document.getElementById("inValidDataInfo");
          invalidData.map((item) => {
            let row, column;
            if (item.rowIndex == undefined) {
              row = "NA";
            } else {
              row = item.rowIndex;
            }
            if (item.columnIndex == undefined) {
              column = "NA";
            } else {
              column = item.columnIndex;
            }

            element.innerHTML +=
              "Column : " +
              column +
              "," +
              " Row : " +
              row +
              "--" +
              item.message +
              "<br></br>";
          });
          let throwMsg = 0;
          invalidData.map((itm) => {
            if (itm.columnIndex == 10) {
              throwMsg = 1;
            }
          });
          // if (throwMsg) {
          //   this.setState({
          //     uploadFile: "",
          //     show: true,
          //     basicType: "danger",
          //     basicTitle:
          //       "Please Input Valid Data and also Ensure Date is in this Format only M/D/YYYY",
          //   });
          // }
        } else {
          document.getElementById("bulkUploadBtn").type = "submit";
          document
            .getElementById("bulkUploadBtn")
            .classList.remove("btn-secondary");
          document.getElementById("bulkUploadBtn").classList.add("btn-info");
        }
      })
      .catch((err) => {
        var errDetails = {
          url: "CSVFileValidator",
          err,
          screen: "Run New Plan",
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  validateAllValues = (jsonData) => {
    var inValidData = [];
    loc_code = this.state.location.value;
    jsonData.map((rec, idx) => {
      // console.log(rec["Pickup Location"], rec["Pickup Location"] !== loc_code);
      if (rec["Pickup Location"] !== loc_code) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 1,
          message: "Pick Location Code is required",
        });
      }
      if (rec["Drop Location"] === "" || rec["Drop Location"] === undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 2,
          message: "Drop Location Code is required",
        });
      }
      if (rec["Order Number"] === "" || rec["Order Number"] === undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 3,
          message: "Order Number  is required",
        });
      }
      if (rec["Invoicing Date"] && rec["Invoicing Date"].includes("-")) {
        if (
          rec["Invoicing Date"] === "" ||
          rec["Invoicing Date"] === undefined
        ) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 4,
            message: "Invoicing Date is required",
          });
        } else if (!isDateFormatValid(rec["Invoicing Date"])) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 4,
            message: "Invoicing Date Format is not Valid",
          });
        }
      } else {
        if (
          rec["Invoicing Date"] === "" ||
          rec["Invoicing Date"] === undefined
        ) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 4,
            message: "Invoicing Date is required",
          });
        } else if (!isDateFormatValid(rec["Invoicing Date"])) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 4,
            message: "Invoicing Date Format is not Valid",
          });
        }
      }
      if (rec["Quantity"] == "" || rec["Quantity"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 1,
          message: "Quantity  is required",
        });
      }
      if (rec["Demand"] == "" || rec["Demand"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 1,
          message: "Demand  is required",
        });
      }
      if (rec["Weight"] == "" || rec["Weight"] == undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 1,
          message: " Weight is required",
        });
      }
      if (
        rec["Dealer Available Time (From 00:00)"] == "" ||
        rec["Dealer Available Time (From 00:00)"] == undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 1,
          message: "Dealer Available Time is required",
        });
      }
      if (
        rec["Dealer Available Time (To 23:59)"] == "" ||
        rec["Dealer Available Time (To 23:59)"] == undefined
      ) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 1,
          message: "Dealer Available Time (To 23:59)  is required",
        });
      }
      if (rec["Delivery Date"] && rec["Delivery Date"].includes("-")) {
        if (rec["Delivery Date"] === "" || rec["Delivery Date"] === undefined) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 10,
            message: "Delivery Date is required",
          });
        } else if (!isDateFormatValid(rec["Delivery Date"])) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 10,
            message: "Delivery Date Format is not Valid",
          });
        }
      } else {
        if (rec["Delivery Date"] === "" || rec["Delivery Date"] === undefined) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 10,
            message: "Delivery Date is required",
          });
        } else if (!isDateFormatValid(rec["Delivery Date"])) {
          inValidData.push({
            rowIndex: idx + 2,
            columnIndex: 10,
            message: "Delivery Date Format is not Valid",
          });
        }
      }
      
      if (rec["Product Code"] === "" || rec["Product Code"] === undefined) {
        inValidData.push({
          rowIndex: idx + 2,
          columnIndex: 11,
          message: "Product Code is required",
        });
      }
      return true;
    });
    document.getElementById("inValidDataInfo").innerHTML = "";
    if (inValidData.length > 0) {
      $("#uploadFile").val("");
      document.getElementById("bulkUploadBtn").type = "button";
      document.getElementById("bulkUploadBtn").classList.remove("btn-info");
      document.getElementById("bulkUploadBtn").classList.add("btn-secondary");
      let invalidData = inValidData;
      let element = document.getElementById("inValidDataInfo");
      invalidData.map((item) => {
        let row, column;
        if (item.rowIndex == undefined) {
          row = "NA";
        } else {
          row = item.rowIndex;
        }
        if (item.columnIndex == undefined) {
          column = "NA";
        } else {
          column = item.columnIndex;
        }

        element.innerHTML +=
          "Column : " +
          column +
          "," +
          " Row : " +
          row +
          "--" +
          item.message +
          "<br></br>";
      });
      // let throwMsg = 0;
      // invalidData.map((itm) => {
      //   if (itm.columnIndex == 10) {
      //     throwMsg = 1;
      //   }
      // });
      // if (throwMsg) {
      //   this.setState({
      //     uploadFile: "",
      //     show: true,
      //     basicType: "danger",
      //     basicTitle:
      //       "Please Input Valid Data and also Ensure Date is in this Format only M/D/YYYY",
      //   });
      // }
      return false;
    } else {
      document.getElementById("bulkUploadBtn").type = "submit";
      document
        .getElementById("bulkUploadBtn")
        .classList.remove("btn-secondary");
      document.getElementById("bulkUploadBtn").classList.add("btn-info");
      return true;
    }
  };

  changeFileHandler = async (e) => {
    loc_code = this.state.location.value;
    //     let logParams = {
    //       "location_code" : this.state.location.value,
    //         "location_name" : this.state.location.label,
    //       "user_name" : localStorage.getItem("username"),
    //       "useremail" : localStorage.getItem("email"),
    //       "client" : localStorage.getItem("client"),
    //       "screen" : "Run New Plan",
    //       "activity" : 'Uploaded the File',
    //       "event" : "Uploaded the File",
    //       "data_type" : this.state.data_type,
    //   }

    //   redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    // console.log(e.target.files[0],'e.target.files[0]')
    this.setState({
      loadFile: e.target.files[0],
    });
    var orderNumbersList = this.state.orderNumbersList;
    document.getElementById("inValidDataInfo").innerHTML = "";
    var extentions = [
      "config",
      "exe",
      "js",
      "jsx",
      "svg",
      "JPG",
      "jpg",
      "jpeg",
      "GIF",
      "gif",
      "PNG",
      "png",
      "BMP",
      "bmp",
      "html",
      "xls",
      "doc",
      "docx",
      "ppt",
      "pptx",
      "pdf",
      "pdfx",
      "html",
      "css",
      "sh",
      "php5",
      "pht",
      "phtml",
      "shtml",
      "asa",
      "cer",
      "asax",
      "swf",
      "xap",
      "php",
      "htaccess",
      "xml",
      "xds",
      "asp",
      "aspx",
      "java",
      "c",
      "c++",
      "ctl",
      "conf",
      "qewe",
      "encrypted",
      "enc",
      "crypted",
      "locked",
      "crypto",
      "crypt",
      "txt",
    ];
    var check = !extentions.includes(e.target.files[0].name);
    if (check) {
      // console.log("hit");
      if (
        e.target.files[0].type === "" ||
        e.target.files[0].type === "text/csv" ||
        e.target.files[0].type === "application/vnd.ms-excel" ||
        e.target.files[0].type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        // console.log("hit");
        // console.log(fileData);
        // console.log(typeof fileData);

        if (
          e.target.files[0].type === "" ||
          e.target.files[0].type === "text/csv"
        ) {
          this.setState({ inputXlFile: false });
          // console.log('hit')
          this.csvFileValidationsForInputFile(e.target.files[0]);
        } else if (
          e.target.files[0].type === "application/vnd.ms-excel" ||
          e.target.files[0].type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        ) {
          const file = e.target.files[0];
          const reader = new FileReader();
          var jsondata = [];
          reader.onload = async (evt) => {
            const data = evt.target.result;
            const workbook = XLSX.read(data, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const jsonData = XLSX.utils.sheet_to_json(sheet, { raw: false });
            // console.log(jsonData, "json data");
            // jsonData.map((item)=>{
            var result = this.validateAllValues(jsonData);
            // })
            // Convert JSON data to CSV format with custom date formatting
            var csvData = jsonData.reduce((csv, row, index) => {
              if (index === 0) {
                // Add header row
                csv += Object.keys(row).join(",") + "\n";
              }
              // Format the invoicing_date field to mm-dd-yyyy
              if (row["Invoicing Date"]) {
                const dateParts = row["Invoicing Date"].split("/");
                if (dateParts.length === 3) {
                  const month = dateParts[0].padStart(2, "0");
                  const day = dateParts[1].padStart(2, "0");
                  const year = dateParts[2];
                  row["Invoicing Date"] = `${month}/${day}/${year}`;
                }
              }
              // console.log('first', row["Delivery Date"]);
              if (row["Delivery Date"]) {
                const dateParts = row["Delivery Date"].split("/");
                if (dateParts.length === 3) {
                  const month = dateParts[0].padStart(2, "0");
                  const day = dateParts[1].padStart(2, "0");
                  const year = dateParts[2];
                  row["Delivery Date"] = `${month}/${day}/${year}`;
                }
              }
              // Add row data
              csv +=
                Object.values(row)
                  .map((value) => {
                    // Check if the value is a number and convert it to a number type
                    if (!isNaN(value)) {
                      if (value.includes(".")) {
                        return parseFloat(value);
                      } else {
                        return parseInt(value, 10);
                      }
                    }
                    return `"${value}"`;
                  })
                  .join(",") + "\n";
              return csv;
            }, "");
            // console.log(csvData,'csv')
            await this.setState({
              loadFile: csvData,
              inputXlFile: true,
            });
            if (result) {
              // console.log('hit after vcalidation')
              this.csvFileValidationsForInputFile(csvData);
            }
          };
          reader.readAsBinaryString(file);
        }
      } else {
        e.target.value = null;
        this.setState({
          uploadFile: "",
          show: true,
          basicType: "danger",
          basicTitle:
            "Please upload file having extensions .csv or .xlsx only.",
        });
      }
    } else {
      e.target.value = null;
      this.setState({
        uploadFile: "",
        show: true,
        basicType: "danger",
        basicTitle: "Please upload file having extensions .csv or .xlsx only.",
      });
    }
  };

  uploadBulkFormHandler = async (event) => {
    event.preventDefault();
    //     var logParams = {
    //       "location_code" : this.state.location.value,
    //       "location_name" : this.state.location.label,
    //       "user_name" : localStorage.getItem("username"),
    //       "useremail" : localStorage.getItem("email"),
    //       "client" : localStorage.getItem("client"),
    //       "screen" : "Summary Dashboard",
    //       "activity" : 'clicked on SAVE Button in the slider',
    //       "event" : "navigated to run plan page",
    //       "data_type" : this.state.data_type,
    //   }
    //   redirectURL.post("/master/loguserUpdatesorChanges",logParams)

    let saveData = new FormData();
    // var cnf = window.confirm("Are you sure you want to proceed ?");
    if (this.state.clickOnsave !== true) {
      await this.setState({ clickOnsave: true });
      if (this.state.inputXlFile) {
        const csvBlob = new Blob([this.state.loadFile], { type: "text/csv" });
        // console.log('hello',this.state.loadFile)
        saveData.append("loadFile", csvBlob, "input.csv");
      } else {
        saveData.append("loadFile", this.state.loadFile);
      }
      //   var notify_email = $("#notify_email").val();
      //   saveData.append("notify_email", notify_email);
      saveData.append("module_type", 1);
      saveData.append("data_type", this.props.data_type);
      //   saveData.append(
      //     "material_configurations",
      //     this.state.material_configurations
      //   );
      let location = this.state.location;
      saveData.append("location_code", location.value);
      //   saveData.append("location_name", location.label);
      saveData.append("user_name", localStorage.getItem("username"));
      saveData.append("transaction_id", this.props.transaction_id);
      saveData.append("useremail", localStorage.getItem("email"));
      saveData.append("client", localStorage.getItem("client"));
      // console.log("saveData",saveData);
      this.setState({
        loadshow: "show-m",
        overly: "show-m",
        // showSlideBlockUpload: "",
        // overlayBlock: "show-n",
      });
      //   var logParams = {
      //     "location_code" : location.value,
      //     "location_name" : location.label,
      //     "user_name" : localStorage.getItem("username"),
      //     "useremail" : localStorage.getItem("email"),
      //     "client" : localStorage.getItem("client"),
      //     "screen" : "Summary Dashboard",
      //     "activity" : 'Input Data File Upload',
      //     "event" : "Run New Plan By File",
      //     "data_type" : this.state.data_type,
      //   }

      //   redirectURL.post("/master/logUserUpdateOrSaveDataActivities",logParams)
      //   .then(()=>{

      //   })
      //   .catch(function (error) {
      //     var errDetails={
      //       url:"/master/logUserUpdateOrSaveDataActivities",
      //       screen:'Run New Plan',
      //       error,

      //   }
      //     redirectURL.post("/master/logErrorsForApiCalls",errDetails)

      // })

      redirectURL
        .post("/dispatch/validateInputDealerCode", saveData, {
          // headers: {
          //   "content-type": "multipart/form-data",
          //   "Access-Control-Allow-Origin": "*",
          //   "Access-Control-Allow-Methods": "POST",
          //   "Access-Control-Allow-Headers": "Origin, Content-Type",
          // },
        })
        .then((response) => {
          var newStatusCode = response.data.status;
          if (newStatusCode == 1) {
            redirectURL
              .post("/tsp/addedNewOBDToPlannedData", saveData)
              .then((response) => {
                // console.log(response, "responseFromProcess");
                var statusCode = response.data.status;
                if (statusCode === 1) {
                  alert("Added New OBD");
                  // this.setState({
                  //   show: true,
                  //   basicTitle: response.data.message,
                  //   basicType: "success",
                  // });
                  window.location.reload();
                } else {
                  // alert("Failed to Add OBD");
                  this.setState({
                    show: true,
                    basicTitle: response.data.message,
                    basicType: "danger",
                    loadshow: "show-n",
                  });
                }
              })
              .catch((error) => {
                alert("Failed to Add New OBD");
                var errDetails = {
                  url: "/dispatch/uploadBulkFormHandler",
                  screen: "Add New OBD",
                  error,
                };
                redirectURL.post("/master/logErrorsForApiCalls", errDetails);
              });
          } else {
            if (newStatusCode == 8) {
              this.setState({
                processErrMsg: response.data.message,
                processErrShow: 1,
                droppedList: response.data.droppedList,
                statusCode: newStatusCode,
                loadshow: "show-n",
                overly: "show-n",
                overlayBlock: "show-n",
              });
            }
          }
        });
    }
  };

  onClickDownloadSample = () => {
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Run New Plan",
      activity: "clicked on Download Sample Data Button in the slider",
      event: "Sample template has been Downloaded",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  boundTypeLocations = async (params) => {
    var plantCodes = [];
    await redirectURL
      .post("master/getBoundTypeLocations", params)
      .then(async (response) => {
        if (response.data.status === "success") {
          let boundLocations = response.data.boundLocations;
          // console.log("locats", boundLocations);
          if (boundLocations.length) {
            boundLocations.map((item) => {
              plantCodes.push(item.location_code);
              return "";
            });
          }
          this.setState({ plantCodes });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getBoundTypeLocations",
          screen: "Run New Plan",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  onclickUploadFile = () => {
    $("#uploadFile").val("");
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Upload New OBD",
      activity: "clicked on Upload File Option in the slide",
      event: "File Upload slide opened",
      data_type: this.state.data_type,
    };
    // console.log('first log params',logParams)
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  closeAlert = async () => {
    await this.setState({
      show: false,
    });
  };
  render() {
    var columnwithDefsForDropped = [
      {
        headerName: "Dealer Code",
        field: "dealer_code",
        width: 130,
        filter: true,
        resizable: true,
        editable: false,
      },
      // {
      //   headerName: "Demand",
      //   field: "demand",
      //   width: 130,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      // },
      {
        headerName: "Remarks",
        field: "remarks",
        width: 200,
        filter: true,
        resizable: true,
        editable: false,
        cellRenderer: (params) => {
          if (params.value === null || params.value === undefined) {
            return "Dealer Details Not Found";
          } else {
            return params.value;
          }
        },
      },
    ];

    var templatePath = require("../../../assets/json/input_data_template.csv");

    return (
      <>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <button
          type="button"
          onClick={this.onClickShowUpload}
          className="btn btn-danger ml-10p float-right"
        >
          <i className="fa fa-upload"></i> Add New OBD
        </button>

        <div className={"sliderBlock2 " + this.state.showSlideBlockUpload}>
          <h5 className="crd-bg p-10p">Upload Input File</h5>
          <div className="row">
            <div className="col-sm-12">
              {this.state.processErrShow == 0 ? (
                <form onSubmit={this.uploadBulkFormHandler.bind(this)}>
                  <div className="row p-20p">
                    <div className="form-group col-sm-12">
                      <a
                        className="btn btn-warning"
                        href={templatePath}
                        target="_blank"
                        onClick={this.onClickDownloadSample}
                      >
                        Download Sample Template
                      </a>
                    </div>
                    <div className="form-group col-sm-12 mb-10p">
                      <label style={{ color: "#000" }}>Upload File</label>
                      <input
                        type="file"
                        name="uploadFile"
                        id="uploadFile"
                        onChange={this.changeFileHandler}
                        className="form-control"
                        onClick={this.onclickUploadFile}
                        required
                      />
                    </div>

                    <div
                      className="form-group col-sm-12 note-font"
                      onClick={this.toggleMoreInfo}
                    >
                      Note : Blank Rows/Columns are not Allowed{" "}
                      <span className="more-btn">
                        {this.state.showMoreInfo ? "Less <" : "More >"}
                      </span>
                      {this.state.showMoreInfo && (
                        <div className="more-info">
                          To Check for Blank Data 1. Click Find & Select. 2.
                          Click to Go to Special. 3. Choose Blanks. 4. Click OK
                          and then all the blank rows/cells will be highlighted.
                          5. Choose the Delete under Cells section on the Home
                          Tab. 6. Click Delete Sheet Rows.
                        </div>
                      )}
                    </div>

                    <div className="form-group col-sm-12 note-font">
                      Disclaimer : Dealer available window for OT customers will
                      be considered from input file, GT customer timings will be
                      considered from pickup/drop locations.
                    </div>
                    {/* <div className="form-group col-sm-12 mb-20p">
                      <label style={{ color: "#000" }}>
                        Notify users via email once the process is completed
                        (comma seperated)
                      </label>
                      <textarea
                        name="notify_email"
                        id="notify_email"
                        className="form-control"
                        placeholder="abc@mail.com,xyz@mail.com"
                      ></textarea>
                    </div> */}

                    <div className="form-group col-sm-12 mb-20p">
                      <button
                        type="submit"
                        id="bulkUploadBtn"
                        className="btn btn-info"
                      >
                        SAVE
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={this.hideSlideBlock.bind(this)}
                      >
                        CANCEL
                      </button>
                    </div>
                    <div id="inValidDataInfo" style={{ color: "red" }}></div>
                  </div>
                </form>
              ) : (
                <div className="" style={{ marginTop: "1em" }}>
                  <p style={{ marginBottom: "1em" }}>
                    {this.state.processErrMsg}
                  </p>
                  {this.state.droppedList.length > 0 ? (
                    <div
                      id="myGrid"
                      style={{
                        width: "100%",
                        height: "300px",
                        marginBottom: "1em",
                      }}
                      className={"ag-theme-balham " + this.state.showGridData}
                    >
                      <AgGridReact
                        // modules={this.state.modules}
                        columnDefs={columnwithDefsForDropped}
                        defaultColDef={this.state.defaultColDef}
                        rowData={this.state.droppedList}
                        enableCharts={false}
                        // autoGroupColumnDef={this.state.autoGroupColumnDef}
                        onGridReady={this.onGridReady1}
                        onGridState={this.onGridState1}
                        frameworkComponents={this.state.frameworkComponents}
                        statusBar={this.state.statusBar}
                        sideBar={this.state.sideBar}
                        stopEditingWhenGridLosesFocus={true}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={false}
                        gridOptions={{
                          context: { componentParent: this },
                        }}
                        // components={this.state.components}
                        enableRangeSelection={true}
                        //onCellClicked={this.onCellClicked}
                        // onCellEditingStopped={this.onCellUpdateData.bind(this)}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <button
                    type="button"
                    onClick={this.fixProcess.bind(this)}
                    className="btn btn-success"
                  >
                    Fix and Reupload
                  </button>
                  {this.state.statusCode == 11 ? (
                    <button
                      type="button"
                      onClick={this.hideSlideBlock.bind(this)}
                      className="btn btn-default"
                    >
                      Cancel
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={"loader " + this.state.loadshow}></div>
        <div
          className={"overlay-part " + this.state.overly}
          onClick={this.hideSlideBlock.bind(this)}
        ></div>
      </>
    );
  }
}

function isDateFormatValid(date) {
  const dateParts = date.split("/");

  if (dateParts.length !== 3) {
    return false;
  }

  const day = parseInt(dateParts[1], 10);
  const month = parseInt(dateParts[0], 10);
  const year = parseInt(dateParts[2], 10);

  if (isNaN(day) || isNaN(month) || isNaN(year)) {
    return false;
  }

  const formattedDate = new Date(year, month - 1, day);

  if (
    formattedDate.getDate() !== day ||
    formattedDate.getMonth() !== month - 1 ||
    formattedDate.getFullYear() !== year
  ) {
    return false;
  }

  return true;
}

function isValidWeight(weight) {
  if (weight.includes(",")) {
    return false;
  } else if (parseFloat(weight) > 50000) {
    return false;
  } else {
    return true;
  }
}

function isPickUPCodeValid(code) {
  if (code !== loc_code) {
    return false;
  } else {
    return true;
  }
}

function validateOrderNumber(order_number, orderNumbersList) {
  // const { orderNumbersList } = this.state;
  // console.log(order_number,typeof(order_number))
  if (orderNumbersList.includes(String(order_number))) {
    return false;
  } else {
    return true;
  }
}

function isDateFormatValidforXLSX(date) {
  // console.log(date, 'date');
  let dateParts = date.split("/");

  if (dateParts.length !== 3) {
    return false;
  }

  var month = parseInt(dateParts[0], 10);
  var day = parseInt(dateParts[1], 10);
  var year = parseInt(dateParts[2], 10);

  if (isNaN(month) || isNaN(day) || isNaN(year)) {
    return false;
  }

  // Adjust the year if it's two digits
  if (year >= 0 && year < 100) {
    // Assuming if the year is less than 50, it belongs to the 21st century, otherwise 20th century
    year += year < 50 ? 2000 : 1900;
  }

  var formattedDate = new Date(year, month - 1, day);
  // console.log(formattedDate,'formated date')
  if (
    formattedDate.getDate() !== day ||
    formattedDate.getMonth() !== month - 1 ||
    formattedDate.getFullYear() !== year
  ) {
    return false;
  }

  return true;
}

function isDropCodeValid(code, plantCodes) {
  if (!plantCodes.includes(code)) {
    return true;
  } else {
    return false;
  }
}
