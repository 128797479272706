import React, { Component } from 'react';
import Select from 'react-select';
import CountUp from 'react-countup';
import redirectURL from "../redirectURL";
import SweetAlert from "react-bootstrap-sweetalert";
import MultiAxisChart from './multiYaxischart';
import { Form } from "react-bootstrap"; 
export default class RecommendedForecast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startMonth: { label: "January", value: 1 },
            endMonth: { label: "December", value: 12 },
            type1: 0,
            type2: 0,
            show: false,
            basicTitle: "",
            basicType: "default",
            summaryData:[],
            totalLoad:0,
            totalVeh416:0,
            totalVeh436:0,
            totalServiceability:0,
            normalloadDataArr:[],
            normalin416Arr:[],
            normalin436Arr:[],
            normalplanningPercentArr:[],
            originalloadDataArr:[],
            originalin416Arr:[],
            originalin436Arr:[],
            originalplanningPercentArr:[],
            slidervalue:100,
            maxCap:[100,100,100,100,100,100,100,100,100,100,100,100],
            percenttotalLoad:0,
            percenttotalVeh416:0,
            percenttotalVeh436:0,
            percenttotalServiceability:0,
            chartType:'spline'
        }
    }

    componentDidMount() {
        this.getRecommendationData()
    }

    getRecommendationData = () =>{
        let type1;
        let type2;
        let summaryData =[];
        let chartData =[];
        let normalloadDataArr=[];
        let normalin416Arr=[];
        let normalin436Arr=[];
        let normalplanningPercentArr=[];
        let totalLoad=0;
        let totalVeh416=0;
        let totalVeh436=0;
        let totalServiceability=0;
        let originalloadDataArr = [];
        let originalin416Arr = [];
        let originalin436Arr = [];
        let originalplanningPercentArr = [];

        let startMonth= this.state.startMonth.label;
        let endMonth = this.state.endMonth.label;
        const monthsInRange = getMonthsInRange(startMonth, endMonth);

        if(monthsInRange == 'Invalid start month is after end month'){
            this.setState({
                show: true,
                basicTitle: "Invalid start month is after end month",
                basicType: "danger",
            })
        }else{
            let params = {
                months:monthsInRange
            }
            redirectURL.post("/forecastvehicle/getRecommendationData",params)
            .then(res =>{
                // console.log(res.data,'respomse')
                if(res.data.records.length){
                    type1 = res.data.records[0].maxIN416
                    type2 = res.data.records[0].maxIN436
                    summaryData= [res.data.records[0]]
                    chartData=res.data.chart_data
                    let req_data = getchartDatainArr(chartData)

                    const months = req_data.map(item => item.month);
                    const totalQtyArr = req_data.map(item => item.totalQty);
                    const totalLocalArr = req_data.map(item => item.totalLocal);
                    const totalUpcountryArr = req_data.map(item => item.totalUpcountry);
                    const IN416Arr = req_data.map(item => item.IN416);
                    const IN436Arr = req_data.map(item => item.IN436);


                    totalLoad = sumTheData(totalQtyArr)
                    totalVeh416 = Math.max(...IN416Arr)
                    totalVeh436 = Math.max(...IN436Arr)
                    totalServiceability = averageOfData(totalUpcountryArr)

                    normalloadDataArr = normalize(totalQtyArr);
                    normalin416Arr = normalize(IN416Arr);
                    normalin436Arr = normalize(IN436Arr);
                    normalplanningPercentArr = normalize(totalUpcountryArr);

                    originalloadDataArr = totalQtyArr;
                    originalin416Arr = IN416Arr;
                    originalin436Arr = IN436Arr;
                    originalplanningPercentArr = totalUpcountryArr;

                    this.setState({
                        type1,type2,summaryData,
                        normalloadDataArr:normalloadDataArr,normalin416Arr:normalin416Arr,normalin436Arr:normalin436Arr,normalplanningPercentArr:normalplanningPercentArr,
                        totalLoad,totalVeh416,totalVeh436,totalServiceability,originalloadDataArr,originalin416Arr,originalin436Arr,originalplanningPercentArr
                     },()=>{
                        this.handleSliderChange()
                     })
                }else{
                    this.setState({
                        show: true,
                        basicTitle: "No Data",
                        basicType: "default",
                    })
                }
            })
        }
    }

    monthHandler = (value, { name }) => {
        this.setState({ [name]: value });
    }

    formatSpecificationName = (name) => {
        return name
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    renderSubTable = (subTableData) => {
        return (
            <table style={{ width: '100%', border: '1px solid lightgrey', marginTop: '8px', marginBottom: "8px" }}>
                <thead>
                    <tr style={{ border: '1px solid lightgrey' }}>
                        {Object.keys(subTableData).map((key) => (
                            <th key={key} style={{ border: '1px solid lightgrey', padding: '8px', textAlign: 'center' }}>{this.formatSpecificationName(key)}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr style={{}}>
                        {Object.values(subTableData).map((value, index) => (
                            <td key={index} style={{ border: '1px solid lightgrey', padding: '8px', textAlign: 'center' }}>{typeof(value) == 'string' ? value : value.toFixed(0)}</td>
                        ))}
                    </tr>
                </tbody>
            </table>
        );
    };

    closeAlert = async () => {
        await this.setState({
          show: false,
        });
      };

      handleSliderChange = (e) =>{
        let val =100
        if(e != undefined){
            val = parseInt(e.target.value)
        }
        
        let updatemaxcap =[]
        let totalLoad = this.state.totalLoad;
        let totalVeh416 = this.state.totalVeh416;
        let totalVeh436 = this.state.totalVeh436;
        let totalServiceability = this.state.totalServiceability;

        let percenttotalLoad = (totalLoad*(val/100)).toFixed(2)
        let percenttotalVeh416 = Math.ceil((totalVeh416*(val/100)))
        let percenttotalVeh436 = Math.ceil((totalVeh436*(val/100)))
        let percenttotalServiceability = (totalServiceability*(val/100)).toFixed(2)

        for (let i = 0; i < 12; i++) {
            updatemaxcap.push(val); 
        }
        this.setState({
            slidervalue:val,
            maxCap:updatemaxcap,
            percenttotalLoad:percenttotalLoad,
            percenttotalVeh416:percenttotalVeh416,
            percenttotalVeh436:percenttotalVeh436,
            percenttotalServiceability:percenttotalServiceability
        })
      }

      handleChartTypeChange = (e)=>{
        this.setState({
            chartType:e.target.value
        })
      }
    
    render() {
        const colors = ["linear-gradient(90deg,rgba(230,248,255,1) 0%, rgba(195,255,253,1) 100%)","#fff", "#EBE3D5", "#F3EEEA"]
        var Months = [
            { label: 'January', value: 1 },
            { label: 'February', value: 2 },
            { label: 'March', value: 3 },
            { label: 'April', value: 4 },
            { label: 'May', value: 5 },
            { label: 'June', value: 6 },
            { label: 'July', value: 7 },
            { label: 'August', value: 8 },
            { label: 'September', value: 9 },
            { label: 'October', value: 10 },
            { label: 'November', value: 11 },
            { label: 'December', value: 12 }
        ];
        return (

            <>
            <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
            ></SweetAlert>
                  
            <div className="row">
                <div className="container" style={{ height: "100vh" }}>
                    <div className="col-12" style={{ paddingLeft: "0px", color: "black", fontSize: "25px", fontWeight: "bold" }}>
                        <p> Vehicles Recommended</p>
                    </div>
                    <div className="mb-20p">
                        <div
                            className="form-group"
                            style={{
                                paddingLeft: "0px",
                                marginBottom: "3px",
                            }}
                        >
                            <div
                                className="col-sm-12 "
                                style={{
                                    marginLeft: "0px",
                                    paddingRight: "0px",
                                    paddingBottom: "10px",
                                }}
                            >
                                <div className="col-sm-2">
                                    <label htmlFor="startMonth" className="">
                                        Start Month
                                    </label>
                                    <Select
                                        id="startMonth"
                                        placeholder={"Select"}
                                        onChange={this.monthHandler}
                                        name="startMonth"
                                        value={this.state.startMonth}
                                        options={Months}
                                    />
                                </div>
                                <div className="col-sm-2">
                                    <label htmlFor="endMonth" className="">
                                        End Month
                                    </label>
                                    <Select
                                        id="endMonth"
                                        placeholder={"Select"}
                                        onChange={this.monthHandler}
                                        name="endMonth"
                                        value={this.state.endMonth}
                                        options={Months}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    <button type="button" onClick={this.getRecommendationData} className="btn btn-warning " style={{ marginTop: '32px' }}>Get Data</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row mb-20p">
                        <div className="col-12 d-flex flex-row justify-content-center">
                            <div className="d-flex mr-5p" style={{ background: "#fff", width: "17%", padding: "5px", borderRadius: "5px" }}>
                                <div className="d-flex flex-column justify-content-between align-items-center pl-3p pr-3p" style={{ width: "100%", paddingLeft: "5px", paddingRight: "5px" }} >
                                    <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#A94438" }}>Total Trucks</p>
                                    <div className="d-flex align-items-center">
                                        <CountUp end={this.state.type1 + this.state.type2} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex mr-5p" style={{ background: "#fff", width: "17%", padding: "5px", borderRadius: "5px" }}>
                                <div className="d-flex flex-column justify-content-between align-items-center pl-3p pr-3p" style={{ width: "100%", paddingLeft: "5px", paddingRight: "5px" }} >
                                    <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#A94438" }}>IN416</p>
                                    <div className="d-flex align-items-center">
                                        <CountUp end={this.state.type1} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                    </div>
                                </div>
                            </div>


                            <div className="d-flex mr-5p" style={{ background: "#fff", width: "17%", padding: "5px", borderRadius: "5px" }}>
                                <div className="d-flex flex-column justify-content-between align-items-center pl-3p pr-3p" style={{ width: "100%", paddingLeft: "5px", paddingRight: "5px" }} >
                                    <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#A94438" }}>IN436</p>
                                    <div className="d-flex align-items-center">
                                        <CountUp end={this.state.type2} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className='col-sm-12'>
                    <div className='col-sm-6 float-right'>
                    <label htmlFor="chartType" style={{marginBottom:'0px'}}>Select Chart Type: </label>
                        <select id="chartType" value={this.state.chartType} onChange={this.handleChartTypeChange}>
                            <option value="spline">Spline</option>
                            <option value="column">Column</option>
                        </select>
                    </div>  
                    </div>
                    <div className='col-sm-12 d-flex'>   
                                        
                            <div className='col-sm-8' style={{height:'500px',paddingRight:'0px'}}>
                            <MultiAxisChart
                            title={"Fleet Analysis"}
                            normalloadDataArr={this.state.normalloadDataArr}
                            normalin416Arr={this.state.normalin416Arr}
                            normalin436Arr={this.state.normalin436Arr}
                            normalplanningPercentArr={this.state.normalplanningPercentArr}
                            originalloadDataArr={this.state.originalloadDataArr}
                            originalin416Arr={this.state.originalin416Arr}
                            originalin436Arr={this.state.originalin436Arr}
                            originalplanningPercentArr={this.state.originalplanningPercentArr}
                            maxCap = {this.state.maxCap}
                            chartType = {this.state.chartType}
                            />
                            </div>
                            
                            {/* <div className='col-sm-1' style={{backgroundColor:'white',maxWidth:'3%'}}> 
                            
                            <Form.Range
                                min={20}
                                max={100}
                                value={this.state.slidervalue}
                                onChange={this.handleSliderChange}
                                style={{ transform: 'rotate(-90deg)', transformOrigin: 'left center',height:'480px',paddingLeft:'60px',margin:'10px' }}
                            />
                            <span className='fbold' style={{ transform: 'rotate(-90deg)', marginTop:'-460px',position:'absolute'}}>{this.state.slidervalue}</span>
                            <span className='fbold' style={{ transform: 'rotate(-90deg)', marginTop:'-240px',position:'absolute'}}>Bar</span>
                            </div> */}
                        <div className='col-sm-4 d-flex justify-content-center align-items-center'>
                            <div className='col-sm-12 card justify-content-center align-items-center pt-10p' style={{height:'500px'}}>
                            <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#A94438" }}>System Recommended</p>
                            
                            <div className="col row justify-content-center align-items-center mb-20p">
                            
                            <div className="col-sm-5 d-flex  mr-5p" style={{ background: "#fff", width: "17%", padding: "5px", borderRadius: "5px",border:'1px solid lightgrey' }}>
                                <div className="d-flex flex-column justify-content-between align-items-center pl-3p pr-3p" style={{ width: "100%", paddingLeft: "5px", paddingRight: "5px" }} >
                                    <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#A94438" }}>Total Load</p>
                                    <div className="d-flex align-items-center">
                                        <CountUp end={this.state.totalLoad} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-5 d-flex  mr-5p" style={{ background: "#fff", width: "17%", padding: "5px", borderRadius: "5px",border:'1px solid lightgrey' }}>
                                <div className="d-flex flex-column justify-content-between align-items-center pl-3p pr-3p" style={{ width: "100%", paddingLeft: "5px", paddingRight: "5px" }} >
                                    <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#A94438" }}>Serviceability</p>
                                    <div className="d-flex align-items-center">
                                        <CountUp end={this.state.totalServiceability} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-5 d-flex mr-5p" style={{ background: "#fff", width: "17%", padding: "5px", borderRadius: "5px",border:'1px solid lightgrey' }}>
                                <div className="d-flex flex-column justify-content-between align-items-center pl-3p pr-3p" style={{ width: "100%", paddingLeft: "5px", paddingRight: "5px" }} >
                                    <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#A94438" }}>IN416</p>
                                    <div className="d-flex align-items-center">
                                        <CountUp end={this.state.type1} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                    </div>
                                </div>
                            </div>


                            <div className="col-sm-5 d-flex mr-5p" style={{ background: "#fff", width: "17%", padding: "5px", borderRadius: "5px",border:'1px solid lightgrey' }}>
                                <div className="d-flex flex-column justify-content-between align-items-center pl-3p pr-3p" style={{ width: "100%", paddingLeft: "5px", paddingRight: "5px" }} >
                                    <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#A94438" }}>IN436</p>
                                    <div className="d-flex align-items-center">
                                        <CountUp end={this.state.type2} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                    </div>
                                </div>
                            </div>
                        
                            </div>
                            {/* <div className='col-sm-12 justify-content-center align-items-center pt-10p'>
                            
                            <h5  style={{color:'#A94438'}}>Total Load  : <span style={{ fontWeight: "bold", color: "#000", fontSize: "23px"}}>{this.state.percenttotalLoad}</span></h5>
                            <h5 style={{color:'#A94438'}}>Total IN416 Vehicles  : <span style={{ fontWeight: "bold", color: "#000", fontSize: "23px"}}>{this.state.percenttotalVeh416}</span></h5>
                            <h5 style={{color:'#A94438'}}>Total IN436 Vehicles  : <span style={{ fontWeight: "bold", color: "#000", fontSize: "23px"}}>{this.state.percenttotalVeh436}</span></h5>
                            <h5 style={{color:'#A94438'}}>Serviceability : <span style={{ fontWeight: "bold", color: "#000", fontSize: "23px"}}>{this.state.percenttotalServiceability}</span></h5>
                            </div> */}
                            
                            <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#A94438" }}>User Recommended</p>
                            
                              
                            <div className="col row justify-content-center align-items-center mb-20p">

                            <div className='col-sm-1' style={{backgroundColor:'white',maxWidth:'3%',position:'absolute',marginRight:'575px'}}>
                            <span className='fbold' style={{ transform: 'rotate(-90deg)',position:'absolute',marginLeft:'65px',marginTop:'-85px'}}>{this.state.slidervalue}</span>
                            <Form.Range
                                min={20}
                                max={100}
                                value={this.state.slidervalue}
                                onChange={this.handleSliderChange}
                                style={{ transform: 'rotate(-90deg)', paddingLeft:'60px' }}
                            />
                            
                            <span className='fbold' style={{ position:'absolute',marginLeft:'50px',marginTop:'60px',transform: 'rotate(270deg)'}}>Threshold</span>
                            </div>
                            <div className="col-sm-5 d-flex  mr-5p" style={{ background: "#fff", width: "17%", padding: "5px", borderRadius: "5px",border:'1px solid lightgrey' }}>
                                <div className="d-flex flex-column justify-content-between align-items-center pl-3p pr-3p" style={{ width: "100%", paddingLeft: "5px", paddingRight: "5px" }} >
                                    <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#A94438" }}>Total Load</p>
                                    <div className="d-flex align-items-center">
                                        <CountUp end={this.state.percenttotalLoad} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-5 d-flex mr-5p" style={{ background: "#fff", width: "17%", padding: "5px", borderRadius: "5px",border:'1px solid lightgrey' }}>
                                <div className="d-flex flex-column justify-content-between align-items-center pl-3p pr-3p" style={{ width: "100%", paddingLeft: "5px", paddingRight: "5px" }} >
                                    <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#A94438" }}>Serviceability</p>
                                    <div className="d-flex align-items-center">
                                        <CountUp end={this.state.percenttotalServiceability} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-5 d-flex mr-5p" style={{ background: "#fff", width: "17%", padding: "5px", borderRadius: "5px",border:'1px solid lightgrey' }}>
                                <div className="d-flex flex-column justify-content-between align-items-center pl-3p pr-3p" style={{ width: "100%", paddingLeft: "5px", paddingRight: "5px" }} >
                                    <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#A94438" }}>IN416</p>
                                    <div className="d-flex align-items-center">
                                        <CountUp end={this.state.percenttotalVeh416} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                    </div>
                                </div>
                            </div>


                            <div className="col-sm-5 d-flex mr-5p" style={{ background: "#fff", width: "17%", padding: "5px", borderRadius: "5px",border:'1px solid lightgrey' }}>
                                <div className="d-flex flex-column justify-content-between align-items-center pl-3p pr-3p" style={{ width: "100%", paddingLeft: "5px", paddingRight: "5px" }} >
                                    <p style={{ fontSize: "17px", margin: "0px", fontWeight: "bold", color: "#A94438" }}>IN436</p>
                                    <div className="d-flex align-items-center">
                                        <CountUp end={this.state.percenttotalVeh436} duration={2.5} style={{ fontWeight: "bold", color: "#000", fontSize: "23px", marginTop: "10px" }} />
                                    </div>
                                </div>
                            </div>

                            
                        
                            </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-sm-12 d-flex flex-column flex-wrap mt-10p ml-10p" style={{ maxHeight:"550px" }}>
                    {(this.state.summaryData.length > 0 && Object.keys(this.state.summaryData[0]).length > 0) && (Object.keys(this.state.summaryData[0].kpi_summary).map((transaction, index) => (
                                <tr key={index} style={{ color: "#000", width:"48%", borderRadius:'4px', background:index / 4 <= 1 ? index %2 == 0 ? '#e3e8e9' : '#fff' : index %2 ==0 ? '#fff' : '#e3e8e9'}}>
                                    <React.Fragment key={transaction}>
                                        <td style={{ border: '1px solid #ddd',borderRight:'0px', padding: '8px', textAlign: 'center', fontWeight: "bold", width:"18%"}}>{this.formatSpecificationName(transaction)}</td>
                                        {this.state.summaryData.map((each, dataIndex) => (
                                           
                                            <td key={dataIndex} style={{
                                                // background: Object.keys(each).length > 0 ? colors[dataIndex] : "",
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                                border: '1px solid #ddd',
                                                width:"28%",
                                                borderLeft:"0px"
                                            }}
                                            >
                                                {each.kpi_summary && this.renderSubTable(each.kpi_summary[transaction])}
                                                {(each.kpi_summary && Object.keys(each.kpi_summary[transaction]).length == 0) && <td style={{ textAlign: "center", display: "flex", justifyContent: "center", marginBottom: "8px" }}>{each.kpi_summary[transaction]}</td>}
                                            </td>
                                        ))}
                                    </React.Fragment>
                                </tr>
                            )))}
                            </div>
                </div>
            </div>
            </>
        )
    }
}

function getMonthsInRange(startMonth, endMonth) {
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    
    const startIndex = months.indexOf(startMonth);
    const endIndex = months.indexOf(endMonth);
    
    if (startIndex === -1 || endIndex === -1 || startIndex > endIndex) {
        return('Invalid start month is after end month');
    }
    
    return months.slice(startIndex, endIndex + 1);
}


function getchartDatainArr(data){
    const monthOrder = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthTotals = {
      January: { qty: 0, wt: 0,local:0,upcountry:0,IN416:0,IN436:0 },
      February: { qty: 0, wt: 0,local:0,upcountry:0,IN416:0,IN436:0 },
      March: { qty: 0, wt: 0,local:0,upcountry:0 ,IN416:0,IN436:0},
      April: { qty: 0, wt: 0,local:0,upcountry:0 ,IN416:0,IN436:0},
      May: { qty: 0, wt: 0,local:0,upcountry:0 ,IN416:0,IN436:0},
      June: { qty: 0, wt: 0,local:0,upcountry:0 ,IN416:0,IN436:0},
      July: { qty: 0, wt: 0 ,local:0,upcountry:0,IN416:0,IN436:0},
      August: { qty: 0, wt: 0,local:0,upcountry:0 ,IN416:0,IN436:0},
      September: { qty: 0, wt: 0 ,local:0,upcountry:0,IN416:0,IN436:0},
      October: { qty: 0, wt: 0 ,local:0,upcountry:0,IN416:0,IN436:0},
      November: { qty: 0, wt: 0 ,local:0,upcountry:0,IN416:0,IN436:0},
      December: { qty: 0, wt: 0 ,local:0,upcountry:0,IN416:0,IN436:0},
  };
  
  data.forEach((entry) => {
    const month = entry.transaction_id.split('-')[0];
    if (monthTotals[month]) {
        monthTotals[month].qty += entry.kpi_summary.planned.quantity + entry.kpi_summary.unplanned.quantity;
        // monthTotals[month].wt += entry.kpi_summary.plan.wt + entry.kpi_summary.unplan.wt;
        monthTotals[month].local += entry.kpi_summary.percent_ontime_delivery.local ;
        monthTotals[month].upcountry += entry.kpi_summary.percent_ontime_delivery.upcountry ;
        monthTotals[month]["IN416"] += entry["IN416"] ;
        monthTotals[month]["IN436"] += entry["IN436"] ;
    }
  });
  
  const totalLoads = monthOrder.map((month) => ({
    month,
    totalQty: monthTotals[month].qty,
    totalLocal: monthTotals[month].local,
    totalUpcountry: monthTotals[month].upcountry,
    IN416:monthTotals[month]["IN416"],
    IN436:monthTotals[month]["IN436"]
    // totalWt: monthTotals[month].wt,
  }));
  return totalLoads;

  }

  const normalize = (data) => {
    const max = Math.max(...data);
    const min = 0;
    if (max === min) {
        return data.map(() => 0);
    }
    return data.map(val => parseFloat(((val - min) / (max - min)).toFixed(2))*100);
};

const sumTheData = (data) => {
    return data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

const averageOfData = (data) => {
    if (data.length === 0) return 0; // Avoid division by zero
    const total = data.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return (total / data.length).toFixed(2);
}