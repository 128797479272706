import React, { Component } from "react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import Constant from "../common/constants";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import redirectURL from "../redirectURL";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import GridButton from "./gridButton";
import RepeatIcon from "./repeatIcon";
import AssignVehicleBtn from "./assignvehiclebtn";
import getToolConfiguration from "./commonFunctions";
import { getHyphenDDMMMYYYYHHMM } from "../common/utils";
import ColumnChart from "./columnChart";
import PieChart from "./pieChart";
import LoadSummaryDrawmap from "./loadSummaryDrawmap.js";
import DrawMapDisptach from "./drawmapDispatch";
import DrawMapDisptachPallet from "./drawmapPalletDispatch";
import * as XLSX from "xlsx";
import NAButton from "./naButton";
import RouteButton from "./routeBtn";
import UpdateVehicleBtn from "./updateVehicleBtn";
import RevokeVehicleBtn from "./RevokeVehicleBtn";
import ConfigSummary from "./configInSummary.js";
import { FaRegCalendarCheck } from "react-icons/fa6";
import { FaRegCalendarTimes } from "react-icons/fa";
import { MdProductionQuantityLimits } from "react-icons/md";
import { FaUserClock } from "react-icons/fa6";
import { FaUserTimes } from "react-icons/fa";
// import { FaClock } from "react-icons/fa";
// import { RiSettings2Line } from "react-icons/ri";
import WarningButton from "./warning-btn.js";
import HyphenButton from "./hyphenButton.js";
import GridCheckbox from "./gridCheckbox";
import UploadData from "./reuseableComponents/uploadComponent.js";
// import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import DeleteBtn from "./deletebtn.js";
import "./viewdispatchplandetails.css";
import EmailBtn from "./email-btn.js";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faClock } from '@fortawesome/free-solid-svg-icons';
// import { faUser } from '@fortawesome/free-solid-svg-icons';
// import { faWeightHanging } from '@fortawesome/free-solid-svg-icons';
// import { faCube } from '@fortawesome/free-solid-svg-icons';
// import { faPercentage } from '@fortawesome/free-solid-svg-icons';
// import { faRoad } from '@fortawesome/free-solid-svg-icons';

// import Counters from "consignmenttruckscountersComponent";
var map;
var moment = require("moment");

export default class ViewDispatchPlanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 70,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        GridButton: GridButton,
        RepeatIcon: RepeatIcon,
        AssignVehicleBtn: AssignVehicleBtn,
        NAButton: NAButton,
        RouteButton: RouteButton,
        UpdateVehicleBtn: UpdateVehicleBtn,
        RevokeVehicleBtn: RevokeVehicleBtn,
        WarningButton: WarningButton,
        HyphenButton: HyphenButton,
        GridCheckbox: GridCheckbox,
        DeleteBtn: DeleteBtn,
        EmailBtn: EmailBtn,
      },
      rowData: [],
      rowSelection: "multiple",
      revokedBack: false,
      updatedPlan: false,
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 100,
      rowClassRules: {
        //"highlitRow": "data.transit_delay == 1",
        //"green":"data.status >= 3"
      },
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      openVehicleAvailabilityModel: false,
      openFreightConfigurationModel: false,
      show: false,
      basicTitle: "",
      openPickupDropLocationsModel: false,
      basicType: "default",
      showAvailablityTable: false,
      data_type: 2,
      location: "",
      vehicle_type: { label: "Select Vehicle Type", value: "" },
      state_name: { label: "Select State", value: "" },
      city: "",
      fixed_vehicle_cost: "",
      driver_cost: "",
      maintenance_cost: "",
      per_cmt_cost: "",
      per_km_cost: "",
      rowId: "",
      locationNames: [],
      vehicle_types: [],
      state_names: [],
      transactionDetails: [],
      outputDetails: [],
      inputDetails: [],
      config: "",
      plannedTrucks: "",
      unPlannedTrucks: "",
      loadsDropped: "",
      inputInfo: "",
      planSummary: "",
      configData: "show-n",
      plannedTrucksData: "show-n",
      unPlannedTrucksData: "show-n",
      loadsDroppedData: "show-n",
      inputInfoData: "show-n",
      planSummaryData: "show-n",
      vehicle_type_config: [],
      material_configuration: [],
      freight_configuration: [],
      vehicle_availability_config: [],
      plannedData: [],
      unPlannedData: [],
      loadDroppedDetails: [],
      planningColumnChart: [],
      planningPieChart: [],
      summary: "activet",
      summaryData: "show-m",
      total_demand_cmt: 0,
      total_dropped_loads: 0,
      tool_config: "",
      overlayBlock: "show-n",
      showSlideBlockUpload: "sidebarcls",
      showSlideMap: "sidebarcls",
      showSlideMapLocations: "",
      sliderTitle: "",
      loadingLocation: [],
      mapType: 1,
      latLngArray: [],
      orderId: "",
      transactionId: "",
      trip_gps_data: [],
      plant_loc_data: [],
      trip_nodes_data: [],
      viewcount: 0,
      opti_type: "",
      run_type: "",
      palletLoadLocations: [],
      sla: 1,
      order: 0,
      quantity: 0,
      weight: 0,
      demandedVolume: 0,
      quantityError: "",
      weightError: "",
      demandedVolumeError: "",
      planningWeightColumnChart: [],
      total_demand_weight: 0,
      chartloader1: "show-m",
      chartloader2: "show-m",
      chartloader3: "show-m",
      chartloader4: "show-m",
      showAdditionalPlannedVehicles: 1,
      locationNameInHeader: "",
      allCustomersList: [],
      allOrdersList: [],
      filterCustomer: { value: "", label: "All" },
      filterOrder: { value: "", label: "All" },
      filterPlannedTucksData: 0,
      showSlideAssignVehicle: "",
      assignvehicle: { value: "", label: "Select" },
      vehicleLists: [],
      showAssignFields: "show-n",
      assignrowdata: [],
      tripinfo: [],
      assignVehicleloader: "show-n",
      planningQuantityColumnChart: [],
      selectedTrucks: "show-n",
      vehiclesSelectedData: [],
      selectedVehicle: "show-n",
      activeVehicleList: [],
      openReassignVehicleModel: false,
      shipmentIds: [],
      selectedShipmentId: "",
      forceClose_reason: "gps_issue",
      forceCloseVehicle: "",
      openUpdateVehicleModel: false,
      oldVehicleNO: "",
      oldVehTripNo: "",
      updateVehicleNo: false,
      assign_vehicle_type: "",
      assign_vehicle_list: [],
      total_customers: 0,
      plan_created_date: 0,
      total_tansit_time: 0,
      total_distance: 0,
      total_round_distance: 0,
      VFR: 0,
      adherence: 0,
      total_orders: 0,
      planned_orders: 0,
      loadshow: "show-n",
      is_reload: "",
      filteredData: [],
      allPlanSummaryData: [],
      transactionInputDetails: [],
      minVFR: 0,
      mapFor: "",
      total_planned_quantity: 0,
      total_given_quantity: 0,
      ontimedispatchPercentage: 0,
      ontimedeliveryPercentage: 0,
      openSavedConfigs: false,
      configs: [],
      showExecutedTime: false,
      showPlanDetails: false,
      showConfigButtonTooltip: false,
      createdDate: "",
      simulation_time: "",
      regular_vehicles: 1,
      additional_vehicles: 1,
      plantLocation: {},
      vehicleLocation: {},
      showVehicleMap: "sidebarcls",
      openEditVehiclePlanModel: false,
      showSlideEditVehiclePlan: "",
      vehicleTypeList: [],
      dispatchPlanObdUpdateData: [],
      newObdVehicleType: { label: "Select", value: "" },
      showNewPlan: false,
      editplanvfr: 0,
      editplanmaxdispatch: "",
      editplanqty: 0,
      editplanvehicle_volume: 0,
      editplanvehicle_weight: 0,
      editplanmaxvehiclevolume: 0,
      editplanmaxvehicleweight: 0,
      editplanloader: "show-n",
      editplantintervalid: "",
      editplanttripno: 0,
      editplanuniqueid: 0,
      editplantransit_type: "",
      drop_node_details: [],
      showDeleteSlider: false,
      deletePlanParams: "",
      is_sunday_planning_enabled: 0,
      consolidatedTrnsxId: "",
      orderNumbersList: [],
      maxStartTime: "",
      remark: { value: "", label: "Select" },
      subReason: "",
      deliveryDate: "",
      dealerAvblEnd: "",
      dealerAvblStart: "",
      dealerAvblEndErr: "",
      dealerAvblStartErr: "",
      invoicingDate: "",
      vehicleProductType: "",
      vehicle_product_type: "",
    };
    this.handleQuantityBlur = this.handleQuantityBlur.bind(this);
    this.handleWeightBlur = this.handleWeightBlur.bind(this);
    this.handleDemandedVolumeBlur = this.handleDemandedVolumeBlur.bind(this);
  }

  async componentDidMount() {
    loadScript(
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDxzGDgeuoSs15Y8z-6EFGt2a2QjjPHF3c&libraries=places,drawing&callback=initMapRoutes"
    );
    try {
      window.initMapRoutes = this.initMapRoutes;
      var curl = window.location.search;
      var ul = curl.split("?");
      let url_type = ul[parseInt(ul.length) - 1];
      var transactionId = url_type;
      let orderId;
      if (
        transactionId !== undefined &&
        transactionId !== "undefined" &&
        transactionId !== ""
      ) {
        transactionId = window.atob(transactionId);
        let order = transactionId.split("-");
        orderId = order[0];
      }
      await this.setState({ transactionId: transactionId, orderId: orderId });
      // let params = {
      //   transactionId: transactionId,
      // };
      await this.getChartsData(transactionId, orderId);
      this.onLoadGetConfigData(orderId);
      await this.getPlanDetails();
      await this.getPlanOutputDetails();
    } catch (error) {}
    await this.getDetailsOfFilteredData();
    this.enablesundayplanning();
    await this.getPlanInputDetails();
  }

  componentWillUnmount() {
    clearInterval(this.state.editplantintervalid);
  }

  onClickBack = () => {
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Summary Dashboard",
      activity: "clicked on Back Button",
      event: "Navigated to Summary Dashboard",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  enablesundayplanning = () => {
    const currentDate = new Date(this.state.editplanmaxdispatch);

    const currentDay = currentDate.getDay();

    const currentHour = currentDate.getHours();

    const enableCheckbox =
      (currentDay === 6 && currentHour >= 16) ||
      (currentDay === 0 && currentHour < 16);

    if (enableCheckbox) {
      this.setState({
        enable_sunday_planning: true,
      });
    } else {
      this.setState({
        enable_sunday_planning: false,
        is_sunday_planning_enabled: 0,
      });
    }
  };

  getVehiclesData(params) {
    redirectURL
      .post("/master/getMasterAvailabilityVehiclesData", params)
      .then(async (response) => {
        if (response.data.status === "success") {
          var vehiclesData = response.data.masterVehicleAvailabilityData;
          vehiclesData = vehiclesData.filter(
            (item) => item.vehicle_breakdown !== 1
          );
          // console.log(vehiclesData)
          var vehicleLists = [];
          var filteredVehicles = [];
          let vehicleTypeList = [];
          filteredVehicles = vehiclesData;
          if (filteredVehicles.length > 0) {
            vehicleTypeList = [
              ...new Set(filteredVehicles.map((obj) => obj.vehicle_type)),
            ].map((vehicle_type) => ({
              label: vehicle_type,
              value: vehicle_type,
            }));
            filteredVehicles.map((item) => {
              vehicleLists.push({
                value: item.vehicle_no,
                label: item.vehicle_no,
                type: item.vehicle_type,
                vehicle_product_type: item.vehicle_product_type,
              });
              return true;
            });
          }

          // var vehicleLists = [];
          // var filteredVehicles = [];
          // redirectURL
          //   .post("/master/getActiveTripVehicles", params)
          //   .then(async (response) => {
          //     if (response.data.status === "success") {
          //       if (response.data.activeTripVehicles.length > 0) {
          //         // console.log(response.data.activeTripVehicles, "not not");
          //         filteredVehicles = vehiclesData.filter(
          //           (itm) =>
          //             !response.data.activeTripVehicles.some(
          //               (data) => data.truck_no == itm.vehicle_no
          //             )
          //         );
          //       } else {
          //         filteredVehicles = vehiclesData;
          //       }

          //       if (filteredVehicles.length > 0) {
          //         filteredVehicles.map((item) => {
          //           vehicleLists.push({
          //             value: item.vehicle_no,
          //             label: item.vehicle_no,
          //             type: item.vehicle_type,
          //           });
          //         });
          //       }
          //       // console.log(vehicleLists)
          vehicleLists.push({
            value: "0",
            label: "Other",
            type: "others",
            vehicle_product_type: "others",
          });
          await this.setState({
            vehicleLists: vehicleLists,
            vehicleTypeList: vehicleTypeList,
          });
          // console.log(vehicleLists)
          //     }
          //   });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "/master/getMasterAvailabilityVehiclesData",
          error,
          screen: "Summary Dashboard",
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  }

  getAssignVehiclesNearPlant = (p) => {
    let location = p.location_code;
    let vehicleLists = [];

    redirectURL
      .post("/dispatch/getAssignVehiclesNearPlant")
      .then((response) => {
        if (response.data) {
          if (response.data.truck_data_for_dispatch_plan != undefined) {
            let data = JSON.parse(response.data.truck_data_for_dispatch_plan);
            if (data.length > 0) {
              let filterData = data.filter((itm) => itm.plant_code == location);
              // console.log(filterData, "dwww");
              // this.setState({assignableVehicles:filterData})
              if (filterData.length > 0) {
                filterData.map((item) => {
                  vehicleLists.push({
                    value: item.truck_no,
                    label: item.truck_no,
                    type: item.vehicle_type,
                  });
                });
              }
              vehicleLists.push({ value: "0", label: "Other", type: "others" });
              // console.log(vehicleLists)
              this.setState({
                vehicleLists: vehicleLists,
              });

              redirectURL
                .post("/master/getActiveTripVehicles", p)
                .then(async (response) => {
                  if (response.data.status === "success") {
                    let activeVehicleList = [];
                    let shipmentIds = [];
                    if (response.data.activeTripVehicles.length > 0) {
                      response.data.activeTripVehicles.map((itm) => {
                        activeVehicleList.push(itm.truck_no);
                        shipmentIds.push(itm);
                      });
                      this.setState({ activeVehicleList, shipmentIds });
                    }
                  }
                })
                .catch(function (error) {
                  var errDetails = {
                    url: "/master/getActiveTripVehicles",
                    error,
                    screen: "Summary Dashboard",
                  };
                  redirectURL.post("/master/logErrorsForApiCalls", errDetails);
                });
            }
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "/dispatch/getAssignVehiclesNearPlant",
          error,
          screen: "Summary Dashboard",
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  getChartsData = (transactionId, orderId) => {
    var transactionId = transactionId;
    let qry = JSON.parse(localStorage.getItem("qry"));
    let location = this.state.location;
    let plant_location_code = qry.plant_location_code;
    let dispatch_planning_type = qry.dispatch_planning_type;
    if (Object.keys(qry).length === 0) {
      plant_location_code = location.value;
      dispatch_planning_type = 2;
    }
    if (this.state.orderId != undefined && this.state.orderId != "") {
      let plant = this.state.orderId.split("_");
      plant = plant[0];
      // console.log(plant,'plant')
      plant_location_code = plant;
    }
    // console.log("qry ",qry)
    this.setState({
      planningColumnChart: [],
      planningModeChart: [],
      chartloader: "show-m",
      chartloader1: "show-m",
      chartloader2: "show-m",
      chartloader3: "show-m",
      chartloader4: "show-m",
    });
    try {
      var reqparams = new FormData();
      reqparams.append("transaction_id", transactionId);
      reqparams.append("order_id", orderId);
      reqparams.append("plant_location_code", plant_location_code);
      reqparams.append("dispatch_planning_type", dispatch_planning_type);

      // redirectURL.post("http://216.48.191.229:8007/dispatch_planning/transactionLevelChartsData",reqparams)

      redirectURL
        .post("dispatch/dispatchplandetails", reqparams)
        .then(async (response) => {
          // console.log("apiResponse ", response.data)
          let planData = JSON.parse(response.data.planning_chart_data);
          let planWeightData = JSON.parse(
            response.data.planning_chart_weight_data
          );
          let planQuantityData = JSON.parse(
            response.data.planning_chart_quantity_data
          );
          // let dropData = JSON.parse(response.data.dropped_reason_chart_data)
          // let total_dropped_loads = dropData[0].total_dropped_loads;
          let total_demand_cmt = planData[0].total_demand_volume;
          let total_demand_weight = planWeightData[0].total_demand_weight;
          let total_quantity_data =
            planQuantityData[0].planning_chart_quantity_data;
          let planningColumnChart = [
            {
              name: "Dispatch Load Volume Summary (M3)",
              colorByPoint: true,
              data: [
                {
                  name: "Total Loads Volume (M3)",
                  y: planData[0].total_demand_volume,
                },
                {
                  name: "Planned Loads Volume (M3)",
                  y: planData[0].planned_demand_volume,
                },
                {
                  name: "Unplanned Loads Volume (M3)",
                  y: planData[0].unplanned_demand_volume,
                },
                {
                  name: "Dropped Loads Volume (M3)",
                  y: planData[0].dropped_demand_volume,
                },
              ],
            },
          ];

          let planningWeightColumnChart = [
            {
              name: "Dispatch Load Weight Summary (Kgs)",
              colorByPoint: true,
              data: [
                {
                  name: "Total Loads Weight (Kgs)",
                  y: planWeightData[0].total_demand_weight,
                },
                {
                  name: "Planned Loads Weight (Kgs)",
                  y: planWeightData[0].planned_demand_weight,
                },
                {
                  name: "Unplanned Loads Weight (Kgs)",
                  y: planWeightData[0].unplanned_demand_weight,
                },
                {
                  name: "Dropped Loads Weight (Kgs)",
                  y: planWeightData[0].dropped_demand_weight,
                },
              ],
            },
          ];

          let planningQuantityColumnChart = [
            {
              name: "Dispatch Load Quantity Summary",
              colorByPoint: true,
              data: [
                {
                  name: "Total Quantity",
                  y: planQuantityData[0].total_quantity,
                },
                {
                  name: "Planned Quantity",
                  y: planQuantityData[0].planned_quantity,
                },
                {
                  name: "Unplanned Quantity",
                  y: planQuantityData[0].unplanned_quantity,
                },
                {
                  name: "Dropped Quantity",
                  y: planQuantityData[0].dropped_quantity,
                },
              ],
            },
          ];
          //    let planningPieChart = [{
          //     name:"Dropped",
          //     colorByPoint: true,
          //     data :  [
          //         {
          //          name:"Total Excluded Passengers",
          //          y: (dropData[0].total_dropped_loads)
          //         },{
          //          name:"Invalid Locations",
          //          y: (dropData[0].invalid_locations)
          //         },
          //     //     {
          //     //     name:"Invalid Materials",
          //     //     y: (dropData[0].invalid_materials)
          //     // }
          // ]
          //    }]

          await this.setState(
            {
              planningColumnChart: planningColumnChart,
              planningWeightColumnChart: planningWeightColumnChart,
              planningQuantityColumnChart: planningQuantityColumnChart,
              // planningPieChart:planningPieChart,
              // total_dropped_loads:total_dropped_loads,
              total_demand_cmt: total_demand_cmt,
              total_demand_weight: total_demand_weight,
              total_quantity_data: total_quantity_data,
              total_planned_quantity: planQuantityData[0].planned_quantity,
              total_given_quantity: planQuantityData[0].total_quantity,
            },
            async () => {
              await setTimeout(() => {
                this.setState(
                  {
                    chartloader1: "show-n",
                  },
                  () => {
                    setTimeout(() => {
                      this.setState(
                        {
                          chartloader2: "show-n",
                        },
                        () => {
                          setTimeout(() => {
                            this.setState(
                              {
                                chartloader3: "show-n",
                              },
                              () => {
                                setTimeout(() => {
                                  this.setState({
                                    chartloader4: "show-n",
                                  });
                                }, 500);
                              }
                            );
                          }, 400);
                        }
                      );
                    }, 300);
                  }
                );
              }, 2500);
            }
          );
        })
        .catch(function (error) {
          var errDetails = {
            url: "dispatch/dispatchplandetails",
            screen: "Summary Dashboard",
            error,
          };
          redirectURL.post("/master/logErrorsForApiCalls", errDetails);
        });
    } catch (e) {}
  };

  getPlanDetails = () => {
    let locationNameInHeader;
    let locationCode;
    var params = {
      transactionId: this.state.transactionId,
    };

    redirectURL
      .post("dispatch/getTransactionDetails", params)
      .then(async (response) => {
        console.log(
          "response.data ",
          response.data[0].no_of_spot_vehicles_given
        );
        // this.setState({ plan_created_date: response.data.created_on });
        var minVFR = response.data[0].min_VFR;
        // console.log(minVFR,'MINVFR')
        if (minVFR === undefined) {
          minVFR = 0;
        }
        this.setState({ minVFR });
        var data_type = 2;

        if (response.data.length > 0) {
          try {
            data_type = response.data[0].data_type;
            // console.log(response.data,"response.data")
            var toolParams = {
              data_type: response.data[0].data_type,
            };
            await getToolConfiguration(toolParams);
            await this.boundTypeLocations(toolParams);
            var tool_config = "";
            if (
              response.data[0].tool_config !== undefined &&
              response.data[0].tool_config != "undefined" &&
              response.data[0].tool_config != ""
            ) {
              tool_config = response.data[0].tool_config;
            }
            var vehicle_type_config = [];
            if (
              response.data[0].vehicle_type_config != undefined &&
              response.data[0].vehicle_type_config != "undefined" &&
              response.data[0].vehicle_type_config != ""
            ) {
              if (typeof response.data[0].vehicle_type_config == "string") {
                vehicle_type_config = JSON.parse(
                  response.data[0].vehicle_type_config
                );
                locationNameInHeader = vehicle_type_config[0].location_name;
                locationCode = vehicle_type_config[0].location_code;
              } else {
                vehicle_type_config = response.data[0].vehicle_type_config;
                locationNameInHeader = vehicle_type_config[0].location_name;
                locationCode = vehicle_type_config[0].location_code;
              }
            }
            var material_configuration = [];
            if (
              response.data[0].material_configuration !== undefined &&
              response.data[0].material_configuration !== "undefined" &&
              response.data[0].material_configuration !== ""
            ) {
              if (typeof response.data[0].material_configuration == "string") {
                material_configuration = JSON.parse(
                  response.data[0].material_configuration
                );
              } else {
                material_configuration =
                  response.data[0].material_configuration;
              }
            }
            var freight_configuration = [];
            if (
              response.data[0].freight_configuration != undefined &&
              response.data[0].freight_configuration != "undefined" &&
              response.data[0].freight_configuration != ""
            ) {
              if (typeof response.data[0].freight_configuration == "string") {
                freight_configuration = JSON.parse(
                  response.data[0].freight_configuration
                );
              } else {
                freight_configuration = response.data[0].freight_configuration;
              }
            }
            var vehicle_availability_config = [];
            if (
              response.data[0].vehicle_availability_config != undefined &&
              response.data[0].vehicle_availability_config != "undefined" &&
              response.data[0].vehicle_availability_config != ""
            ) {
              if (
                typeof response.data[0].vehicle_availability_config == "string"
              ) {
                vehicle_availability_config = JSON.parse(
                  response.data[0].vehicle_availability_config
                );
              } else {
                vehicle_availability_config =
                  response.data[0].vehicle_availability_config;
              }
            }
            var opti_type = "";
            if (
              response.data[0].opti_type == 0 ||
              response.data[0].opti_type == "0"
            ) {
              opti_type = "(Optimize by Distance)";
            } else {
              opti_type = "(Optimize by Time)";
            }
            var run_type = "";
            if (
              response.data[0].run_with_transit == 1 ||
              response.data[0].run_with_transit == "1"
            ) {
              run_type = "(Transit Time With Multiple City Clubing)";
            }
            if (
              response.data[0].run_with_RDD == 1 ||
              response.data[0].run_with_RDD == "1"
            ) {
              run_type = "(RDD Preference)";
            }
            if (
              response.data[0].run_with_transit_cityclub == 1 ||
              response.data[0].run_with_transit_cityclub == "1"
            ) {
              run_type = "(Transit Time With Single City Clubing)";
            }
            if (
              response.data[0].days_of_runs != "" &&
              response.data[0].days_of_runs != undefined
            ) {
              var days_of_runs = response.data.days_of_runs;
            } else {
              var days_of_runs = 1;
            }

            var gpsVehiclesSelectedData = [];
            if (
              response.data[0].gpsVehiclesSelectedData !== undefined &&
              response.data[0].gpsVehiclesSelectedData !== "undefined" &&
              response.data[0].gpsVehiclesSelectedData !== ""
            ) {
              if (typeof response.data[0].gpsVehiclesSelectedData == "string") {
                gpsVehiclesSelectedData = JSON.parse(
                  response.data[0].gpsVehiclesSelectedData
                );
              }
            }
            var currentAvailableVehiclesData =
              response.data[0].currentAvailableVehicles;
            var no_of_spot_vehicles_givenData =
              response.data[0].no_of_spot_vehicles_given;
            // console.log(currentAvailableVehiclesData)
            // console.log(no_of_spot_vehicles_givenData)
            if (
              currentAvailableVehiclesData !== undefined &&
              currentAvailableVehiclesData !== "undefined" &&
              currentAvailableVehiclesData !== ""
            ) {
              if (typeof currentAvailableVehiclesData == "string") {
                currentAvailableVehiclesData = JSON.parse(
                  response.data[0].currentAvailableVehicles
                );
              }
              // currentAvailableVehiclesData.map((item)=>{
              //   item.vehicle_next_available_date=getDDMMMYYYYHHMMDefault(item.vehicle_next_available_date)

              //   })
            }
            if (
              response.data[0].no_of_spot_vehicles_given !== undefined &&
              response.data[0].no_of_spot_vehicles_given !== "undefined" &&
              response.data[0].no_of_spot_vehicles_given !== ""
            ) {
              if (
                typeof response.data[0].no_of_spot_vehicles_given == "string"
              ) {
                no_of_spot_vehicles_givenData = JSON.parse(
                  response.data[0].no_of_spot_vehicles_given
                );
              }
              no_of_spot_vehicles_givenData.map((item) => {
                item.vehicle_type = item.vehicle_type.label;
                // item.vehicle_next_available_date=getDDMMMYYYYHHMMDefault(item.available_date_time)
                item.vehicle_no = "spot";
                item.location_name = locationNameInHeader;
                item.location_code = this.state.transactionId.split("_")[0];
                item.max_distance_per_day = "N/A";
              });
            }
            let is_reload = "";
            if (response.data[0].select_reload == 1) {
              is_reload = "Reload Plan";
            }
            var vehiclesSelectedData = [];

            if (
              currentAvailableVehiclesData !== undefined &&
              currentAvailableVehiclesData !== "undefined" &&
              currentAvailableVehiclesData !== ""
            ) {
              vehiclesSelectedData = [...currentAvailableVehiclesData];
            }
            if (
              no_of_spot_vehicles_givenData !== undefined &&
              no_of_spot_vehicles_givenData !== "undefined" &&
              no_of_spot_vehicles_givenData !== ""
            ) {
              vehiclesSelectedData = [
                ...vehiclesSelectedData,
                ...no_of_spot_vehicles_givenData,
              ];
            }

            if (
              gpsVehiclesSelectedData.length > 0 &&
              currentAvailableVehiclesData.length === 0
            ) {
              vehiclesSelectedData = [
                ...gpsVehiclesSelectedData,
                ...vehiclesSelectedData,
              ];
            }
            var total_vehicles = response.data[0].total_vehicles;
            if (
              total_vehicles !== undefined &&
              total_vehicles !== "undefined" &&
              total_vehicles !== ""
            ) {
              if (typeof total_vehicles == "string") {
                total_vehicles = JSON.parse(response.data[0].total_vehicles);
                locationNameInHeader = total_vehicles[0].location_name;
                locationCode = total_vehicles[0].location_code;
              }
              // currentAvailableVehiclesData.map((item)=>{
              //   item.vehicle_next_available_date=getDDMMMYYYYHHMMDefault(item.vehicle_next_available_date)

              //   })
            } else {
              total_vehicles = vehiclesSelectedData;
            }
            // console.log(gpsVehiclesSelectedData,'hellorrr')
            // vehiclesSelectedData=[...vehiclesSelectedData,...gpsVehiclesSelectedData]
            // console.log(vehiclesSelectedData,'gpsVehiclesSelectedData',total_vehicles)
            var date = response.data[0].createDate;
            let createDate = getHyphenDDMMMYYYYHHMM(date);
            // var d = date.split("T")
            // var createDate = d[0]
            // var time = d[1].split(":").slice(0,2).join(":")
            // createDate +=(`  ${time}`)

            let drop_node_details;
            if (
              response.data[0].drop_node_details !== undefined &&
              response.data[0].drop_node_details !== "undefined" &&
              response.data[0].drop_node_details !== ""
            ) {
              drop_node_details = response.data[0].drop_node_details;
            }

            await this.setState({
              transactionDetails: response.data,
              createdDate: createDate,
              sla: days_of_runs,
              data_type: data_type,
              opti_type: opti_type,
              tool_config: tool_config,
              vehicle_type_config: vehicle_type_config,
              material_configuration: material_configuration,
              freight_configuration: freight_configuration,
              vehicle_availability_config: vehicle_availability_config,
              orderId: response.data[0].order_id,
              locationNameInHeader,
              run_type: run_type,
              vehiclesSelectedData: total_vehicles,
              is_reload: is_reload,
              location: { label: locationNameInHeader, value: locationCode },
              simulation_time: response.data[0].simulation_time,
              drop_node_details: drop_node_details,
            });
            var p = {
              data_type: response.data[0].data_type,
              location_code: response.data[0].location_code,
            };

            this.getVehiclesData(p);
            // this.getAssignVehiclesNearPlant(p);
          } catch (e) {}
        }
        await setTimeout(async () => {
          await this.getPlanInputDetails();
        }, 500);
      })
      .catch(function (error) {
        var errDetails = {
          url: "dispatch/getTransactionDetails",
          screen: "Summary Dashboard",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  getRowStyle = (params) => {
    if (
      params.data.veh_considered_for_planning &&
      params.data.veh_considered_for_planning.toLowerCase() === "yes"
    ) {
      return { backgroundColor: "#D0FFC6" };
    }
    if (params.data.duplicate_obd === 1) {
      return { backgroundColor: "#ffd0d0" };
    }
    return null;
  };
  boundTypeLocations = async (param) => {
    let locationNames = this.state.locationNames;

    await redirectURL
      .post("master/getBoundTypeLocations", param)
      .then(async (response) => {
        if (response.data.status == "success") {
          let boundLocations = response.data.boundLocations;
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              locationNames.push({
                value: item.location_code,
                label: item.location_name,
                latitude: item.latitude,
                longitude: item.longitude,
              });
            });
            await this.setState({
              locationNames: locationNames,
              location: locationNames[0],
            });
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getBoundTypeLocations",
          screen: "Summary Dashboard",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };
  updateOrderDetails = async (propdata) => {
    console.log("first data", propdata);
    let data = propdata.data;
    let logParams = {
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "View Dispatch Plan Details",
      activity: "clicked on Edit Button in the aggrid",
      event: "Edit pickup/Drop Location Modal Opened",
    };
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    await this.setState({
      order: data.order_number,
      quantity: parseFloat(data.quantity),
      weight: parseFloat(data.weight_tons),
      demandedVolume: parseFloat(data.demand_cmt),
      transactionId: data.transaction_id,
      deliveryDate: moment(data.delivery_date, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      ),
      dealerAvblEnd: data.dealer_available_end,
      dealerAvblStart: data.dealer_available_start,
    });
    // dealer_available_end,dealer_available_start
    var previousData = {
      order: data.order_number,
      quantity: parseFloat(data.quantity),
      weight: parseFloat(data.weight_tons),
      demandedVolume: parseFloat(data.demand_cmt),
      transactionId: data.transaction_id,
      dealerAvblEnd: data.dealer_available_end,
      dealerAvblStart: data.dealer_available_start,
    };
    await this.setState({
      previousData: previousData,
    });
    this.openSliderForOrderUpdate();
  };
  openSliderForOrderUpdate = () => {
    // this.setState(prevState =>({
    //     openPickupDropLocationsModel :!prevState.openPickupDropLocationsModel,
    //     modalType : 2
    // }))
    this.setState({
      showSlideForUpdateOrderDetails: "slide25",
      overlayBlock: "show-m",
    });
  };
  getPlanOutputDetails = () => {
    var params = {
      transactionId: this.state.transactionId,
    };

    redirectURL
      .post("dispatch/getTransactionOutputDetails", params)
      .then(async (response) => {
        var outputDetails = response.data;
        // console.log("first outputDetails", outputDetails)
        var consolidatedTrnsxId = outputDetails[0].consolidatedTrnsxId;
        this.setState({
          consolidatedTrnsxId,
        });
        var plannedData = [];
        var unPlannedData = [];
        var allCustomersList = [];
        var allOrdersList = [];
        if (outputDetails.length > 0) {
          var plannedIndividualData = await outputDetails.filter(
            (od) => od.is_planned === 1
          );
          if (plannedIndividualData.length > 0) {
            try {
              plannedIndividualData = plannedIndividualData.sort(
                GetSortAscOrder("trip_no")
              );
              var groupPlannedData = groupBy(
                plannedIndividualData,
                (rdata) => rdata.trip_no
              );
              // groupPlannedData1.forEach((plannedVehicleData1, key) =>{
              //     plannedVehicleData1 = plannedVehicleData1.sort(GetSortAscOrder("trip_no"));
              //     var groupPlannedData = groupBy(plannedVehicleData1, rdata => rdata.trip_no);
              // console.log("groupPlannedData ", groupPlannedData)
              groupPlannedData.forEach((plannedVehicleData, k) => {
                var demand_volume = 0;
                var demand_weight = 0;
                var demand_quantity = 0;
                var distance = 0;
                var triptime = 0;
                var transitTime = 0;
                var unLoadingTime = [];
                var loadingTime = [];
                var totaltires = 0;
                var latLngArray = [];
                var customers = [];
                plannedVehicleData.map((pvd) => {
                  // console.log('pve,',pvd)
                  allCustomersList.push({
                    customer_code: pvd.drop_node,
                    customer_name: pvd.drop_node_name,
                  });
                  allOrdersList.push({ orderNo: pvd.order_number });

                  demand_volume =
                    parseFloat(demand_volume) + parseFloat(pvd.demand_volume);
                  demand_weight =
                    parseFloat(demand_weight) + parseFloat(pvd.demand_weight);
                  demand_quantity =
                    parseInt(demand_quantity) + parseInt(pvd.quantity);
                  distance = pvd.distance;
                  triptime = pvd.drop_node_total_time_hrs;
                  // transitTime = pvd.drop_node_transit_time_hrs;
                  loadingTime.push(pvd.drop_node_loading_time_mins);
                  unLoadingTime.push(pvd.drop_node_unloading_time_mins);
                  latLngArray.push({
                    lat: pvd.demand_node_latitude,
                    lng: pvd.demand_node_longitude,
                    drop_node: pvd.drop_node,
                    vehicle_type: pvd.vehicle_type,
                    demand_volume: pvd.demand_volume,
                    demand_weight: pvd.demand_weight,
                  });
                  totaltires = parseInt(totaltires) + parseInt(pvd.no_of_tires);
                  customers.push({ customer: pvd.drop_node });
                  return true;
                });
                var customerArray = [];
                let totuniqueObject = {};
                var totobjTitle;
                loadingTime = addTimes(loadingTime);
                unLoadingTime = addTimes(unLoadingTime);
                // Loop for the array elements
                for (let i in customers) {
                  // Extract the title
                  if (
                    customers[i]["customer"] !== "" &&
                    customers[i]["customer"] !== undefined
                  ) {
                    totobjTitle = customers[i]["customer"];

                    // Use the title as the index
                    totuniqueObject[totobjTitle] = customers[i];
                  }
                }
                // Loop to push unique object into array
                for (var a in totuniqueObject) {
                  customerArray.push(totuniqueObject[a]);
                }
                // console.log("customerArray ", customerArray)
                plannedData.push({
                  vehicle_no: plannedVehicleData[0].vehicle_no,
                  cluster: plannedVehicleData[0].cluster,
                  zone: plannedVehicleData[0].zone,
                  pickup_node: plannedVehicleData[0].pickup_node,
                  drop_node: plannedVehicleData[0].drop_node,
                  total_demand: parseFloat(demand_volume).toFixed(2),
                  total_weight: parseFloat(demand_weight).toFixed(2),
                  total_quantity: parseFloat(demand_quantity).toFixed(2),
                  distance: distance,
                  triptime: triptime,
                  route_no: plannedVehicleData[0].route_no,
                  is_available: plannedVehicleData[0].is_available,
                  vehicle_type: plannedVehicleData[0].vehicle_type,
                  vehicle_start_time_mins:
                    plannedVehicleData[0].vehicle_start_time_mins,
                  vehicle_loading_start_time:
                    plannedVehicleData[0].vehicle_loading_start_time,
                  vehicle_volume: plannedVehicleData[0].vehicle_volume,
                  vehicle_priority: plannedVehicleData[0].vehicle_priority,
                  transporter_code: plannedVehicleData[0].transporter_code,
                  transporter_name: plannedVehicleData[0].transporter_name,
                  route_mode: plannedVehicleData[0].route_mode,
                  order_date: plannedVehicleData[0].order_date,
                  no_of_tires: totaltires,
                  no_of_drops: plannedVehicleData.length,
                  no_of_customer: customerArray.length,
                  legs_data: plannedVehicleData,
                  latLngArray: latLngArray,
                  trip_no: plannedVehicleData[0].trip_no,
                  vehicle_max_volume: plannedVehicleData[0].vehicle_max_volume,
                  vehicle_max_weight: plannedVehicleData[0].vehicle_max_weight,
                  order_number: plannedVehicleData[0].order_number,
                  invoicing_date: plannedVehicleData[0].invoicing_date,
                  quantity: plannedVehicleData[0].quantity,
                  round_trip_distance:
                    plannedVehicleData[0].round_trip_distance,
                  arrival_time_at_first_dealer:
                    plannedVehicleData[0].arrival_time_at_first_dealer,
                  last_dealer_time_to_complete:
                    plannedVehicleData[0].last_dealer_time_to_complete,
                  return_time_to_plant_from_last_dealer:
                    plannedVehicleData[0].return_time_to_plant_from_last_dealer,
                  round_trip_time: plannedVehicleData[0].round_trip_time,
                  total_drop_node_transit_time_hrs:
                    plannedVehicleData[0].total_drop_node_transit_time_hrs,
                  // transitTime: transitTime,
                  total_plan_time_hrs:
                    plannedVehicleData[0].total_plan_time_hrs,
                  // loadingTime:loadingTime,
                  // unLoadingTime:unLoadingTime
                  loadingTime: plannedVehicleData[0].total_loading_time,
                  unLoadingTime: plannedVehicleData[0].total_unloading_time,
                  route_finalized: plannedVehicleData[0].route_finalized,
                  max_start_time: plannedVehicleData[0].max_start_time,
                  cost: plannedVehicleData[0].cost,
                  is_vfr_violated: plannedVehicleData[0].is_vfr_violated,
                  vehicle_transit_type:
                    plannedVehicleData[0].vehicle_transit_type,
                  is_addremove_plan: plannedVehicleData[0].is_addremove_plan,
                  remark: plannedVehicleData[0].remark,
                  sub_reason: plannedVehicleData[0].sub_reason,
                  product_code: plannedVehicleData[0].product_code,
                  vehicle_product_type:
                    plannedVehicleData[0].vehicle_product_type,
                });
                // console.log(plannedVehicleData[0], "first planned data");

                var total_customers = 0;
                var total_turn_time = 0;
                var transit_time_min = 0;
                var transit_time_hrs = 0;
                var total_distance = 0;
                var total_round_distance = 0;
                var total_planned_orders = 0;
                var weight_utilization = 0,
                  volume_utilization = 0,
                  finalVfr = 0;

                plannedData.map((item) => {
                  total_planned_orders += item.no_of_drops;
                  total_customers += item.no_of_customer;
                  total_turn_time += item.round_trip_time;
                  var time_veh =
                    item.total_drop_node_transit_time_hrs.split(":");
                  transit_time_hrs += parseInt(time_veh[0]);
                  transit_time_min += parseInt(time_veh[1]);
                  total_distance += item.distance;
                  total_round_distance += parseFloat(item.round_trip_distance);
                  weight_utilization = parseFloat(
                    (parseFloat(item.total_weight) /
                      parseFloat(item.vehicle_max_weight)) *
                      100
                  );
                  volume_utilization = parseFloat(
                    (parseFloat(item.total_demand) /
                      parseFloat(item.vehicle_max_volume)) *
                      100
                  );
                  if (weight_utilization > volume_utilization) {
                    finalVfr += weight_utilization;
                  } else {
                    finalVfr += volume_utilization;
                  }
                });

                if (transit_time_min > 60) {
                  transit_time_hrs += parseInt(transit_time_min / 60);
                  transit_time_min -= parseInt(transit_time_min / 60) * 60;
                }

                var planned_data_len = plannedData.length;
                var VFR = (finalVfr / planned_data_len).toFixed(2);
                if (transit_time_hrs < 10) {
                  transit_time_hrs = "0" + transit_time_hrs;
                }
                if (transit_time_min < 10) {
                  transit_time_min = "0" + transit_time_min;
                }
                this.setState({
                  total_tansit_time: transit_time_hrs + ":" + transit_time_min,
                  total_distance: total_distance,
                  total_round_distance: parseInt(total_round_distance),
                  VFR,
                  planned_orders: total_planned_orders,
                  total_customers,
                  // adherence:((total_planned_orders/total_orders)*100).toFixed(2)
                });
              });
              // });
            } catch (error) {
              // console.log(error, "errer");
            }
          }
          unPlannedData = await outputDetails.filter(
            (od) => od.is_planned != 1
          );

          var customerlistsdata = [{ value: "", label: "All" }];
          if (allCustomersList.length > 0) {
            var customernewArray = [];

            let totuniqueObject = {};
            var totobjTitle;

            for (let i in allCustomersList) {
              if (
                allCustomersList[i]["customer_code"] !== "" &&
                allCustomersList[i]["customer_code"] !== undefined
              ) {
                totobjTitle = allCustomersList[i]["customer_code"];
                totuniqueObject[totobjTitle] = allCustomersList[i];
              }
            }
            for (var a in totuniqueObject) {
              customernewArray.push(totuniqueObject[a]);
            }
            if (customernewArray.length > 0) {
              customernewArray.map((item) => {
                customerlistsdata.push({
                  value: item.customer_code,
                  label: item.customer_code + " - " + item.customer_name,
                });
              });
            }
          }

          var orderlistsdata = [{ value: "", label: "All" }];
          if (allOrdersList.length > 0) {
            var ordernewArray = [];

            let totuniqueObject = {};
            var totobjTitle;

            for (let i in allOrdersList) {
              if (
                allOrdersList[i]["orderNo"] !== "" &&
                allOrdersList[i]["orderNo"] !== undefined
              ) {
                totobjTitle = allOrdersList[i]["orderNo"];
                totuniqueObject[totobjTitle] = allOrdersList[i];
              }
            }
            for (var a in totuniqueObject) {
              ordernewArray.push(totuniqueObject[a]);
            }
            if (ordernewArray.length > 0) {
              ordernewArray.map((item) => {
                orderlistsdata.push({
                  value: item.orderNo,
                  label: item.orderNo,
                });
              });
            }
          }
        }

        this.setState(
          {
            outputDetails: response.data,
            plannedData: plannedData,
            unPlannedData: unPlannedData,
            allCustomersList: customerlistsdata,
            allOrdersList: orderlistsdata,
            filteredData: plannedData,
          }
          // ,()=>{
          //   console.log(this.state.filteredData,"filteredData")
          // }
        );
        var onTimeDispatchCount = 0;
        var onTimeDeliveryCount = 0;

        var ontimedeliveryPercentage;
        var ontimedispatchPercentage;

        if (response.data[0].ontime_delivery === undefined) {
          this.setState({ ontimedeliveryPercentage: "N/A" });
          this.setState({ ontimedispatchPercentage: "N/A" });
        } else if (response.data[0].ontime_dispatch !== undefined) {
          response.data.map((each) => {
            if (each.ontime_dispatch === 1) {
              onTimeDispatchCount++;
            }
            if (each.ontime_delivery === 1) {
              onTimeDeliveryCount++;
            }
          });
          ontimedispatchPercentage = (
            (onTimeDispatchCount / response.data.length) *
            100
          ).toFixed(2);
          ontimedeliveryPercentage = (
            (onTimeDeliveryCount / response.data.length) *
            100
          ).toFixed(2);

          this.setState({ ontimedispatchPercentage, ontimedeliveryPercentage });
        }
        // console.log(onTimeDeliveryCount, "onTimeDeliveryCount")
        // console.log(onTimeDispatchCount,"ontimedispatchcount")
      })
      .catch(function (error) {
        var errDetails = {
          url: "dispatch/getTransactionOutputDetails",
          screen: "Summary Dashboard",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  getPlanInputDetails = () => {
    var params = {
      // transactionId : this.state.transactionId
      order_id: this.state.orderId,
      transaction_id: this.state.transactionId,
    };
    // console.log("first params", params);
    redirectURL
      .post("dispatch/getTransactionRequestInputDetails", params)
      .then(async (response) => {
        // console.log(response.data, "first input");
        if (response.data[0].created_on) {
          var [year, mon, day] = response.data[0].created_on
            .split("T")[0]
            .split("-");

          var [hr, min] = response.data[0].created_on.split("T")[1].split(":");
          var date = day + "-" + mon + "-" + year + " " + hr + ":" + min;
          this.setState({
            created_on: date,
          });
        }
        var inputDetails = response.data;
        // await this.setState({i : response.data })

        // console.log(inputDetails.length,'first inputs')
        var validInputDetails = [];
        var invalidInputDetails = [];
        if (inputDetails.length > 0) {
          validInputDetails = await inputDetails.filter(
            (od) => od.invalid_data !== 1
          );
          invalidInputDetails = await inputDetails.filter(
            (od) => od.invalid_data === 1 || od.is_qty_less === 1
          );
        }

        var orderNumbersList = [];
        validInputDetails.map((each) => {
          orderNumbersList.push(each.order_number);
          return true;
        });
        invalidInputDetails.map((each) => {
          orderNumbersList.push(each.order_number);
          return true;
        });
        // console.log(orderNumbersList,"first viewdispatch order numbers before render")

        // console.log('first vali2dInputDetails',inputDetails)
        var total_orders = inputDetails.length;
        var invoicingDate = "";
        if (inputDetails.length) {
          invoicingDate = inputDetails[0].invoicing_date;
          // console.log("first validInputDetails", inputDetails);
        }
        await this.setState({
          orderNumbersList: orderNumbersList,
          inputDetails: validInputDetails,
          loadDroppedDetails: invalidInputDetails,
          total_orders,
          invoicingDate,
        });
      })
      .catch(function (error) {
        var errDetails = {
          url: "dispatch/getTransactionRequestInputDetails",
          screen: "Summary Dashboard",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  closeAlert = async () => {
    await this.setState({
      show: false,
      remark: { value: "", label: "Select" },
      subReason: "",
    });
  };
  getWeekOffColumn = () => {
    var inputDetails = this.state.inputDetails;
    var unPlannedData = this.state.unPlannedData;
    let data = [];
    // console.log("first ord", inputDetails, unPlannedData);
    for (let each of unPlannedData) {
      data.push(
        ...inputDetails.filter(
          (item) => item.order_number === each.order_number
        )
      );
    }
    // console.log("first ord", data, unPlannedData);
    unPlannedData.map((item) => {
      data.map((order) => {
        if (order.order_number === item.order_number) {
          item.holiday = order.holiday[0];
          item.dealer_type = order.dealer_type;
          item.customer_type = order.customer_type;
        }
        return true;
      });
      return true;
    });
    this.setState({ unPlannedData });
  };

  onClickTab = (pageType) => {
    var config = "activet";
    var plannedTrucks = "";
    var unPlannedTrucks = "";
    var loadsDropped = "";
    var inputInfo = "";
    var summary = "";
    var planSummary = "";
    var configData = "show-m";
    var plannedTrucksData = "show-n";
    var unPlannedTrucksData = "show-n";
    var loadsDroppedData = "show-n";
    var inputInfoData = "show-n";
    var summaryData = "show-n";
    var planSummaryData = "show-n";
    var selectedTrucks = "show-n";
    var selectedVehicle = "show-n";
    if (pageType === "config") {
      config = "activet";
      plannedTrucks = "";
      unPlannedTrucks = "";
      loadsDropped = "";
      inputInfo = "";
      summary = "";
      planSummary = "";
      configData = "show-m";
      plannedTrucksData = "show-n";
      unPlannedTrucksData = "show-n";
      loadsDroppedData = "show-n";
      inputInfoData = "show-n";
      summaryData = "show-n";
      planSummaryData = "show-n";
      selectedTrucks = "show-n";
      selectedVehicle = "show-n";
    } else if (pageType === "plannedTrucks") {
      config = "";
      plannedTrucks = "activet";
      unPlannedTrucks = "";
      loadsDropped = "";
      inputInfo = "";
      summary = "";
      planSummary = "";
      configData = "show-n";
      plannedTrucksData = "show-m";
      unPlannedTrucksData = "show-n";
      loadsDroppedData = "show-n";
      inputInfoData = "show-n";
      summaryData = "show-n";
      planSummaryData = "show-n";
      selectedTrucks = "show-n";
      selectedVehicle = "show-n";
      this.getDetailsOfFilteredData();
    } else if (pageType === "unPlannedTrucks") {
      config = "";
      plannedTrucks = "";
      unPlannedTrucks = "activet";
      loadsDropped = "";
      inputInfo = "";
      summary = "";
      planSummary = "";
      configData = "show-n";
      plannedTrucksData = "show-n";
      unPlannedTrucksData = "show-m";
      loadsDroppedData = "show-n";
      inputInfoData = "show-n";
      summaryData = "show-n";
      planSummaryData = "show-n";
      selectedTrucks = "show-n";
      selectedVehicle = "show-n";

      this.getWeekOffColumn();
    } else if (pageType === "loadsDropped") {
      config = "";
      plannedTrucks = "";
      unPlannedTrucks = "";
      loadsDropped = "activet";
      inputInfo = "";
      summary = "";
      planSummary = "";
      configData = "show-n";
      plannedTrucksData = "show-n";
      unPlannedTrucksData = "show-n";
      loadsDroppedData = "show-m";
      inputInfoData = "show-n";
      summaryData = "show-n";
      planSummaryData = "show-n";
      selectedTrucks = "show-n";
      selectedVehicle = "show-n";
    } else if (pageType === "inputInfo") {
      config = "";
      plannedTrucks = "";
      unPlannedTrucks = "";
      loadsDropped = "";
      inputInfo = "activet";
      summary = "";
      planSummary = "";
      configData = "show-n";
      plannedTrucksData = "show-n";
      unPlannedTrucksData = "show-n";
      loadsDroppedData = "show-n";
      inputInfoData = "show-m";
      summaryData = "show-n";
      planSummaryData = "show-n";
      selectedTrucks = "show-n";
      selectedVehicle = "show-n";
    } else if (pageType === "summary") {
      config = "";
      plannedTrucks = "";
      unPlannedTrucks = "";
      loadsDropped = "";
      inputInfo = "";
      summary = "activet";
      planSummary = "";
      configData = "show-n";
      plannedTrucksData = "show-n";
      unPlannedTrucksData = "show-n";
      loadsDroppedData = "show-n";
      inputInfoData = "show-n";
      summaryData = "show-m";
      planSummaryData = "show-n";
      selectedTrucks = "show-n";
      selectedVehicle = "show-n";
    } else if (pageType === "planSummary") {
      config = "";
      plannedTrucks = "";
      unPlannedTrucks = "";
      loadsDropped = "";
      inputInfo = "";
      summary = "";
      planSummary = "activet";
      configData = "show-n";
      plannedTrucksData = "show-n";
      unPlannedTrucksData = "show-n";
      loadsDroppedData = "show-n";
      inputInfoData = "show-n";
      summaryData = "show-n";
      planSummaryData = "show-m";
      selectedTrucks = "show-n";
      selectedVehicle = "show-n";
      this.getDetailsOfFilteredData();
    } else if (pageType === "selectedTrucks") {
      config = "";
      plannedTrucks = "";
      unPlannedTrucks = "";
      loadsDropped = "";
      inputInfo = "";
      summary = "";
      planSummary = "";
      configData = "show-n";
      plannedTrucksData = "show-n";
      unPlannedTrucksData = "show-n";
      loadsDroppedData = "show-n";
      inputInfoData = "show-n";
      summaryData = "show-n";
      planSummaryData = "show-n";
      selectedTrucks = "activet";
      selectedVehicle = "show-m";
    }
    this.setState({
      config: config,
      plannedTrucks: plannedTrucks,
      unPlannedTrucks: unPlannedTrucks,
      loadsDropped: loadsDropped,
      inputInfo: inputInfo,
      configData: configData,
      summary: summary,
      planSummary: planSummary,
      plannedTrucksData: plannedTrucksData,
      unPlannedTrucksData: unPlannedTrucksData,
      loadsDroppedData: loadsDroppedData,
      inputInfoData: inputInfoData,
      summaryData: summaryData,
      planSummaryData: planSummaryData,
      selectedTrucks,
      selectedVehicle,
    });
  };

  hideSlideBlock = async () => {
    // console.log('hit')
    await this.setState({
      overlayBlock: "show-n",
      showSlideForUpdateOrderDetails: "",
      showSlideBlockUpload: "",
      showSlideAssignVehicle: "",
      showSlideMap: "",
      showSlideMapLocations: "",
      loadshow: "show-n",
      overly: "show-n",
      showAdditionalPlannedVehicles: 0,
      updateVehicleNo: false,
      assignvehicle: { value: "", label: "Select" },
      showAssignFields: "show-n",
      other_vehicle_no: "",
      mapFor: "",
      regular_vehicles: 1,
      additional_vehicles: 1,
      showVehicleMap: "",
      showSlideEditVehiclePlan: "",
      showDeleteSlider: false,
      remark: { value: "", label: "Select" },
      subReason: "",
      quantityError: "",
      demandedVolumeError: "",
      weightError: "",
      dealerAvblEndErr: "",
      dealerAvblStartErr: "",
      vehicleProductType: "",
    });
    // console.log('hti here')
    if (this.gridApi4) {
      this.gridApi4.deselectAll();
    }
  };
  saveOrderDetails = (params) => {
    let basicTitle = "Order Details Updated Successfully";
    let data_type = this.state.data_type;

    redirectURL
      .post("/master/saveOrderDetails", params)
      .then(async (response) => {
        if (response.data.status === "success") {
          this.setState((prevState) => ({
            openPickupDropLocationsModel:
              !prevState.openPickupDropLocationsModel,
            showSlideForUpdateOrderDetails: "",
            overlayBlock: "show-n",
            order: "",
            quantity: "",
            weight: "",
            demandedVolume: "",
            showloader: "show-n",
            show: true,
            basicTitle: basicTitle,
            basicType: "success",
          }));
          this.getPlanDetails();
        } else {
          this.setState({
            showloader: "show-n",
            show: true,
            basicTitle: response.data.message,
            basicType: "danger",
          });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "/master/saveOrderDetails",
          screen: "Pick Up/Drop Locations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };
  locationFormHandler = (e) => {
    // console.log('hit')
    e.preventDefault();
    this.setState({
      showloader: "show-m",
    });
    let pickUpLocationCode = this.state.transactionId.split("_")[0];
    let logParams = {
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "View Dispatch Screen ",
      activity: "clicked on SAVE Button in the Modal",
      event: "Updated Order Details are saved",
    };
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    let order = this.state.order;
    let quantity = parseFloat(this.state.quantity);
    let weight = parseFloat(this.state.weight);
    let demandedVolume = parseFloat(this.state.demandedVolume);
    let transaction_id = this.state.transactionId;
    var deliveryDate = moment(this.state.deliveryDate).format("DD-MM-YYYY");
    var { dealerAvblEnd, dealerAvblStart } = this.state;
    let params = {
      order,
      quantity,
      weight,
      demandedVolume,
      transaction_id,
      deliveryDate,
      dealerAvblEnd,
      dealerAvblStart,
    };
    // if ((parseFloat(quantity) >= 0.1 && parseFloat(quantity) <= 1000) && (parseFloat(weight) >= 0.1 && parseFloat(weight) <= 50000) && (parseFloat(demandedVolume) >= 0.1 && parseFloat(demandedVolume) <= 100)) {
    //   this.saveOrderDetails(params)
    // } else {
    //   this.setState({
    //     show:true,
    //     basicTitle:"One or more values are outside valid ranges",
    //     basicType:'danger',
    //   })
    // }
    if (
      parseFloat(quantity) > 0 &&
      parseFloat(quantity) <= 1000 &&
      parseFloat(weight) > 0 &&
      parseFloat(weight) <= 50000 &&
      parseFloat(demandedVolume) > 0 &&
      parseFloat(demandedVolume) <= 100 &&
      this.state.dealerAvblEndErr === "" &&
      this.state.dealerAvblStartErr === ""
    ) {
      this.saveOrderDetails(params);
    } else {
      this.setState({
        show: true,
        basicTitle: "One or more values are outside valid ranges",
        basicType: "danger",
      });
    }
  };

  handleOrderDetails = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    // console.log('first ',name,value)
    if (name === "dealerAvblEnd" || name === "dealerAvblStart") {
      const regex = /^[0-9:]*$/;
      // console.log('first ',name,value,regex.test(value))
      if (regex.test(value)) {
        this.setState({
          [name]: value,
        });
      }
    } else {
      this.setState({
        [name]: value,
      });
    }
  };
  handleQuantityBlur() {
    if (parseFloat(this.state.quantity) >= 1000) {
      this.setState({
        quantityError: "Quantity must be less than or equal to 1000",
      });
    } else if (parseFloat(this.state.quantity) < 0) {
      this.setState({ quantityError: "Quantity must be greater than 0" });
    } else {
      this.setState({ quantityError: "" });
    }
  }

  handleWeightBlur() {
    if (parseFloat(this.state.weight) >= 50000) {
      this.setState({
        weightError: "Weight must be less than or equal to 50000",
      });
    } else if (parseFloat(this.state.weight) < 0) {
      this.setState({ weightError: "weight must be greater than 0" });
    } else {
      this.setState({ weightError: "" });
    }
  }

  handleDemandedVolumeBlur() {
    if (parseFloat(this.state.demandedVolume) >= 100) {
      this.setState({
        demandedVolumeError:
          "Demanded Volume must be less than or equal to 100",
      });
    } else if (parseFloat(this.state.demandedVolume) < 0) {
      this.setState({
        demandedVolumeError: "Demanded Volum must be greater than 0",
      });
    } else {
      this.setState({ demandedVolumeError: "" });
    }
  }

  handledealerAvblStartBlur = () => {
    var { dealerAvblStart } = this.state;
    var times = dealerAvblStart.split(":");
    // console.log('first time',times,dealerAvblStart)
    if (times.length !== 2) {
      this.setState({
        dealerAvblStartErr: "Dealer Available Start Time format is incorrect",
      });
    } else if (
      times[0].length > 2 ||
      times[1].length > 2 ||
      times.length !== 2
    ) {
      this.setState({
        dealerAvblStartErr: "Dealer Available Start Time format is incorrect",
      });
    } else if (parseInt(times[0]) > 24 || parseInt(times[1]) > 59) {
      this.setState({
        dealerAvblStartErr: "Dealer Available Start Time format is incorrect",
      });
    } else {
      this.setState({ dealerAvblStartErr: "" });
    }
  };

  handledealerAvblEndBlur = () => {
    var { dealerAvblEnd } = this.state;
    var times = dealerAvblEnd.split(":");
    // console.log('first time',times,dealerAvblEnd)

    if (times.length !== 2) {
      this.setState({
        dealerAvblEndErr: "Dealer Available End Time format is incorrect",
      });
    } else if (
      times[0].length > 2 ||
      times[1].length > 2 ||
      times.length !== 2
    ) {
      this.setState({
        dealerAvblEndErr: "Dealer Available End Time format is incorrect",
      });
    } else if (parseInt(times[0]) > 24 || parseInt(times[1]) > 59) {
      this.setState({
        dealerAvblEndErr: "Dealer Available End Time format is incorrect",
      });
    } else {
      this.setState({ dealerAvblEndErr: "" });
    }
  };

  handleDeliveryDateChange = (event) => {
    // console.log("first date", event.target.value);
    this.setState({ deliveryDate: event.target.value });
  };
  handelPickupDropModelCancel = () => {
    let logParams = {
      // "location_code" : this.state.location.value,
      // "location_name" : this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Summary Dashboard",
      activity: "clicked on Cancel Button in the Modal",
      event: "Modal is closed",
      // "data_type" : this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);

    this.setState((prevState) => ({
      openPickupDropLocationsModel: !prevState.openPickupDropLocationsModel,
      showSlideForUpdateOrderDetails: "",
      overlayBlock: "show-n",
      order: "",
      quantity: 0,
      weight: "",
      demandedVolume: "",
      quantityError: "",
      weightError: "",
      demandedVolumeError: "",
      dealerAvblEndErr: "",
      dealerAvblStartErr: "",
    }));
  };
  onRouteData = async (row) => {
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "planned Summary ",
      activity: "clicked on Route Icon in aggrid",
      event: "Slider opened with a map",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    await this.setState({
      overlayBlock: "show-m",
      tripinfo: [row.data],
      mapFor: "viewRoute",
    });
    // console.log("allroutes ",this.state.mapFor)
    var loadingLocation = [];
    if (this.state.location != undefined && this.state.location != "") {
      loadingLocation.push({
        lat: this.state.location.latitude,
        lng: this.state.location.longitude,
      });
    }
    let qry = JSON.parse(localStorage.getItem("qry"));
    let location = this.state.location;
    let plant_location_code = qry.plant_location_code;
    let dispatch_planning_type = qry.dispatch_planning_type;
    if (Object.keys(qry).length === 0) {
      plant_location_code = location.value;
      dispatch_planning_type = 2;
    }

    var params = {
      transaction_id: this.state.transactionId,
      dispatch_planning_type: dispatch_planning_type,
      plant_location_code: plant_location_code,
      trip_no: row.data.trip_no,
      return_route: 1,
    };
    if (row.data.is_available == 0) {
      params.return_route = 0;
    }

    if (this.state.orderId != undefined && this.state.orderId != "") {
      let plant = this.state.orderId.split("_");
      plant = plant[0];
      // console.log(plant,'plant')
      params.plant_location_code = plant;
    }

    redirectURL
      .post("/dispatch/fetchPlanRouteDetails", params)
      .then((response) => {
        // console.log("Response4", response.data);

        if (
          response.data.plant_loc_data !== "" &&
          response.data.plant_loc_data !== undefined
        ) {
          var plantData = JSON.parse(response.data.plant_loc_data);
        } else {
          var plantData = [];
        }

        if (
          response.data.trip_gps_data !== "" &&
          response.data.trip_gps_data !== undefined
        ) {
          var trip_gps = JSON.parse(response.data.trip_gps_data);
        } else {
          var trip_gps = [];
        }
        // console.log("trip_gps ", trip_gps)
        if (
          response.data.trip_nodes_data !== "" &&
          response.data.trip_nodes_data !== undefined
        ) {
          var tripnodes = JSON.parse(response.data.trip_nodes_data);
        } else {
          var tripnodes = [];
        }
        // console.log("trip_gps ", trip_gps)
        // console.log("plantData ", plantData)
        // console.log("tripnodes ", tripnodes)
        if (row.data.is_available == 0) {
          this.setState({
            trip_gps_data: trip_gps,
            plant_loc_data: plantData,
            trip_nodes_data: tripnodes,
            // showSlideBlockUpload:"slide60",
            showSlideMap: "slide60",
            overlayBlock: "show-m",
            viewcount: 1,
            showAdditionalPlannedVehicles: 1,
          });
        } else {
          this.setState({
            trip_gps_data: trip_gps,
            plant_loc_data: plantData,
            trip_nodes_data: tripnodes,
            // showSlideBlockUpload:"slide60",
            showSlideMap: "slide60",
            overlayBlock: "show-m",
            viewcount: 1,
            showAdditionalPlannedVehicles: 0,
          });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "/dispatch/fetchPlanRouteDetails",
          screen: "Summary Dashboard",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });

    // this.setState({
    //     loadingLocation : loadingLocation,
    //     mapType : 1,
    //     latLngArray : row.data.latLngArray,
    //     showSlideBlockUpload:"slide45",
    //     overlayBlock:"show-m",
    //     sliderTitle : "Trip : "+row.data.trip_no+" Total Distance : "+row.data.distance+" Km"
    // })
  };

  onClickVehicleLocation = (params) => {
    // console.log('hit here',params.data,this.state.locationNames)
    var { locationNames } = this.state;
    var data = params.data;
    var locationCode = params.data.location_code;
    var plantLocation = locationNames.filter(
      (item) => item.value === locationCode
    )[0];
    var locations = [];
    data.demand_node_latitude = data.last_packet_latitude;
    data.demand_node_longitude = data.last_packet_longitude;
    plantLocation.name = "plant";
    locations.push(data, plantLocation);
    // console.log(plantLocation,'lats langs',locations)
    if (
      data.last_packet_latitude === undefined ||
      data.last_packet_longitude === undefined ||
      data.last_packet_longitude === "" ||
      data.last_packet_latitude === ""
    ) {
      this.setState({
        show: true,
        basicTitle: "No Data Found",
        basicType: "danger",
      });
    } else {
      this.setState({
        showVehicleMap: "slide60",
        overlayBlock: "show-m",
        plantLocation,
        vehicleLocation: locations,
        mapFor: "vehicleLocation",
      });
    }
  };
  onShowLegsData = async (row) => {
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Summary Dashboard",
      activity: "clicked on view Button in aggrid",
      event: "another aggrid opened below the row",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    var plannedcolsDefs1 = [
      // {
      //     headerName:"Trip No",
      //     field:"trip_no",
      //     width:"100",
      // },
      {
        headerName: "Planned Day",
        headerTooltip: "Planned Day",
        field: "drop_node_total_time_day",
        width: "90",
        valueGetter: function (params) {
          try {
            if (
              params.data.drop_node_total_time_day !== "" &&
              params.data.drop_node_total_time_day !== undefined
            ) {
              var a = parseInt(params.data.drop_node_total_time_day);
              return "Day - " + (a + 1);
            } else {
              return params.data.drop_node_total_time_day;
            }
          } catch (error) {}
        },
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Drop Sequence",
        headerTooltip: "Drop Sequence",
        field: "drop_sequence",
        width: "100",
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      // {
      //     headerName:"Trip No",
      //     headerTooltip:"Trip No",
      //     field:"trip_no",
      //     width:"120",
      // },
      {
        headerName: "Drop Location Code (Destination)",
        headerTooltip: "Drop Location Code (Destination)",
        field: "drop_node",
        width: "150",
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Drop Location Name (Destination)",
        headerTooltip: "Drop Location Name (Destination)",
        field: "drop_node_name",
        width: "150",
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Destination Location City",
        headerTooltip: "Destination Location City",
        field: "demand_node_location",
        width: "120",
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Dealer Type",
        field: "dealer_type",
        width: "100",
        valueGetter: (params) => {
          if (
            params.data.dealer_type !== "" &&
            params.data.dealer_type !== undefined
          ) {
            return params.data.dealer_type;
          } else {
            return "N/A";
          }
        },
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Customer Type",
        field: "customer_type",
        width: "100",
        valueGetter: (params) => {
          if (
            params.data.customer_type !== "" &&
            params.data.customer_type !== undefined
          ) {
            return params.data.customer_type;
          } else {
            return "N/A";
          }
        },
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Week Off",
        field: "week_off",
        width: 100,
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      // {
      //     headerName:"Destination Location State",
      //     headerTooltip:"Destination Location State",
      //     field:"demand_node_state",
      //     width:"150",
      // },
      {
        headerName: "Order Number",
        headerTooltip: "Order Number",
        field: "order_number",
        width: "120",
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Invoicing Date",
        headerTooltip: "Invoicing Date",
        field: "invoicing_date",
        width: "120",
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      // {
      //     headerName:"Vehicle No",
      //     field:"vehicle_no",
      //     width:"180"
      // },
      // {
      //     headerName:"Vehicle Type",
      //     field:"vehicle_type",
      //     width:"120"
      // },
      // {
      //     headerName:"Transaporter Code",
      //     field:"transporter_code",
      //     width:"150"
      // },
      // {
      //     headerName:"Transaporter Name",
      //     field:"transporter_name",
      //     width:"150"
      // },
      // {
      //     headerName:"Route Mode",
      //     field:"route_mode",
      //     width:"120"
      // },
      // {
      //     headerName:"Pick Up Location (Source)",
      //     field:"pickup_node",
      //     width:"150",
      // },
      // {
      //     headerName:"Vehicle Max Volume(M³)",
      //     field:"vehicle_max_volume",
      //     width:160
      // },
      // {
      //     headerName:"Vehicle Max Weight(Kgs)",
      //     field:"vehicle_max_weight",
      //     width:160
      // },
      {
        headerName: "Quantity",
        headerTooltip: "Quantity",
        field: "quantity",
        width: "90",
        valueGetter: (params) => {
          return params.data.quantity.toFixed(2);
        },
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Weight (Kgs)",
        headerTooltip: "Weight (Kgs)",
        field: "demand_weight",
        width: "90",
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Volume (M³)",
        headerTooltip: "Volume (M³)",
        field: "demand_volume",
        width: "90",
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      // {
      //     headerName:"Distance From Pickup Location (Kms)",
      //     headerTooltip:"Distance From Pickup Location (Kms)",
      //     field:"distance",
      //     width:"200",
      // },

      {
        headerName: "Distance To Destination (Kms)",
        headerTooltip: "Distance To Destination (Kms)",
        field: "distance",
        width: "150",
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Expected Delivery Date",
        field: "delivery_date",
        width: "120",
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      // {
      //     headerName:"Time From Pickup Location (HH:MM)",
      //     headerTooltip:"Time From Pickup Location (HH:MM)",
      //     field:"drop_node_total_time_hrs",
      //     width:"200",
      // },
      {
        headerName: "Time To Destination",
        headerTooltip: "Time To Destination",
        field: "drop_node_total_time_hrs",
        width: "150",
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Transit Time (HH:MM)",
        headerTooltip: "Transit Time (HH:MM)",
        field: "drop_node_transit_time_hrs",
        width: "120",
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      // {
      //     headerName:"Loading Time (HH:MM)",
      //     headerTooltip:"Loading Time (HH:MM)",
      //     field:"drop_node_loading_time_mins",
      //     width:"120",
      // },
      {
        headerName: "Unloading Time (HH:MM)",
        headerTooltip: "Unloading Time (HH:MM)",
        field: "drop_node_unloading_time_mins",
        width: "120",
        cellClass: function (params) {
          try {
            if (params.data.is_newly_added != undefined) {
              if (params.data.is_newly_added == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      // {
      //     headerName:"Distance From Previous Location (Kms)",
      //     field:"distance_from_prev_location",
      //     width:"200",
      // },
      // {
      //     headerName:"Time From Previous Location (HH:MM)",
      //     field:"time_from_prev_location",
      //     width:"200",
      // },
      // {
      //     headerName:"Drop Location City",
      //     field:"demand_node_location",
      //     width:"120",
      // },
      // {
      //     headerName:"Zone",
      //     field:"zone",
      //     width:"100"
      // },
      // {
      //     headerName:"Drop Location (Destination)",
      //     field:"drop_node",
      //     width:"150"
      // },
      // {
      //     headerName:"Vehicle Volume",
      //     field:"vehicle_volume",
      //     width:"120"
      // },
      // {
      //     headerName:"Vehicle Priority",
      //     field:"vehicle_priority",
      //     width:"120"
      // },
    ];
    if (row.data.legs_data != 0) {
      await this.setState({
        detailCellRendererParams: {
          detailGridOptions: {
            columnDefs: plannedcolsDefs1,
            overlayNoRowsTemplate: "No rows to show",
            defaultColDef: this.state.defaultColDef,
            statusBar: this.state.statusBar,
            sideBar: this.state.sideBar,
            enableRangeSelection: true,
          },
          getDetailRowData: async function (param) {
            // console.log("param ", param.data)
            param.successCallback(row.data.legs_data);
          },
          masterDetail: true,
        },
      });
      // console.log(propsdata);
      if (row.colDef.field == "details") {
        row.node.setExpanded(!row.node.expanded);
      } else {
        row.node.setExpanded(false);
      }
    } else {
      this.setState({
        detailCellRendererParams: {
          detailGridOptions: {
            columnDefs: plannedcolsDefs1,
          },
          getDetailRowData: function (params) {
            //console.log("Child ", params);
            params.successCallback([]);
          },
        },
      });
    }
  };
  onClickShowReloadedRoutesData() {
    let viewcount = this.state.viewcount;
    if (viewcount == 0) {
      let logParams = {
        location_code: this.state.location.value,
        location_name: this.state.location.label,
        user_name: localStorage.getItem("username"),
        useremail: localStorage.getItem("email"),
        client: localStorage.getItem("client"),
        screen: "Summary Dashboard",
        activity: "clicked on view planned additional vehicle routes Button",
        event: "Slider Opened with a map",
        data_type: this.state.data_type,
      };

      redirectURL.post("/master/loguserUpdatesorChanges", logParams);
      this.setState({
        // trip_gps_data: trip_gps,
        // plant_loc_data: plantData,
        // trip_nodes_data: tripnodes,
        // showSlideBlockUpload:"slide60",
        showAdditionalPlannedVehicles: 1,
        showSlideMap: "slide60",
        overlayBlock: "show-m",
        viewcount: 0,
      });
    } else {
      this.onClickShowRoutesData();
      this.setState({
        showAdditionalPlannedVehicles: 1,
      });
    }
  }

  getDetailsOfFilteredData = async () => {
    // console.log('HIT')

    let filteredData = [];
    let allPlanSummaryData = [];

    let data = this.state.plannedData;
    //  console.log(data,'data')
    data.map((item) => allPlanSummaryData.push(...item.legs_data));
    //  console.log(this.state.filterPlannedTucksData,'filterd 1')
    if (this.state.filterPlannedTucksData == 1) {
      let customer_code = this.state.filterCustomer.value;
      let orderNo = this.state.filterOrder.value;

      if (customer_code != "" && orderNo != "") {
        filteredData = data.filter((item) =>
          item.legs_data.some(
            (drops) =>
              drops.drop_node == customer_code && drops.order_number == orderNo
          )
        );
        //  console.log(filteredData, "new hit here");
      } else if (customer_code != "") {
        filteredData = data.filter((item) =>
          item.legs_data.some((drops) => drops.drop_node == customer_code)
        );
      } else if (orderNo != "") {
        filteredData = data.filter((item) =>
          item.legs_data.some((drops) => drops.order_number == orderNo)
        );
      }
    } else {
      filteredData = this.state.plannedData;
    }
    //  console.log(filteredData,"PlannedSummary")
    await this.setState({ allPlanSummaryData, filteredData });
  };

  onClickShowRoutesData() {
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Summary Dashboard",
      activity: "clicked on view planned available vehicle routes Button",
      event: "Slider Opened with a map",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);

    this.setState({
      overlayBlock: "show-m",
    });
    let qry = JSON.parse(localStorage.getItem("qry"));
    let location = this.state.location;
    let plant_location_code = qry.plant_location_code;
    let dispatch_planning_type = qry.dispatch_planning_type;
    if (Object.keys(qry).length === 0) {
      plant_location_code = location.value;
      dispatch_planning_type = 2;
    }
    var params = {
      transaction_id: this.state.transactionId,
      dispatch_planning_type: dispatch_planning_type,
      plant_location_code: plant_location_code,
    };

    if (this.state.orderId != undefined && this.state.orderId != "") {
      let plant = this.state.orderId.split("_");
      plant = plant[0];
      // console.log(plant,'plant')
      params.plant_location_code = plant;
    }

    redirectURL
      .post("/dispatch/fetchPlanRouteDetails", params)
      .then((response) => {
        // console.log("Response", response.data);
        if (
          response.data.plant_loc_data !== "" &&
          response.data.plant_loc_data !== undefined
        ) {
          var plantData = JSON.parse(response.data.plant_loc_data);
        } else {
          var plantData = [];
        }

        if (
          response.data.trip_gps_data !== "" &&
          response.data.trip_gps_data !== undefined
        ) {
          var trip_gps = JSON.parse(response.data.trip_gps_data);
        } else {
          var trip_gps = [];
        }
        // console.log("trip_gps ", trip_gps)
        if (
          response.data.trip_nodes_data !== "" &&
          response.data.trip_nodes_data !== undefined
        ) {
          var tripnodes = JSON.parse(response.data.trip_nodes_data);
        } else {
          var tripnodes = [];
        }
        // console.log(trip_gps,plantData,tripnodes,'ll')
        this.setState({
          trip_gps_data: trip_gps,
          plant_loc_data: plantData,
          trip_nodes_data: tripnodes,
          // showSlideBlockUpload:"slide60",
          showSlideMap: "slide60",
          overlayBlock: "show-m",
          viewcount: 0,
        });
      })
      .catch(function (error) {
        var errDetails = {
          url: "/dispatch/fetchPlanRouteDetails",
          screen: "Summary Dashboard",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  }
  onClickShowLocationData() {
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Summary Dashboard",
      activity: "clicked on View Locations Button",
      event: "Slider Opened witha a map",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    this.setState({
      overlayBlock: "show-m",
    });
    var unPlannedData = this.state.unPlannedData;
    this.setState({
      palletLoadLocations: unPlannedData,
      showSlideMapLocations: "slide60",
      overlayBlock: "show-m",
      viewcount: 0,
    });
  }

  initMapRoutes = () => {
    //console.log("Yes Here")
    //console.log("mapFor ",this.props.mapFor)
    //console.log("mapinfo ",this.props.mapinfo)
    // try{
    //     var currentwindow;
    //     var dealer='';
    //     var consigner='';
    //     this.setState({
    //         dealerCode:this.props.consigneecoords
    //     });
    //     var routeinfo ;
    //     if(this.state.plant_loc_data !== "" && this.state.plant_loc_data !== undefined)
    //     {
    //         var plant_loc_data = this.state.plant_loc_data;
    //     }
    //     else{
    //         var plant_loc_data = []
    //     }
    //     if(plant_loc_data.length > 0)
    //     {
    //         var lt=parseFloat(plant_loc_data[0].latitude);
    //         var ln=parseFloat(plant_loc_data[0].longitude);
    //     }
    //     else{
    //         var lt=28.4519751;
    //         var ln=77.0310713;
    //     }
    //     if(this.state.trip_gps_data !== "" && this.state.trip_gps_data !== undefined)
    //     {
    //         var trip_gps = this.state.trip_gps_data;
    //     }
    //     else{
    //         var trip_gps = []
    //     }
    //     if(this.state.trip_nodes_data !== "" && this.state.trip_nodes_data !== undefined)
    //     {
    //         var trip_nodes_data = this.state.trip_nodes_data;
    //     }
    //     else{
    //         var trip_nodes_data = []
    //     }
    //     var mapOptions = {
    //         zoom: 13,
    //         zoomControl: true,
    //         mapTypeControl: true,
    //         scaleControl: true,
    //         streetViewControl: true,
    //         rotateControl: true,
    //         fullscreenControl: true,
    //         labels:true,
    //         mapTypeControlOptions: {
    //             mapTypeIds: ['roadmap'],
    //         },
    //         center: new window.google.maps.LatLng(lt,ln),
    //         mapTypeId: window.google.maps.MapTypeId.ROADMAP
    //     };
    //     map = new window.google.maps.Map(document.getElementById('mapRoute'),
    //         mapOptions);
    //     var bounds = new window.google.maps.LatLngBounds();
    //     bounds.extend(new window.google.maps.LatLng(lt,ln));
    //         // Create our info window content
    //     var currentinfowindow = null;
    //     var arr = trip_gps;
    //     // console.log("trip_gps ", arr)
    //     var infowindow = new window.google.maps.InfoWindow();
    //     var markers= []
    //     if(arr.length > 0)
    //     {
    //         var line=new window.google.maps.Polyline(
    //         {
    //             map:map,
    //             strokeColor: '#157254',
    //             strokeOpacity: 1.0,
    //             strokeWeight: 2.5,
    //             icons: [{
    //                     icon: {
    //                             path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
    //                             strokeColor:'#ff0000',
    //                             fillColor:'#157254',
    //                             fillOpacity:1,
    //                             strokeWeight: 2
    //                             },
    //                     repeat:'100px',
    //                     path:[]
    //                 }]
    //             });
    //         for (let i = 0; i < arr.length; i++) {
    //             var path=line.getPath().getArray();
    //             let latLng=new window.google.maps.LatLng(parseFloat(arr[i].latitude),parseFloat(arr[i].longitude));
    //             path.push(latLng);
    //             line.setPath(path);
    //         }
    //     }
    //     if(plant_loc_data.length > 0)
    //     {
    //         for (var l = 0; l < plant_loc_data.length; l++) {
    //             bounds.extend(new window.google.maps.LatLng(parseFloat(plant_loc_data[l].latitude), parseFloat(plant_loc_data[l].longitude)));
    //             var marker = new window.google.maps.Marker({
    //                 position: new window.google.maps.LatLng(parseFloat(plant_loc_data[l].latitude), parseFloat(plant_loc_data[l].longitude)),
    //                 // icon:require('../../assets/images/business.png'),
    //                 title:plant_loc_data[l].location_label,
    //                 map: map,
    //             });
    //             addMarker(new window.google.maps.LatLng(parseFloat(plant_loc_data[l].latitude), parseFloat(plant_loc_data[l].longitude)),plant_loc_data[l].location_label);
    //         }
    //     }
    //     if(trip_nodes_data.length > 0)
    //     {
    //         for (var l = 0; l < trip_nodes_data.length; l++) {
    //             bounds.extend(new window.google.maps.LatLng(parseFloat(trip_nodes_data[l].latitude), parseFloat(trip_nodes_data[l].longitude)));
    //             var marker = new window.google.maps.Marker({
    //                 position: new window.google.maps.LatLng(parseFloat(trip_nodes_data[l].latitude), parseFloat(trip_nodes_data[l].longitude)),
    //                 // icon:require('../../assets/images/business.png'),
    //                 title:trip_nodes_data[l].location_label,
    //                 map: map,
    //             });
    //             addMarker(new window.google.maps.LatLng(parseFloat(trip_nodes_data[l].latitude), parseFloat(trip_nodes_data[l].longitude)),trip_nodes_data[l].location_label);
    //         }
    //     }
    //     function addMarkerPlant(location,lbl) {
    //         var marker = new window.google.maps.Marker({
    //             // color:"#ffffff",
    //             // fontSize:"20px",
    //             position: location,
    //             label:lbl,
    //             icon: require('../../assets/images/business.png'),
    //             map: map
    //         });
    //         markers.push(marker);
    //         var infowindow = new window.google.maps.InfoWindow({
    //             maxWidth: 350
    //         });
    //         marker.addListener('click', function() {
    //             infowindow.open(map, marker);
    //         });
    //         //oms.addMarker(marker);
    //     }
    //     function addMarker(location,lbl) {
    //         var marker = new window.google.maps.Marker({
    //             // color:"#ffffff",
    //             // fontSize:"20px",
    //             position: location,
    //             label:lbl,
    //             // icon: require('../../assets/images/business.png'),
    //             map: map
    //         });
    //         markers.push(marker);
    //         var infowindow = new window.google.maps.InfoWindow({
    //             maxWidth: 350
    //         });
    //         marker.addListener('click', function() {
    //             infowindow.open(map, marker);
    //         });
    //         //oms.addMarker(marker);
    //     }
    //     map.fitBounds(bounds)
    // }
    // catch(e){
    //     console.log("Error ",e)
    // }
  };

  onChangeFilterCustomer = async (filterCustomer) => {
    await this.setState({ filterCustomer });
  };
  onChangeFilterOrder = async (filterOrder) => {
    await this.setState({ filterOrder });
  };

  onClickFilterData = async () => {
    // console.log(this.state.filterOrder.value=="",'order',this.state.filterCustomer.value=="",'customffffer');
    // console.log(this.state.filterPlannedTucksData)
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "planned Summary",
      activity: "clicked on Get Data Button",
      event: "Data in aggrid filtered",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    if (
      this.state.filterOrder.value !== "" ||
      this.state.filterCustomer.value !== ""
    ) {
      // console.log(this.state.filterOrder.value,'order',this.state.filterCustomer.value,'customer');

      await this.setState({ filterPlannedTucksData: 1 });
    } else {
      await this.setState({ filterPlannedTucksData: 0 });
    }
    this.getDetailsOfFilteredData();
  };

  onClickResetPlannedVehicleData = async () => {
    // console.log(this.state.location)
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Summary Dashboard",
      activity: "clicked on Reset Button",
      event: "Customer and Order Number fields set to All",
      data_type: this.state.data_type,
    };
    // console.log(logParams)

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    // .then((res)=>{
    //   console.log(res)
    // })
    // .catch((err)=>{
    //   console.log(err)
    // })
    await this.setState({
      filterPlannedTucksData: 0,
      filterCustomer: { value: "", label: "All" },
      filterOrder: { value: "", label: "All" },
    });
    this.getDetailsOfFilteredData();
  };
  async onClickShowAssignForm(rownode) {
    // console.log("Rownode first", rownode.data);
    var { vehicle_product_type } = rownode.data;
    let logParamsWebPage = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Summary Dashboard",
      activity: "clicked on Assign Vehicle Button",
      event: "Slider Opened with assign vehicle option",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParamsWebPage);

    var assign_vehicle_list = [];
    // console.log(this.state.vehicleLists,'first vehicleLists')
    this.state.vehicleLists.filter((item) => {
      // if (item.type == rownode.data.vehicle_type ||  item.type == 'others') {
      if (
        item.type === "others" ||
        item.vehicle_product_type === vehicle_product_type
      ) {
        assign_vehicle_list.push({
          value: item.value,
          label: item.label,
        });
      }
      // }
      return true;
    });
    // console.log(this.state.vehicleLists, "vehicle list");
    // console.log(assign_vehicle_list, "assign first");
    // console.log(
    //   rownode.data.vehicle_type,
    //   "assign first",
    //   vehicle_product_type
    // );
    await this.setState({
      showSlideAssignVehicle: "slide30",
      overlayBlock: "show-m",
      assignrowdata: [rownode.data],
      assign_vehicle_list,
      vehicle_product_type,
    });
  }
  onChangeAssignVehicle(assignvehicle) {
    this.setState({ assignvehicle, revokedBack: true }, () => {
      var vehicle_no = this.state.assignvehicle.value;
      if (vehicle_no !== "") {
        if (vehicle_no === "0") {
          this.setState({
            showAssignFields: "show-m",
          });
        } else {
          this.setState({
            showAssignFields: "show-n",
          });
        }
      } else {
        this.setState({
          showAssignFields: "show-n",
        });
      }
    });

    if (this.state.activeVehicleList.length > 0) {
      let shipList = this.state.shipmentIds;
      let selectedShipmentId;
      shipList.map((itm) => {
        if (itm.truck_no == assignvehicle.value) {
          selectedShipmentId = itm.shipment_id;
        }
      });

      // if (this.state.activeVehicleList.includes(assignvehicle.value)) {
      //   this.setState({
      //     openReassignVehicleModel: true,
      //     showSlideAssignVehicle: "",
      //     selectedShipmentId,
      //     forceCloseVehicle: assignvehicle.value,
      //   });
      // }
    }
  }

  changeHandler(event) {
    var name = event.target.name;
    var value = event.target.value;
    var pattern = /^[A-Z0-9]{9}$/;
    // var key=event.target.keyCode()
    // console.log(key)
    var symbols = "'\"~!@#$%^&*()_+=-_|}{][:;<>?/ .,";

    var key = value[value.length - 1];

    // if (pattern.test(value.toUpperCase())) {
    //     console.log(value,'valuw')
    // }
    // console.log('Clicked',!symbols.includes(key))
    if (!symbols.includes(key)) {
      this.setState({
        [name]: value.toUpperCase(),
      });
    }

    if (this.state.activeVehicleList.length > 0) {
      let shipList = this.state.shipmentIds;
      let selectedShipmentId;
      shipList.map((itm) => {
        if (itm.truck_no == value) {
          selectedShipmentId = itm.shipment_id;
        }
      });

      // if (this.state.activeVehicleList.includes(value)) {
      //   this.setState({
      //     openReassignVehicleModel: true,
      //     showSlideAssignVehicle: "",
      //     selectedShipmentId,
      //     forceCloseVehicle: value,
      //   });
      // }
    }
  }

  onClickAssignSelectedVehicle() {
    let clickdetails = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Summary Dashboard",
      activity: "clicked on Assigned a Vehicle",
      event: "Assign Vehicle",
      data_type: this.state.data_type,
    };
    // console.log(logParams)
    redirectURL.post("/master/loguserUpdatesorChanges", clickdetails);

    // console.log(
    //   "first vehicleProductType2",
    //   this.state.vehicleProductType === ""
    // );
    this.setState({ assignVehicleloader: "show-m" });
    var assignvehicle = this.state.assignvehicle.value;
    var transactionId = this.state.transactionId;
    var other_vehicle_no = this.state.other_vehicle_no;
    var orderId = this.state.orderId;
    var rowD = this.state.assignrowdata;
    // console.log(rowD,'first row');
    var oNumber = "";
    var trip_no = "";
    var plant_location_code = "";
    var viewPage = window.location.href;
    if (this.state.updateVehicleNo !== true) {
      if (assignvehicle === "0" && this.state.vehicleProductType === "") {
        this.setState({
          show: true,
          basicTitle: "Please choose vehicle Product Type",
          basicType: "danger",
          assignVehicleloader: "show-n",
        });
        return;
      }
      if (rowD.length > 0) {
        trip_no = rowD[0].trip_no;
        oNumber = rowD[0].order_number;
        plant_location_code = rowD[0].pickup_node;
      }
      if (
        assignvehicle !== "" &&
        transactionId !== "" &&
        transactionId !== undefined &&
        orderId !== "" &&
        orderId !== undefined
      ) {
        // console.log("assignvehicle ", assignvehicle)
        if (assignvehicle === "0") {
          var vNo = other_vehicle_no;
        } else {
          vNo = assignvehicle;
        }
        // console.log(
        //   "first vehicleProductType",
        //   this.state.vehicleProductType === ""
        // );
        if (vNo !== "") {
          var params = {
            vehicle_no: vNo,
            transaction_id: transactionId,
            order_id: orderId,
            trip_no: trip_no,
            oNumber: oNumber,
            application_link: viewPage,
            plant_location_code: plant_location_code,
            // vehicle_product_type: this.state.vehicleProductType.value,
          };
          if (this.state.vehicleProductType !== "") {
            params.vehicle_product_type = this.state.vehicleProductType.value;
          }

          // console.log('hit',this.state.location.value)
          var updateVehicle = "";
          if (this.state.updateVehicleNo) {
            updateVehicle = "Update Vehicle";
          } else {
            updateVehicle = "Assign Vehicle";
          }

          let logParams = {
            location_code: this.state.location.value,
            location_name: this.state.location.label,
            user_name: localStorage.getItem("username"),
            useremail: localStorage.getItem("email"),
            client: localStorage.getItem("client"),
            screen: "Summary Dashboard",
            activity: updateVehicle,
            event: updateVehicle,
            data_type: this.state.data_type,
          };

          redirectURL.post(
            "/master/logUserUpdateOrSaveDataActivities",
            logParams
          );

          redirectURL
            .post("/dispatch/assignVehicleAndCreateShipment", params)
            .then((response) => {
              if (response.data.status.status === "Success") {
                this.setState({
                  showSlideAssignVehicle: "",
                  overlayBlock: "show-m",
                  assignrowdata: [],
                  other_vehicle_no: "",

                  showNewPlan: false,
                  updatedPlan: true,
                  showSlideEditVehiclePlan: "",
                  overlayBlock: "show-n",

                  assignvehicle: { value: "", label: "Select" },
                  assignVehicleloader: "show-n",
                  showAssignFields: "show-n",
                  openReassignVehicleModel: false,
                  updateVehicleNo: false,
                });
                this.getPlanOutputDetails();
              } else if (response.data.status.status !== "Success") {
                this.setState({
                  show: true,
                  basicTitle: response.data.status.message,
                  basicType: "danger",
                  assignVehicleloader: "show-n",
                  showSlideAssignVehicle: "",
                  overlayBlock: "show-n",
                  assignrowdata: [],
                  other_vehicle_no: "",
                  assignvehicle: { value: "", label: "Select" },
                  // assignVehicleloader: "show-n",
                  showAssignFields: "show-n",
                  openReassignVehicleModel: false,
                  updateVehicleNo: false,
                });
                this.getPlanOutputDetails();
              }
            })
            .catch(function (error) {
              var errDetails = {
                url: "/dispatch/assignVehicleAndCreateShipment",
                screen: "Summary Dashboard",
                error,
              };
              redirectURL.post("/master/logErrorsForApiCalls", errDetails);
            });
        } else {
          this.setState({
            show: true,
            basicTitle: "Please choose vehicle",
            basicType: "danger",
            assignVehicleloader: "show-n",
          });
        }
      } else {
        if (assignvehicle === "") {
          this.setState({
            show: true,
            basicTitle: "Please choose vehicle",
            basicType: "danger",
            assignVehicleloader: "show-n",
          });
        } else {
          this.setState({
            show: true,
            basicTitle: "Please check transaction id and order id",
            basicType: "danger",
            assignVehicleloader: "show-n",
          });
        }
      }
    } else {
      let oldVehicleNO = this.state.oldVehicleNO;
      let oldVehTripNo = parseInt(this.state.oldVehTripNo);
      let planned_distance = 0;

      if (rowD.length > 0) {
        planned_distance = rowD[0].round_trip_distance;
      }
      // console.log(
      //   "first vehicleProductType",
      //   this.state.vehicleProductType === ""
      // );

      if (
        assignvehicle !== "" &&
        transactionId !== "" &&
        transactionId !== undefined &&
        orderId !== "" &&
        orderId !== undefined
      ) {
        // console.log("assignvehicle ", assignvehicle)
        if (assignvehicle === "0") {
          vNo = other_vehicle_no;
          if (this.state.vehicleProductType === "") {
            this.setState({
              show: true,
              basicTitle: "Please choose vehicle Product Type",
              basicType: "danger",
              assignVehicleloader: "show-n",
            });
            return;
          }
        } else {
          vNo = assignvehicle;
        }
        if (vNo !== undefined && vNo !== "") {
          var params2 = {
            new_vehicle_no: vNo,
            transaction_id: transactionId,
            order_id: orderId,
            trip_no: oldVehTripNo,
            old_vehicle_no: oldVehicleNO,
            planned_distance: planned_distance,
            // vehicle_product_type:this.state.vehicleProductType.value,
            user_email: localStorage.getItem("email"),
            vehicle_product_type: this.state.vehicle_product_type,
          };
          if (this.state.vehicleProductType !== "") {
            params2.vehicle_product_type = this.state.vehicleProductType.value;
          }
          redirectURL
            .post("/dispatch/updateVehicleNoOfAssignedVehicle", params2)
            .then((response) => {
              if (
                response.data.status === "Success" &&
                response.data.message === "success"
              ) {
                this.setState({
                  updateVehicleNo: false,
                  assignVehicleloader: "show-n",
                  showSlideAssignVehicle: "",
                  overlayBlock: "show-n",
                  showAssignFields: "show-n",
                  openReassignVehicleModel: false,
                });
                this.getPlanOutputDetails();
              } else {
                this.setState({
                  show: true,
                  basicTitle: response.data.message,
                  basicType: "danger",
                  assignVehicleloader: "show-n",
                  showSlideAssignVehicle: "",
                  overlayBlock: "show-n",
                  openReassignVehicleModel: false,
                  updateVehicleNo: false,
                });
              }
            })
            .catch(function (error) {
              var errDetails = {
                url: "/dispatch/updateVehicleNoOfAssignedVehicle",
                screen: "Summary Dashboard",
                error,
              };
              redirectURL.post("/master/logErrorsForApiCalls", errDetails);
            });
        } else {
          this.setState({
            show: true,
            basicTitle: "Please choose vehicle",
            basicType: "danger",
            assignVehicleloader: "show-n",
          });
        }
      } else {
        this.setState({
          show: true,
          basicTitle: "Please check transaction id and order id",
          basicType: "danger",
          assignVehicleloader: "show-n",
        });
      }
    }
  }

  onClickDownloadPlannedData = () => {
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Planned Summary",
      activity: "clicked on Download Planned Data Button",
      event: "Planned Data Dowloaded in Excel",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    let planData = this.state.plannedData;
    let allData = [];
    planData.map((item) => allData.push(...item.legs_data));

    const data = allData.map((item) => {
      if (item.drop_node_total_time_day == 0) {
        item.drop_node_total_time_day = "Day - 1";
      } else if (item.drop_node_total_time_day == 1) {
        item.drop_node_total_time_day = "Day - 2";
      } else if (item.drop_node_total_time_day == 2) {
        item.drop_node_total_time_day = "Day - 3";
      }
      return {
        "Trip No": item.trip_no,
        "Drop Sequence": item.drop_sequence,
        "Vehicle Type": item.vehicle_type,
        "Order Number": item.order_number,
        "Vehicle No": item.vehicle_no,
        "Invoicing Date": item.invoicing_date,
        Quantity: item.quantity,
        "Drop Location Code (Destination)": item.drop_node,
        "Drop Location Name (Destination)": item.drop_node_name,
        "Destination Location City": item.demand_node_location,
        "Volume (M3)": item.demand_volume,
        "Weight (Kgs)": item.demand_weight,
        "Planned Day": item.drop_node_total_time_day,
        "Max Dispatch Time (HH:MM)": item.vehicle_start_time_mins,
        "Distance To Destination (Kms)": item.distance,
        "Time To Destination": item.drop_node_total_time_hrs,
        "Delivery Date": item.delivery_date,
        "Customer Type": item.customer_type,
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const timestamp = moment.parseZone(new Date()).format("YYYY-MM-DD");

    const dataBlob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileName = `Planned Vehicles Data_${timestamp}.xlsx`;
    const downloadLink = document.createElement("a");

    downloadLink.href = window.URL.createObjectURL(dataBlob);
    downloadLink.download = fileName;
    downloadLink.click();
  };

  handelModelCancel = () => {
    this.setState({
      openReassignVehicleModel: false,
      overlayBlock: "show-n",
      updateVehicleNo: false,
      assignvehicle: { value: "", label: "Select" },
    });
  };

  handelUpdateModelCancel = () => {
    this.setState({
      openUpdateVehicleModel: false,
      overlayBlock: "show-n",
      updateVehicleNo: false,
    });
  };

  handelEditVehiclePlanModelCancel = () => {
    this.setState({
      openEditVehiclePlanModel: false,
      overlayBlock: "show-n",
    });
  };

  forceCloseAndReassign = () => {
    this.setState({ assignVehicleloader: "show-m" });

    var shipment_id = this.state.selectedShipmentId;
    var truck_no = this.state.forceCloseVehicle;
    var dept_code = "SNDG";
    // var force_closure_time = moment.parseZone().format("YYYY-MM-DD HH:mm:ss");
    var username = localStorage.getItem("username");
    var userEmail = localStorage.getItem("email");
    var others_Reason = "";
    var reasonforceclose = this.state.forceClose_reason;
    var params = {
      dept_code: dept_code,
      shipment_id: [shipment_id],
      truck_no: truck_no,
      username: username,
      userEmail: userEmail,
      reasonforceclose: reasonforceclose,
      others_reason: others_Reason,
    };

    redirectURL
      .post("/dispatch/forceCloseTrip", params)
      .then((response) => {
        if (response.data.status == "Success") {
          this.onClickAssignSelectedVehicle();
        } else {
          this.setState({
            show: true,
            basicTitle: "Error",
            basicType: "danger",
            assignVehicleloader: "show-n",
          });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "/dispatch/forceCloseTrip",
          screen: "Summary Dashboard",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  forceCloseInputField = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    this.setState({ [name]: value });
  };
  onClickUpdateAssignVehicle = (props) => {
    var { vehicle_product_type } = props.data;
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Planned Summary",
      activity: "clicked on Update Vehicle Button",
      event: "Modal Opened with Yes or No",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);

    // console.log("first update vehicleLists", this.state.vehicleLists);
    var assign_vehicle_list = [];
    this.state.vehicleLists.filter((item) => {
      if (
        item.type === "others" ||
        item.type === props.data.vehicle_type ||
        item.vehicle_product_type === vehicle_product_type
      ) {
        if (
          item.type === "others" ||
          item.vehicle_product_type === vehicle_product_type
        ) {
          assign_vehicle_list.push({
            value: item.value,
            label: item.label,
          });
        }
      }
      return true;
    });
    // console.log("first update assign_vehicle_list", assign_vehicle_list);
    this.setState({
      oldVehicleNO: props.data.vehicle_no,
      oldVehTripNo: props.data.trip_no,
      openUpdateVehicleModel: true,
      assign_vehicle_list,
      assignrowdata: [props.data],
      vehicle_product_type,
    });
  };

  handleUpdateVehicle = () => {
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Summary Dashboard",
      activity: "clicked on yes Button in the modal",
      event: "Slider opended with assign vehicle option",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    this.setState({
      showSlideAssignVehicle: "slide30",
      overlayBlock: "show-m",
      updateVehicleNo: true,
      openUpdateVehicleModel: false,
    });
  };

  onClickCloseConfigs = () => {
    this.setState({ openSavedConfigs: false });
  };

  onLoadGetConfigData = async (order_id) => {
    var configs = [];
    await redirectURL
      .post("/master/getorderConfigs", { order_id })
      .then((response) => {
        configs = response.data;
      });
    await this.setState({
      configs: configs,
    });
  };

  onClickConfigs = async () => {
    // to hide icon process changed
    // var configs=[]
    // await redirectURL.post("/master/getorderConfigs",{order_id:this.state.orderId})
    // .then( (response)=>{
    //   configs=response.data
    // })
    await this.setState({ openSavedConfigs: true });
  };

  onChnageVehicleType = (event) => {
    // console.log(event.target.name,typeof event.target.value,'hello')
    var name = event.target.name,
      value = parseInt(event.target.value);
    // console.log('hello',value === 1)
    if (value === 1) {
      // console.log('hello',value)
      value = 0;
    } else {
      value = 1;
    }
    this.setState({ [name]: value });
  };

  onClickRevokeAssignVehicle = (row) => {
    // console.log(row.data,'da56a')
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Summary Dashboard",
      activity: "clicked on Revoke Button",
      event: "Alert is opened with yer or no",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);

    let transaction_id = this.state.transactionId;
    let vehicle_no = row.data.vehicle_no;
    let trip_no = row.data.trip_no;
    let round_trip_distance = row.data.round_trip_distance;

    var cnf = window.confirm("Are you sure you want to proceed?");

    if (cnf === true) {
      this.setState({
        loadshow: "show-m",
      });

      let param = {
        transaction_id: transaction_id,
        vehicle_no: vehicle_no,
        trip_no: trip_no,
        round_trip_distance: round_trip_distance,
        useremail: localStorage.getItem("email"),
      };

      let logParams = {
        location_code: this.state.location.value,
        location_name: this.state.location.label,
        user_name: localStorage.getItem("username"),
        useremail: localStorage.getItem("email"),
        client: localStorage.getItem("client"),
        screen: "Summary Dashboard",
        activity: "Vehicle Revoked",
        event: "Vehicle Revoke",
        data_type: this.state.data_type,
      };

      redirectURL.post("/master/logUserUpdateOrSaveDataActivities", logParams);

      let clickdetails = {
        location_code: this.state.location.value,
        location_name: this.state.location.label,
        user_name: localStorage.getItem("username"),
        useremail: localStorage.getItem("email"),
        client: localStorage.getItem("client"),
        screen: "Summary Dashboard",
        activity: "Vehicle Revoked",
        event: "clicked on Revoke Vehicle",
        data_type: this.state.data_type,
      };

      redirectURL.post("/master/loguserUpdatesorChanges", clickdetails);

      redirectURL
        .post("/dispatch/revokeVehicleAssignment", param)
        .then((resp) => {
          if (resp.data.status == "Success" && resp.data.message == "success") {
            this.getPlanOutputDetails();
            this.setState({
              loadshow: "show-n",
              show: true,
              basicTitle: "Vehicle Successfully Revoked",
              basicType: "success",
            });
          } else {
            this.setState({
              show: true,
              basicTitle: "Error",
              basicType: "danger",
              loadshow: "show-n",
            });
          }
        })
        .catch(function (error) {
          var errDetails = {
            url: "/dispatch/revokeVehicleAssignment",
            screen: "Summary Dashboard",
            error,
          };
          redirectURL.post("/master/logErrorsForApiCalls", errDetails);
        });
    }
  };

  editVehicleDispatchPlan = async (param) => {
    let data = param.data;
    let vfr = Math.max(
      ((data.total_weight / data.vehicle_max_weight) * 100).toFixed(2),
      ((data.total_demand / data.vehicle_max_volume) * 100).toFixed(2)
    );
    // console.log(moment.parseZone(data.max_start_time).format('YYYY-MM-DD HH:mm'),'getHyphenYYYYMMDDHHMMSS(data.max_start_time)')
    let editplanvfr = vfr;
    let editplanmaxdispatch = moment
      .parseZone(data.max_start_time)
      .format("YYYY-MM-DD HH:mm");
    var maxStartTime = new Date(
      moment.parseZone(data.max_start_time).format("YYYY-MM-DD HH:mm")
    );
    let editplanqty = data.total_quantity;
    let editplanvehicle_volume = data.total_demand;
    let editplanvehicle_weight = data.total_weight;
    let editplanmaxvehiclevolume = data.vehicle_max_volume;
    let editplanmaxvehicleweight = data.vehicle_max_weight;
    let editplanttripno = data.trip_no;
    let editplantransit_type = data.vehicle_transit_type;

    //changes commented need to test
    // let current_time = new Date();
    let checktime = new Date(editplanmaxdispatch);
    let current_time = await this.checkCurrentTime(checktime);
    if (checktime < current_time) {
      let difference = new Date(current_time) - new Date(checktime);
      const differenceInDays = difference / (1000 * 60 * 60 * 24);

      if (differenceInDays > 4) {
        editplanmaxdispatch = moment
          .parseZone(checktime)
          .format("YYYY-MM-DD HH:mm");
      } else {
        editplanmaxdispatch = moment
          .parseZone(current_time)
          .format("YYYY-MM-DD HH:mm");
      }

      // console.log(current_time,'cure  timr',editplanmaxdispatch)
    }

    // if(data.vehicle_no != "Not Assigned" && data.vehicle_no != '' ){
    //   this.setState({
    //     openEditVehiclePlanModel:true,
    //     newObdVehicleType:{label: data.vehicle_type, value: data.vehicle_type},
    //     dispatchPlanObdUpdateData:data.legs_data,
    //     editplanvfr,editplanmaxdispatch,editplanqty,
    //     editplanvehicle_volume,editplanvehicle_weight,
    //     editplanmaxvehiclevolume,editplanmaxvehicleweight,
    //     editplanttripno
    //   })
    // }
    // else{
    await this.setState({
      showSlideEditVehiclePlan: "slide90",
      overlayBlock: "show-m",
      dispatchPlanObdUpdateData: data.legs_data,
      editplanvfr,
      editplanmaxdispatch,
      editplanqty,
      editplanvehicle_volume,
      editplanvehicle_weight,
      editplanmaxvehiclevolume,
      editplanmaxvehicleweight,
      editplanttripno,
      newObdVehicleType: { label: data.vehicle_type, value: data.vehicle_type },
      editplantransit_type,
      // newObdVehicleType:{label: data.vehicle_type, value: data.vehicle_type},
      maxStartTime,
    });
    await this.enablesundayplanning();
    // }
  };

  onClickDeletePlanBtn = async (params) => {
    // console.log(this.state.transactionId,"transaction_id");
    let tripDetails = params.data;
    let location = this.state.location;

    let deletePlanParams = {
      plant_location_code: location.value,
      transaction_id: this.state.transactionId,
      trip_no: tripDetails.trip_no,
      client: localStorage.getItem("client"),
      user_emailID: localStorage.getItem("email"),
      truck_no: tripDetails.vehicle_no,
    };
    this.setState({
      showDeleteSlider: true,
      // overlayBlock:"show-m",
      deletePlanParams,
    });
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "View Dispatch PlanDetails- planned Summary",
      activity: "clicked on Delete Button",
      event: "opened Delete Popup",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  onClickEmailBtn = (params) => {
    var tripDetails = params.data;
    var location = this.state.location;
    var emailDcParams = {
      plant_location_code: location.value,
      transaction_id: this.state.transactionId,
      trip_no: tripDetails.trip_no,
      client: localStorage.getItem("client"),
      user_emailID: localStorage.getItem("email"),
      truck_no: tripDetails.vehicle_no,
      application_link: window.location.href,
    };
    // console.log("first ", emailDcParams);
    redirectURL
      .post("/dispatch/notifyDcMails", emailDcParams)
      .then((response) => {
        // console.log('first response',response)
        if (response.status === 200) {
          this.setState({
            basicTitle: "Mail sent to DCs",
            basicType: "success",
            show: true,
          });
        }
      });
  };

  deleteDispatchPlan = async () => {
    // api call to delete plan

    if (this.state.remark.value === "") {
      this.setState({
        show: true,
        basicTitle: "Please provide reason for Plan Deletion",
        basicType: "danger",
      });
      return;
    }

    if (
      this.state.remark.value === "Others" &&
      this.state.subReason.trim() === ""
    ) {
      this.setState({
        show: true,
        basicTitle: "Please provide reason for Plan Deletion",
        basicType: "danger",
      });
      return;
    }
    var params = {
      ...this.state.deletePlanParams,
      remark: this.state.remark.value,
      subReason: this.state.subReason,
    };

    redirectURL.post("/tsp/deletePlan", params).then(async (resp) => {
      console.log(resp);
      if (resp.data.status == "Success") {
        this.setState({
          show: true,
          // basicTitle:resp.data.message,
          basicTitle: "Trip Deleted Successfully",
          basicType: "success",
          deletePlanParams: "",
          showDeleteSlider: false,
          overlayBlock: "show-n",
        });
        let logParams = {
          location_code: this.state.location.value,
          location_name: this.state.location.label,
          user_name: localStorage.getItem("username"),
          useremail: localStorage.getItem("email"),
          client: localStorage.getItem("client"),
          screen: "View Dispatch PlanDetails- planned Summary",
          activity: "Confirmed Delete",
          event: "Deleted plan Successfully",
          data_type: this.state.data_type,
        };

        redirectURL.post("/master/loguserUpdatesorChanges", logParams);
        await this.getPlanOutputDetails();
      } else {
        this.setState({
          show: true,
          basicTitle: resp.data.message,
          basicType: "danger",
          deletePlanParams: "",
          showDeleteSlider: false,
          overlayBlock: "show-n",
        });
      }
    });
  };

  onCanceldeletePlan = (e) => {
    this.setState({
      showDeleteSlider: false,
      overlayBlock: "show-n",
      deletePlanParams: "",
      remark: { value: "", label: "Select" },
      subReason: "",
    });
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "View Dispatch PlanDetails- planned Summary",
      activity: "clicked on Cancel Delete Button",
      event: "Cancelled Delete plan action",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  handleEditVehiclePlan = () => {
    this.setState({
      showSlideEditVehiclePlan: "slide90",
      overlayBlock: "show-m",
      openEditVehiclePlanModel: false,
    });
  };

  handelChangeTypeVehiclePlan = () => {
    this.setState({
      openEditVehiclePlanModel: false,
      show: true,
      basicTitle: "Please Revoke Vehicle",
      basicType: "info",
    });
  };

  handleNewObdVehicleType = (vehTyp) => {
    this.setState({
      newObdVehicleType: vehTyp,
    });
  };

  handleRowDataChanged = (params) => {
    params.api.forEachNode((node) => {
      node.setSelected(true);
    });
  };

  onGridReady3 = (params) => {
    this.gridApi3 = params.api;
    this.gridColumnApi3 = params.columnApi;
  };

  onGridState3 = () => {
    this.colState3 = this.gridColumnApi3.getColumnState();
    this.rowGroupState3 = this.gridColumnApi3.getRowGroupColumns();
    this.pivotColumns3 = this.gridColumnApi3.getPivotColumns();
    window.filterState3 = this.gridApi3.getFilterModel();
    this.gridApi3.setFilterModel(window.filterState3);
  };

  onGridReady4 = (params) => {
    this.gridApi4 = params.api;
    this.gridColumnApi4 = params.columnApi;
  };

  onGridState4 = () => {
    this.colState4 = this.gridColumnApi4.getColumnState();
    this.rowGroupState4 = this.gridColumnApi4.getRowGroupColumns();
    this.pivotColumns4 = this.gridColumnApi4.getPivotColumns();
    window.filterState4 = this.gridApi4.getFilterModel();
    this.gridApi4.setFilterModel(window.filterState4);
  };

  handleChangePlan = async () => {
    let location_code = this.state.location.value;
    let vehicle_start_time = this.state.editplanmaxdispatch;
    let vehicle_volume = this.state.editplanvehicle_volume;
    let vehicle_weight = this.state.editplanvehicle_weight;
    let vehicle_type = this.state.newObdVehicleType.value;
    let editplantransit_type = this.state.editplantransit_type;
    let trip_no;
    let totalPlannedData = [];
    let transaction_id = this.state.transactionId;
    let order_id = this.state.orderId;
    var consolidatedTrnsxId;

    if (this.state.consolidatedTrnsxId == undefined) {
      consolidatedTrnsxId = this.state.transactionId;
      await this.setState({
        consolidatedTrnsxId: this.state.transactionId,
      });
    } else {
      consolidatedTrnsxId = this.state.consolidatedTrnsxId;
      await this.setState({
        consolidatedTrnsxId: this.state.consolidatedTrnsxId,
      });
    }
    let editplanuniqueid = moment.parseZone().format("YYYYMMDDHHmmss");
    this.setState({ editplanuniqueid });

    this.gridApi3.forEachNode((node) => totalPlannedData.push(node.data));
    let totalPlannedOrders = totalPlannedData.map((itm) => itm.order_number);
    trip_no = totalPlannedData[0].trip_no;

    var plannedSelectedData = [];
    plannedSelectedData = this.gridApi3.getSelectedRows();
    let plannedObdOrderNos = plannedSelectedData.map((itm) => itm.order_number);

    let removed_obds = totalPlannedOrders.filter(
      (item) => !plannedObdOrderNos.some((element) => element === item)
    );
    var unPlannedSelectedData = [];
    unPlannedSelectedData = this.gridApi4.getSelectedRows();
    let addUnPlannedObdOrderNos = unPlannedSelectedData.map(
      (itm) => itm.order_number
    );
    var selectedObds = [...unPlannedSelectedData, ...plannedSelectedData];
    var vehicleStartTime = moment(vehicle_start_time).format("YYYY-MM-DD");
    var otCustomers = selectedObds.filter((obd) => obd.customer_type === "OT");
    var otDealerCodes = [];
    otCustomers.map((item) => {
      otDealerCodes.push(item.drop_node);
      return true;
    });
    var otDealerDetails = this.state.inputDetails;
    // console.log("first otdeta", otDealerDetails);
    var checkForTime = false,
      chekcForDate = false;
    otCustomers.map((obd) => {
      var deliveryDate = moment(obd.delivery_date, "DD-MM-YYYY").format(
        "YYYY-MM-DD"
      );
      if (vehicleStartTime > deliveryDate) {
        chekcForDate = true;
      } else if (vehicleStartTime === deliveryDate) {
        var vehicleStartTimeMins = moment(vehicle_start_time).format("HH:mm");
        var dropToTime = otDealerDetails.filter(
          (item) =>
            item.location_code === obd.drop_location_code &&
            item.delivery_date === obd.delivery_date
        );
        dropToTime = dropToTime[0].dealer_available_end;
        if (vehicleStartTimeMins > dropToTime) {
          checkForTime = true;
        }
      }
      return "";
    });
    if (chekcForDate) {
      this.setState({
        show: true,
        basicTitle: "Current Execution Date is greater than Delivery Date",
        basicType: "danger",
      });
      return;
    }
    if (checkForTime) {
      this.setState({
        show: true,
        basicTitle:
          "Current Execution Time is greater than Dealer Available Time Window",
        basicType: "danger",
      });
      return;
    }
    let params = {
      order_id,
      transaction_id,
      consolidatedTrnsxId,
      trip_no,
      vehicle_type,
      plannedObdOrderNos,
      removed_obds,
      addUnPlannedObdOrderNos,
      location_code,
      vehicle_start_time,
      vehicle_volume,
      vehicle_weight,
      addremoveplanID: editplanuniqueid,
      transit_type: editplantransit_type,
      is_sunday_planning_enabled: this.state.is_sunday_planning_enabled,
    };

    if (
      (unPlannedSelectedData.length > 0 || plannedSelectedData.length > 0) &&
      vehicle_type !== ""
    ) {
      redirectURL.post("tsp/changePlan", params).then((res) => {
        if (res.data.status === "success") {
          this.checkEditPlanStatus(
            res.data.trip_no,
            res.data.transactionId,
            res.data.addremoveplanID
          );
          this.stopintervalIfnoOutput(
            res.data.trip_no,
            res.data.transactionId,
            res.data.addremoveplanID
          );
          this.setState({
            showNewPlan: true,
            editplanloader: "show-m",
            // show: true,
            // basicTitle: res.data.message,
            // basicType: "info",
          });
        } else {
          this.setState({
            show: true,
            basicTitle: res.data.message,
            basicType: "danger",
          });
        }
      });
    } else {
      if (vehicle_type === "") {
        this.setState({
          show: true,
          basicTitle: "Please Select Vehicle Type",
          basicType: "warning",
        });
      } else {
        this.setState({
          show: true,
          basicTitle: "Please Select OBD's",
          basicType: "warning",
        });
      }
    }
  };

  checkEditPlanStatus = (trip_no, transaction_id, addremoveplanID) => {
    let id = setInterval(() => {
      redirectURL
        .post("tsp/checkEditPlanStatus", {
          trip_no,
          transaction_id,
          addremoveplanID,
        })
        .then((res) => {
          if (res.data.length > 0) {
            clearInterval(id);
            this.setState({
              dispatchNewPlanObdData: res.data,
              showNewPlan: true,
              editplanloader: "show-n",
            });
          }
        });
    }, 10000);
    this.setState({ editplantintervalid: id });
  };

  stopintervalIfnoOutput = (trip_no, transaction_id, addremoveplanID) => {
    setTimeout(() => {
      redirectURL
        .post("tsp/checkEditPlanStatus", {
          trip_no,
          transaction_id,
          addremoveplanID,
        })
        .then(async (res) => {
          if (res.data.length == 0) {
            await clearInterval(this.state.editplantintervalid);
            await this.setState({
              editplanloader: "show-n",
              show: true,
              basicTitle: "Please Contact Admin",
              basicType: "danger",
              showSlideEditVehiclePlan: "",
              overlayBlock: "show-n",
            });
          }
        });
    }, 120000);
  };

  updateThePlan = async () => {
    let location_code = this.state.location.value;
    let transaction_id = this.state.transactionId;
    let trip_no = this.state.editplanttripno;
    let addremoveplanID = this.state.editplanuniqueid;

    if (this.state.remark.value === "") {
      this.setState({
        show: true,
        basicTitle: "Please provide reason for Plan change",
        basicType: "danger",
      });
      return;
    }

    if (
      this.state.remark.value === "Others" &&
      this.state.subReason.trim() === ""
    ) {
      this.setState({
        show: true,
        basicTitle: "Please provide reason for Plan change",
        basicType: "danger",
      });
      return;
    }
    let params = {
      transaction_id,
      location_code,
      trip_no,
      addremoveplanID,
      application_link: window.location.href,
      remark: this.state.remark.value,
      subReason: this.state.subReason,
    };
    await redirectURL.post("tsp/updateThePlan", params).then((res) => {
      // console.log(res.data,'redd')
      if (res.data.status == "success") {
        var message;
        if (res.data.message == "successfull") {
          message = "Trip Edited Successfully";
        } else {
          message = res.data.message;
        }
        this.setState({
          showNewPlan: false,
          show: true,
          updatedPlan: true,
          basicTitle: message,
          basicType: "success",
          showSlideEditVehiclePlan: "",
          overlayBlock: "show-n",
          remark: { value: "", label: "Select" },
          subReason: "",
        });
        this.getPlanOutputDetails();
      } else {
        this.setState({
          showNewPlan: false,
          show: true,
          basicTitle: res.data.message,
          basicType: "info",
          showSlideEditVehiclePlan: "",
          overlayBlock: "show-n",
        });
      }
    });
  };

  onClickWarningBtn = () => {
    this.setState({
      show: true,
      basicTitle: "Low VFR% planned. Business to take call",
      basicType: "info",
    });
  };

  handlerStartDateTime = async (event) => {
    // var d = getHyphenYYYYMMDDHHMM(event._d);

    var d = moment.parseZone(event.target.value).format("YYYY-MM-DD HH:mm");

    await this.setState({ editplanmaxdispatch: d });
    await this.enablesundayplanning();
    await this.checkCurrentTime(this.state.maxStartTime);
  };

  calculateTotal = (array) => {
    const initialValue = { quantity: 0, demand_volume: 0, demand_weight: 0 };

    return array.reduce(
      (accumulator, { quantity, demand_volume, demand_weight }) => ({
        quantity: accumulator.quantity + quantity,
        demand_volume: accumulator.demand_volume + demand_volume,
        demand_weight: accumulator.demand_weight + demand_weight,
      }),
      initialValue
    );
  };

  onPlannedDataSelectionChanged = async () => {
    let editplanmaxvehiclevolume = this.state.editplanmaxvehiclevolume;
    let editplanmaxvehicleweight = this.state.editplanmaxvehicleweight;

    let planneddata = this.gridApi3.getSelectedRows();
    let unplanneddata = this.gridApi4.getSelectedRows();

    const totalArr = await this.calculateTotal(planneddata);
    const totalArr2 = await this.calculateTotal(unplanneddata);

    const combinedTotal = {
      alleditplanqty: totalArr.quantity + totalArr2.quantity,
      alleditplanvehicle_volume:
        totalArr.demand_volume + totalArr2.demand_volume,
      alleditplanvehicle_weight:
        totalArr.demand_weight + totalArr2.demand_weight,
    };

    let vfr = Math.max(
      (
        (combinedTotal.alleditplanvehicle_weight / editplanmaxvehicleweight) *
        100
      ).toFixed(2),
      (
        (combinedTotal.alleditplanvehicle_volume / editplanmaxvehiclevolume) *
        100
      ).toFixed(2)
    );

    await this.setState({
      editplanvfr: vfr,
      editplanqty: combinedTotal.alleditplanqty.toFixed(2),
      editplanvehicle_volume:
        combinedTotal.alleditplanvehicle_volume.toFixed(2),
      editplanvehicle_weight:
        combinedTotal.alleditplanvehicle_weight.toFixed(2),
    });
  };

  cancelplanhideSlideBlock = () => {
    this.setState({
      showSlideEditVehiclePlan: "",
      showNewPlan: false,
      overlayBlock: "show-n",
      remark: { value: "", label: "Select" },
      subReason: "",
    });
  };

  disablePastDt = (current) => {
    const yesterday = moment().subtract(1, "day");
    return current.isAfter(yesterday);
  };

  handlesundayplanning = async () => {
    if (this.state.is_sunday_planning_enabled == 1) {
      await this.setState({ is_sunday_planning_enabled: 0 });
    } else {
      await this.setState({ is_sunday_planning_enabled: 1 });
    }
    await this.checkCurrentTime(this.state.maxStartTime);
  };

  getCurrentDateTime() {
    let createdDate = this.state.createdDate;
    const now = new Date(createdDate);
    // Subtract 7 days from the current date
    now.setDate(now.getDate());
    // Add an offset, for example, 1 minute
    now.setMinutes(now.getMinutes() + 1); // Adding 1 minute to ensure future time
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  getMaxCurrentDateTime() {
    let createdDate = this.state.createdDate;
    const now = new Date(createdDate);
    // Subtract 7 days from the current date
    now.setDate(now.getDate() + 4);
    // Add an offset, for example, 1 minute
    now.setMinutes(now.getMinutes() + 1); // Adding 1 minute to ensure future time
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  async checkCurrentTime(checktime) {
    // console.log("first,", this.state.editplanmaxdispatch);
    var week_off = "Sunday",
      pickupFrom,
      pickupTo;

    var location = this.state.location.value;
    await redirectURL
      .post("master/getBoundTypeLocations", { data_type: 2 })
      .then(async (response) => {
        if (response.data.status === "success") {
          let boundLocations = response.data.boundLocations;
          if (boundLocations.length) {
            var record = boundLocations.filter(
              (item) => item.location_code === location
            );
            record = record[0];

            week_off = record.week_off[0].toLowerCase();
            pickupFrom = record.pickup_from_time;
            pickupTo = record.pickup_to_time;
          }
        }
      });
    const weekdays = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];

    let current_time = new Date();
    var startTime = current_time.getHours();
    pickupFrom = parseInt(pickupFrom.split(":")[0]);
    pickupTo = parseInt(pickupTo.split(":")[0]);
    var { editplanmaxdispatch } = this.state;
    if (this.state.editplanmaxdispatch === "") {
      if (startTime > pickupTo) {
        current_time.setDate(current_time.getDate() + 1);

        current_time.setHours(pickupFrom);
        current_time.setMinutes(0);
        current_time.setSeconds(0);
        current_time.setMilliseconds(0);
      }

      if (
        weekdays[current_time.getDay()] === week_off &&
        this.state.is_sunday_planning_enabled === 0
      ) {
        current_time.setDate(current_time.getDate() + 1);
        current_time.setHours(pickupFrom);
        current_time.setMinutes(0);
        current_time.setSeconds(0);
        current_time.setMilliseconds(0);
      }
      editplanmaxdispatch = moment
        .parseZone(checktime)
        .format("YYYY-MM-DD HH:mm");
      if (checktime < current_time) {
        editplanmaxdispatch = moment
          .parseZone(current_time)
          .format("YYYY-MM-DD HH:mm");
      }

      await this.setState({ editplanmaxdispatch });
    } else {
      if (
        weekdays[new Date(editplanmaxdispatch).getDay()] === week_off &&
        this.state.is_sunday_planning_enabled === 0
      ) {
        if (startTime > pickupTo) {
          current_time.setDate(current_time.getDate() + 1);
          current_time.setHours(pickupFrom);
          current_time.setMinutes(0);
          current_time.setSeconds(0);
          current_time.setMilliseconds(0);
        }
        if (
          weekdays[current_time.getDay()] === week_off &&
          this.state.is_sunday_planning_enabled === 0
        ) {
          current_time.setDate(current_time.getDate() + 1);
          current_time.setHours(pickupFrom);
          current_time.setMinutes(0);
          current_time.setSeconds(0);
          current_time.setMilliseconds(0);
        }

        this.setState({
          show: true,
          basicTitle: "Week Off Plan is not Enabled",
          basicType: "danger",
          editplanmaxdispatch: moment
            .parseZone(current_time)
            .format("YYYY-MM-DD HH:mm"),
        });
      }
    }
    return current_time;
  }
  onChangeRemark = (remark) => {
    // console.log('event',remark)
    this.setState({ remark });
  };

  onChangeSubRemark = (reason) => {
    // console.log("onChangeSubRemark", "reason", reason.target.value.length);
    var value = reason.target.value;
    var regex = /^[a-zA-Z\s]*$/;
    if (regex.test(value) && value.length < 126) {
      this.setState({ subReason: reason.target.value });
    }
  };

  onChangeProductType = (vehicleProductType) => {
    this.setState({ vehicleProductType });
  };
  render() {
    var vehicleProductTypes = [
      { label: "AMBIENT", value: "AMBIENT" },
      { label: "REEFER", value: "REEFER" },
      { label: "CHILLED", value: "CHILLED" },
    ];
    var updatedInputdetails = this.state.inputDetails;
    var drop_node_details = this.state.drop_node_details;
    if (updatedInputdetails.length > 0) {
      updatedInputdetails.map((itm) => {
        if (
          itm.drop_location_name === undefined ||
          itm.drop_location_name === ""
        ) {
          drop_node_details.map((item) => {
            if (item.location_code === itm.drop_location_code) {
              itm.drop_location_name = item.location_name;
            }
            return true;
          });
        }
        return true;
      });
    }
    // console.log('first2',updatedInputdetails.length)
    // var ordNumers=[]
    // updatedInputdetails=updatedInputdetails.filter((item)=>{
    //   if (!ordNumers.includes(item.order_number)){
    //     ordNumers.push(item.order_number)
    //     return item
    //   }
    // })
    // console.log('first',this.state.unPlannedData.length,this.state.planned_orders,this.state.loadDroppedDetails.length)

    let location = this.state.location.value;
    let showCost;

    if (location === "LK1SLK02") {
      showCost = false;
    } else {
      showCost = true;
    }

    var vehicleTypecolsDefs = [
      {
        headerName: "Vehicle Type",
        field: "vehicle_type",
        width: "150",
      },
      {
        headerName: "Vehicle Name",
        field: "vehicle_name",
        width: "100",
      },
      {
        headerName: "Vehicle Priority",
        field: "vehicle_priority",
        width: "100",
      },
      {
        headerName: "Vehicle Availability (from)",
        field: "vehicle_availability_from",
        width: "150",
      },
      {
        headerName: "Vehicle Availability (to)",
        field: "vehicle_availability_to",
        width: "140",
      },
      {
        headerName: "Break Time (from)",
        field: "break_time_from",
        width: "110",
      },
      {
        headerName: "Break Time (to) ",
        field: "break_time_to",
        width: "100",
      },
      {
        headerName: "Min Volume (M³)",
        field: "min_volume_cmt",
        width: "120",
      },
      {
        headerName: "Max Volume (M³)",
        field: "max_volume_cmt",
        width: "120",
      },
      {
        headerName: "Vehicle Width (mm)",
        field: "vehicle_dimensions_width_mm",
        width: "120",
      },
      {
        headerName: "Vehicle Height (mm)",
        field: "vehicle_dimensions_height_mm",
        width: "125",
      },
      {
        headerName: "Vehicle Depth (mm)",
        field: "vehicle_dimensions_depth_mm",
        width: "120",
      },
      {
        headerName: "Vehicle Min Weight (Kgs)",
        field: "vehicle_min_weight",
        width: "145",
      },
      {
        headerName: "Vehicle Max Weight (Kgs)",
        field: "vehicle_max_weight",
        width: "145",
      },
      // {
      //     headerName:"Vehicle Capacity (CMT)",
      //     field:"vehicle_capacity",
      //     width:"135"
      // },
      {
        headerName: "No Of Deliveries",
        field: "max_no_of_nodes",
        width: "100",
      },
      {
        headerName: "Min Load Per Delivery",
        field: "min_load_per_delivery",
        width: "140",
      },
      {
        headerName: "Max Distance (Kms)",
        field: "max_distance",
        width: "120",
      },
    ];
    var materialConfigcolsDefs = [
      {
        headerName: Constant.MATERIAL_CODE,
        field: "material_code",
        width: "120",
      },
      {
        headerName: Constant.MATERIAL_NAME,
        field: "material_name",
        width: "130",
      },
      {
        headerName: Constant.MATERIAL_TYPE,
        field: "material_type",
        width: "130",
      },
      {
        headerName: Constant.MATERIAL_WEIGHT,
        field: "material_weight",
        width: "150",
      },
      {
        headerName: Constant.MATERIAL_DIMENSIONS_DEPTH,
        field: "material_dimensions_depth_mm",
        width: "200",
      },
      {
        headerName: Constant.MATERIAL_DIMENSIONS_WIDTH,
        field: "material_dimensions_width_mm",
        width: "200",
      },
      {
        headerName: Constant.MATERIAL_DIMENSIONS_HEIGHT,
        field: "material_dimensions_height_mm",
        width: "200",
      },
    ];
    var freightConfigcolsDefs = [
      {
        headerName: Constant.FORM_VEHICLE_TYPES,
        field: "vehicle_type",
        width: "120",
      },
      {
        headerName: Constant.DEALER_FORM_STATE,
        field: "state",
        width: "130",
      },
      {
        headerName: Constant.DEALER_FORM_CITY,
        field: "city",
        width: "130",
      },
      {
        headerName: Constant.FREIGHT_FIXED_VEHICLE_COST,
        field: "fixed_vehicle_cost",
        width: "130",
      },
      {
        headerName: Constant.FREIGHT_DRIVER_COST,
        field: "driver_cost",
        width: "150",
      },
      {
        headerName: Constant.FREIGHT_MAINTENANCE_COST,
        field: "maintenance_cost",
        width: "200",
      },
      {
        headerName: Constant.FREIGHT_PER_CMT_COST,
        field: "per_cmt_cost",
        width: "200",
      },
      {
        headerName: Constant.FREIGHT_PER_KM_COST,
        field: "per_km_cost",
        width: "200",
      },
    ];
    var vehicleAvailabilityConfigcolsDefs = [
      {
        headerName: "Vehicle Type",
        field: "vehicle_type",
        width: "150",
      },
      {
        headerName: "Date",
        field: "vehicle_date",
        width: "100",
      },
      {
        headerName: "Vehicle Number",
        field: "vehicle_no",
        width: "100",
      },
      {
        headerName: "Vehicle Availability (from)",
        field: "vehicle_availability_from",
        width: "150",
      },
      {
        headerName: "Vehicle Availability (to)",
        field: "vehicle_availability_to",
        width: "140",
      },
      {
        headerName: "Break Time (from)",
        field: "break_time_from",
        width: "110",
      },
      {
        headerName: "Break Time (to) ",
        field: "break_time_to",
        width: "100",
      },
      {
        headerName: "Transporter Name",
        field: "transporter_name",
        width: "120",
      },
      {
        headerName: "Vehicle Priority",
        field: "vehicle_priority",
        width: "100",
      },
    ];
    var vehiclesSelectedDataDefs = [
      {
        headerName: "Vehicle Number",
        field: "vehicle_no",
        width: "120",
      },
      // {
      //   headerName: "Maximum Distance Per Plan",
      //   field: "max_distance_per_day",
      //   width: "140",
      // },
      {
        headerName: "Vehicle Type ",
        field: "vehicle_type",
        width: "150",
      },
      {
        headerName: "Plant Code ",
        field: "location_code",
        width: "100",
      },
      {
        headerName: "Plant Name ",
        field: "location_name",
        width: "130",
      },
      {
        headerName: "Available Time ",
        field: "vehicle_next_available_date",
        width: "130",
        valueGetter: function (params) {
          try {
            if (
              params.data.vehicle_next_available_date !== "" &&
              params.data.vehicle_next_available_date !== undefined
            ) {
              // return getDDMMYYYYHHMM(params.data.vehicle_next_available_date);
              return moment
                .parseZone(params.data.vehicle_next_available_date)
                .format("YYYY-MM-DD HH:mm");
            } else {
              return "";
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Transit Type",
        field: "transit_type",
        width: "110",
        valueGetter: (params) => {
          if (
            params.data.transit_type !== "" &&
            params.data.transit_type !== undefined
          ) {
            return params.data.transit_type;
          } else {
            return "N/A";
          }
        },
      },
      {
        headerName: "GPS Status",
        field: "timestamp",
        width: "110",
        valueGetter: (params) => {
          // console.log(params.data.timestamp,'hello')
          try {
            if (
              params.data.gps_status_24hrs !== undefined &&
              params.data.gps_status_24hrs != ""
            ) {
              if (params.data.gps_status_24hrs == "no") {
                return "Inactive";
              } else if (params.data.gps_status_24hrs == "N/A") {
                return "N/A";
              } else {
                return "Active";
              }
            } else {
              return "N/A";
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Last Known Address",
        field: "last_known_address",
        width: "110",
        valueGetter: (params) => {
          // console.log(params.data,'hllo')
          try {
            if (
              params.data.last_known_address !== undefined &&
              params.data.last_known_address !== ""
            ) {
              return params.data.last_known_address;
            } else {
              return "N/A";
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Last GPS Time",
        field: "latest_gps_packet",
        width: "130",
        valueGetter: (params) => {
          // console.log(params.data,'hllo')
          try {
            if (
              params.data.latest_gps_packet !== undefined &&
              params.data.latest_gps_packet !== ""
            ) {
              return getHyphenDDMMMYYYYHHMM(params.data.latest_gps_packet);
            } else {
              return "N/A";
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Vehicle Location",
        pinned: "left",
        width: 90,
        filter: true,
        resizable: true,
        editable: false,
        params: {
          onClickFunction: this.onClickVehicleLocation,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "RouteButton",
          };
          return rendComponent;
        },
      },
      // {
      //   headerName: "Assigned To OT",
      //   // pinned: "left",
      //   width: 110,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      //   cellRendererSelector: function (params) {
      //     var rendComponent = {
      //       component: "GridCheckbox",
      //       params: {
      //         screenName: 'summaryDash' // Pass the screen name to the GridCheckbox component
      //       }
      //     };
      //     return rendComponent;
      //   },
      // cellClass: function(params) {
      //   // console.log(params.data,'lparasa')
      //   if (params.data.assigned_to_ot === true) {
      //     return 'light-bg';
      //   }
      // },
      // },
    ];

    var plannedcolsDefs = [
      {
        headerName: "",
        field: "_id",
        width: 50,
        filter: true,
        resizable: true,
        editable: false,
        params: {
          onClickFunction: this.onRouteData,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "RouteButton",
          };
          return rendComponent;
        },
      },
      {
        headerName: "",
        field: "notify_dc",
        width: 8,
        filter: true,
        resizable: true,
        editable: false,
        params: {
          buttonName: "E Mail",
          onClickFunction: this.onClickEmailBtn,
        },
        cellRendererSelector: function (params) {
          try {
            var rendComponent = "";
            if (params.data.route_finalized === 1) {
              rendComponent = {
                component: "EmailBtn",
              };
              return rendComponent;
            } else {
              rendComponent = {
                component: "NAButton",
              };
              return rendComponent;
            }
          } catch (error) {}
        },
      },
      // {
      //   headerName: "Info",
      //   field: "is_vfr_violated",
      //   width: 50,
      //   filter: true,
      //   resizable: true,
      //   editable: false,
      //   params: {
      //     onClickFunction: this.onClickWarningBtn,
      //   },
      //   cellRendererSelector: function (params) {
      //     try {
      //       if ( params.data.is_vfr_violated != undefined ) {
      //         if (params.data.is_vfr_violated == 1 ) {
      //           var rendComponent = {
      //             component: "WarningButton",
      //           };
      //           return rendComponent;
      //         } else {
      //           var rendComponent = {
      //             component: "HyphenButton",
      //           };
      //           return rendComponent;
      //         }
      //       }else{
      //         var rendComponent = {
      //           component: "HyphenButton",
      //         };
      //         return rendComponent;
      //       }
      //     } catch (error) { }
      //   },
      // },
      {
        headerName: "Details",
        field: "details",
        width: 100,
        filter: true,
        resizable: true,
        editable: false,
        params: {
          buttonName: "View",
          iconName: "fa fa-eye",
          onClickFunction: this.onShowLegsData,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      },

      {
        headerName: "Assign Vehicle",
        field: "assign_vehicle",
        width: 160,
        filter: true,
        resizable: true,
        editable: false,
        cellRendererSelector: function (params) {
          try {
            if (
              params.data.vehicle_no == "Not Assigned" &&
              params.data.route_finalized != 1
            ) {
              if (params.data.transaction_update == 1) {
                var rendComponent = {
                  component: "NAButton",
                };
                return rendComponent;
              } else {
                var rendComponent = {
                  component: "AssignVehicleBtn",
                };
                return rendComponent;
              }
            } else if (params.data.route_finalized == 1) {
              var rendComponent = {
                component: "UpdateVehicleBtn",
              };
              return rendComponent;
            } else {
              var rendComponent = {
                component: "NAButton",
              };
              return rendComponent;
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Revoke Assignment",
        field: "revoke_vehicle",
        width: 160,
        filter: true,
        resizable: true,
        editable: false,
        cellRendererSelector: function (params) {
          try {
            var rendComponent = "";
            if (params.data.route_finalized === 1) {
              rendComponent = {
                component: "RevokeVehicleBtn",
              };
              return rendComponent;
            } else {
              rendComponent = {
                component: "NAButton",
              };
              return rendComponent;
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Edit Plan",
        field: "edit_plan",
        width: 120,
        filter: true,
        resizable: true,
        editable: false,
        params: {
          buttonName: "Edit",
          onClickFunction: this.editVehicleDispatchPlan,
          extraParams: this.state.revokedBack,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
        // tooltipValueGetter: (p) =>
        //   "Create any fixed message, e.g. This is the Athlete’s Age ",
        // headerTooltip: "Tooltip for Age Column Header",
        tooltipValueGetter: (params) => {
          // console.log(params,"cccccccccccccccc")
          // Use params object to conditionally render tooltip message
          if (
            params.data.vehicle_no !== "Not Assigned" &&
            params.data.route_finalized === 1
          ) {
            return "Revoke Again To Edit";
          } else {
            return "";
          }
        },
      },
      {
        headerName: "",
        field: "delete_plan",
        width: 40,
        filter: true,
        resizable: true,
        editable: false,
        params: {
          buttonName: "Delete",
          onClickFunction: this.onClickDeletePlanBtn,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "DeleteBtn",
          };
          return rendComponent;
        },
      },
      {
        headerName: "Plan Edited",
        field: "is_addremove_plan",
        width: "100",
        valueGetter: (params) => {
          if (
            params.data.is_addremove_plan !== "" &&
            params.data.is_addremove_plan !== undefined
          ) {
            return params.data.is_addremove_plan == 1 ? "Yes" : "No";
          } else {
            return "No";
          }
        },
      },
      {
        headerName: "Trip No",
        headerTooltip: "Trip No",
        field: "trip_no",
        width: "90",
      },
      {
        headerName: "Vehicle No",
        headerTooltip: "Vehicle No",
        field: "vehicle_no",
        width: "120",
        cellRendererSelector: function (params) {
          try {
            var rendComponent = {
              component: "RepeatIcon",
            };
            return rendComponent;
          } catch (error) {}
        },
      },
      {
        headerName: "Vehicle Type",
        headerTooltip: "Vehicle Type",
        field: "vehicle_type",
        width: 150,
      },
      {
        headerName: "Cost (₹)",
        headerTooltip: "Cost",
        field: "cost",
        width: 100,
        // hide:showCost
      },
      // {
      //     headerName:"Transaporter Code",
      //     field:"transporter_code",
      //     width:"150"
      // },
      // {
      //     headerName:"Transaporter Name",
      //     field:"transporter_name",
      //     width:"150"
      // },
      // {
      //     headerName:"Route Mode",
      //     field:"route_mode",
      //     width:"120"
      // },
      // {
      //     headerName:"Order Date",
      //     field:"order_date",
      //     width:"120",
      //     valueGetter:function(params){
      //         try {
      //             if(params.data.order_date !== "" && params.data.order_date !== undefined)
      //             {
      //                 return getHyphenDDMMMYYYY(params.data.order_date);
      //             }
      //             else{
      //                 return "";
      //             }
      //         } catch (error) {

      //         }
      //     }
      // },
      {
        headerName: "Transit Type",
        field: "vehicle_transit_type",
        width: "110",
        valueGetter: (params) => {
          if (
            params.data.vehicle_transit_type !== "" &&
            params.data.vehicle_transit_type !== undefined
          ) {
            return params.data.vehicle_transit_type;
          } else {
            return "N/A";
          }
        },
      },
      {
        headerName: "Transporter Name",
        headerTooltip: "Transporter Name",
        field: "transporter_name",
        width: "130",
      },
      // {
      //     headerName:"Pick Up Location (Source)",
      //     headerTooltip:"Pick Up Location (Source)",
      //     field:"pickup_node",
      //     width:"150",
      // },
      {
        headerName: "No Of Orders",
        headerTooltip: "No Of Orders",
        field: "no_of_drops",
        width: "90",
      },
      {
        headerName: "No Of Customers",
        headerTooltip: "No Of Customers",
        field: "no_of_customer",
        width: "100",
      },
      {
        headerName: "Total Quantity",
        headerTooltip: "Total Quantity",
        field: "total_quantity",
        width: "100",
      },
      {
        headerName: "Vehicle Utilization (%)",
        headerTooltip: "Vehicle Utilization (%)",
        field: "total_weight",
        width: "120",
        valueGetter: function (params) {
          try {
            if (
              params.data.total_weight !== undefined &&
              params.data.total_weight !== ""
            ) {
              return Math.max(
                (
                  (params.data.total_weight / params.data.vehicle_max_weight) *
                  100
                ).toFixed(2),
                (
                  (params.data.total_demand / params.data.vehicle_max_volume) *
                  100
                ).toFixed(2)
              );
            } else {
              return "";
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Vehicle Max Weight (Kgs)",
        headerTooltip: "Vehicle Max Weight (Kgs)",
        field: "vehicle_max_weight",
        width: 120,
      },
      {
        headerName: "Total Planned Weight (Kgs)",
        headerTooltip: "Total Planed Weight (Kgs)",
        field: "total_weight",
        width: "120",
      },
      {
        headerName: "Onward Trip Distance (Kms)",
        headerTooltip: "Onward Trip Distance (Kms)",
        field: "distance",
        width: 130,
      },
      {
        headerName: "Round Trip (Kms)",
        headerTooltip: "Round Trip (Kms)",
        field: "round_trip_distance",
        width: 110,
      },
      {
        headerName: "Vehicle Max Volume (M³)",
        headerTooltip: "Vehicle Max Volume (M³)",
        field: "vehicle_max_volume",
        width: 120,
      },
      {
        headerName: "Total Planned Volume (M³)",
        headerTooltip: "Total Planed Volume (M³)",
        field: "total_demand",
        width: "120",
      },
      {
        headerName: "Product Code",
        headerTooltip: "Product Code",
        field: "product_code",
        width: "100",
      },
      // {
      //     headerName:"Warehouse (Source)",
      //     field:"pickup_node",
      //     width:"150",
      // },
      // {
      //     headerName:"Total No of Tires",
      //     field:"no_of_tires",
      //     width:"150",
      // },
      // {
      //     headerName:"Trip Weight (Tons)",
      //     field:"demand",
      //     width:140,
      //     valueGetter:function(params){
      //         try {
      //             if(params.data.demand !== "" && params.data.demand !== undefined)
      //             {
      //                 return Math.round(params.data.demand);
      //             }
      //             else{
      //                 return "";
      //             }
      //         } catch (error) {

      //         }
      //     }
      // },
      // {
      //   headerName: "Loading Start Time (HH:MM)",
      //   headerTooltip: "Loading Start Time",
      //   field: "vehicle_loading_start_time",
      //   width: 140,
      // },
      {
        headerName: "Max Dispatch Time (HH:MM)",
        headerTooltip: "Max Dispatch Time (HH:MM)",
        field: "max_start_time",
        width: 140,
        valueGetter: (par) => {
          return par.data.max_start_time;
        },
      },
      {
        headerName: "Arrival At First Dealer (Start)",
        headerTooltip: "Arrival At First Dealer (Start)",
        field: "arrival_time_at_first_dealer",
        width: 130,
        valueGetter: function (params) {
          try {
            if (
              params.data.arrival_time_at_first_dealer !== "" &&
              params.data.arrival_time_at_first_dealer !== undefined
            ) {
              return params.data.arrival_time_at_first_dealer;
              // return getHyphenDDMMMYYYYHHMM(params.data.arrival_time_at_first_dealer);
            } else {
              return "";
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Departure At Last Dealer (End)",
        headerTooltip: "Departure At Last Dealer (End)",
        field: "last_dealer_time_to_complete",
        width: 140,
        valueGetter: function (params) {
          try {
            if (
              params.data.last_dealer_time_to_complete !== "" &&
              params.data.last_dealer_time_to_complete !== undefined
            ) {
              return params.data.last_dealer_time_to_complete;
              // return getHyphenDDMMMYYYYHHMM(params.data.last_dealer_time_to_complete);
            } else {
              return "";
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Return to Plant (HH:MM)",
        headerTooltip: "Return to Plant",
        field: "return_time_to_plant_from_last_dealer",
        width: 140,
        valueGetter: function (params) {
          try {
            if (
              params.data.return_time_to_plant_from_last_dealer !== "" &&
              params.data.return_time_to_plant_from_last_dealer !== undefined
            ) {
              // return getHyphenDDMMMYYYYHHMM(params.data.return_time_to_plant_from_last_dealer);
              return params.data.return_time_to_plant_from_last_dealer;
            } else {
              return "";
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Total Planned Trip Time(Hrs)",
        headerTooltip: "Total Planned Trip Time",
        field: "total_plan_time_hrs",
        width: 130,
      },
      {
        headerName: "Total Round Trip Time(Hrs)",
        headerTooltip: "Total Round Trip Time",
        field: "round_trip_time",
        width: 130,
      },
      {
        headerName: "Total Transit Time (HH:MM)",
        headerTooltip: "Total Transit Time",
        field: "total_drop_node_transit_time_hrs",
        width: 130,
      },
      {
        headerName: "Total Loading Time (HH:MM)",
        headerTooltip: "Total Loading Time (HH:MM)",
        field: "loadingTime",
        width: 130,
        valueGetter: function (params) {
          try {
            if (
              params.data.loadingTime !== "" &&
              params.data.loadingTime !== undefined
            ) {
              // return getHyphenDDMMMYYYYHHMM(params.data.loadingTime);
              return params.data.loadingTime;
            } else {
              return "";
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Total Unloading Time (HH:MM)",
        headerTooltip: "Total Unloading Time (HH:MM)",
        field: "unLoadingTime",
        width: 130,
        valueGetter: function (params) {
          try {
            if (
              params.data.unLoadingTime !== "" &&
              params.data.unLoadingTime !== undefined
            ) {
              // return getHyphenDDMMMYYYYHHMM(params.data.unLoadingTime);
              return params.data.unLoadingTime;
            } else {
              return "";
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Remark",
        field: "remark",
        width: "150",
        valueGetter: (params) => {
          if (params.data.remark !== undefined && params.data.remark !== "") {
            return params.data.remark;
          } else {
            return "N/A";
          }
        },
      },
      {
        headerName: "Other Reasons",
        field: "sub_reason",
        width: "150",
        valueGetter: (params) => {
          // console.log("first", params.data);
          if (
            params.data.sub_reason !== undefined &&
            params.data.sub_reason !== ""
          ) {
            return params.data.sub_reason;
          } else {
            return "";
          }
        },
      },
      // {
      //     headerName:"Zone",
      //     field:"zone",
      //     width:"100"
      // },
      // {
      //     headerName:"Drop Location (Destination)",
      //     field:"drop_node",
      //     width:"150"
      // },
      // {
      //     headerName:"Route No",
      //     field:"route_no",
      //     width:"120",
      // },
      // {
      //     headerName:"Vehicle Volume",
      //     field:"vehicle_volume",
      //     width:"120"
      // },
      // {
      //     headerName:"Vehicle Priority",
      //     field:"vehicle_priority",
      //     width:140
      // },
    ];

    var unPlannedcolsDefs = [
      // {
      //     headerName:"Vehicle No",
      //     field:"vehicle_no",
      //     width:"100"
      // },
      // {
      //     headerName:"Cluster",
      //     field:"cluster",
      //     width:"100"
      // },
      // {
      //     headerName:"Zone",
      //     field:"zone",
      //     width:"100"
      // },
      // {
      //     headerName:"Pick Up Location (Source)",
      //     field:"pickup_node",
      //     width:"150",
      // },
      {
        headerName: "Drop Location Code",
        headerTooltip: "Drop Location Code",
        field: "drop_node",
        width: "150",
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Drop Location Name",
        headerTooltip: "Drop Location Name",
        field: "drop_node_name",
        width: "180",
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Drop Location City",
        headerTooltip: "Drop Location City",
        field: "demand_node_location",
        width: "150",
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Order Number",
        field: "order_number",
        width: "120",
        valueGetter: (params) => {
          return params.data.order_number;
        },
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Distance from Plant",
        headerTooltip: "Distance from plant",
        field: "distance_from_plant",
        width: "100",
        valueGetter: (params) => {
          if (
            params.data.distance_from_plant !== "" &&
            params.data.distance_from_plant !== undefined
          ) {
            return params.data.distance_from_plant.toFixed(2);
          } else {
            return "N/A";
          }
        },
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Week Off",
        field: "holiday",
        width: 100,
        filter: true,
        resizable: true,
        editable: false,
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Demand Volume (M³)",
        headerTooltip: "Demand Volume (M³)",
        field: "demand_volume",
        width: "130",
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Weight (Kgs)",
        headerTooltip: "Weight (Kgs)",
        field: "demand_weight",
        width: "120",
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Quantity",
        headerTooltip: "Quantity",
        field: "quantity",
        width: "100",
        valueGetter: (params) => {
          if (
            params.data.quantity !== "" &&
            params.data.quantity !== undefined
          ) {
            return params.data.quantity.toFixed(2);
          } else {
            return "N/A";
          }
        },
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Dealer Type",
        field: "dealer_type",
        width: "100",
        valueGetter: (params) => {
          if (
            params.data.dealer_type !== "" &&
            params.data.dealer_type !== undefined
          ) {
            return params.data.dealer_type;
          } else {
            return "N/A";
          }
        },
        cellClass: function (params) {
          try {
            if (params.data.new_obd !== undefined) {
              if (params.data.new_obd === 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Customer Type",
        field: "customer_type",
        width: "100",
        valueGetter: (params) => {
          if (
            params.data.customer_type !== "" &&
            params.data.customer_type !== undefined
          ) {
            return params.data.customer_type;
          } else {
            return "N/A";
          }
        },
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Delivery Date",
        field: "delivery_date",
        width: "120",
        valueGetter: (params) => {
          if (params.data.delivery_date.includes("T")) {
            let date = params.data.delivery_date.split("T");
            return date[0];
          } else {
            return params.data.delivery_date;
          }
        },
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      // {
      //     headerName:"Sales Order No",
      //     field:"so_number",
      //     width:"180",
      // },

      // {
      //     headerName:"Route No",
      //     field:"route_no",
      //     width:"120",
      // },
      // {
      //     headerName:"Vehicle Type",
      //     field:"vehicle_type",
      //     width:"120"
      // },
      // {
      //     headerName:"Vehicle Volume",
      //     field:"vehicle_volume",
      //     width:"120"
      // },
      // {
      //     headerName:"Vehicle Priority",
      //     field:"vehicle_priority",
      //     width:"120"
      // },
      // {
      //     headerName:"Transaporter Code",
      //     field:"transporter_code",
      //     width:"120"
      // },
      // {
      //     headerName:"Transaporter Name",
      //     field:"transporter_name",
      //     width:"120"
      // },
      // {
      //     headerName:"Route Mode",
      //     field:"route_mode",
      //     width:"120"
      // },
    ];
    var tool_config = this.state.tool_config;
    var material_configuration_chk = 0;
    // if (tool_config !== undefined && tool_config !== "") {
    //   material_configuration_chk = tool_config[0].material_configuration;
    // }
    var inputDetailscolsDefs = [
      {
        headerName: "Actions",
        field: "cluster",
        width: "100",
        pinned: "left",
        filter: false,
        sortable: false,
        params: {
          buttonName: "Edit",
          onClickFunction: this.updateOrderDetails,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      },
      {
        headerName: "Pickup Location (Source)",
        headerTooltip: "Pickup Location (Source)",
        field: "pickup_location_code",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Drop Location Code (Destination)",
        headerTooltip: "Drop Location Code (Destination)",
        field: "drop_location_code",
        width: 160,
        filter: true,
        resizable: true,
        editable: false,
        cellClass: function (params) {
          try {
            if (params.data.new_obd !== undefined) {
              if (params.data.new_obd === 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Drop Location Name (Destination)",
        headerTooltip: "Drop Location Name (Destination)",
        field: "drop_location_name",
        width: 150,
        filter: true,
        resizable: true,
        editable: false,
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Drop Location City",
        headerTooltip: "Drop Location City",
        field: "location_city",
        width: 140,
        filter: true,
        resizable: true,
        editable: false,
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Week Off",
        field: "holiday",
        width: 100,
        filter: true,
        resizable: true,
        editable: false,
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      // {
      //     headerName: "Destination State",
      //     field: "drop_location_state",
      //     width:200,
      //     filter: true,
      //     resizable: true,
      //     editable:false
      // },
      // {
      //     headerName: "Sales Order No",
      //     field: "so_number",
      //     width:200,
      //     filter: true,
      //     resizable: true,
      //     editable:false
      // },
      // {
      //     headerName: "No. of Tires",
      //     field: "no_of_tires",
      //     width:200,
      //     filter: true,
      //     resizable: true,
      //     editable:false
      // },
      {
        headerName: "Order Number",
        headerTooltip: "Order Number",
        field: "order_number",
        width: "130",
        cellClass: function (params) {
          try {
            if (params.data.new_obd !== undefined) {
              if (params.data.new_obd === 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Invoicing Date",
        headerTooltip: "Invoicing Date",
        field: "invoicing_date",
        width: "120",
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Quantity",
        headerTooltip: "Quantity",
        field: "quantity",
        width: "100",
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Weight (Kgs)",
        headerTooltip: "Weight (Kgs)",
        field: "weight_tons",
        width: 120,
        filter: true,
        resizable: true,
        editable: false,
        valueGetter: function (params) {
          try {
            if (
              params.data.weight_tons !== "" &&
              params.data.weight_tons !== undefined
            ) {
              return Math.round(params.data.weight_tons * 100) / 100;
            } else {
              return "";
            }
          } catch (error) {}
        },
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Demand Volume (M³)",
        headerTooltip: "Demand Volume (M³)",
        field: "demand_cmt",
        width: 120,
        filter: true,
        resizable: true,
        editable: false,
        // valueGetter: function (params) {
        //   try {
        //     if (
        //       params.data.demand_cmt !== "" &&
        //       params.data.demand_cmt !== undefined
        //     ) {
        //       return Math.round(params.data.demand_cmt * 100) / 100;
        //     } else {
        //       return "";
        //     }
        //   } catch (error) { }
        // },
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Dealer Available From",
        headerTooltip: "Dealer Available From",
        field: "dealer_available_start",
        width: "130",
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Dealer Available To",
        headerTooltip: "Dealer Available To",
        field: "dealer_available_end",
        width: "120",
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Delivery Date",
        field: "delivery_date",
        width: "120",
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Product Code",
        field: "product_code",
        width: "90",
        valueGetter: (params) => {
          if (
            params.data.product_code !== "" &&
            params.data.product_code !== undefined
          ) {
            return params.data.product_code;
          } else {
            return "N/A";
          }
        },
        cellClass: function (params) {
          try {
            if (params.data.new_obd != undefined) {
              if (params.data.new_obd == 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Dealer Type",
        field: "dealer_type",
        width: "120",
        valueGetter: (params) => {
          if (
            params.data.dealer_type !== "" &&
            params.data.dealer_type !== undefined
          ) {
            return params.data.dealer_type;
          } else {
            return "N/A";
          }
        },
        cellClass: function (params) {
          try {
            if (params.data.new_obd !== undefined) {
              if (params.data.new_obd === 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Customer Type",
        field: "customer_type",
        width: "100",
        valueGetter: (params) => {
          if (
            params.data.customer_type !== "" &&
            params.data.customer_type !== undefined
          ) {
            return params.data.customer_type;
          } else {
            return "N/A";
          }
        },
        cellClass: function (params) {
          try {
            if (params.data.new_obd !== undefined) {
              if (params.data.new_obd === 1) {
                return "gHighlight";
              }
            }
          } catch (error) {}
        },
      },
    ];

    var planSummarycolsDefs = [
      {
        headerName: "Trip No",
        field: "trip_no",
        width: "90",
      },
      {
        headerName: "Planned Day",
        headerTooltip: "Planned Day",
        field: "drop_node_total_time_day",
        width: "110",
        valueGetter: function (params) {
          try {
            if (
              params.data.drop_node_total_time_day !== "" &&
              params.data.drop_node_total_time_day !== undefined
            ) {
              var a = parseInt(params.data.drop_node_total_time_day);
              return "Day - " + (a + 1);
            } else {
              return params.data.drop_node_total_time_day;
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Vehicle No",
        field: "vehicle_no",
        width: "120",
        cellRendererSelector: function (params) {
          try {
            var rendComponent = {
              component: "RepeatIcon",
            };
            return rendComponent;
          } catch (error) {}
        },
      },
      {
        headerName: "Vehicle Type",
        field: "vehicle_type",
        width: "150",
      },
      {
        headerName: "Cost (₹)",
        field: "cost",
        width: "100",
        hide: showCost,
      },
      {
        headerName: "Drop Sequence",
        headerTooltip: "Drop Sequence",
        field: "drop_sequence",
        width: "100",
      },

      {
        headerName: "Order Number",
        headerTooltip: "Order Number",
        field: "order_number",
        width: "120",
      },
      {
        headerName: "Invoicing Date",
        headerTooltip: "Invoicing Date",
        field: "invoicing_date",
        width: "100",
      },
      // {
      //     headerName:"Transaporter Code",
      //     field:"transporter_code",
      //     width:"150"
      // },
      // {
      //     headerName:"Transaporter Name",
      //     field:"transporter_name",
      //     width:"150"
      // },
      // {
      //     headerName:"Route Mode",
      //     field:"route_mode",
      //     width:"120"
      // },
      {
        headerName: "Pick Up Location (Source)",
        field: "pickup_node",
        width: "140",
      },
      {
        headerName: "Drop Location Code (Destination)",
        headerTooltip: "Drop Location Code (Destination)",
        field: "drop_node",
        width: "150",
      },
      {
        headerName: "Drop Location Name (Destination)",
        headerTooltip: "Drop Location Name (Destination)",
        field: "drop_node_name",
        width: "150",
      },
      {
        headerName: "Destination Location City",
        headerTooltip: "Destination Location City",
        field: "demand_node_location",
        width: "120",
      },
      {
        headerName: "Destination Location State",
        headerTooltip: "Destination Location State",
        field: "demand_node_state",
        width: "120",
      },
      // {
      //     headerName:"Vehicle Max Volume(M³)",
      //     field:"vehicle_max_volume",
      //     width:160
      // },
      // {
      //     headerName:"Vehicle Max Weight(Kgs)",
      //     field:"vehicle_max_weight",
      //     width:160
      // },
      {
        headerName: "Quantity",
        headerTooltip: "Quantity",
        field: "quantity",
        width: "90",
        valueGetter: (params) => {
          try {
            if (
              params.data.quantity !== undefined &&
              params.data.quantity !== ""
            ) {
              return params.data.quantity.toFixed(2);
            } else {
              return "N/A";
            }
          } catch {}
        },
      },
      {
        headerName: "Volume (M³)",
        headerTooltip: "Volume (M³)",
        field: "demand_volume",
        width: "90",
      },
      {
        headerName: "Weight (Kgs)",
        headerTooltip: "Weight (Kgs)",
        field: "demand_weight",
        width: "90",
      },
      // {
      //     headerName:"Distance From Pickup Location (Kms)",
      //     headerTooltip:"Distance From Pickup Location (Kms)",
      //     field:"distance",
      //     width:"200",
      // },

      {
        headerName: "Distance To Destination (Kms)",
        headerTooltip: "Distance To Destination (Kms)",
        field: "distance",
        width: "150",
      },
      {
        headerName: "Expected Delivery Date",
        field: "delivery_date",
        width: "120",
      },
      // {
      //     headerName:"Time From Pickup Location (HH:MM)",
      //     headerTooltip:"Time From Pickup Location (HH:MM)",
      //     field:"drop_node_total_time_hrs",
      //     width:"200",
      // },
      {
        headerName: "Time To Destination",
        headerTooltip: "Time To Destination",
        field: "drop_node_total_time_hrs",
        width: "150",
      },
      {
        headerName: "Transit Time (HH:MM)",
        headerTooltip: "Transit Time (HH:MM)",
        field: "drop_node_transit_time_hrs",
        width: "120",
      },
      {
        headerName: "Loading Time (HH:MM)",
        headerTooltip: "Loading Time (HH:MM)",
        field: "drop_node_loading_time_mins",
        width: "120",
      },
      {
        headerName: "Unloading Time (HH:MM)",
        headerTooltip: "Unloading Time (HH:MM)",
        field: "drop_node_unloading_time_mins",
        width: "120",
      },
      // {
      //     headerName:"Distance From Previous Location (Kms)",
      //     field:"distance_from_prev_location",
      //     width:"200",
      // },
      // {
      //     headerName:"Time From Previous Location (HH:MM)",
      //     field:"time_from_prev_location",
      //     width:"200",
      // },
      // {
      //     headerName:"Drop Location City",
      //     field:"demand_node_location",
      //     width:"120",
      // },
      // {
      //     headerName:"Zone",
      //     field:"zone",
      //     width:"100"
      // },
      // {
      //     headerName:"Drop Location (Destination)",
      //     field:"drop_node",
      //     width:"150"
      // },
      // {
      //     headerName:"Vehicle Volume",
      //     field:"vehicle_volume",
      //     width:"120"
      // },
      // {
      //     headerName:"Vehicle Priority",
      //     field:"vehicle_priority",
      //     width:"120"
      // },
    ];
    var loadsDroppedcolsDefs = [
      // {
      //     headerName:"Drop Location (Destination)",
      //     field:"drop_location_code",
      //     width:"250"
      // },
      // {
      //     headerName:"Pick Up Location (Source)",
      //     field:"pickup_node",
      //     width:"150",
      // },
      {
        headerName: "Drop Location Code (Destination)",
        headerTooltip: "Drop Location Code (Destination)",
        field: "drop_location_code",
        width: "150",
      },
      {
        headerName: "Drop Location Name (Destination)",
        headerTooltip: "Drop Location Name (Destination)",
        field: "drop_location_name",
        width: "150",
      },
      {
        headerName: "Drop Location City",
        headerTooltip: "Drop Location City",
        field: "location_city",
        width: "150",
      },
      {
        headerName: "Order Number",
        headerTooltip: "Order Number",
        field: "order_number",
        width: "150",
      },
      {
        headerName: "Demand Volume (M³)",
        headerTooltip: "Demand Volume (M³)",
        field: "demand_cmt",
        width: "120",
      },
      {
        headerName: "Weight (Kgs)",
        headerTooltip: "Weight (Kgs)",
        field: "weight_tons",
        width: "120",
      },
      {
        headerName: "Quantity",
        headerTooltip: "Quantity",
        field: "quantity",
        width: "100",
      },
      {
        headerName: "Dropped Reason",
        headerTooltip: "Dropped Reasong",
        field: "invalid_locations",
        width: "250",
        valueGetter: function (params) {
          try {
            if (
              (params.data.invalid_location !== undefined &&
                params.data.invalid_location !== "") ||
              (params.data.is_qty_less !== undefined &&
                params.data.is_qty_less !== "") ||
              (params.data.is_kdd_enabled !== undefined &&
                params.data.is_kdd_enabled !== "")
            ) {
              if (params.data.invalid_location === 1) {
                return "Dealer Location Data Not In System";
              } else if (params.data.invalid_location === 2) {
                return "Delivery Date is less then Current Date";
              } else if (
                params.data.is_kdd_enabled !== undefined &&
                params.data.is_kdd_enabled !== ""
              ) {
                if (params.data.is_kdd_enabled === 1) {
                  return "KDD Customer";
                }
              } else if (
                params.data.is_qty_less !== undefined &&
                params.data.is_qty_less !== ""
              ) {
                if (params.data.is_qty_less === 1) {
                  return "Insufficient Load for Planning(< MOQ)";
                }
              } else if (
                params.data.is_kdd_enabled !== undefined &&
                params.data.is_kdd_enabled !== ""
              ) {
                if (params.data.is_kdd_enabled === 1) {
                  return "KDD Customer";
                }
              }
            } else {
              return "";
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Delivery Date",
        field: "delivery_date",
        width: "120",
      },
    ];
    // if (material_configuration_chk == 1) {
    //   loadsDroppedcolsDefs.push(
    //     {
    //       headerName: "Material",
    //       headerTooltip: "Material",
    //       field: "material_code",
    //       width: "250",
    //     },
    //     {
    //       headerName: "Invalid Material",
    //       headerTooltip: "Invalid Material",
    //       field: "invalid_material",
    //       width: "250",
    //     }
    //   );
    // } else {
    // }
    var data_type = this.state.data_type;
    var urlType = "outbound";
    if (data_type == 1 || data_type == "1") {
      urlType = "inbound";
    }
    let availableVehiclesGpsData = [];
    let availableVehiclesNodeData = [];

    // let availableVehiclesGpsData = this.state.showAdditionalPlannedVehicles;
    // let availableVehiclesNodeData = this.state.trip_nodes_data;
    // if (this.state.showAdditionalPlannedVehicles == 1  ) {

    //   availableVehiclesGpsData = this.state.trip_gps_data.filter(
    //     (item) => item.is_available == 0
    //   )
    //   availableVehiclesNodeData = this.state.trip_nodes_data.filter(
    //     (item) => item.is_available == 0
    //   );
    //   } else {
    //     availableVehiclesGpsData = this.state.trip_gps_data.filter(
    //       (item) => item.is_available == 1
    //     );
    //     availableVehiclesNodeData = this.state.trip_nodes_data.filter(
    //       (item) => item.is_available == 1
    //     );
    //   }
    if (
      this.state.regular_vehicles == 1 &&
      this.state.additional_vehicles == 1
    ) {
      availableVehiclesGpsData = this.state.trip_gps_data.filter(
        (item) => item.is_available == 0 || item.is_available == 1
      );
      availableVehiclesNodeData = this.state.trip_nodes_data.filter(
        (item) => item.is_available == 0 || item.is_available == 1
      );
    } else if (
      this.state.regular_vehicles == 1 &&
      this.state.additional_vehicles == 0
    ) {
      availableVehiclesGpsData = this.state.trip_gps_data.filter(
        (item) => item.is_available == 1
      );
      availableVehiclesNodeData = this.state.trip_nodes_data.filter(
        (item) => item.is_available == 1
      );
    } else if (
      this.state.regular_vehicles == 0 &&
      this.state.additional_vehicles == 1
    ) {
      availableVehiclesGpsData = this.state.trip_gps_data.filter(
        (item) => item.is_available == 0
      );
      availableVehiclesNodeData = this.state.trip_nodes_data.filter(
        (item) => item.is_available == 0
      );
    }
    // console.log(this.state.filteredData,'lllllr',availableVehiclesGpsData)

    //     let filteredData = [];
    //     let allPlanSummaryData = [];
    //     let data = this.state.plannedData;
    //     data.map((item) => allPlanSummaryData.push(...item.legs_data));
    //     if (this.state.filterPlannedTucksData == 1) {
    //       let customer_code = this.state.filterCustomer.value;
    //       let orderNo = this.state.filterOrder.value;
    //       // console.log("Hello");

    //       if (customer_code != "" && orderNo != "") {
    //         filteredData = data.filter((item) =>
    //           item.legs_data.some(
    //             (drops) =>
    //               drops.drop_node == customer_code && drops.order_number == orderNo
    //           )
    //         );
    //         console.log(filteredData, "new for1");
    //       } else if (customer_code != "") {
    //         filteredData = data.filter((item) =>
    //           item.legs_data.some((drops) => drops.drop_node == customer_code)
    //         );
    //       } else if (orderNo != "") {
    //         filteredData = data.filter((item) =>
    //           item.legs_data.some((drops) => drops.order_number == orderNo)
    //         );
    //       }
    //     } else {
    //       filteredData = this.state.plannedData;
    //     }

    //
    // var hasFlagOne = this.state.plannedData.some(function(item) {
    //   return item.route_finalized === 1;
    // });

    var { gpsVehiclesSelectedData } = this.state;
    // console.log(this.state.vehicleLists);

    let outputDetails = this.state.outputDetails;

    let plannedCustomersList = [];
    let unPlannedCustomersList = [];

    outputDetails.map((each) => {
      if (each.is_planned === 1) {
        let check = plannedCustomersList.includes(each.drop_node);
        if (!check) {
          plannedCustomersList.push(each.drop_node);
        }
      } else if (each.is_planned === 0) {
        let check = unPlannedCustomersList.includes(each.drop_node);
        if (!check) {
          unPlannedCustomersList.push(each.drop_node);
        }
      }
    });

    let plannedQuantity = 0;
    let unPlannedQuantity = 0;
    for (let each of outputDetails) {
      if (each.is_planned === 1) {
        plannedQuantity += parseFloat(each.quantity);
      } else if (each.is_planned === 0) {
        unPlannedQuantity += parseFloat(each.quantity);
      }
    }
    let totalQuantity =
      parseFloat(plannedQuantity) + parseFloat(unPlannedQuantity);
    let data = this.state.filteredData;
    var tatTotal = 0;
    var tat = 0;
    if (data.length > 0) {
      data.map((each) => {
        tatTotal += parseFloat(each.round_trip_time);
      });
      tat = (tatTotal / data.length).toFixed(2);
    }

    var existingPlanColDfs = [
      // {
      //     headerName:"Trip No",
      //     field:"trip_no",
      //     width:"100",
      // },

      // {
      //   headerName: "Planned Day",
      //   headerTooltip: "Planned Day",
      //   field: "drop_node_total_time_day",
      //   width: "90",
      //   valueGetter: function (params) {
      //     try {
      //       if (
      //         params.data.drop_node_total_time_day !== "" &&
      //         params.data.drop_node_total_time_day !== undefined
      //       ) {
      //         var a = parseInt(params.data.drop_node_total_time_day);
      //         return "Day - " + (a + 1);
      //       } else {
      //         return params.data.drop_node_total_time_day;
      //       }
      //     } catch (error) { }
      //   },
      // },
      // {
      //   headerName: "Drop Sequence",
      //   headerTooltip: "Drop Sequence",
      //   field: "drop_sequence",
      //   width: "100",
      // },
      // {
      //     headerName:"Trip No",
      //     headerTooltip:"Trip No",
      //     field:"trip_no",
      //     width:"120",
      // },
      {
        headerName: "Drop Location Code (Destination)",
        headerTooltip: "Drop Location Code (Destination)",
        field: "drop_node",
        width: "150",
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        headerName: "Drop Location Name (Destination)",
        headerTooltip: "Drop Location Name (Destination)",
        field: "drop_node_name",
        width: "150",
      },
      {
        headerName: "Destination Location City",
        headerTooltip: "Destination Location City",
        field: "demand_node_location",
        width: "120",
      },
      // {
      //     headerName:"Destination Location State",
      //     headerTooltip:"Destination Location State",
      //     field:"demand_node_state",
      //     width:"150",
      // },
      {
        headerName: "Order Number",
        headerTooltip: "Order Number",
        field: "order_number",
        width: "120",
      },
      {
        headerName: "Invoicing Date",
        headerTooltip: "Invoicing Date",
        field: "invoicing_date",
        width: "120",
      },
      {
        headerName: "Quantity",
        headerTooltip: "Quantity",
        field: "quantity",
        width: "90",
        valueGetter: (params) => {
          return params.data.quantity.toFixed(2);
        },
      },
      {
        headerName: "Weight (Kgs)",
        headerTooltip: "Weight (Kgs)",
        field: "demand_weight",
        width: "90",
      },
      {
        headerName: "Volume (M³)",
        headerTooltip: "Volume (M³)",
        field: "demand_volume",
        width: "90",
      },
      // {
      //     headerName:"Distance From Pickup Location (Kms)",
      //     headerTooltip:"Distance From Pickup Location (Kms)",
      //     field:"distance",
      //     width:"200",
      // },

      {
        headerName: "Distance To Destination (Kms)",
        headerTooltip: "Distance To Destination (Kms)",
        field: "distance",
        width: "150",
      },
      {
        headerName: "Expected Delivery Date",
        field: "delivery_date",
        width: "120",
      },
      // {
      //     headerName:"Time From Pickup Location (HH:MM)",
      //     headerTooltip:"Time From Pickup Location (HH:MM)",
      //     field:"drop_node_total_time_hrs",
      //     width:"200",
      // },
      {
        headerName: "Time To Destination",
        headerTooltip: "Time To Destination",
        field: "drop_node_total_time_hrs",
        width: "150",
      },
      {
        headerName: "Transit Time (HH:MM)",
        headerTooltip: "Transit Time (HH:MM)",
        field: "drop_node_transit_time_hrs",
        width: "120",
      },
      // {
      //     headerName:"Loading Time (HH:MM)",
      //     headerTooltip:"Loading Time (HH:MM)",
      //     field:"drop_node_loading_time_mins",
      //     width:"120",
      // },
      {
        headerName: "Unloading Time (HH:MM)",
        headerTooltip: "Unloading Time (HH:MM)",
        field: "drop_node_unloading_time_mins",
        width: "120",
      },
    ];

    var newPlanColDfs = [
      // {
      //     headerName:"Trip No",
      //     field:"trip_no",
      //     width:"100",
      // },

      {
        headerName: "Planned Day",
        headerTooltip: "Planned Day",
        field: "drop_node_total_time_day",
        width: "90",
        valueGetter: function (params) {
          try {
            if (
              params.data.drop_node_total_time_day !== "" &&
              params.data.drop_node_total_time_day !== undefined
            ) {
              var a = parseInt(params.data.drop_node_total_time_day);
              return "Day - " + (a + 1);
            } else {
              return params.data.drop_node_total_time_day;
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Drop Sequence",
        headerTooltip: "Drop Sequence",
        field: "drop_sequence",
        width: "100",
      },
      // {
      //     headerName:"Trip No",
      //     headerTooltip:"Trip No",
      //     field:"trip_no",
      //     width:"120",
      // },
      {
        headerName: "Drop Location Code (Destination)",
        headerTooltip: "Drop Location Code (Destination)",
        field: "drop_node",
        width: "150",
        // checkboxSelection: true,
        // headerCheckboxSelection: true,
      },
      {
        headerName: "Drop Location Name (Destination)",
        headerTooltip: "Drop Location Name (Destination)",
        field: "drop_node_name",
        width: "150",
      },
      {
        headerName: "Destination Location City",
        headerTooltip: "Destination Location City",
        field: "demand_node_location",
        width: "120",
      },
      {
        headerName: "Dealer Type",
        field: "dealer_type",
        width: "100",
        valueGetter: (params) => {
          if (
            params.data.dealer_type !== "" &&
            params.data.dealer_type !== undefined
          ) {
            return params.data.dealer_type;
          } else {
            return "N/A";
          }
        },
      },
      {
        headerName: "Week Off",
        field: "week_off",
        width: 100,
      },
      // {
      //     headerName:"Destination Location State",
      //     headerTooltip:"Destination Location State",
      //     field:"demand_node_state",
      //     width:"150",
      // },
      {
        headerName: "Order Number",
        headerTooltip: "Order Number",
        field: "order_number",
        width: "120",
      },
      {
        headerName: "Invoicing Date",
        headerTooltip: "Invoicing Date",
        field: "invoicing_date",
        width: "120",
      },
      {
        headerName: "Quantity",
        headerTooltip: "Quantity",
        field: "quantity",
        width: "90",
        valueGetter: (params) => {
          return params.data.quantity.toFixed(2);
        },
      },
      {
        headerName: "Weight (Kgs)",
        headerTooltip: "Weight (Kgs)",
        field: "demand_weight",
        width: "90",
      },
      {
        headerName: "Volume (M³)",
        headerTooltip: "Volume (M³)",
        field: "demand_volume",
        width: "90",
      },
      // {
      //     headerName:"Distance From Pickup Location (Kms)",
      //     headerTooltip:"Distance From Pickup Location (Kms)",
      //     field:"distance",
      //     width:"200",
      // },

      {
        headerName: "Distance To Destination (Kms)",
        headerTooltip: "Distance To Destination (Kms)",
        field: "distance",
        width: "150",
      },
      {
        headerName: "Expected Delivery Date",
        field: "delivery_date",
        width: "120",
      },
      // {
      //     headerName:"Time From Pickup Location (HH:MM)",
      //     headerTooltip:"Time From Pickup Location (HH:MM)",
      //     field:"drop_node_total_time_hrs",
      //     width:"200",
      // },
      {
        headerName: "Time To Destination",
        headerTooltip: "Time To Destination",
        field: "drop_node_total_time_hrs",
        width: "150",
      },
      {
        headerName: "Transit Time (HH:MM)",
        headerTooltip: "Transit Time (HH:MM)",
        field: "drop_node_transit_time_hrs",
        width: "120",
      },
      // {
      //     headerName:"Loading Time (HH:MM)",
      //     headerTooltip:"Loading Time (HH:MM)",
      //     field:"drop_node_loading_time_mins",
      //     width:"120",
      // },
      {
        headerName: "Unloading Time (HH:MM)",
        headerTooltip: "Unloading Time (HH:MM)",
        field: "drop_node_unloading_time_mins",
        width: "120",
      },
    ];

    var updatedunPlannedcolsDefs = [
      // {
      //     headerName:"Vehicle No",
      //     field:"vehicle_no",
      //     width:"100"
      // },
      // {
      //     headerName:"Cluster",
      //     field:"cluster",
      //     width:"100"
      // },
      // {
      //     headerName:"Zone",
      //     field:"zone",
      //     width:"100"
      // },
      // {
      //     headerName:"Pick Up Location (Source)",
      //     field:"pickup_node",
      //     width:"150",
      // },
      {
        headerName: "Drop Location Code",
        headerTooltip: "Drop Location Code",
        field: "drop_node",
        width: "150",
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      {
        headerName: "Drop Location Name",
        headerTooltip: "Drop Location Name",
        field: "drop_node_name",
        width: "180",
      },
      {
        headerName: "Drop Location City",
        headerTooltip: "Drop Location City",
        field: "demand_node_location",
        width: "150",
      },
      {
        headerName: "Order Number",
        field: "order_number",
        width: "120",
        valueGetter: (params) => {
          return params.data.order_number;
        },
      },
      {
        headerName: "Distance from Plant",
        headerTooltip: "Distance from plant",
        field: "distance_from_plant",
        width: "100",
        valueGetter: (params) => {
          if (
            params.data.distance_from_plant !== "" &&
            params.data.distance_from_plant !== undefined
          ) {
            return params.data.distance_from_plant.toFixed(2);
          } else {
            return "N/A";
          }
        },
      },
      {
        headerName: "Week Off",
        field: "holiday",
        width: 100,
        filter: true,
        resizable: true,
        editable: false,
      },
      {
        headerName: "Demand Volume (M³)",
        headerTooltip: "Demand Volume (M³)",
        field: "demand_volume",
        width: "130",
      },
      {
        headerName: "Weight (Kgs)",
        headerTooltip: "Weight (Kgs)",
        field: "demand_weight",
        width: "120",
      },
      {
        headerName: "Quantity",
        headerTooltip: "Quantity",
        field: "quantity",
        width: "100",
        valueGetter: (params) => {
          if (
            params.data.quantity !== "" &&
            params.data.quantity !== undefined
          ) {
            return params.data.quantity.toFixed(2);
          } else {
            return "N/A";
          }
        },
      },
      {
        headerName: "Delivery Date",
        field: "delivery_date",
        width: "120",
      },
      // {
      //     headerName:"Sales Order No",
      //     field:"so_number",
      //     width:"180",
      // },

      // {
      //     headerName:"Route No",
      //     field:"route_no",
      //     width:"120",
      // },
      // {
      //     headerName:"Vehicle Type",
      //     field:"vehicle_type",
      //     width:"120"
      // },
      // {
      //     headerName:"Vehicle Volume",
      //     field:"vehicle_volume",
      //     width:"120"
      // },
      // {
      //     headerName:"Vehicle Priority",
      //     field:"vehicle_priority",
      //     width:"120"
      // },
      // {
      //     headerName:"Transaporter Code",
      //     field:"transporter_code",
      //     width:"120"
      // },
      // {
      //     headerName:"Transaporter Name",
      //     field:"transporter_name",
      //     width:"120"
      // },
      // {
      //     headerName:"Route Mode",
      //     field:"route_mode",
      //     width:"120"
      // },
    ];
    var remarks = [
      { value: "Load Cancelled", label: "Load Cancelled" },
      {
        value: "New OBD received within same route",
        label: "New OBD received within same route",
      },
      {
        value: "Multiple vehicles planned for same route",
        label: "Multiple vehicles planned for same route",
      },
      { value: "Invalid Clubbing", label: "Invalid Clubbing" },
      { value: "IFRS Risk", label: "IFRS Risk" },
      {
        value: "Delay in Customer Serving",
        label: "Delay in Customer Serving",
      },
      { value: "Duplicate Order Number", label: "Duplicate Order Number" },
      { value: "Others", label: "Others" },
    ];
    // console.log('remark',this.state.remark.value)
    return (
      <>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>

        <div class="row mb-3p mt-0 pt- 0 ">
          <div className="col-sm-12 d-flex">
            <div
              className={
                this.state.showAvailablityTable
                  ? "col-sm-7 "
                  : "col-sm-12 " + "  d-inline"
              }
            >
              {this.state.simulation_time != "" &&
              localStorage.getItem("role") == "admin" ? (
                <span className="float-right f11">
                  Simulation Time : {this.state.simulation_time}
                </span>
              ) : (
                ""
              )}
              <h5
                className="fbold d-flex justify-content-between"
                style={{ fontSize: "16px" }}
              >
                <div>
                  <div className="row">
                    <div className="col-md-12 col-sm-12">
                      <ul className="breadcrumb">
                        <li>
                          <a href={"/dipatchplanhistory?outbound"}>
                            Summary Dashboard
                          </a>
                        </li>
                        <li className="dispatch-heading ">
                          Dispatch Plan Details /{" "}
                          {this.state.locationNameInHeader} /{" "}
                          {this.state.transactionId}
                          {" / "}
                          {this.state.run_type}{" "}
                          {this.state.is_reload == ""
                            ? ""
                            : " / " + this.state.is_reload}{" "}
                          - Min VFR-{this.state.minVFR}% /{" "}
                          {this.state.createdDate}{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </h5>

              <div className="row mt-3p">
                <div className="col-md-12 col-sm-12">
                  <ul className="d-tabs">
                    <li
                      onClick={this.onClickTab.bind(this, "inputInfo")}
                      className={"lttabs " + this.state.inputInfo}
                    >
                      <button type="button" className="btn">
                        Order Information
                      </button>
                    </li>
                    <li
                      onClick={this.onClickTab.bind(this, "selectedTrucks")}
                      className={"lttabs " + this.state.selectedTrucks}
                    >
                      <button type="button" className="btn">
                        Vehicle Availability
                      </button>
                    </li>

                    <li
                      onClick={this.onClickTab.bind(this, "summary")}
                      className={"lttabs " + this.state.summary}
                    >
                      <button type="button" className="btn">
                        Summary
                      </button>
                    </li>

                    <li
                      onClick={this.onClickTab.bind(this, "plannedTrucks")}
                      className={"lttabs " + this.state.plannedTrucks}
                    >
                      <button type="button" className="btn">
                        Planned Summary
                      </button>
                    </li>
                    <li
                      onClick={this.onClickTab.bind(this, "planSummary")}
                      className={"lttabs " + this.state.planSummary}
                    >
                      <button type="button" className="btn">
                        Planned Vehicles
                      </button>
                    </li>

                    <li
                      onClick={this.onClickTab.bind(this, "unPlannedTrucks")}
                      className={"lttabs " + this.state.unPlannedTrucks}
                    >
                      <button type="button" className="btn">
                        Unplanned Loads
                      </button>
                    </li>

                    <li
                      onClick={this.onClickTab.bind(this, "loadsDropped")}
                      className={"lttabs " + this.state.loadsDropped}
                    >
                      <button type="button" className="btn">
                        Invalid Drop Data
                      </button>
                    </li>

                    <li className="float-right" style={{ alignSelf: "center" }}>
                      <a
                        href={"/dipatchplanhistory?" + urlType}
                        className="btn btn-danger mr-10p mb-10p"
                        onClick={this.onClickBack}
                      >
                        Back
                      </a>
                      {this.state.configs.length > 0 ? (
                        <button
                          type="button"
                          onClick={this.onClickConfigs}
                          className="btn btn-danger mr-10p mb-10p"
                          style={{ position: "relative", marginLeft: "10px" }}
                          onMouseEnter={() =>
                            this.setState({ showConfigButtonTooltip: true })
                          }
                          onMouseLeave={() =>
                            this.setState({ showConfigButtonTooltip: false })
                          }
                        >
                          <i className="fa fa-cogs f13"></i>
                          {this.state.showConfigButtonTooltip && (
                            <span
                              style={{
                                visibility: "visible",
                                position: "absolute",
                                bottom: "85%",
                                left: "-15%",
                                transform: "transalateX(-50%)",
                                backgroundColor: "white",
                                color: "#000000",
                                padding: "2px",
                                borderRadius: "4px",
                                fontSize: "12px",
                              }}
                            >
                              Configurations
                            </span>
                          )}
                        </button>
                      ) : (
                        ""
                      )}
                    </li>
                  </ul>
                </div>
              </div>
              {/* <Counters context={this} /> */}

              <div className={"row mt-5p " + this.state.configData}>
                {this.state.vehicle_type_config.length > 0 ? (
                  <div className="col-md-6 col-sm-6">
                    <div className="col-md-12 col-sm-12">
                      <h5 className="summaryDetailsTitle">
                        Vehicle Type Configurations
                      </h5>
                    </div>
                    <div
                      id="myGrid"
                      style={{ width: "100%", height: "40vh" }}
                      className="col-sm-12 ag-theme-balham dropdown"
                    >
                      {/* {console.log("hit")} */}
                      <AgGridReact
                        // modules={this.state.modules}
                        rowData={this.state.vehicle_type_config}
                        columnDefs={vehicleTypecolsDefs}
                        gridOptions={{ context: { componentParent: this } }}
                        defaultColDef={this.state.defaultColDef}
                        frameworkComponents={this.state.frameworkComponents}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        enableRangeSelection={true}
                        sideBar={this.state.sideBar}
                        statusBar={this.state.statusBar}
                        enablePivot={true}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.material_configuration.length > 0 ? (
                  <div className="col-md-6 col-sm-6">
                    <div className="col-md-12 col-sm-12">
                      <h5 className="summaryDetailsTitle">
                        Material Configurations
                      </h5>
                    </div>
                    <div
                      id="myGrid"
                      style={{ width: "100%", height: "40vh" }}
                      className="col-sm-12 ag-theme-balham dropdown"
                    >
                      <AgGridReact
                        // modules={this.state.modules}
                        rowData={this.state.material_configuration}
                        columnDefs={materialConfigcolsDefs}
                        gridOptions={{ context: { componentParent: this } }}
                        defaultColDef={this.state.defaultColDef}
                        frameworkComponents={this.state.frameworkComponents}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        enableRangeSelection={true}
                        sideBar={this.state.sideBar}
                        statusBar={this.state.statusBar}
                        enablePivot={true}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.freight_configuration.length > 0 ? (
                  <div className="col-md-6 col-sm-6">
                    <div className="col-md-12 col-sm-12 mt-20p">
                      <h5 className="summaryDetailsTitle">
                        Freight Configurations
                      </h5>
                    </div>
                    <div
                      id="myGrid"
                      style={{ width: "100%", height: "40vh" }}
                      className="col-sm-12 ag-theme-balham dropdown"
                    >
                      <AgGridReact
                        // modules={this.state.modules}
                        rowData={this.state.freight_configuration}
                        columnDefs={freightConfigcolsDefs}
                        gridOptions={{ context: { componentParent: this } }}
                        defaultColDef={this.state.defaultColDef}
                        frameworkComponents={this.state.frameworkComponents}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        enableRangeSelection={true}
                        sideBar={this.state.sideBar}
                        statusBar={this.state.statusBar}
                        enablePivot={true}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {this.state.vehicle_availability_config.length > 0 ? (
                  <div className="col-md-6 col-sm-6">
                    <div className="col-md-12 col-sm-12 mt-20p">
                      <h5 className="summaryDetailsTitle">
                        Vehicle Availability Configurations
                      </h5>
                    </div>
                    <div
                      id="myGrid"
                      style={{ width: "100%", height: "40vh" }}
                      className="col-sm-12 ag-theme-balham dropdown"
                    >
                      <AgGridReact
                        rowData={this.state.vehicle_availability_config}
                        columnDefs={vehicleAvailabilityConfigcolsDefs}
                        gridOptions={{ context: { componentParent: this } }}
                        defaultColDef={this.state.defaultColDef}
                        frameworkComponents={this.state.frameworkComponents}
                        paginationPageSize={this.state.paginationPageSize}
                        pagination={true}
                        enableRangeSelection={true}
                        sideBar={this.state.sideBar}
                        statusBar={this.state.statusBar}
                        enablePivot={true}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {/* Selected Trucks */}
              <div className={"row mt-5p " + this.state.selectedVehicle}>
                <div
                  id="myGrid1"
                  style={{ width: "100%", height: "70vh" }}
                  className="col-sm-12 ag-theme-balham dropdown"
                >
                  <AgGridReact
                    // modules={this.state.modules}
                    rowData={this.state.vehiclesSelectedData}
                    columnDefs={vehiclesSelectedDataDefs}
                    gridOptions={{ context: { componentParent: this } }}
                    defaultColDef={this.state.defaultColDef}
                    frameworkComponents={this.state.frameworkComponents}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    getRowStyle={this.getRowStyle}
                    enableRangeSelection={true}
                    sideBar={this.state.sideBar}
                    statusBar={this.state.statusBar}
                    enablePivot={true}
                  />
                </div>
              </div>
              {/* Planned Trucks*/}
              <div className={"row mt-1 " + this.state.plannedTrucksData}>
                <>
                  <div className="col-sm-12 mb-2">
                    <div className="card">
                      <div className="card-header pt-1">
                        <div className="row">
                          <div className="col-sm-2 mb-5p">
                            <label>Order Number</label>
                            <Select
                              value={this.state.filterOrder}
                              name="filterMode"
                              onChange={this.onChangeFilterOrder}
                              options={this.state.allOrdersList}
                            />
                          </div>

                          <div className="col-sm-2">
                            <label>Customer</label>
                            <Select
                              value={this.state.filterCustomer}
                              name="filterCustomer"
                              onChange={this.onChangeFilterCustomer}
                              options={this.state.allCustomersList}
                            />
                          </div>

                          <div className="col-sm-2">
                            <button
                              type="button"
                              className="btn btn-success "
                              onClick={this.onClickFilterData}
                              style={{ marginTop: "30px" }}
                            >
                              Get Data
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger "
                              onClick={this.onClickResetPlannedVehicleData}
                              style={{ marginTop: "30px" }}
                            >
                              Reset
                            </button>
                          </div>
                          <div
                            style={{ marginTop: "30px", marginLeft: "auto" }}
                          >
                            <button
                              type="button"
                              onClick={this.onClickShowRoutesData.bind(this)}
                              className="btn btn-success ml-10p"
                            >
                              Vehicle Routes
                            </button>
                            {/* <button
                              type="button"
                              onClick={this.onClickShowReloadedRoutesData.bind(this)}
                              className="btn btn-success ml-10p"
                            >
                              Additional Vehicle Routes
                          </button> */}
                            <button
                              type="button"
                              onClick={this.onClickDownloadPlannedData}
                              className="btn btn-danger ml-10p"
                            >
                              <i className="fa fa-download"></i> Planned Data
                            </button>

                            <UploadData
                              location={this.state.location}
                              transaction_id={this.state.transactionId}
                              data_type={this.state.data_type}
                              orderNumbersList={this.state.orderNumbersList}
                              invoicingDate={this.state.invoicingDate}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3p mb-1" style={{ width: "100%" }}>
                    {/* col-sm-12 container-for-planned beffect */}
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 beffect ">
                      <div className="card">
                        <div className="card-body">
                          <div
                            className="crm-numbers pb-0"
                            style={{
                              margin: "0px !important",
                              padding: "0px !important",
                            }}
                          >
                            <div
                              className="row"
                              style={{
                                margin: "0px",
                                padding: "0px",
                                alignSelf: "center",
                              }}
                            >
                              {/* customers counter */}
                              <div
                                className=" col cursorPointer"
                                style={{ marginTop: "9px", minWidth: "135px" }}
                              >
                                <span
                                  className="f12"
                                  style={{ marginTop: "0px", padding: "0px" }}
                                >
                                  {/* <FontAwesomeIcon icon={faUser} style={{ fontSize: '24px', color: 'darkgreen' }} /> */}
                                  <i
                                    className="fa fa-user f20 txt-primary"
                                    style={{
                                      fontSize: "17px",
                                      marginTop: "0px  !important",
                                      padding: "0px !important",
                                    }}
                                  ></i>
                                  <span style={{ paddingLeft: "2px" }}>
                                    Total Customers
                                  </span>
                                </span>
                                <h4 className="f17 txt-primary">
                                  <span className="counter">
                                    {plannedCustomersList.length +
                                      unPlannedCustomersList.length}
                                  </span>
                                </h4>
                                <div className="row pb-0">
                                  <div className="col cursorPointer pr-0 pl-0">
                                    <span
                                      className="f13"
                                      style={{
                                        fontSize: "12px ",
                                        paddingLeft: "1px",
                                      }}
                                    >
                                      <FaUserClock
                                        className="txt-primary"
                                        style={{ fontSize: "14px" }}
                                      />
                                      <br /> Planned
                                    </span>
                                    <h5 className="f17 txt-primary">
                                      <span className="counter">
                                        {plannedCustomersList.length}
                                      </span>
                                    </h5>
                                  </div>
                                  <div className="col cursorPointer pr-1 pl-1">
                                    <span
                                      className="f13"
                                      style={{ fontSize: "12px" }}
                                    >
                                      <FaUserTimes
                                        className="txt-primary"
                                        style={{ fontSize: "14px" }}
                                      />
                                      <br /> Unplanned
                                    </span>
                                    <h5 className="f17 txt-primary">
                                      <span className="counter">
                                        {unPlannedCustomersList.length}
                                      </span>
                                    </h5>
                                  </div>
                                </div>
                              </div>

                              {/* quantity counter */}
                              <div
                                className="col cursorPointer"
                                style={{
                                  alignSelf: "center",
                                  margin: "10px",
                                  minWidth: "165px",
                                }}
                              >
                                <span className="f12">
                                  {/* <FontAwesomeIcon icon={faUser} style={{ fontSize: '24px', color: 'darkgreen' }} /> */}
                                  <MdProductionQuantityLimits
                                    className="green"
                                    style={{ fontSize: "20px" }}
                                  />
                                  <span style={{ marginLeft: "3px" }}>
                                    Total Quantity
                                  </span>
                                </span>
                                <h4 className="f17 green">
                                  <span className="counter">
                                    {totalQuantity.toFixed(2)}
                                  </span>
                                </h4>
                                <div className="row">
                                  <div className="col cursorPointer pr-0 pl-0">
                                    <span
                                      className="f13"
                                      style={{ fontSize: "12px " }}
                                    >
                                      <FaRegCalendarCheck
                                        className="green"
                                        style={{ fontSize: "14px" }}
                                      />
                                      <br /> Planned
                                    </span>

                                    <h5 className="f17">
                                      <span className="counter green">
                                        {plannedQuantity.toFixed(2)}
                                      </span>
                                    </h5>
                                  </div>
                                  <div className="col cursorPointer pr-1 pl-1">
                                    <span
                                      className="f13"
                                      style={{ fontSize: "12px" }}
                                    >
                                      <FaRegCalendarTimes
                                        className="green"
                                        style={{ fontSize: "14px" }}
                                      />
                                      <br /> Unplanned
                                    </span>

                                    <h5 className="f17">
                                      <span className="counter green">
                                        {unPlannedQuantity.toFixed(2)}
                                      </span>
                                    </h5>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="col cursorPointer"
                                style={{
                                  margin: "0px",
                                  alignSelf: "center",
                                  minWidth: "110px",
                                }}
                              >
                                <span
                                  className="f12"
                                  style={{ margin: "0px", padding: "0px" }}
                                >
                                  {/* <FontAwesomeIcon icon={faRoad} style={{ fontSize: '24px', color: 'darkgreen' }} /> */}
                                  <i
                                    className="fa fa-road  f20"
                                    style={{
                                      fontSize: "20px",
                                      color: "#dc5df5",
                                    }}
                                  ></i>
                                  <br />
                                  Total Round Trip Distance (KM)
                                </span>
                                <h4
                                  className="f17"
                                  style={{ color: "#dc5df5" }}
                                >
                                  <span className="counter">
                                    {this.state.total_round_distance}
                                  </span>
                                </h4>
                              </div>
                              <div
                                className="col cursorPointer"
                                style={{
                                  margin: "0px",
                                  minWidth: "80px",
                                  marginTop: "22px",
                                }}
                              >
                                <span
                                  className="f12"
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    marginBottom: "9px",
                                  }}
                                >
                                  {/* <FontAwesomeIcon icon={faPercentage} style={{ fontSize: '24px', color: 'darkgreen' }}❄ /> */}
                                  <i
                                    className="fa fa-calendar-check-o f20"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "20px",
                                      color: "#0683cc",
                                      marginBottom: "11px",
                                    }}
                                  ></i>
                                  <br /> Planning %
                                </span>
                                <h4
                                  className="f17"
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    color: "#0683cc",
                                    marginTop: "8px",
                                  }}
                                >
                                  <span className="counter">
                                    {(
                                      (this.state.planned_orders /
                                        (this.state.unPlannedData.length +
                                          this.state.planned_orders +
                                          this.state.loadDroppedDetails
                                            .length)) *
                                      100
                                    ).toFixed(2)}
                                  </span>
                                </h4>
                              </div>

                              <div
                                className="col cursorPointer "
                                style={{
                                  margin: "0px",
                                  alignSelf: "center",
                                  minWidth: "100px",
                                }}
                              >
                                <span className="f12">
                                  {/* <FontAwesomeIcon icon={faPercentage} style={{ fontSize: '24px', color: 'darkgreen' }}❄ /> */}
                                  <i
                                    className="fa fa-snowflake green f24"
                                    aria-hidden="true"
                                    style={{ fontSize: "24px" }}
                                  >
                                    ❄
                                  </i>
                                  <br /> Cool Chain <br /> Adherence %
                                </span>
                                <h4 className="f17 green">
                                  <span className="counter">
                                    {/* {((this.state.total_planned_quantity/this.state.total_given_quantity)*100).toFixed(2)} */}
                                    100.00
                                  </span>
                                </h4>
                              </div>
                              <div
                                className="col cursorPointer"
                                style={{
                                  margin: "0px",
                                  alignSelf: "center",
                                  minWidth: "65px",
                                }}
                              >
                                <span className="f12">
                                  {/* <FontAwesomeIcon icon={faPercentage} style={{ fontSize: '24px', color: 'darkgreen' }} /> */}
                                  <i
                                    className="fa fa-cube f24"
                                    style={{
                                      fontSize: "24px",
                                      color: "#dc5df5",
                                      marginBottom: "7px",
                                    }}
                                  ></i>
                                  <br /> VFR %
                                </span>
                                <h4
                                  className="f17"
                                  style={{ color: "#dc5df5", marginTop: "7px" }}
                                >
                                  <span className="counter">
                                    {this.state.VFR}
                                  </span>
                                </h4>
                              </div>
                              <div
                                className="col cursorPointer"
                                style={{
                                  margin: "0px",
                                  alignSelf: "center",
                                  minWidth: "95px",
                                }}
                              >
                                <span className="f12">
                                  {/* <FontAwesomeIcon icon={faPercentage} style={{ fontSize: '24px', color: 'darkgreen' }}❄ /> */}
                                  <i
                                    className="fa fa-clock-o f24"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "24px",
                                      color: "#0683cc",
                                    }}
                                  ></i>
                                  <br />
                                  Ontime Dispatch %
                                </span>
                                <h4
                                  className="f17"
                                  style={{ color: "#0683cc" }}
                                >
                                  <span className="counter">
                                    {this.state.ontimedispatchPercentage}
                                  </span>
                                </h4>
                              </div>
                              <div
                                className="col cursorPointer"
                                style={{
                                  margin: "0px",
                                  alignSelf: "center",
                                  minWidth: "85px",
                                }}
                              >
                                <span className="f12">
                                  {/* <FontAwesomeIcon icon={faPercentage} style={{ fontSize: '24px', color: 'darkgreen' }}❄ /> */}
                                  <i
                                    className="fa fa-clock-o green"
                                    aria-hidden="true"
                                    style={{ fontSize: "24px" }}
                                  ></i>
                                  <br /> Ontime Delivery %
                                </span>
                                <h4 className="green f17">
                                  <span className="counter">
                                    {this.state.ontimedeliveryPercentage}
                                  </span>
                                </h4>
                              </div>
                              <div
                                className="col cursorPointer"
                                style={{
                                  margin: "0px",
                                  minWidth: "65px",
                                  marginTop: "24px",
                                }}
                              >
                                <span
                                  className="f12"
                                  style={{
                                    margin: "0px",
                                    padding: "0px",
                                    marginTop: "4px",
                                  }}
                                >
                                  <i
                                    className="fa fa-clock-o txt-primary"
                                    aria-hidden="true"
                                    style={{
                                      fontSize: "24px",
                                      marginBottom: "9px",
                                    }}
                                  ></i>
                                  <br />
                                  Avg TAT
                                </span>
                                <h4
                                  className="f17 txt-primary"
                                  style={{ marginTop: "10px" }}
                                >
                                  <span className="counter">{tat}</span>
                                </h4>
                              </div>

                              {/* <div className="col cursorPointer">
                              <span className="f13"> */}
                              {/* <FontAwesomeIcon icon={faClock} style={{ fontSize: '24px', color: 'darkgreen' }} /> */}
                              {/* <i className="fa fa-clock-o" style={{ fontSize: '24px', color: ' red' }}></i>
                                <br /> Total Transit Time (HH : MM)
                              </span>
                              <h4 className=" txt-danger f30">
                                <span className="counter">
                                  {this.state.total_tansit_time}
                                </span>
                              </h4> */}
                              {/* </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* planed Summary  */}
                  <div
                    id="myGrid"
                    style={{ width: "100%", height: "70vh" }}
                    className="col-sm-12 ag-theme-balham dropdown mt-2"
                  >
                    <AgGridReact
                      rowData={this.state.filteredData}
                      columnDefs={plannedcolsDefs}
                      gridOptions={{ context: { componentParent: this } }}
                      statusBar={this.state.statusBar}
                      sideBar={this.state.sideBar}
                      enablePivot={true}
                      defaultColDef={this.state.defaultColDef}
                      frameworkComponents={this.state.frameworkComponents}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      enableRangeSelection={true}
                      // onCellClicked={this.onCellClickedGrid.bind(this)}
                      masterDetail={true}
                      detailCellRendererParams={
                        this.state.detailCellRendererParams
                      }
                      rowClassRules={this.state.rowClassRules}
                    />
                  </div>
                </>
              </div>

              {/*Unplanned Loads */}
              <div className={"row mt-5p " + this.state.unPlannedTrucksData}>
                <button
                  type="button"
                  onClick={this.onClickShowLocationData.bind(this)}
                  className="btn btn-success ml-10p"
                >
                  View Locations
                </button>
                <div
                  id="myGrid"
                  style={{ width: "100%", height: "70vh" }}
                  className="col-sm-12 ag-theme-balham dropdown"
                >
                  <AgGridReact
                    // modules={this.state.modules}
                    rowData={this.state.unPlannedData}
                    columnDefs={unPlannedcolsDefs}
                    gridOptions={{ context: { componentParent: this } }}
                    defaultColDef={this.state.defaultColDef}
                    frameworkComponents={this.state.frameworkComponents}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    sideBar={this.state.sideBar}
                    enablePivot={true}
                    enableRangeSelection={true}
                    statusBar={this.state.statusBar}
                    getRowStyle={this.getRowStyle}
                  />
                </div>
              </div>

              {/* Ivalid Dropped Data */}
              <div className={"row mt-5p " + this.state.loadsDroppedData}>
                <div
                  id="myGrid"
                  style={{ width: "100%", height: "70vh" }}
                  className="col-sm-12 ag-theme-balham dropdown"
                >
                  <AgGridReact
                    // modules={this.state.modules}
                    rowData={this.state.loadDroppedDetails}
                    columnDefs={loadsDroppedcolsDefs}
                    gridOptions={{ context: { componentParent: this } }}
                    defaultColDef={this.state.defaultColDef}
                    frameworkComponents={this.state.frameworkComponents}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    enableRangeSelection={true}
                    sideBar={this.state.sideBar}
                    enablePivot={true}
                    statusBar={this.state.statusBar}
                    getRowStyle={this.getRowStyle}
                  />
                </div>
              </div>
              {/* Order Information */}
              <div className={"row mt-5p " + this.state.inputInfoData}>
                <div
                  id="myGrid"
                  style={{ width: "100%", height: "70vh" }}
                  className="col-sm-12 ag-theme-balham dropdown"
                >
                  <div
                    style={{
                      fontSize: "12px",
                      color: "#ff0000",
                      textAlign: "right",
                      marginTop: "2px",
                    }}
                  >
                    Note: &nbsp; 1.Invalid orders are not being shown
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <br />
                    2.Duplicate OBDs are shown in light Red Colour <br />
                    3.Newly Added OBDs are shown in
                    Green&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </div>
                  <AgGridReact
                    // modules={this.state.modules}
                    rowData={updatedInputdetails}
                    columnDefs={inputDetailscolsDefs}
                    gridOptions={{ context: { componentParent: this } }}
                    defaultColDef={this.state.defaultColDef}
                    frameworkComponents={this.state.frameworkComponents}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    enableRangeSelection={true}
                    sideBar={this.state.sideBar}
                    enablePivot={true}
                    statusBar={this.state.statusBar}
                    getRowStyle={this.getRowStyle}
                  />
                </div>
              </div>

              {/* planed Vehicles  */}
              {/* Planned Vehicles Data */}
              <div className={"row mt-5p " + this.state.planSummaryData}>
                <div
                  id="myGrid"
                  style={{ width: "100%", height: "70vh" }}
                  className="col-sm-12 ag-theme-balham dropdown"
                >
                  <AgGridReact
                    // modules={this.state.modules}
                    rowData={this.state.allPlanSummaryData}
                    columnDefs={planSummarycolsDefs}
                    gridOptions={{ context: { componentParent: this } }}
                    defaultColDef={this.state.defaultColDef}
                    frameworkComponents={this.state.frameworkComponents}
                    paginationPageSize={this.state.paginationPageSize}
                    pagination={true}
                    sideBar={this.state.sideBar}
                    enablePivot={true}
                    enableRangeSelection={true}
                    statusBar={this.state.statusBar}
                  />
                </div>
              </div>

              <div class={"row mb-5p " + this.state.summaryData}>
                {this.state.total_quantity_data == 0 ? (
                  <div />
                ) : (
                  <div className={"mxheit col-sm-6 "}>
                    <div className="card">
                      <div className="card-body">
                        <div
                          className={"chartLoading " + this.state.chartloader3}
                        >
                          <div className="loaderpos">
                            <img
                              src={require("../../assets/images/loading.gif")}
                            />
                          </div>
                        </div>
                        <ColumnChart
                          title={"Dispatch Load Quantity Summary"}
                          series={this.state.planningQuantityColumnChart}
                          yaxisTitle={"Quantity"}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {this.state.total_demand_weight == 0 ? (
                  <div />
                ) : (
                  <div className={"mxheit col-sm-6 "}>
                    <div className="card">
                      <div className="card-body">
                        <div
                          className={"chartLoading " + this.state.chartloader2}
                        >
                          <div className="loaderpos">
                            <img
                              src={require("../../assets/images/loading.gif")}
                            />
                          </div>
                        </div>
                        <ColumnChart
                          title={"Dispatch Load Weight Summary (Kgs)"}
                          series={this.state.planningWeightColumnChart}
                          yaxisTitle={"Weight (Kgs)"}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {this.state.total_demand_cmt == 0 ? (
                  <div />
                ) : (
                  <div className={"mxheit col-sm-6 "}>
                    <div className="card">
                      <div className="card-body">
                        <div
                          className={"chartLoading " + this.state.chartloader1}
                        >
                          <div className="loaderpos">
                            <img
                              src={require("../../assets/images/loading.gif")}
                            />
                          </div>
                        </div>
                        <ColumnChart
                          title={"Dispatch Load Volume Summary (M3)"}
                          series={this.state.planningColumnChart}
                          yaxisTitle={"Volume (M3)"}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {this.state.total_dropped_loads == 0 ? (
                  <div />
                ) : (
                  <div className={"mxheit col-sm-6 "}>
                    <div className="card">
                      <div className="card-body">
                        <PieChart
                          title={"Dropped Data"}
                          series={this.state.planningPieChart}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={"sliderBlock2 " + this.state.showSlideBlockUpload}
              >
                <h5 className="crd-bg p-10p">{this.state.sliderTitle}</h5>
                <div className="row">
                  <div className="col-sm-12">
                    {this.state.latLngArray.length > 0 ? (
                      <LoadSummaryDrawmap
                        context={this}
                        coords={this.state.latLngArray}
                        loadingLocation={this.state.loadingLocation}
                        stepcoords={1}
                        mapType={this.state.mapType}
                        mapFor={"load_management"}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={"overlay-block " + this.state.overlayBlock}
            onClick={this.hideSlideBlock}
          ></div>
          <div className={"sliderBlock2 " + this.state.showSlideMap}>
            <h5 className="crd-bg p-10p" style={{ fontSize: "15px" }}>
              View Routes
              <span className="float-right f13 ml-30p">
                {/* Round Trip Distance:{" "}
                {this.state.tripinfo.length > 0
                  ? this.state.tripinfo[0].round_trip_distance
                  : ""}{" "}
                Kms */}
                <label className="mt-19p d-flex align-items-center">
                  <input
                    onClick={this.onChnageVehicleType}
                    checked={this.state.additional_vehicles}
                    value={this.state.additional_vehicles}
                    name="additional_vehicles"
                    type="checkbox"
                  />
                  &nbsp;Additional Vehicles
                </label>
              </span>
              <span className="float-right f13 align-items-center">
                {/* Total Trip Distance:{" "}
                {this.state.tripinfo.length > 0
                  ? this.state.tripinfo[0].distance
                  : ""}{" "}
                Kms */}
                <label className="d-flex align-items-center">
                  <input
                    onClick={this.onChnageVehicleType}
                    name="regular_vehicles"
                    checked={this.state.regular_vehicles}
                    value={this.state.regular_vehicles}
                    type="checkbox"
                  />
                  &nbsp;Regular Vehicles
                </label>
              </span>
            </h5>
            <div className="row">
              <div className="col-sm-12">
                {this.state.trip_gps_data.length > 0 ? (
                  <DrawMapDisptach
                    context={this}
                    plant_loc_data={this.state.plant_loc_data}
                    trip_gps_data={availableVehiclesGpsData}
                    trip_nodes_data={availableVehiclesNodeData}
                    palletLoadLocations={this.state.unPlannedData}
                    viewtype={this.state.viewcount}
                    map_for={this.state.mapFor}
                    tripsData={this.state.filteredData}
                  />
                ) : (
                  ""
                )}
                {/* <div id="mapRoute" className="loadSummaryMap" style={{width:'100%',height:"85vh"}}></div> */}
              </div>
            </div>
          </div>
          <div className={"sliderBlock2 " + this.state.showVehicleMap}>
            <h5 className="crd-bg p-10p" style={{ fontSize: "15px" }}>
              Vehicle Location
            </h5>
            <div className="row">
              <div className="col-sm-12">
                {this.state.mapFor === "vehicleLocation" ? (
                  <DrawMapDisptachPallet
                    context={this}
                    map_for={this.state.mapFor}
                    plant_location={this.state.plantLocation}
                    palletLoadLocations={this.state.vehicleLocation}
                  />
                ) : (
                  ""
                )}
                {/* <div id="mapRoute" className="loadSummaryMap" style={{width:'100%',height:"85vh"}}></div> */}
              </div>
            </div>
          </div>
          <div className={"sliderBlock2 " + this.state.showSlideMapLocations}>
            <h5 className="crd-bg p-10p">View Locations</h5>
            <div className="row">
              <div className="col-sm-12">
                {this.state.palletLoadLocations.length > 0 ? (
                  <DrawMapDisptachPallet
                    context={this}
                    palletLoadLocations={this.state.palletLoadLocations}
                    viewtype={this.state.viewcount}
                  />
                ) : (
                  ""
                )}
                {/* <div id="mapRoute" className="loadSummaryMap" style={{width:'100%',height:"85vh"}}></div> */}
              </div>
            </div>
          </div>

          <div
            className={"sliderBlock2 " + this.state.showSlideAssignVehicle}
            style={{ overflowX: "hidden" }}
          >
            <h5 className="crd-bg p-10p">Assign Vehicle</h5>
            <div className="row p-15p">
              <div className="col-sm-12">
                <div className="form-group">
                  <label>Select Vehicle</label>
                  <Select
                    value={this.state.assignvehicle}
                    onChange={this.onChangeAssignVehicle.bind(this)}
                    options={this.state.assign_vehicle_list}
                  />
                </div>
              </div>
              <div className={"col-sm-12 " + this.state.showAssignFields}>
                <div className="form-group">
                  <label>Enter Vehicle No</label>
                  <input
                    type="text"
                    name="other_vehicle_no"
                    className="form-control"
                    value={this.state.other_vehicle_no}
                    onChange={this.changeHandler.bind(this)}
                  />
                </div>

                {/* <div className="col-sm-12"> */}
                <div className="form-group ">
                  <label
                    htmlFor="vehicleProductType"
                    className="col-form-label f12"
                  >
                    Vehicle Product Type
                  </label>
                  <Select
                    placeholder={"Select"}
                    onChange={this.onChangeProductType}
                    name="vehicleProductType"
                    value={this.state.vehicleProductType}
                    options={vehicleProductTypes}
                    isMulti={false} // Enable multi-select
                    required
                  />
                  {/* </div> */}
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.onClickAssignSelectedVehicle.bind(this)}
                  >
                    Assign Vehicle
                  </button>
                </div>
              </div>
              <div className={"chartLoading " + this.state.assignVehicleloader}>
                <div className="loaderpos">
                  <img src={require("../../assets/images/loading.gif")} />
                </div>
              </div>
            </div>
          </div>

          <Modal
            closeOnOverlayClick={false}
            open={this.state.openReassignVehicleModel}
            onClose={this.handelModelCancel}
            classNames={{ modal: "vehiclepriority-modelClass handle-postion" }}
          >
            <div className="col-sm-12">
              <div className="mt-20p mb-10p">
                <h6>
                  Vehicle has an Active Trip Shipment ID-
                  {this.state.selectedShipmentId}. <br />
                  Do you wish to <b>Force Close & Assign</b> ?
                </h6>
              </div>

              <div className="col-md-4">
                <div className="form-group ">
                  <label
                    htmlFor="forceClose_reason"
                    className="col-form-label f12"
                  >
                    Force Close Reason
                  </label>

                  <select
                    name="forceClose_reason"
                    id="forceClose_reason"
                    value={this.state.forceClose_reason}
                    required
                    className="forminp form-control"
                    onChange={this.forceCloseInputField}
                  >
                    {/* <option value="" disabled selected>Select</option> */}
                    <option value="gps_issue">GPS Issue</option>
                    <option value="trip_closed">Trip Closed</option>
                    <option value="load_cancelled">Load Cancelled</option>
                    <option value="new_trip_allocated">
                      New Trip Allocated
                    </option>
                    <option value="driver_changed">Driver Changed</option>
                  </select>
                </div>
              </div>

              <div className="btns-center">
                <button
                  type="button"
                  onClick={this.forceCloseAndReassign}
                  className="btn tbtn assign-btn"
                >
                  Assign Vehicle
                </button>
                <button
                  type="button"
                  className="btn tbtn cancel-btn ass-cancel-btn"
                  onClick={this.handelModelCancel}
                >
                  CANCEL
                </button>
              </div>
              <div className={"chartLoading " + this.state.assignVehicleloader}>
                <div className="loaderpos">
                  <img src={require("../../assets/images/loading.gif")} />
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            closeOnOverlayClick={false}
            open={this.state.openUpdateVehicleModel}
            onClose={this.handelUpdateModelCancel}
            classNames={{ modal: "vehiclepriority-modelClass handle-postion" }}
          >
            <div className="col-sm-12">
              <div className="mt-20p mb-10p">
                <h6>
                  Are you sure you want to update <b>Vehicle Number</b> ?
                </h6>
              </div>

              <div className="btns-center">
                <button
                  type="button"
                  onClick={this.handleUpdateVehicle}
                  className="btn tbtn assign-btn"
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn tbtn cancel-btn ass-cancel-btn"
                  onClick={this.handelUpdateModelCancel}
                >
                  No
                </button>
              </div>
            </div>
          </Modal>

          {/* vehicle edit plan Confirmation model */}
          <Modal
            closeOnOverlayClick={false}
            open={this.state.openEditVehiclePlanModel}
            onClose={this.handelEditVehiclePlanModelCancel}
            classNames={{ modal: "vehiclepriority-modelClass handle-postion" }}
          >
            <div className="col-sm-12">
              <div className="mt-20p mb-10p">
                <h6>
                  Edit Plan for the same <b>Vehicle Type</b> ?
                </h6>
              </div>

              <div className="btns-center">
                <button
                  type="button"
                  onClick={this.handleEditVehiclePlan}
                  className="btn tbtn assign-btn"
                >
                  Yes
                </button>
                <button
                  type="button"
                  className="btn tbtn cancel-btn ass-cancel-btn"
                  onClick={this.handelChangeTypeVehiclePlan}
                >
                  No
                </button>
              </div>
            </div>
          </Modal>

          {/* edit plan sidebar */}
          <div
            className={"overlay-block " + this.state.overlayBlock}
            onClick={this.hideSlideBlock}
          ></div>
          <div
            className={"sliderBlock2 " + this.state.showSlideEditVehiclePlan}
            style={{ overflowX: "hidden" }}
          >
            {this.state.showNewPlan === false ? (
              <>
                <h5 className="crd-bg p-10p">Update Vehicle Plan</h5>
                <div className="row">
                  <div className="col-sm-12">
                    <span className="fbold">
                      VFR % : {this.state.editplanvfr}
                    </span>
                    <span className="fbold ml-15p">
                      Quantity : {this.state.editplanqty}
                    </span>
                    <span className="fbold ml-15p">
                      Weight : {this.state.editplanvehicle_weight}
                    </span>
                    <span className="fbold ml-10p">
                      Volume : {this.state.editplanvehicle_volume}
                    </span>
                    <span className="fbold ml-10p">
                      Max Weight : {this.state.editplanmaxvehicleweight}
                    </span>
                    <span className="fbold ml-10p">
                      Max Volume : {this.state.editplanmaxvehiclevolume}
                    </span>
                    <span
                      className="fbold ml-0 pl-10 col-sm-2 col-md-2 d-flex "
                      style={{
                        maxWidth: "140px",
                        verticalAlign: "middle",
                        lineHeight: "35px",
                      }}
                    >
                      Max Dispatch Time :
                    </span>
                    <div className="col-sm-3" style={{ padding: "0px" }}>
                      <input
                        type="datetime-local"
                        className="form-control"
                        name="editplanmaxdispatch"
                        id="editplanmaxdispatch"
                        autoComplete="off"
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={this.handlerStartDateTime}
                        min={this.getCurrentDateTime()}
                        max={this.getMaxCurrentDateTime()}
                        value={this.state.editplanmaxdispatch}
                        style={{ width: "180px", padding: "0px" }}
                        readonly
                      />
                    </div>
                    <label
                      className="float-right"
                      style={{ margin: "0px" }}
                      htmlFor="sundayplanning"
                      for="css"
                    >
                      &nbsp;&nbsp;Enable DC Week Off planning &nbsp;&nbsp;
                    </label>
                    <input
                      className="ml-2 mr-0 float-right"
                      type="checkbox"
                      id="sundayplanning"
                      checked={
                        this.state.is_sunday_planning_enabled === 1
                          ? true
                          : false
                      }
                      onClick={this.handlesundayplanning}
                      name="is_sunday_planning_enabled"
                      value={this.state.is_sunday_planning_enabled}
                      disabled={!this.state.enable_sunday_planning}
                    />
                  </div>
                  <h5 className="col-sm-10 d-flex align-items-end">
                    Planned OBD
                  </h5>
                  <div className="col-sm-2">
                    <div className="form-group">
                      <label>Select Vehicle</label>
                      <Select
                        value={this.state.newObdVehicleType}
                        isDisabled={
                          this.state.newObdVehicleType.value != ""
                            ? true
                            : false
                        }
                        onChange={this.handleNewObdVehicleType}
                        options={this.state.vehicleTypeList}
                      />
                    </div>
                  </div>

                  <div
                    id="myGrid3"
                    style={{ width: "100%", height: "30vh" }}
                    className="col-sm-12 ag-theme-balham dropdown"
                  >
                    <AgGridReact
                      rowData={this.state.dispatchPlanObdUpdateData}
                      columnDefs={existingPlanColDfs}
                      gridOptions={{
                        context: { componentParent: this },
                        rowSelection: "multiple",
                      }}
                      defaultColDef={this.state.defaultColDef}
                      onRowDataUpdated={this.handleRowDataChanged}
                      frameworkComponents={this.state.frameworkComponents}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      enableRangeSelection={true}
                      onGridReady={this.onGridReady3}
                      onGridState={this.onGridState3}
                      onSelectionChanged={this.onPlannedDataSelectionChanged}
                    />
                  </div>
                  <h5 className="col-sm-12 mt-5p">Unplanned OBD</h5>
                  <div
                    id="myGrid3"
                    style={{ width: "100%", height: "30vh" }}
                    className="col-sm-12 ag-theme-balham dropdown"
                  >
                    <AgGridReact
                      rowData={this.state.unPlannedData}
                      columnDefs={updatedunPlannedcolsDefs}
                      gridOptions={{
                        context: { componentParent: this },
                        rowSelection: "multiple",
                      }}
                      defaultColDef={this.state.defaultColDef}
                      frameworkComponents={this.state.frameworkComponents}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      enableRangeSelection={true}
                      onGridReady={this.onGridReady4}
                      onGridState={this.onGridState4}
                      onSelectionChanged={this.onPlannedDataSelectionChanged}
                    />
                  </div>

                  <div className="col-sm-12 mt-5p d-flex justify-content-end">
                    <div className="form-group">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.handleChangePlan}
                      >
                        Change Plan
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <h5 className="crd-bg p-10p">Update Vehicle Plan</h5>
                <div className="row p-10p">
                  <h5
                    className="col-sm-10 d-flex align-items-end"
                    style={{ fontWeight: "bold" }}
                  >
                    New Plan
                  </h5>

                  <div
                    id="myGrid3"
                    style={{ width: "100%", height: "50vh" }}
                    className="col-sm-12 ag-theme-balham dropdown"
                  >
                    <AgGridReact
                      rowData={this.state.dispatchNewPlanObdData}
                      columnDefs={newPlanColDfs}
                      gridOptions={{
                        context: { componentParent: this },
                        rowSelection: "multiple",
                      }}
                      defaultColDef={this.state.defaultColDef}
                      onRowDataUpdated={this.handleRowDataChanged}
                      frameworkComponents={this.state.frameworkComponents}
                      paginationPageSize={this.state.paginationPageSize}
                      pagination={true}
                      enableRangeSelection={true}
                      onGridReady={this.onGridReady3}
                      onGridState={this.onGridState3}
                    />
                  </div>
                  {/* <div className="col-md-12 " style={{display:'flex',flexDirection:'column'}}> */}
                  <div className="col-md-12 pt-3">
                    <div className="col-md-12 mt-5p">
                      <div className="float-left col-md-4">
                        <div className="float-left col-sm-11">
                          <label
                            htmlFor="remarks"
                            style={{ fontWeight: "bold" }}
                          >
                            Reason for plan change{" "}
                            <span className="redColor">*</span> :
                          </label>
                          <Select
                            options={remarks}
                            name="remarks"
                            onChange={this.onChangeRemark}
                            value={this.state.remark}
                          />
                        </div>
                      </div>
                      <div
                        className={`${
                          this.state.remark.value === "Others"
                            ? "show-m"
                            : "show-n"
                        } float-left ml-4`}
                      >
                        <label
                          className=""
                          style={{ fontWeight: "bold" }}
                          htmlFor="otherreason"
                        >
                          Enter the Reason<span className="redColor"> * </span>:
                        </label>
                        <input
                          onChange={this.onChangeSubRemark}
                          id="otherreason"
                          value={this.state.subReason}
                          className="col-md-12"
                          type="text"
                          style={{ height: "37px" }}
                        />
                      </div>
                      <div className="form-group float-right mt-4">
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={this.updateThePlan}
                        >
                          Update
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={this.cancelplanhideSlideBlock}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* //add this loader */}
            <div className={"chartLoading " + this.state.editplanloader}>
              <div className="loaderpos">
                <img src={require("../../assets/images/loading.gif")} />
              </div>
            </div>
          </div>

          <div>
            <ConfigSummary
              handleOnClose={this.onClickCloseConfigs}
              openConfigmd={this.state.openSavedConfigs}
              data={this.state.configs}
            />
          </div>

          {/* <div className={"sliderBlock " + this.state.showDeleteSlider}>
              <div>
              <p className="fbold redColor f18" >  Are you sure to delete plan ? </p>
              <div className="d-flex">
                <button className="btn btn-danger ml-1" onClick={this.deleteDispatchPlan}>Delete</button>
                <button className="btn btn-warning" onClick={this.onCanceldeletePlan}>Cancel</button>
              </div>
              </div>
          </div> */}
          <Modal
            closeOnOverlayClick={false}
            open={this.state.showDeleteSlider}
            onClose={this.onCanceldeletePlan}
            classNames={{
              modal: "vehiclepriority-modelClass handle-postion delete-popup12",
            }}
            style={{ backgroundColor: "white", padding: "0px" }}
          >
            <div
              className="col-md-12 "
              style={{
                backgroundColor: "white",
                border: "0px",
                padding: "12px",
                paddingTop: "29px",
              }}
            >
              <div className="col-md-12">
                <div className="float-left col-md-7">
                  <div className="float-left col-md-12">
                    <label htmlFor="remarks" style={{ fontWeight: "bold" }}>
                      Reason for plan Deletion{" "}
                      <span className="redColor">*</span> :
                    </label>
                    <Select
                      options={remarks}
                      name="remarks"
                      onChange={this.onChangeRemark}
                      value={this.state.remark}
                      menuPortalTarget={document.body} // Render menu in the body
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Ensure it's on top
                      }}
                    />
                  </div>
                </div>
                <div
                  className={`${
                    this.state.remark.value === "Others" ? "show-m" : "show-n"
                  } float-left col-md-5 pr-4`}
                >
                  <label
                    className=""
                    style={{ fontWeight: "bold" }}
                    htmlFor="otherreason"
                  >
                    Enter the Reason<span className="redColor"> * </span>:
                  </label>
                  <input
                    onChange={this.onChangeSubRemark}
                    id="otherreason"
                    value={this.state.subReason}
                    className="col-md-12 "
                    type="text"
                    style={{ height: "37px" }}
                  />
                </div>
              </div>
              <div className="mt-20p mb-20p pb-4p col-sm-12 pt-10p">
                <h6 className="d-flex justify-content-center">
                  Are you sure, You want to Delete Plan?
                </h6>
              </div>

              <div className="btns-center pb-12p">
                <button
                  type="button"
                  onClick={this.deleteDispatchPlan}
                  className="btn tbtn assign-btn"
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn tbtn cancel-btn ass-cancel-btn"
                  onClick={this.onCanceldeletePlan}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
          {/* <Modal closeOnOverlayClick={false} open={this.state.openPickupDropLocationsModel} onClose={this.handelPickupDropModelCancel} classNames={{modal: 'pickupModal'}} >
              <div className="container-fluid">                            
                <div className="row">
                  <form className="col-xl-12 col-lg-12" onSubmit={this.locationFormHandler}> 
                      <div className="col-xl-12 col-lg-12">
                          <div className="form-group">
                            <div className="form-group">
                              <label className="c-lbl fbold">
                                Order <span className="redColor">*</span>
                              </label>
                              <input
                                type="number"
                                name="order"
                                value={this.state.order}
                                className="form-control forminp"
                                placeholder="Order"
                                readOnly
                                required
                                disabled
                              />

                              <label className="c-lbl fbold">
                                Quantity <span className="redColor">*</span>
                              </label>
                              <input
                                type="number"
                                name="quantity"
                                value={this.state.quantity}
                                onChange={this.handleOrderDetails}
                                className="form-control forminp"
                                onBlur={this.handleQuantityBlur}
                                placeholder="Quantity"
                                required
                              />
                              {this.state.quantityError && (
                                <div style={{color:"red"}} className="error-message">{this.state.quantityError}</div>
                              )}

                              <label className="c-lbl fbold">
                                Weight <span className="redColor">*</span>
                              </label>
                              <input
                                type="number"
                                name="weight"
                                value={this.state.weight}
                                onChange={this.handleOrderDetails}
                                className="form-control forminp"
                                onBlur={this.handleWeightBlur}
                                placeholder="Weight"
                                step="0.01"
                                required
                              />
                              {this.state.weightError && (
                                <div style={{color:"red"}} className="error-message">{this.state.weightError}</div>
                              )}

                              <label className="c-lbl fbold">
                                Demanded Volume <span className="redColor">*</span>
                              </label>
                              <input
                                type="number"
                                name="demandedVolume"
                                value={this.state.demandedVolume}
                                onChange={this.handleOrderDetails}
                                onBlur={this.handleDemandedVolumeBlur}
                                className="form-control forminp"
                                placeholder="Demanded Volume"
                                step="0.01"
                                required
                              />
                              {this.state.demandedVolumeError && (
                                <div style={{color:"red"}} className="error-message">{this.state.demandedVolumeError}</div>
                              )}
                            </div>
                          </div>	
                      </div>
                      <div className="form-group">
                        <button type="submit" className="btn tbtn float-right save-btn" id="saveGeo">SAVE</button>
                        <button type='button' className="btn tbtn float-right cancel-btn" onClick={this.handelPickupDropModelCancel}>CANCEL</button>	
                      </div>
                  </form>    
                </div>
              </div>              
          </Modal> */}
          <div
            className={"overlay-block " + this.state.overlayBlock}
            onClick={this.hideSlideBlock}
          ></div>
          <div
            className={
              "sliderBlock2 " + this.state.showSlideForUpdateOrderDetails
            }
          >
            <h5 className="crd-bg p-10p">Update Order Details</h5>
            <div className="row">
              <form
                className="col-xl-12 col-lg-12"
                onSubmit={this.locationFormHandler}
              >
                <div className="col-xl-12 col-lg-12">
                  <div className="form-group">
                    <div className="form-group">
                      <label className="c-lbl fbold">
                        Order <span className="redColor">*</span>
                      </label>
                      <input
                        type="number"
                        name="order"
                        value={this.state.order}
                        className="form-control forminp"
                        placeholder="Order"
                        readOnly
                        required
                        disabled
                      />

                      <label className="c-lbl fbold">
                        Quantity <span className="redColor">*</span>
                      </label>
                      <input
                        type="number"
                        name="quantity"
                        value={this.state.quantity}
                        onChange={this.handleOrderDetails}
                        className="form-control forminp"
                        onBlur={this.handleQuantityBlur}
                        placeholder="Quantity"
                        required
                      />
                      {this.state.quantityError && (
                        <div style={{ color: "red" }} className="error-message">
                          {this.state.quantityError}
                        </div>
                      )}

                      <label className="c-lbl fbold">
                        Weight <span className="redColor">*</span>
                      </label>
                      <input
                        type="number"
                        name="weight"
                        value={this.state.weight}
                        onChange={this.handleOrderDetails}
                        className="form-control forminp"
                        onBlur={this.handleWeightBlur}
                        placeholder="Weight"
                        step="0.01"
                        required
                      />
                      {this.state.weightError && (
                        <div style={{ color: "red" }} className="error-message">
                          {this.state.weightError}
                        </div>
                      )}

                      <label className="c-lbl fbold">
                        Demand Volume <span className="redColor">*</span>
                      </label>
                      <input
                        type="number"
                        name="demandedVolume"
                        value={this.state.demandedVolume}
                        onChange={this.handleOrderDetails}
                        onBlur={this.handleDemandedVolumeBlur}
                        className="form-control forminp"
                        placeholder="Demanded Volume"
                        step="0.01"
                        required
                      />
                      {this.state.demandedVolumeError && (
                        <div style={{ color: "red" }} className="error-message">
                          {this.state.demandedVolumeError}
                        </div>
                      )}

                      <label className="c-lbl fbold">
                        Delivery Date <span className="redColor">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control forminp"
                        name="deliveryDate"
                        id="deliveryDate"
                        autoComplete="off"
                        onKeyDown={(e) => e.preventDefault()}
                        onChange={this.handleDeliveryDateChange}
                        value={this.state.deliveryDate}
                        style={{ width: "180px", padding: "0px" }}
                      />
                      {this.state.weightError && (
                        <div style={{ color: "red" }} className="error-message">
                          {this.state.weightError}
                        </div>
                      )}

                      <label className="c-lbl fbold">
                        Dealer Availability Start Time{" "}
                        <span className="redColor">*</span>
                      </label>
                      <input
                        type="text"
                        name="dealerAvblStart"
                        value={this.state.dealerAvblStart}
                        onChange={this.handleOrderDetails}
                        onBlur={this.handledealerAvblStartBlur}
                        className="form-control forminp"
                        placeholder="Available Time"
                        // step="0.01"
                        required
                      />
                      {this.state.dealerAvblStartErr && (
                        <div style={{ color: "red" }} className="error-message">
                          {this.state.dealerAvblStartErr}
                        </div>
                      )}

                      <label className="c-lbl fbold">
                        Dealer Availability End Time{" "}
                        <span className="redColor">*</span>
                      </label>
                      <input
                        type="text"
                        name="dealerAvblEnd"
                        value={this.state.dealerAvblEnd}
                        onChange={this.handleOrderDetails}
                        onBlur={this.handledealerAvblEndBlur}
                        className="form-control forminp"
                        placeholder="Available Time"
                        // step="0.01"
                        required
                      />
                      {this.state.dealerAvblEndErr && (
                        <div style={{ color: "red" }} className="error-message">
                          {this.state.dealerAvblEndErr}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    className="btn tbtn float-right save-btn"
                    id="saveGeo"
                  >
                    SAVE
                  </button>
                  <button
                    type="button"
                    className="btn tbtn float-right cancel-btn"
                    onClick={this.handelPickupDropModelCancel}
                  >
                    CANCEL
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div className={"loader " + this.state.loadshow}></div>
        </div>
      </>
    );
  }
}

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

function loadScript(url) {
  var index = window.document.getElementsByTagName("script")[0];
  var script = window.document.createElement("script");
  script.src = url;
  script.async = true;
  script.defer = true;
  index.parentNode.insertBefore(script, index);
}

function GetSortAscOrder(prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
}

function arr_diff(a1, a2) {
  var a = [],
    diff = [];

  for (var i = 0; i < a1.length; i++) {
    a[a1[i]] = true;
  }

  for (var i = 0; i < a2.length; i++) {
    if (a[a2[i]]) {
      delete a[a2[i]];
    } else {
      a[a2[i]] = true;
    }
  }

  for (var k in a) {
    diff.push(k);
  }

  return diff;
}

function addTimes(timeArray) {
  let totalSeconds = 0;

  for (let i = 0; i < timeArray.length; i++) {
    const [hours, minutes, seconds] = timeArray[i].split(":").map(Number);
    if (seconds != undefined && seconds != "") {
      totalSeconds += hours * 3600 + minutes * 60 + seconds;
    } else {
      totalSeconds += hours * 3600 + minutes * 60;
    }
  }

  let hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds % 3600) / 60);
  let seconds = totalSeconds % 60;

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
}
