import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import noDataToDisplay from "highcharts/modules/no-data-to-display";

// Initialize the no-data-to-display module
noDataToDisplay(Highcharts);

const BarChart = (props) => {
  const options = {
    chart: {
      type: "column",
    },
    title: {
      text: props.title,
      align: "center",
    },
    subtitle: {
      align: "left",
    },
    xAxis: {
      categories: props.categories,
      crosshair: true,
      accessibility: {
        description: "Countries",
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: props.yaxis ? props.yaxis : '',
      },
    },
    tooltip: {
      valueSuffix: props.suffix ? '(' + props.suffix + ')' : "",
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: props.series.length > 0 ? props.series : [],
    lang: {
      noData: "No data to display",
    },
    noData: {
      style: {
        fontSize: '16px',
        color: '#303030'
      }
    }
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default BarChart;
