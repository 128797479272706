import React,{Component} from "react";
import Constant from "../common/constants";
import redirectURL from '../redirectURL';
import Select from 'react-select';
import getToolConfiguration from "./commonFunctions";
import FixedVehicleCost from './vehicleFixedCost';
import VehicleCityLevelCost from './vehicleCityLevelVariableCost';
import VehicleLevelCost from './vehicleLevelVariableCost';
import ZoneLevelCost from './zonelevelcost';
import Vehiclecostcustomerspecific from './vehiclecostcustomerspecific'


export default class VehicleCostConfigurations extends Component{
    constructor(props){
        super(props);
        this.state={
            locationNames:[],
            location:'',
            fixedCostTab:"",
            vehicleLevelCostTab : "",
            cityWiseCostTab : "",
            zoneWiseCostTab: "",
            fixedCostData: false,
            vehicleLevelCostData:false,
            cityWiseCostData:false,
            zoneWiseCostData:false,
            customercostTab:'activet',
            customercostData:true,
        }
        
    }

    async componentDidMount(){
        
        var curl = window.location.search;
        var ul= curl.split("?");
        // console.log("ul ",ul)
        // let url_type = window.location.href
        let url_type = ul[parseInt(ul.length)-1];
        // console.log("url_type ", url_type)
        // let path_type = url_type.split('/')
        let path_type = url_type
        let data_type
        // let path = path_type[path_type.length-1]
        let path =url_type

        if (path == 'inbound'){
            data_type = 1
        }else{
            data_type = 2
        }
        await this.setState({data_type : data_type})

        let params = {
            data_type : data_type
        }
        await getToolConfiguration(params)
        this.toolConfigurationSetup()
        await this.boundTypeLocations(params)
    }

    boundTypeLocations = async(param) => {

        let location_access = JSON.parse(localStorage.getItem("location_access"))

        let locationNames = [];

        if(location_access == null){
            locationNames.push({label:'ALL',value:''})
        }

        await redirectURL.post('master/getBoundTypeLocations',param)
          .then(async (response) =>{
              if(response.data.status == 'success'){
                  let boundLocations = response.data.boundLocations
                  if(boundLocations.length > 0){
                      boundLocations.map(item =>{
                        if(location_access != null){
                            if(location_access.includes(item.location_code)){
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })

                                }
                            }else{
                                locationNames.push({ value : item.location_code,
                                                    label : item.location_name })
                            }
                      })
                      await this.setState({
                          locationNames : locationNames,
                          location : locationNames[0]
                      })
                  }
              }
          })
          
      }

    locationChanged =async (location) =>{
        this.setState({
            location : location
        })
        await getToolConfiguration({plant_code:location.value})
        this.toolConfigurationSetup()
    }

    toolConfigurationSetup = () =>{
        
        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        var plant_wise_freight_fixed_cost = 0;
        var plant_wise_freight_vehicle_type_variable_cost = 0;
        var plant_wise_freight_city_level_vehicle_cost = 0;
        var plant_wise_freight_zone_level_cost = 0;

        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                plant_wise_freight_fixed_cost = toolConfig[0].plant_wise_freight_fixed_cost;
                plant_wise_freight_vehicle_type_variable_cost = toolConfig[0].plant_wise_freight_vehicle_type_variable_cost;
                plant_wise_freight_city_level_vehicle_cost = toolConfig[0].plant_wise_freight_city_level_vehicle_cost;
                plant_wise_freight_zone_level_cost = toolConfig[0].zone_level_cost
            }
    
            if(plant_wise_freight_fixed_cost == 1)
            {
                this.setState({fixedCostTab : "activet",
                              fixedCostData:true})
            }
            else if(plant_wise_freight_vehicle_type_variable_cost == 1)
            {
                this.setState({vehicleLevelCostTab : "activet",
                                vehicleLevelCostData:true})
            }
            else if(plant_wise_freight_city_level_vehicle_cost == 1)
            {
                this.setState({cityWiseCostTab : "activet",
                                cityWiseCostData:true})
            }
            else if(plant_wise_freight_zone_level_cost == 1)
            {
                this.setState({zoneWiseCostTab : "activet",
                zoneWiseCostData:true})
            }
        }
    }


    onClickTab = (pageType) => {

        if(pageType == "fixedCostData")
        {
            var fixedCostTab = "activet";
            var cityWiseCostTab = "";
            var vehicleLevelCostTab = "";
            var zoneWiseCostTab = "";
            var fixedCostData = true;
            var vehicleLevelCostData = false;
            var cityWiseCostData = false;
            var zoneWiseCostData = false;
            var customercostTab = "";
            var customercostData = false;
            
        }
        else if(pageType == "vehicleLevelCostData")
        {

            var fixedCostTab = "";
            var vehicleLevelCostTab = "activet";
            var cityWiseCostTab = "";
            var zoneWiseCostTab = "";
            var fixedCostData = false;
            var vehicleLevelCostData = true;
            var cityWiseCostData = false;
            var zoneWiseCostData = false;
            var customercostTab = "";
            var customercostData = false;
        }
        else if(pageType == "cityWiseCostData")
        {
            var fixedCostTab = "";
            var vehicleLevelCostTab = "";
            var cityWiseCostTab = "activet";
            var zoneWiseCostTab = "";
            var fixedCostData = false;
            var vehicleLevelCostData = false;
            var cityWiseCostData = true;
            var zoneWiseCostData = false;
            var customercostTab = "";
            var customercostData = false;
        }
        else if(pageType == "zoneWiseCostData")
        {
            var fixedCostTab = "";
            var vehicleLevelCostTab = "";
            var cityWiseCostTab = "";
            var zoneWiseCostTab = "activet";
            var fixedCostData = false;
            var vehicleLevelCostData = false;
            var cityWiseCostData = false;
            var zoneWiseCostData = true;
            var customercostTab = "";
            var customercostData = false;
        }else if(pageType == "customercostData")
            {
                var fixedCostTab = "";
                var vehicleLevelCostTab = "";
                var cityWiseCostTab = "";
                var zoneWiseCostTab = "";
                var fixedCostData = false;
                var vehicleLevelCostData = false;
                var cityWiseCostData = false;
                var zoneWiseCostData = true;
                var customercostTab = "activet";
                var customercostData = true;
            }
        this.setState({
            fixedCostTab:fixedCostTab,
            vehicleLevelCostTab:vehicleLevelCostTab,
            cityWiseCostTab:cityWiseCostTab,
            fixedCostData:fixedCostData,
            vehicleLevelCostData:vehicleLevelCostData,
            cityWiseCostData:cityWiseCostData,
            zoneWiseCostData:zoneWiseCostData,
            zoneWiseCostTab:zoneWiseCostTab,
            customercostTab:customercostTab,
            customercostData:customercostData
        })
    }


    render(){

        let toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
        var plant_wise_freight_configuration = 0;
        var plant_wise_freight_fixed_cost = 0;
        var plant_wise_freight_vehicle_type_variable_cost = 0;
        var plant_wise_freight_city_level_vehicle_cost = 0;
        var plant_wise_freight_zone_level_cost = 0;

        if(toolConfig !=undefined && toolConfig !="")
        {
            if(toolConfig.length > 0)
            {
                plant_wise_freight_configuration = toolConfig[0].plant_wise_freight_configuration
                plant_wise_freight_fixed_cost = toolConfig[0].plant_wise_freight_fixed_cost;
                plant_wise_freight_vehicle_type_variable_cost = toolConfig[0].plant_wise_freight_vehicle_type_variable_cost;
                plant_wise_freight_city_level_vehicle_cost = toolConfig[0].plant_wise_freight_city_level_vehicle_cost;
                plant_wise_freight_zone_level_cost = toolConfig[0].zone_level_cost
            }
        }
        const {location,locationNames,fixedCostData,vehicleLevelCostData,cityWiseCostData,
        fixedCostTab,vehicleLevelCostTab,cityWiseCostTab,zoneWiseCostData,zoneWiseCostTab,customercostData,customercostTab}=this.state

        var userCheck=localStorage.getItem('role')==='admin'

        return(
            <div className="">
            <div className="row mb-5p pt-5p">
                    <div className="col-sm-12">
                        
                            <h5 className='fbold  d-flex justify-content-between'>
                                <span>Vehicle Cost Configurations</span>
                            </h5>
                        
                    </div>
                </div> 
                    <div className="row">
                    <div className="col-md-2 col-sm-12">
                        <div className="form-group ">
                            <label className="col-form-label f12">{Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}</label>
                            <Select
                                placeholder={"Select"}
                                onChange={this.locationChanged}
                                name="location"
                                value={location}
                                options={locationNames} />

                        </div>
                    </div>

                    <div className="col-md-8 col-sm-12 mt-35p" >
                        <ul className="d-tabs">
                            {/* {plant_wise_freight_fixed_cost == 1 ?
                            <li onClick={this.onClickTab.bind(this,"fixedCostData")} className={"lttabs "+(fixedCostTab)}>
                                <button type="button" className="btn">Fixed Vehicle Cost</button>
                            </li>
                            :''}

                            {plant_wise_freight_vehicle_type_variable_cost == 1 ?
                            <li onClick={this.onClickTab.bind(this,"vehicleLevelCostData")} className={"lttabs "+(vehicleLevelCostTab)}>
                                <button type="button" className="btn">Variable Vehicle Cost</button>
                            </li>
                            :''}

                            {plant_wise_freight_city_level_vehicle_cost == 1 ?
                            <li onClick={this.onClickTab.bind(this,"cityWiseCostData")} className={"lttabs "+(cityWiseCostTab)}>
                                <button type="button" className="btn">City Wise Vehicle Cost</button>
                            </li>
                            :''}

                            {plant_wise_freight_zone_level_cost == 1 ?
                            <li onClick={this.onClickTab.bind(this,"zoneWiseCostData")} className={"lttabs "+(zoneWiseCostTab)} >
                                <button type="button" className="btn">Zone Wise Cost</button>
                            </li>:''} */}
                            <li onClick={this.onClickTab.bind(this,"customercostData")} className={"lttabs "+(customercostTab)} >
                                <button type="button" className="btn">Cost Details</button>
                            </li>
                        </ul>
                    </div>
                    </div>
                    {customercostData ?  location != '' ? <Vehiclecostcustomerspecific plantLocation={location}/> :'': ''}
                    {/* {fixedCostData ?  location != '' ? <FixedVehicleCost plantLocation={location}/> :'': ''}
                    {vehicleLevelCostData ? location != '' ? <VehicleLevelCost plantLocation={location}/> :'': ''}
                    {cityWiseCostData ? location != '' ? <VehicleCityLevelCost plantLocation={location}/> :'': ''}
                    {zoneWiseCostData ? location != '' ? <ZoneLevelCost plantLocation={location}/> :'': ''} */}
            </div> 
        )
    }
}