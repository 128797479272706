module.exports = {
  GOOGLE_KEY: "AIzaSyC6cJAlLsrTTgm5Ck795Vg6X_EToa7FG-A",
  PAGE_TITLE_DEALER: "Suppliers",
  PAGE_TITLE_DEALER_PARTS: "Parts Details",
  PAGE_TITLE_CULSTER_SCREEN: "Current Month Suppliers Cluster",
  PAGE_TITLE_VEHICLE_DETAILS: "Vehicle Details",
  PAGE_TITLE_BINPACK: "Bin Packing",
  PAGE_TITLE_STATES: "States",
  DEALER_FORM_ADD: "Add Dealer",
  DEALER_FORM_UPLOAD: "Upload Dealer",
  DEALER_FORM_UPLOAD_PARTS: "Upload Dealer Parts",
  DEALER_FORM_EDIT: "Edit Dealer",
  DEALER_FORM_UPLOAD_FILE_XLS: "Upload File",
  DEALER_FORM_PLANT: "Plant",
  DEALER_FORM_CODE: "Dealer Code",
  DEALER_FORM_NAME: "Dealer Name",
  DEALER_FORM_START_TIME: "Start Time",
  DEALER_FORM_END_TIME: "End Time",
  DEALER_FORM_ADDRESS: "Address",
  DEALER_FORM_CITY: "City",
  DEALER_FORM_STATE: "State",
  DEALER_FORM_PINCODE: "Pincode",
  DEALER_FORM_LAT: "Latitude",
  DEALER_FORM_LNG: "Longitude",
  PAGE_TITLE_PLANT: "Plants",
  PLANT_FORM_ADD: "Add Plant",
  PLANT_FORM_EDIT: "Edit Plant",
  FORM_PLANT_NAME: "Plant Name",
  FORM_PLANT_LAT: "Latitude",
  FORM_PLANT_LNG: "Longitude",
  PAGE_TITLE_VEHICLE: "Vehicle Details",
  FORM_VEHICLE_ADD: "Add Vehicle",
  FORM_VEHICLE_PLANT: "Plant",
  FORM_VEHICLE_SOURCE_POINT: "Source Point",
  FORM_VEHICLE_NO: "Vehicle No",
  FORM_VEHICLE_TYPES: "Vehicle Type",
  FORM_VEHICLE_MODE: "Vehicle Mode",
  FORM_VEHICLE_CAPACITY: "Capacity (CMT)",
  FORM_VEHICLE_MILEAGE: "Mileage",
  FORM_VEHICLE_CAPACITY_MIN: "Min. Capacity (CMT)",
  FORM_VEHICLE_CAPACITY_MAX: "Max. Capacity (CMT)",
  FORM_VEHICLE_MIN_CMT_DEALER: "Min. CMT per Dealer",
  FORM_VEHICLE_MAX_NODES: "Maximum Nodes",
  FORM_VEHICLE_MAX_DISTANCE: "Maximum Distance (KMS)",
  FORM_VEHICLE_TELESCOPIC_ROUTE: "Telescopic Route",
  FORM_VEHICLE_DEVIATION_DISTANCE_PERCENT: "Deviation Distance (%)",
  FORM_VEHICLE_RECOMMEND_CMT: "Recommendation CMT",
  FORM_VEHICLE_LAST_DEALER_CMT: "Last Dealer CMT",
  PAGE_TITLE_LOAD_PLANNING: "Load Planning",
  FORM_LOAD_PLANNING_TITLE: "Add Load Planning",
  FORM_LOAD_PLANNING_DEALER: "Dealer",
  FORM_LOAD_PLANNING_TYPE: "Load Type",
  FORM_LOAD_PLANNING_CAPACITY: "Load Capacity",
  FORM_LOAD_PLANNING_START: "Open Time",
  FORM_LOAD_PLANNING_END: "Close Time",
  FORM_SUMMARY_CLUSTER: "Select Cluster",
  FORM_SUMMARY_START_TIME: "Start Time",
  FORM_SUMMARY_END_TIME: "End Time",
  FORM_SUMMARY_DELIVERY_DATE: "Delivery Date",
  MASTER_VEHICLE_DATA_CONFIGURATION_HEADER_NAME: "Vehicle Configurations",
  MASTER_VEHICLE_CHECKLIST_HEADER_NAME: "Vehicle Checklist for Transportation",
  MASTER_GPS_QUALITY: "GPS Quality",
  MASTER_VEHICLE_LOCATION_CONFIGURATION_HEADER_NAME: "Vehicle Location",
  MASTER_VEHICLE_DATA_AVAILABILITY_HEADER_NAME: "Vehicle Availability",
  MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME: "+ Add Vehicle Type",
  ADD_VEHICLE_DATA_REQUIREMENT_BUTTON_NAME: "+ Add Requirement",
  MASTER_VEHICLE_DATA_AVAILABILITY_BUTTON_NAME: "+ Add Vehicle Availability",
  MASTER_PICKUP_DROP_LOCATIONS_SCREEN_HEADER_NAME: "Pickup/Drop Locations",
  MASTER_PICKUP_DROP_LOCATIONS_SCREEN_BUTTON_NAME: "+Add Pickup/Drop Locations",
  MASTER_PICKUP_DROP_LOCATIONS_SCREEN_PICKUP_DISPALY_NAME: "PICKUP",
  MASTER_PICKUP_DROP_LOCATIONS_SCREEN_DROP_DISPALY_NAME: "DROP",
  MASTER_PICKUP_DROP_LOCATIONS_SCREEN_BOTH_DISPALY_NAME: "BOTH",
  MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION: "Pickup Location",
  MASTER_VEHICLE_DATA_CONFIGURATION_SELECTE_DATE: "Select Date",
  MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_TYPE: "Vehicle Type",
  MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_NAME: "Vehicle Type Name",
  MASTER_VEHICLE_DATA_CONFIGURATION_CMT_MIN_VOLUME: "Min Volume(M3)",
  MASTER_VEHICLE_DATA_CONFIGURATION_CMT_MAX_VOLUME: "Max Volume(M3)",
  MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_PRIORITY: "Vehicle Priority",
  MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_DIMENSIONS:
    "Vehicle Seating Capacity",
  MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MIN_WEIGHT:
    "Vehicle Min Weight (Kgs)",
  MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MAX_WEIGHT:
    "Vehicle Max Weight (Kgs)",
  MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MAX_DISTANCE:
    "Max Distance per Trip (Kms)",
  MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_AVAILABILITY_TIME:
    "Vehicle Operational Hours (HH:MM)",
  MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_BREAK_TIME:
    "Vehicle Break Window (HH:MM)",
  MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MAX_DELIVERY_INBOUND:
    "Suppliers / Vendor Clubbing Limits",
  MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MAX_DELIVERY_OUTBOUND:
    "Consignees / Dealers Clubbing Limits",
  MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MIN_LOAD:
    "Min Load Per Delivery (CMT)",
  MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_CAPACITY: "Vehicle Capacity (CMT)",
  MATERIAL_CODE: "Material Code",
  MATERIAL_NAME: "Material Name",
  MATERIAL_TYPE: "Material Type",
  MATERIAL_WEIGHT: "Material Weight",
  MATERIAL_DIMENSIONS: "Material Dimensions (mm)",
  MATERIAL_DIMENSIONS_DEPTH: "Material Dimensions Depth(mm)",
  MATERIAL_DIMENSIONS_WIDTH: "Material Dimensions Width(mm)",
  MATERIAL_DIMENSIONS_HEIGHT: "Material Dimensions Height(mm)",
  FREIGHT_FIXED_VEHICLE_COST: "Fixed Vehicle Cost",
  FREIGHT_DRIVER_COST: "Driver Cost",
  FREIGHT_MAINTENANCE_COST: "Maintenance Cost",
  FREIGHT_PER_CMT_COST: "Per CMT Cost",
  FREIGHT_PER_KM_COST: "Per KM Cost",
  TRANSPORTER_FORM_STATE: "State",
  TRANSPORTER_CODE: "Transporter Code",
  TRANSPORTER_NAME: "Transporter Name",
  MASTER_STACKING_PLANTWAREHOUSE_LOCATIONS_SCREEN_HEADER_NAME:
    "Pickup Location",
  MASTER_STACKING_PLANTWAREHOUSE_LOCATIONS_SCREEN_ADD_BUTTON_NAME:
    "+ Add Pickup Location",
  STACKING_VEHICLE_DETAILS_CONFIGURATION_LOCATION: "Pickup Location",
  STACKING_VEHICLE_DETAILS_HEADER_NAME: "Container / Vehicle Configuration",
  STACKING_VEHICLE_DETAILS_BUTTON_NAME:
    "+ Add Container / Vehicle Configuration",
  STACKING_VEHICLE_DETAILS_VEHICLE_TYPE: "Vehicle Type",
  STACKING_VEHICLE_DETAILS_VEHICLE_NAME: "Vehicle Name",
  STACKING_VEHICLE_DETAILS_VEHICLE_DIMENSIONS: "Vehicle Dimensions (mm)",
  STACKING_VEHICLE_DETAILS_VEHICLE_MAX_UTILIZATION:
    "Vehicle Max Utilization (%)",
  STACKING_VEHICLE_DETAILS_VEHICLE_MAX_WEIGHT: "Vehicle Max Weight (Kgs)",
  STACKING_VEHICLE_DETAILS_VEHICLE_CAPACITY: "Vehicle Capacity (CMT)",
  VEHICLE_COST_CONFIGURATION_FIXED_COST_HEADER: "Fixed Vehicle Cost",
  VEHICLE_COST_CONFIGURATION_FIXED_COST_BUTTON: "+ Add Fixed Vehicle Cost",
  VEHICLE_COST_CONFIGURATION_VEHICLE_TYPE: "Vehicle Type",
  VEHICLE_COST_CONFIGURATION_FIXED_VEHICLE_COST: "Fixed Vehicle Cost",
  VEHICLE_COST_CONFIGURATION_MAINTENANCE_COST: "Maintenance Cost",
  VEHICLE_COST_CONFIGURATION_DRIVER_COST: "Driver Cost",
  VEHICLE_COST_CONFIGURATION_VEHICLE_LEVEL_COST_HEADER: "Vehicle Cost",
  VEHICLE_COST_CONFIGURATION_VEHICLE_LEVEL_COST_BUTTON: "+ Add Vehicle Cost",
  VEHICLE_COST_CONFIGURATION_VEHICLE_CITY_LEVEL_COST_HEADER: "City Wise Cost",
  VEHICLE_COST_CONFIGURATION_VEHICLE_CITY_LEVEL_COST_BUTTON:
    "+ Add City Wise Cost",
  VEHICLE_COST_CONFIGURATION_VEHICLE_STATE: "State",
  VEHICLE_COST_CONFIGURATION_VEHICLE_CITY: "City",
  VEHICLE_COST_CONFIGURATION_VEHICLE_KM_COST: "Per Kilometer Cost",
  VEHICLE_COST_CONFIGURATION_VEHICLE_CMT_COST: "Per CMT Cost",
  TIMER_VALUES: [
    "00 : 00",
    "00 : 30",
    "01 : 00",
    "01 : 30",
    "02 : 00",
    "02 : 30",
    "03 : 00",
    "03 : 30",
    "04 : 00",
    "04 : 30",
    "05 : 00",
    "05 : 30",
    "06 : 00",
    "06 : 30",
    "07 : 00",
    "07 : 30",
    "08 : 00",
    "08 : 30",
    "09 : 00",
    "09 : 30",
    "10 : 00",
    "10 : 30",
    "11 : 00",
    "11 : 30",
    "12 : 00",
    "12 : 30",
    "13 : 00",
    "13 : 30",
    "14 : 00",
    "14 : 30",
    "15 : 00",
    "15 : 30",
    "16 : 00",
    "16 : 30",
    "17 : 00",
    "17 : 30",
    "18 : 00",
    "18 : 30",
    "19 : 00",
    "19 : 30",
    "20 : 00",
    "20 : 30",
    "21 : 00",
    "21 : 30",
    "22 : 00",
    "22 : 30",
    "23 : 00",
    "23 : 30",
  ],
  TRANSPORTER: "Transporter",
  ZONE: "Zone",
  ROUTE: "Route",
  MASTER_ROUTE_DATA_CONFIGURATION: "Route Configurations",
  MASTER_PICKUP_DROP_LOCATIONS_SCREEN_BULK_BUTTON_NAME: "Bulk Upload Locations",
  MATERIALS_SCREEN_BUTTON_NAME: "+Add Material Details",
  MATERIALS_SCREEN_BULK_BUTTON_NAME: "Bulk Upload Materials",
  TRANSPORTER_MASTER_SCREEN_BUTTON_NAME: "+Add Transporter Details",
  TRANSPORTER_MASTER_SCREEN_BULK_BUTTON_NAME: "Bulk Upload Transporters",
  TRANSPORTER_OPERATIONS_SCREEN_BUTTON_NAME: "+Add Transporter Operations",
  TRANSPORTER_OPERATIONS_SCREEN_BULK_BUTTON_NAME:
    "Bulk Upload Transporters Operations",
  MASTER_VEHICLE_DATA_AVAILABILITY_BULK_BUTTON_NAME: "Bulk Upload Availability",
  MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_NUMBER: "Vehicle Number",
  MASTER_VEHICLE_DATA_AVAILABILITY_MASTER_DETAILS_HEADER_NAME: "Vehicle Master",
  MASTER_VEHICLE_DATA_AVAILABILITY_MASTER_DETAILS_BUTTON_NAME:
    "+ Add Master Vehicle",
  MASTER_VEHICLE_DATA_AVAILABILITY_MASTER_DETAILS_BULK_BUTTON_NAME:
    "Bulk Upload Master Vehicles",
  MASTER_VEHICLE_DATA_CONFIGURATION_NEXT_DAY: "Select Planning Duration",
  MASTER_VEHICLE_DATA_CONFIGURATION_FROM_DATE: "From Date",
  MASTER_VEHICLE_DATA_CONFIGURATION_TO_DATE: "To Date",
  DISPATCH_PLAN_DATA_CONFIGURATION_SELECTE_DATE: "Order Date",
};
