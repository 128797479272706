import React, {Component} from 'react';
import getToolConfiguration from "../optirun-admin/commonFunctions";
import withRouter from "../withRouter";
import redirectURL from '../redirectURL';
import { BsBoxes,BsGraphUpArrow, BsPieChart, BsCalendarCheck } from "react-icons/bs";
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import { GiGps } from "react-icons/gi";
import { FcPlanner, FcComboChart  } from "react-icons/fc";
// import { BiDashboard } from 'react-icons/bi';
import { FaTruckFront } from "react-icons/fa6";
import { FaClipboardCheck } from "react-icons/fa6";
import { TbWorldUpload } from "react-icons/tb";
import { TiStopwatch } from "react-icons/ti";
// import { MdBatchPrediction } from "react-icons/md";
import { MdOutlineDashboard, MdOutlineDisplaySettings,MdBatchPrediction } from "react-icons/md";
import { TbRouteOff } from "react-icons/tb";
import { MdCompareArrows } from "react-icons/md";
import { IoAnalyticsSharp } from "react-icons/io5";
var ownerMails = [
    "nanda@enmovil.in",
    "ravi@enmovil.in",
    "nanda@enmovil.ai",
    "ravi@enmovil.ai",
    "venkat.moganty@enmovil.in",
    "venkat.moganty@enmovil.ai",
    "babujani@enmovil.in",
    "Yugi@enmovil.in",
    "Shekhar.bhoj@enmovil.in",
    "Mousumi.mahapatra@enmovil.in",
    "Sushmita.k@enmovil.in",
    "Bharat.satya@enmovil.in",
    "jagdeesan.v@enmovil.ai",
    "dhaval.bhoyar@enmovil.in",
    "prafulla.kumar@enmovil.in",
    "soubhagya.pattanaik@enmovil.in",
];
var email = localStorage.getItem("email");
var mailCheck = ownerMails.includes(email);

class Sidebar extends Component {
    constructor(props){
      super(props);
      this.state = {
        mainmenutoggle : "show-n",
        tripIcons : "show-n",
        planningIcons : "show-n",
        forecastIcons : "show-n",
        toggleCheckListIcon: false,
        plantCodeFrom_PW: ''
      }
      localStorage.setItem("module_type",1)
      localStorage.setItem("data_type",2)
      getToolConfiguration({data_type:2})
    }
    async componentDidMount(){
      
      let sbar = localStorage.getItem("activeSBar")
      // let location = qry.plant_location_code;
      this.handleSidebarTools(sbar,false)
      let location_access = JSON.parse(localStorage.getItem("location_access"))
      if(location_access != null){
        if(location_access.includes('LK1SLK02')){
          this.setState({
            toggleCheckListIcon: true
          })
      }
      }else{
        this.setState({
          toggleCheckListIcon: true
        })
      }
      // await this.getLocationWithVehicleChecklistEnabled()
    }
    
    getLocationWithVehicleChecklistEnabled = async () => {
      let params = {
          data_type: localStorage.getItem('data_type')
      }
      await redirectURL.post("master/getPlantwiseToolConfig", params)
          .then((resp)=>{
              let getPlantCode = resp.data.filter((e)=>{
                return e.hasOwnProperty("vehicle_checklist") && e.vehicle_checklist === 1;
              }).map((e) => e.plant_code);
              this.setState({
                  plantCodeFrom_PW: getPlantCode
              }, ()=>{                
                let qry = JSON.parse(localStorage.getItem("location_access"))
                if(qry != null){
                  
                  let hasCode = qry ? qry.some((item) => this.state.plantCodeFrom_PW.includes(item)) : false;

                  this.setState({
                    toggleCheckListIcon: hasCode
                  })
                } else {
                  let hasCode = getPlantCode ? Boolean(getPlantCode): false
                  this.setState({
                    toggleCheckListIcon: hasCode
                  })
                }
              })
          })
          .catch(function (error) {
              var errDetails={
                  url:"master/getPlantwiseToolConfig",
                  screen:'Requirements',
                error,
                }
              redirectURL.post("/master/logErrorsForApiCalls",errDetails)
          })
    }
    
    toggleMainMenu = () =>{
      console.log(this.state.mainmenutoggle)
      if(this.state.mainmenutoggle == "show-n")
      {
        var mainmenutoggle = "show-m";
      }
      else
      {
        var mainmenutoggle = "show-n";
      }
      this.setState({
          mainmenutoggle :mainmenutoggle
      })
    }
    closeToggleMainMenu = () => {
      this.setState({
          mainmenutoggle :'show-n'
      })
    }

    onclickKPIDash=()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on KPI Dashboard module',
        "event" : "Navigated to KPI Dashboard page",
        // "data_type" : this.state.data_type,
      }

      redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }

    
    onclickSummaryDash=()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Summary Dashboard module',
        "event" : "Navigated to Summary Dashboard page",
        // "data_type" : this.state.data_type,
      }

      redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }
    
    
    onclickRunNewPlan=()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Run New plan module',
        "event" : "Navigated to Run New Plan page",
        // "data_type" : this.state.data_type,
      }

      redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }

    onclickConsolidatedRun = () => {
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Consolidated Run plan module',
        "event" : "Navigated to Consolidated Run Plan page",
        // "data_type" : this.state.data_type,
      }
      // console.log(logParams,"logparams")
      redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }
    
    onclickPlanOverview=()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on plan Overview module',
        "event" : "Navigated to Run plan Overview page",
        // "data_type" : this.state.data_type,
      }

      redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }
    productMasterModule=()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Product Master module',
        "event" : "Navigated to Run Product Master page",
        // "data_type" : this.state.data_type,
      }

      redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }
    onclickPickUp=()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Pick Up/Drop Locations module',
        "event" : "Navigated to Pick Up/Drop Locations page",
        // "data_type" : this.state.data_type,
    }
  
    redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }

    onclickVehicleConfigurations=()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Vehicle Configurations module',
        "event" : "Navigated to Vehicle Configurations page",
        // "data_type" : this.state.data_type,
    }
  
    redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }
    onclickVehicleCheckList = () => {
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Vehicle Checklist module',
        "event" : "Navigated to Vehicle Checklist page",
        // "data_type" : this.state.data_type,
    }
  
    redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }

    onclickVehicleAvalConfig=()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Vehicle Availability configurations module',
        "event" : "Navigated to Vehicle Availability configurations page",
        // "data_type" : this.state.data_type,
      }
    
      redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }

    onClickVehicleStatus = () => {
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Vehicle Status module',
        "event" : "Navigated to Vehicle Status Screen",
        // "data_type" : this.state.data_type,
      }
      redirectURL.post("/master/loguserUpdatesorChanges",logParams)

    }

    onclickZonConfig=()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Zone Configurations module',
        "event" : "Navigated to Zone configurations page",
        // "data_type" : this.state.data_type,
      }
    
      redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }

    onclickTrnsConfig=()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Transporter Configurations module',
        "event" : "Navigated to Transporter configurations page",
        // "data_type" : this.state.data_type,
      }
    
      redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }
    
    onclickRouteConfig=()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Route Configurations module',
        "event" : "Navigated to Route configurations page",
        // "data_type" : this.state.data_type,
      }
    
      redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }
       
    onclickConfig=()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Configurations module',
        "event" : "Navigated to configurations page",
        // "data_type" : this.state.data_type,
      }
    
      redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }

          
    onclickTrips=()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Trips module',
        "event" : "Navigated to Trips page",
        // "data_type" : this.state.data_type,
      }
    
      redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }

    onclickunscheduledstoppages=()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Unscheduled stoppages module',
        "event" : "Navigated to Unscheduled stoppages page",
        // "data_type" : this.state.data_type,
      }
    
      redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }
    
    onClickAnalyticsDashboard =()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Analyticsdashboard module',
        "event" : "Navigated to Analyticsdashboard page",
        // "data_type" : this.state.data_type,
      }
    
      redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }
    
    
          
    onclickTruckGPS=()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Trucks GPS module',
        "event" : "Navigated to Truck GPS page",
        // "data_type" : this.state.data_type,
      }
    
      redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }
     
          
    onclickReports=()=>{
      let logParams = {
        // "location_code" : this.state.location.value,
        // "location_name" : this.state.location.label,
        "user_name" : localStorage.getItem("username"),
        "useremail" : localStorage.getItem("email"),
        "client" : localStorage.getItem("client"),
        "screen" : "Home Page",
        "activity" : 'clicked on Reports  module',
        "event" : "Navigated to Reports page",
        // "data_type" : this.state.data_type,
      }
    
      redirectURL.post("/master/loguserUpdatesorChanges",logParams)
    }
    redirectToModule = function(moduleType) {
      var toolConfig = JSON.parse(localStorage.getItem('tlcfg'))
      if(toolConfig !=undefined && toolConfig !="")
      {
        if(moduleType == 0)
        {
          window.location.href = "";
        }
        if(moduleType == 1)
        {
          var dispatchConfig = toolConfig.filter((tc) => tc.module_type == 1);
          if(dispatchConfig.length > 0)
          {
            // console.log(dispatchConfig,"dispatchConfig---test-1")
            var dispatchConfiginbound = dispatchConfig.filter((tc) => tc.data_type == 1);
            var dispatchConfigoutbound = dispatchConfig.filter((tc) => tc.data_type == 2);
            if(dispatchConfiginbound.length > 0 && dispatchConfigoutbound.length > 0)
            {
              window.location.href = "/loadprocessrun?inbound";
              localStorage.setItem("module_type",1);
              localStorage.setItem("data_type",1);
              localStorage.setItem("both_data_types_enabled_for_dispatch",1);
            }
            else if(dispatchConfiginbound.length > 0)
            {
              localStorage.setItem("module_type",1);
              localStorage.setItem("data_type",1);
              localStorage.setItem("both_data_types_enabled_for_dispatch",0);
              window.location.href = "/loadprocessrun?inbound";
            }
            else if(dispatchConfigoutbound.length > 0)
            {
              localStorage.setItem("module_type",1);
              localStorage.setItem("data_type",2);
              localStorage.setItem("both_data_types_enabled_for_dispatch",0);
              window.location.href = "/loadprocessrun?outbound";
            }
            else
            {
              alert("Please Contact Your Administrator");
            }
          }
        }        
        if(moduleType == 2)
        {
          var dispatchConfig = toolConfig.filter((tc) => tc.module_type == 2);
          if(dispatchConfig.length > 0)
          {
            var dispatchConfiginbound = dispatchConfig.filter((tc) => tc.data_type == 1);
            var dispatchConfigoutbound = dispatchConfig.filter((tc) => tc.data_type == 2);
            if(dispatchConfiginbound.length > 0 && dispatchConfigoutbound.length == 0)
            {
              localStorage.setItem("module_type",2);
              localStorage.setItem("data_type",1);
              localStorage.setItem("both_data_types_enabled_for_stacking",0);
              window.location.href = "/stackplan?inbound";
            }
            else if(dispatchConfigoutbound.length > 0 && dispatchConfiginbound.length == 0)
            {
              localStorage.setItem("module_type",2);
              localStorage.setItem("data_type",1);
              localStorage.setItem("both_data_types_enabled_for_stacking",0);
              window.location.href = "/stackplan?outbound";
            }
            else
            {
              // window.location.href = "/dispatchPlanningHomePage";
              window.location.href = "/stackplan?inbound";
              localStorage.setItem("module_type",2);
              localStorage.setItem("data_type",1);
              localStorage.setItem("both_data_types_enabled_for_stacking",1);
            }
          }
          else
          {
            localStorage.setItem("module_type",2);
            window.location.href = "/stackplan";
          }
        }
    
        if(moduleType == 4)
        {
          // var dispatchConfig = toolConfig.filter((tc) => tc.module_type == 2);
          // if(dispatchConfig.length > 0)
          // {
          //   var dispatchConfiginbound = dispatchConfig.filter((tc) => tc.data_type == 1);
          //   var dispatchConfigoutbound = dispatchConfig.filter((tc) => tc.data_type == 2);
          //   if(dispatchConfiginbound.length > 0 && dispatchConfigoutbound.length == 0)
          //   {
          //     localStorage.setItem("module_type",3);
          //     localStorage.setItem("data_type",1);
          //     localStorage.setItem("both_data_types_enabled_for_stacking",0);
          //     window.location.href = "/stackplan?inbound";
          //   }
          //   else if(dispatchConfigoutbound.length > 0 && dispatchConfiginbound.length == 0)
          //   {
          //     localStorage.setItem("module_type",3);
          //     localStorage.setItem("data_type",1);
          //     localStorage.setItem("both_data_types_enabled_for_stacking",0);
          //     window.location.href = "/stackplan?outbound";
          //   }
          //   else
          //   {
          //     // window.location.href = "/dispatchPlanningHomePage";
          //     window.location.href = "/stackplan?inbound";
          //     localStorage.setItem("module_type",3);
          //     localStorage.setItem("data_type",1);
          //     localStorage.setItem("both_data_types_enabled_for_stacking",1);
          //   }
          // }
          // else
          // {
            localStorage.setItem("module_type",4);
            window.location.href = "/forecast-predict";
          // }
        }
        
      }
      else
      {
        alert("No Access...");
      }
    }.bind(this);

    handleSidebarTools = (name,onloadpage) =>{
      // console.log(name,'ehdiuhesui',this.state.tripIcons)
      let hideActiveBar;
      hideActiveBar = localStorage.getItem("activeSBar");
      // console.log(hideActiveBar,'hide',onloadpage)
      if(name == "planningTools"){
        
        if(hideActiveBar != null && hideActiveBar == 'planningTools' && onloadpage){
          var planningIcons = "show-n";
          var tripIcons = "show-n";
          var forecastIcons = "show-n";
          localStorage.removeItem("activeSBar");
        }else{
          var planningIcons = "show-m";
          var tripIcons = "show-n";
          var forecastIcons = "show-n";
          localStorage.setItem("activeSBar",'planningTools');
        }

      }else if(name == "tripTools"){
        if(hideActiveBar != null && hideActiveBar == 'tripTools' && onloadpage){
          var planningIcons = "show-n";
          var tripIcons = "show-n";
          var forecastIcons = "show-n";
          localStorage.removeItem("activeSBar");
        }else{
          var planningIcons = "show-n";
          var tripIcons = "show-m";
          var forecastIcons = "show-n";
          localStorage.setItem("activeSBar",'tripTools');
        }

      }else if(name == "forecastTools"){
        if(hideActiveBar != null && hideActiveBar == 'forecastTools' && onloadpage){
          var planningIcons = "show-n";
          var tripIcons = "show-n";
          var forecastIcons = "show-n";
          localStorage.removeItem("activeSBar");
        }else{
          var planningIcons = "show-n";
          var tripIcons = "show-n";
          var forecastIcons = "show-m";
          localStorage.setItem("activeSBar",'forecastTools');
        }
      }

      this.setState({
         planningIcons : planningIcons,
         tripIcons : tripIcons,
         forecastIcons : forecastIcons
      })
    }

    render(){
        var path = "";
        var activeChk = "";
        var url_type = window.location.href;
        if(url_type !=undefined && url_type !="")
        {
          var path_type = url_type.split('/');
          var path = path_type[path_type.length-1];
          var path_type_2 = url_type.split('?');
          var path_type_3 = path_type_2[0].split('/');
          var activeChk = path_type_3[path_type.length-1];
        }
        var inbound_vehicle_availability = 0;
        var inbound_vehicle_availability_type = 0;
        var inbound_material_configurations = 0;
        var inbound_plant_wise_transporter_configuration = 0;
        var inbound_plant_wise_freight_configuration = 0;
        var inbound_route_configuration = 0;
        var inbound_auto_clustering = 0;
        var inbound_transporter_share_of_business = 0;
        var inbound_route_configuration_type = 0;
        var outbound_vehicle_availability = 0;
        var outbound_vehicle_availability_type = 0;
        var outbound_material_configurations = 0;
        var outbound_plant_wise_transporter_configuration = 0;
        var outbound_plant_wise_freight_configuration = 0;
        var outbound_route_configuration = 0;
        var outbound_auto_clustering = 0;
        var outbound_transporter_share_of_business = 0;
        var outbound_route_configuration_type = 0;

        if(localStorage.getItem('tlcfg') !=undefined && localStorage.getItem('tlcfg') !="")
        {
          var toolConfig = JSON.parse(localStorage.getItem('tlcfg'));
          if(toolConfig.length > 0)
          {
              var inboundData = toolConfig.filter((t) => t.module_type == 1 && t.data_type == 1);
              if(inboundData.length > 0)
              {
                inbound_material_configurations = inboundData[0].material_configuration;
                inbound_vehicle_availability = inboundData[0].vehicle_availability;
                
                if(inboundData[0].vehicle_availability_by_vehicle_type===1){
                  inbound_vehicle_availability_type =1
              } if(inboundData[0].vehicle_availability_by_individual_vehicle===1){
                inbound_vehicle_availability_type =2
              }

                inbound_plant_wise_transporter_configuration = inboundData[0].plant_wise_transporter_configuration;
                inbound_plant_wise_freight_configuration = inboundData[0].plant_wise_freight_configuration;
                inbound_route_configuration = inboundData[0].route_configuration;
                inbound_auto_clustering = inboundData[0].auto_clustering;
                inbound_transporter_share_of_business = inboundData[0].transporter_share_of_business;
                

                if(inboundData[0].plant_wise_configuration===1){
                  inbound_route_configuration_type =1
              } 
              if(inboundData[0].zone_wise_configuration===1){
                inbound_route_configuration_type =2
              }
              if(inboundData[0].transporter_wise_configuration===1){
                inbound_route_configuration_type =3
              }

              }
              var outboundData = toolConfig.filter((t) => t.module_type == 1 && t.data_type == 2);
              if(outboundData.length > 0)
              {
                outbound_material_configurations = outboundData[0].material_configuration;
                outbound_vehicle_availability = outboundData[0].vehicle_availability;
                
                if(outboundData[0].vehicle_availability_by_vehicle_type===1){
                  outbound_vehicle_availability_type =1
              } if(outboundData[0].vehicle_availability_by_individual_vehicle===1){
                outbound_vehicle_availability_type =2
              }
                outbound_plant_wise_transporter_configuration = outboundData[0].plant_wise_transporter_configuration;
                outbound_plant_wise_freight_configuration = outboundData[0].plant_wise_freight_configuration;
                outbound_route_configuration = outboundData[0].route_configuration;
                outbound_auto_clustering = outboundData[0].auto_clustering;
                outbound_transporter_share_of_business = outboundData[0].transporter_share_of_business;
                

                if(outboundData[0].plant_wise_configuration===1){
                  outbound_route_configuration_type =1
              } 
              if(outboundData[0].zone_wise_configuration===1){
                outbound_route_configuration_type =2
              }
              if(outboundData[0].transporter_wise_configuration===1){
                outbound_route_configuration_type =3
              }
              }
          }
        }
        var inboundZoneMapping = 0;
        if(inbound_transporter_share_of_business == 1 || inbound_route_configuration_type == 2)
        {
          inboundZoneMapping = 1;
        }
        var outboundZoneMapping = 0;
        if(outbound_transporter_share_of_business == 1 || outbound_route_configuration_type == 2)
        {
          outboundZoneMapping = 1;
        }
        var conCheck = 0;
        if(localStorage.getItem("role") == "admin")
        {
          if(path == "" || path == "dashboard" || path == "configurations")
          {
            conCheck = 1;
          }
        } 
        return (
            <div className="left_col scroll-view">
            {/* <div className="navbar nav_title" style={{border: "0"}}>
              <a id="menu_toggle" className="site_title" onClick={this.toggleMainMenu}><i className="fa fa-th"></i> <span></span></a>
            </div> */}
            <div className={"main-menu "+this.state.mainmenutoggle}>
                <i className='fa fa-close float-right p-20p close-toggle' onClick={this.closeToggleMainMenu}></i>
                <a style={{width:"50%"}} id="menu_toggle_1" className="site_title" onClick={this.toggleMainMenu}><i className="fa fa-th"></i> <span></span></a>
                  <div className="clearfix"></div>
                  <div className="col-sm-12">
                      <div className="m_content">
                          <div className="col-md-12" onClick={() => { this.redirectToModule(1) }}>
                              <div className="x_content center">
                                <h2 className="dTile1 bgc2fdc1">
                                  <img src={require("../../assets/images/home.png")} />
                                </h2>
                              </div>
                          </div>
                          <div className="col-sm-12" onClick={() => { this.redirectToModule(0) }}>
                              <div className="x_content center">
                                <h6 className="h6custom">Home</h6>
                              </div>
                          </div>
                        </div>
                  </div>
                  <div className="col-sm-12">
                      <div className="m_content">
                          <div className="col-md-12" onClick={() => { this.redirectToModule(1) }}>
                              <div className="x_content center">
                                <h2 className="dTile1 bgc2fdc1">
                                  <img src={require("../../assets/images/dispatch_planning.png")} />
                                </h2>
                              </div>
                          </div>
                          <div className="col-sm-12" onClick={() => { this.redirectToModule(1) }}>
                              <div className="x_content center">
                                <h6 className="h6custom">Run New Plan</h6>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-12">
                      <div className="m_content">
                        <div className="col-md-12" onClick={() => { this.redirectToModule(2) }}>
                            <div className="x_content center">
                              <h2 className="dTile1 bgc2fdc1">
                                <img src={require("../../assets/images/stacking.png")} />
                              </h2>
                            </div>
                        </div>
                        <div className="col-sm-12" onClick={() => { this.redirectToModule(2) }}>
                            <div className="x_content center">
                              <h6 className="h6custom">Stacking</h6>
                            </div>
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-12">
                      <div className="m_content">
                        <div className="col-md-12" onClick={() => { this.redirectToModule(3) }}>
                            <div className="x_content center">
                              <h2 className="dTile1 bgc2fdc1">
                                <img src={require("../../assets/images/production_planning.png")} />
                              </h2>
                            </div>
                        </div>
                        <div className="col-sm-12" onClick={() => { this.redirectToModule(3) }}>
                            <div className="x_content center">
                              <h6 className="h6custom">Production Planning</h6>
                            </div>
                        </div>
                      </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="m_content">
                      <div className="col-md-12" onClick={() => { this.redirectToModule(4) }}>
                          <div className="x_content center">
                            <h2 className="dTile1 bgc2fdc1">
                              <img src={require("../../assets/images/forecasting.png")} />
                            </h2>
                          </div>
                      </div>
                      <div className="col-sm-12" onClick={() => { this.redirectToModule(4) }}>
                          <div className="x_content center">
                            <h6 className="h6custom">Forecasting</h6>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12">
                      <div className="m_content">
                        <div className="col-md-12" onClick={() => { this.redirectToModule(5) }}>
                            <div className="x_content center">
                              <h2 className="dTile1 bgc2fdc1">
                                <img src={require("../../assets/images/resource_schedule.png")} />
                              </h2>
                            </div>
                        </div>
                        <div className="col-sm-12" onClick={() => { this.redirectToModule(5) }}>
                            <div className="x_content center">
                              <h6 className="h6custom">Resource Scheduling</h6>
                            </div>
                        </div>
                      </div>
                  </div>
            </div>
            <div className="clearfix"></div>
            <div id="sidebar-menu" className="main_menu_side hidden-print main_menu">
              <div className="menu_section">
                <h3>General</h3>
                <ul className="nav side-menu">
                  {/* {(path == "" || path == "dashboard" || path == "configurations" || path === "forecast-input" || path === "forecast-previous" || path.includes("forecast-output") || path === "forecast-predict" || path === "forecast-customers"  || path === "forecast-materials" || path.includes("forecast-prev-output") || path === "forecast-suppliers") ? */}
                  {/* <li>
                    <a href={"/"}><i className="fa fa-home"></i> Home</a>                   
                  </li> */}
                  {/* :""} */}
                  {/* {(conCheck == 1 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(path == "configurations" ? "theme-clr":"")} href={"/configurations"}><i className="fa fa-cogs"></i> Configurations</a>                   
                  </li>
                  :""} */}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "pickup_and_drop" ? "theme-clr":"")} href={"/pickup_and_drop?inbound"}><i className="fa fa-map-marker"></i> Pickup/Drop Locations</a>
                  </li>:""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "vehicles_data" ? "theme-clr":"")} href={"/vehicles_data?inbound"}><i className="fa fa-truck"></i> Vehicle Configurations</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && inbound_vehicle_availability == 1 && inbound_vehicle_availability_type == 2 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "vehicle_availability" ? "theme-clr":"")} href={"/vehicle_availability?inbound"}><i className="fa fa-calendar-check-o"></i> Vehicle Availability</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && inboundZoneMapping == 1 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "zonemapping" ? "theme-clr":"")} href={"/zonemapping?inbound"}><i className="fa fa-link"></i> Zone Mapping</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && inbound_plant_wise_transporter_configuration == 1 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "transporter_details" ? "theme-clr":"")} href={"/transporter_details?inbound"}><i className="fa fa-users"></i> Transporters</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && inbound_material_configurations == 1 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "materialconfigurations" ? "theme-clr":"")} href={"/materialconfigurations?inbound"}><i className="fa fa-archive"></i> Material Configurations</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && inbound_plant_wise_freight_configuration == 1 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "freightconfigurations" ? "theme-clr":"")} href={"/freightconfigurations?inbound"}><i className="fa fa-th-large"></i> Freight Configurations</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && inbound_route_configuration == 1 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "routeconfigurations" ? "theme-clr":"")} href={"/routeconfigurations?inbound"}><i className="fa fa-map-signs"></i> Route Configurations</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && inbound_auto_clustering == 1 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "autoclustering" ? "theme-clr":"")} href={"/autoclustering?inbound"}><i className="fa fa-database"></i> Auto Clustering</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && inbound_transporter_share_of_business == 1 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "transportershareofbusiness" ? "theme-clr":"")} href={"/transportershareofbusiness?inbound"}><i className="fa fa-credit-card"></i> Transporter Share Of Business</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "loadprocessrun" ? "theme-clr":"")} href={"/loadprocessrun?inbound"}><i className="fa fa-tasks"></i> Run New Plan</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 1 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "dipatchplanhistory" ? "theme-clr":"")} href={"/dipatchplanhistory?inbound"}><i className="fa fa-history"></i> Dispatch Plan Summary</a>
                  </li>
                  :""}
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && localStorage.getItem("user_type")!=="quality" && localStorage.getItem("user_type")!=="security") ?
                  <li>
                      <a className={((activeChk == "kpi_bi_dashboard" ) ? "theme-clr":"")} onClick={this.onclickKPIDash} href={"/kpi_bi_dashboard?outbound"}><i className="fa fa-line-chart"></i> KPI Dashboard</a>
                  </li>
                  :""}

                
                <li className = {(this.state.planningIcons == "show-m" ? "theme-clr iconColor light-bg ":"light-bg")} onClick={this.handleSidebarTools.bind(this,"planningTools")} >
                  <a className = {(this.state.planningIcons == "show-m" ? "theme-clr iconColor":"")} ><FcPlanner style={{fontSize:"22px"}} /> Planning Tools </a>
                </li>
                
                {(localStorage.getItem("user_type") === "quality" || localStorage.getItem("user_type") === "security") ?"": 
                  <li className = {(this.state.tripIcons == "show-m" ? "theme-clr iconColor light-bg":"light-bg")} onClick={this.handleSidebarTools.bind(this,"tripTools")} >
                    <a className = {(this.state.tripIcons == "show-m" ? "theme-clr iconColor":"")} ><GiGps style={{fontSize:"22px"}} /> Tracking Tools </a>
                  </li>
                }
                
                
                {(localStorage.getItem("role") === "admin"  )  || mailCheck ?
                <li className = {(this.state.forecastIcons == "show-m" ? "theme-clr iconColor light-bg":"light-bg")} onClick={this.handleSidebarTools.bind(this,"forecastTools")} >
                  <a className = {(this.state.forecastIcons == "show-m" ? "theme-clr iconColor":"")} ><MdBatchPrediction style={{fontSize:"22px"}} /> Forecast Tools </a>
                </li>
                :''}
                <li className="dividerls"></li>
                { this.state.planningIcons == 'show-m' ?
                <>
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && (localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER" )) ?
                  <li>
                      <a className={((activeChk == "dipatchplanhistory" || activeChk == "") ? "theme-clr":"")} onClick={this.onclickSummaryDash} href={"/dipatchplanhistory?outbound"}><i className="fa fa-history f16"></i> Summary Dashboard</a>
                  </li>
                  :""}
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && (localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER" )) ?
                  <li>
                      <a className={(activeChk == "consolidatedRun" ? "theme-clr":"")} onClick={this.onclickConsolidatedRun} href={"/consolidatedRun?outbound"}><TbWorldUpload style={{fontSize:"26px"}}/>Consolidated Run Plan</a>
                  </li>
                  :""}
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && (localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER")) ?
                  <li>
                      <a className={(activeChk == "loadprocessrun" ? "theme-clr":"")} onClick={this.onclickRunNewPlan} href={"/loadprocessrun?outbound"}><i className="fa fa-tasks f16"></i>Run New Plan</a>
                  </li>
                  :""}
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && (localStorage.getItem("role") === "admin"  )) ?
                  <li>
                  <a className={((activeChk == "editplan_dashboard"  ? "theme-clr":"") ? "theme-clr":"")} onClick={this.onclickPlanOverview}  href={"/editplan_dashboard?outbound"} ><BsGraphUpArrow  style={{fontSize:"22px"}} /> <span style={{display:"block"}}>Plans Overview</span></a>
                </li>
                  :""}
                  
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && (localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER")) ?
                  <li>
                  <a className={((activeChk == "productmaster"  ? "theme-clr":"") ? "theme-clr":"")} onClick={this.productMasterModule}  href={"/productmaster?outbound"} ><MdOutlineProductionQuantityLimits  style={{fontSize:"22px"}} /> <span style={{display:"block"}}>Product Type Master</span></a>
                </li>
                  :""}
                  {/* <li className="dividerls"></li> */}
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && (localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER")) ?
                  <li>
                      <a className={(activeChk == "pickup_and_drop" ? "theme-clr":"")} onClick={this.onclickPickUp} href={"/pickup_and_drop?outbound"}><i className="fa fa-map-marker f16"></i> Pickup/Drop Locations</a>
                  </li>:""}
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && (localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER")) ?
                  <li>
                      <a className={(activeChk == "vehicles_data" ? "theme-clr":"")} onClick={this.onclickVehicleConfigurations} href={"/vehicles_data?outbound"}><i className="fa fa-truck f16"></i> Vehicle Configurations</a>
                  </li>
                  :""}
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && outbound_vehicle_availability == 1 && outbound_vehicle_availability_type == 2  && (localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER" || localStorage.getItem("user_type") === "SERVICE")) ?
                  <li>
                      <a className={(activeChk == "vehicle_availability" ? "theme-clr":"")} onClick={this.onclickVehicleAvalConfig} href={"/vehicle_availability?outbound"}><i className="fa fa-calendar-check-o f16"></i> Vehicle Availability</a>
                  </li>
                  :""}
                  {( this.state.toggleCheckListIcon === true && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && (localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER" || localStorage.getItem("user_type") === "security" || localStorage.getItem("user_type") === "quality")) ?
                  <li>
                      <a className={(activeChk == "vehicle_checklist" ? "theme-clr":"")} onClick={this.onclickVehicleCheckList} href={"/vehicle_checklist"}><FaClipboardCheck  style={{fontSize:"20px"}}/><span><br /></span> Vehicle Checklist</a>
                  </li>
                  :""}
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && localStorage.getItem("user_type") === "admin")  || localStorage.getItem("role") == "admin" ?
                  <li>
                      <a className={(activeChk == "vehicleStatusDashboard" ? "theme-clr":"")} onClick={this.onClickVehicleStatus} href={"/vehicleStatusDashboard"}><FaTruckFront style={{fontSize:"26px"}}/> Vehicle Status</a>
                  </li>
                  :""}
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && mailCheck) ?
                  <li>
                      <a className={(activeChk == "geofenceList" ? "theme-clr":"")} href={"/geofenceList"}><TbRouteOff style={{fontSize:"32px"}}/> Route Restrictions</a>
                  </li>
                  :""}
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && outboundZoneMapping == 1 && localStorage.getItem("user_type") === "admin")  || localStorage.getItem("role") == "admin" ?
                  <li>
                      <a className={(activeChk == "zonemapping" ? "theme-clr":"")} onClick={this.onclickZonConfig} href={"/zonemapping?outbound"}><i className="fa fa-link f16"></i> Zone Configurations</a>
                  </li>
                  :""}
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2  && (localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER")) ?
                  // {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && outbound_plant_wise_transporter_configuration == 1 && (localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER")) ?
                  <li>
                      <a className={(activeChk == "transporter_details" ? "theme-clr":"")} onClick={this.onclickTrnsConfig} href={"/transporter_details?outbound"}><i className="fa fa-users f16"></i> Transporters Configurations</a>
                  </li>
                  :""}
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && outbound_material_configurations == 1 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "materialconfigurations" ? "theme-clr":"")} href={"/materialconfigurations?outbound"}><i className="fa fa-archive f16"></i> Material Configurations</a>
                  </li>
                  :""}
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && outbound_plant_wise_freight_configuration == 1 && localStorage.getItem("user_type") === "admin") || localStorage.getItem("role") == "admin" ?
                  <li>
                      <a className={(activeChk == "freightconfigurations" ? "theme-clr":"")} href={"/freightconfigurations?outbound"}><i className="fa fa-th-large f16"></i> Freight Configurations</a>
                  </li>
                  :""}
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && outbound_route_configuration == 1 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "routeconfigurations" ? "theme-clr":"")} onClick={this.onclickRouteConfig} href={"/routeconfigurations?outbound"}><i className="fa fa-map-signs f16"></i> Route Configurations</a>
                  </li>
                  :""}
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && outbound_auto_clustering == 1 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "autoclustering" ? "theme-clr":"")} href={"/autoclustering?outbound"}><i className="fa fa-database f16"></i> Auto Clustering</a>
                  </li>
                  :""}
                  {/* {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && outbound_transporter_share_of_business == 1) ?
                  <li>
                      <a className={(activeChk == "transportershareofbusiness" ? "theme-clr":"")} href={"/transportershareofbusiness?outbound"}><i className="fa fa-credit-card"></i> Transporter Share Of Business</a>
                  </li>
                  :""} */}
                  
                  {(localStorage.getItem("role") == "admin" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(path == "configurations?dispatch_planning" ? "theme-clr":"")} onClick={this.onclickConfig} href={"/configurations?dispatch_planning"}><i className="fa fa-cogs f16"></i> Configurations</a>                   
                  </li>
                  :""}

                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 2 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "inputplan" ? "theme-clr":"")} href={"/inputplan"}><i className="fa fa-table f16"></i> Input Plan Summary</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 2 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "stackplan" ? "theme-clr":"")} href={"/stackplan"}><i className="fa fa-tasks f16"></i> Load Plan Summary</a>
                  </li>
                  :""}
                  {(path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 2 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "previousplans" ? "theme-clr":"")} href={"/previousplans"}><i className="fa fa-history f16"></i> Dispatch Plan Summary</a>
                  </li>
                  :""}
                  {(path != "configurations" && localStorage.getItem("module_type") == 1 && localStorage.getItem("data_type") == 2 && (localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER" )) ? 
                  <li className={(activeChk == "plantwconfigurations" ? "theme-clr ":"")}>
                      <a className={(activeChk == "plantwconfigurations" ? "theme-clr":"")} onClick={this.onclickConsignments} href={"/plantwconfigurations"}><MdOutlineDisplaySettings style={{fontSize:"35px"}} /> <>Plant Wise Configurations</> </a>
                  </li>
                  :""}
                  {(localStorage.getItem("role") == "admin" && path != "" && path != "dashboard" && path != "configurations" && localStorage.getItem("module_type") == 2 && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(path == "configurations?stacking" ? "theme-clr":"")} href={"/configurations?stacking"}><i className="fa fa-cogs"></i> Configurations</a>                   
                  </li>
                  :""}

              </>
              :''}

                  
                  {(path === "forecast-input" || path.includes("forecast-prev-output") || path === "forecast-previous" || path.includes("forecast-output") || path === "forecast-predict"  || path === "forecast-output" || path === "forecast-customers"  || path === "forecast-materials" || path === "forecast-suppliers" && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "forecast-suppliers" ? "theme-clr":"")} href={"/forecast-suppliers"}><i className="fa fa-users"></i> Suppliers</a>
                  </li>
                  :""}
                  {(path === "forecast-input" || path.includes("forecast-prev-output") || path === "forecast-previous" || path.includes("forecast-output") || path === "forecast-predict"  || path === "forecast-output" || path === "forecast-customers"  || path === "forecast-materials" || path === "forecast-suppliers" && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "forecast-customers" ? "theme-clr":"")} href={"/forecast-customers"}><i className="fa fa-users"></i> Customers</a>
                  </li>
                  :""}
                   {(path === "forecast-input" || path.includes("forecast-prev-output") || path === "forecast-previous" || path.includes("forecast-output") || path === "forecast-predict"  || path === "forecast-output" || path === "forecast-customers"  || path === "forecast-materials" || path === "forecast-suppliers" && localStorage.getItem("user_type") === "admin") ?
                 <li>
                      <a className={(activeChk == "forecast-materials" ? "theme-clr":"")} href={"/forecast-materials"}><i className="fa fa-cubes"></i> Materials</a>
                  </li>
                  :""}
                  {(path === "forecast-input" || path.includes("forecast-prev-output") || path === "forecast-previous" || path.includes("forecast-output") || path === "forecast-predict"  || path === "forecast-output" || path === "forecast-customers"  || path === "forecast-materials" || path === "forecast-suppliers" && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "forecast-input" ? "theme-clr":"")} href={"/forecast-input"}><i className="fa fa-table"></i> Input Data</a>
                  </li>
                  :""}
                  {(path === "forecast-input" || path.includes("forecast-prev-output") || path === "forecast-previous" || path.includes("forecast-output") || path === "forecast-predict" || path === "forecast-customers"  || path === "forecast-materials" || path === "forecast-suppliers" && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "forecast-predict" ? "theme-clr":"")} href={"/forecast-predict"}><i className="fa fa-pie-chart"></i> Forecast</a>
                  </li>
                  :""}
                  {(path === "forecast-input" || path.includes("forecast-prev-output") || path === "forecast-previous" || path.includes("forecast-output") || path === "forecast-predict" || path === "forecast-customers"  || path === "forecast-materials" || path === "forecast-suppliers" && localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(activeChk == "forecast-previous" ? "theme-clr":"")} href={"/forecast-previous"}><i className="fa fa-pie-chart"></i> Previous Plan</a>
                  </li>
                  :""}

                  {/* Tracking Menus */}
                  {localStorage.getItem("user_type") === "admin" ? 
                    <li className="dividerls"></li>
                  :""}
    

                  { this.state.tripIcons == 'show-m' ?
                  <>
                  {localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER" || localStorage.getItem("user_type") === "SERVICE" ? 
                  <li className={(activeChk == "trips" ? "theme-clr":"")}>
                      <a className={(activeChk == "trips" ? "theme-clr":"")} onClick={this.onclickTrips} href={"/trips"}><i className="fa fa-truck"></i> Trips</a>
                  </li>
                  :""}
                  {localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER" || localStorage.getItem("user_type") === "SERVICE" ? 
                  <li className={(activeChk == "gpsquality" ? "theme-clr":"")}>
                      <a className={(activeChk == "gpsquality" ? "theme-clr":"")} onClick={this.onclickTrips} href={"/gpsquality?outbound"}><i className="fa fa-signal"></i> GPS Quality</a>
                  </li>
                  :""}
                  {/* {localStorage.getItem("user_type") === "admin"? 
                  <li className={(activeChk == "consignments" ? "theme-clr":"")}>
                      <a className={(activeChk == "consignments" ? "theme-clr":"")} onClick={this.onclickConsignments} href={"/consignments"}><BsBoxes style={{fontSize:"24px"}} /> Consignments </a>
                  </li>
                  :""} */}
                  {/* {localStorage.getItem("user_type") === "admin"? 
                  <li className={(activeChk == "analyticsdashboard" ? "theme-clr":"")}>
                      <a className={(activeChk == "analyticsdashboard" ? "theme-clr":"")} onClick={this.onClickAnalyticsDashboard} href={"/analyticsdashboard"}><MdOutlineDashboard  style={{fontSize:"24px"}} /> Analytics Dashboard </a>
                  </li>
                  :""} */}

                  {/* {localStorage.getItem("email") == "optirun@enmovil.in"? 
                  <li className={(activeChk == "questionnarie" ? "theme-clr":"")}>
                      <a className={(activeChk == "questionnarie" ? "theme-clr":"")} onClick={this.onclickConsignments} href={"/questionnarie"}><MdOutlineDisplaySettings style={{fontSize:"36px"}} /> <>questionnarie</> </a>
                  </li>
                  :""} */}

                  {localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER" || localStorage.getItem("user_type") === "SERVICE" ? 
                  <li className={(activeChk == "trucksgps" ? "theme-clr":"")}>
                      <a className={(activeChk == "trucksgps" ? "theme-clr":"")} onClick={this.onclickTruckGPS} href={"/trucksgps"}><i style={{display:"inline-block"}} className="fa fa-map-marker"></i> Truck GPS Data</a>
                  </li>
                  :""}

                  {localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER" || localStorage.getItem("user_type") === "SERVICE" ? 
                  <li className={(activeChk == "unscheduledstoppages" ? "theme-clr":"")}>
                      <a className={(activeChk == "unscheduledstoppages" ? "theme-clr":"")} onClick={this.onclickunscheduledstoppages} href={"/unscheduledstoppages"}><TiStopwatch style={{fontSize:"24px"}} /> Unscheduled Stoppages </a>
                  </li>
                  :""}
                  {localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER" || localStorage.getItem("user_type") === "SERVICE" ? 
                  <li className={(activeChk == "deviationreports" ? "theme-clr":"")}>
                      <a className={(activeChk == "deviationreports" ? "theme-clr":"")} onClick={this.onclickTrips} href={"/deviationreports"}>
                        <BsPieChart style={{fontSize:"24px"}} /> Deviation Reports</a>
                  </li>
                  :""}
                  <li className={(activeChk == "actualdatafo" ? "theme-clr":"")}>
                      <a className={(activeChk == "actualdatafo" ? "theme-clr":"")} onClick={this.onclickTrips} href={"/actualdatafo"}>
                        <MdCompareArrows style={{fontSize:"46px"}} /> FO Data</a>
                  </li>
                </>
                  :''}
                  
                  {/* {localStorage.getItem("user_type") === "admin" || localStorage.getItem("user_type") === "USER" || localStorage.getItem("user_type") === "SERVICE" ? 
                  <li className={(activeChk == "overspeed" ? "theme-clr":"")}>
                      <a className={(activeChk == "overspeed" ? "theme-clr":"")} onClick={this.onclickReports} href={"/overspeed"}><i style={{display:"inline-block"}} className="fa fa-clipboard"></i> Reports</a>
                  </li>
                  :""} */}

                  {/* Vehicle Forecasting Menus */}


                
                { this.state.forecastIcons == 'show-m' ?
                <>
                  {( localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(path == "vehicleForecastConfig?outbound" ? "theme-clr":"")} onClick={this.onclickConfig} href={"/vehicleForecastConfig?outbound"}><i className="fa fa-truck"></i>Vehicle Configuration</a>                   
                  </li>
                  :""}
                  {( localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(path == "VehicleForecastOrderDetails?outbound" ? "theme-clr":"")} onClick={this.onclickConfig} href={"/VehicleForecastOrderDetails?outbound"}><i className="fa fa-database"></i> Forecast Input Data</a>                   
                  </li>
                  :""}
                  {( localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(path == "vehicleforecastplanhistory?outbound" ? "theme-clr":"")} onClick={this.onclickConfig} href={"/vehicleforecastplanhistory?outbound"}><i className="fa fa-columns"></i> Forecast Output Data</a>                   
                  </li>
                  :""}
                  {( localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(path == "loadanalysis?outbound" ? "theme-clr":"")} onClick={this.onclickConfig} href={"/loadanalysis?outbound"}><i className="fa fa-pie-chart"></i> OBD Analysis</a>                   
                  </li>
                  :""}
                  {( localStorage.getItem("user_type") === "admin") ?
                  <li>
                      <a className={(path == "recommendedvehicles?outbound" ? "theme-clr":"")} onClick={this.onclickConfig} href={"/recommendedvehicles?outbound"}><IoAnalyticsSharp  style={{fontSize:"24px"}} /> Forecast Summary</a>                   
                  </li>
                  :""}
              </>
              :''}

                </ul>

              </div> 
            </div>
          </div>
        );
    }
}

export default withRouter(Sidebar) ;
