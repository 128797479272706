import React, { useState, useEffect } from "react";
import Countdown from "./countdown";
import Timer from "./timename";

const TimerComponent = (props) => {
  const timers = props.allTransactionIds;

  const [activeTimerIndex, setActiveTimerIndex] = useState(0);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setActiveTimerIndex((prevIndex) => (prevIndex + 1) % timers.length);
    }, 3000);

    return () => {
      clearTimeout(timerId);
    };
  }, [activeTimerIndex, timers]);

  const handleTimeout = () => {
    setActiveTimerIndex((prevIndex) => (prevIndex + 1) % timers.length);
  };

  const currentTimerObj = timers[activeTimerIndex];

  return (
    <div>
      {timers.length > 0 ? (
        <div className="float-right timerDisplay">
          <Timer timer={currentTimerObj} />
          {timers.map((timer, index) => (
            <div
              key={timer.id}
              style={{ display: index === activeTimerIndex ? "block" : "none" }}
            >
              <Countdown
                duration={timer.duration}
                onTimeout={handleTimeout}
                timerKey={timer.id}
              />
            </div>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TimerComponent;
