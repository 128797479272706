import React, { Component } from "react";
// import Constant from "../common/constants";
import redirectURL from "../../redirectURL";
import Select from "react-select";
import getToolConfiguration from "../commonFunctions";
import LoadProcessRun from "../dispatchplanhistory";
import DailySummaryCharts from "./dailysummarycharts";

export default class DailyPlansDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationNames: [],
      location: "",
      summarydashboardplantwiseTab: "activet",
      dailyplanschartsTab: "",
      summarydashboardplantwiseData: true,
      dailyplanschartsData: false,
    };
  }

  async componentDidMount() {
    var curl = window.location.search;
    var ul = curl.split("?");
    let url_type = ul[parseInt(ul.length) - 1];
    let data_type;
    let path = url_type;

    if (path == "inbound") {
      data_type = 1;
    } else {
      data_type = 2;
    }
    await this.setState({ data_type: data_type });

    let params = {
      data_type: data_type,
    };
    let toolparameters = {
      data_type: data_type,
    };
    await getToolConfiguration(toolparameters);
    await this.boundTypeLocations(params);
    let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
  }

  boundTypeLocations = async (param) => {
    let location_access = JSON.parse(localStorage.getItem("location_access"));

    let locationNames = [{ label: "All", value: "All" }];
    await redirectURL
      .post("master/getBoundTypeLocations", param)
      .then(async (response) => {
        if (response.data.status == "success") {
          let boundLocations = response.data.boundLocations;
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              if (location_access != null) {
                if (location_access.includes(item.location_code)) {
                  locationNames.push({
                    value: item.location_code,
                    label: item.location_name,
                  });
                }
              } else {
                locationNames.push({
                  value: item.location_code,
                  label: item.location_name,
                });
              }
            });

            if (locationNames.length > 0) {
              await this.setState({
                locationNames: locationNames,
                location: locationNames[0],
              });
            } else {
              await this.setState({
                locationNames: [{ label: "Please Add Data", value: "no_data" }],
                location: [{ label: "Please Add Data", value: "no_data" }],
              });
            }
          }
        }
      });
  };

  locationChanged = async (location) => {
    await this.setState({
      location: location,
    });

    await getToolConfiguration({ plant_code: location.value });
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Transporter Configurations",
      activity: `Clicked on pickup location Dropdown`,
      event: `Selected ${location.value} plant`,
      data_type: this.state.data_type,
    };
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  onClickTab = (pageType) => {
    var tabName = pageType;

    var summarydashboardplantwiseTab = "activet";
    var dailyplanschartsTab = "";
    var summarydashboardplantwiseData = true;
    var dailyplanschartsData = false;
    if (pageType === "summarydashboardplantwiseData") {
      summarydashboardplantwiseTab = "activet";
      dailyplanschartsTab = "";
      summarydashboardplantwiseData = true;
      dailyplanschartsData = false;
    } else if (pageType === "dailyplanschartsData") {
      summarydashboardplantwiseTab = "";
      dailyplanschartsTab = "activet";
      summarydashboardplantwiseData = false;
      dailyplanschartsData = true;
    }
    this.setState({
      summarydashboardplantwiseTab: summarydashboardplantwiseTab,
      dailyplanschartsTab: dailyplanschartsTab,
      summarydashboardplantwiseData: summarydashboardplantwiseData,
      dailyplanschartsData: dailyplanschartsData,
    });
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "dispatch plans summary  ",
      activity: `Clicked on  ${tabName} tab`,
      event: `Navigated to ${tabName} screen `,
      data_type: this.state.data_type,
    };
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
  };

  render() {
    const {
      location,
      locationNames,
      summarydashboardplantwiseTab,
      dailyplanschartsTab,
      summarydashboardplantwiseData,
      dailyplanschartsData,
    } = this.state;
    return (
      <div className="">
        {/* start  */}
        {/* <div className="row mb-10p">
          <div className="col-sm-12">
            <h5 className="fbold  d-flex justify-content-between">
              <span>Transporter Configurations</span>
            </h5>
          </div>
        </div> */}
        {/* start  */}
        <div className="row">
          {/* start  */}
          {/* <div className="col-md-2 col-sm-12">
            <div className="form-group ">
              <label className="col-form-label f12">
                {Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}
              </label>
              <Select
                placeholder={"Select"}
                onChange={this.locationChanged}
                name="location"
                value={location}
                options={locationNames}
              />
            </div>
          </div> */}
          {/* start  */}
          <div className="col-sm-12">
            <ul className="d-tabs">
              <li
                onClick={this.onClickTab.bind(this, "dailyplanschartsData")}
                className={"lttabs " + dailyplanschartsTab}
              >
                <button type="button" className="btn">
                  Planning Summary
                </button>
              </li>

              <li
                onClick={this.onClickTab.bind(
                  this,
                  "summarydashboardplantwiseData"
                )}
                className={"lttabs " + summarydashboardplantwiseTab}
              >
                <button type="button" className="btn mt-30p">
                  Dispatch Plans{" "}
                </button>
              </li>
            </ul>
          </div>
        </div>
        {summarydashboardplantwiseData ? (
          location !== "" ? (
            <LoadProcessRun plantLocation={location} />
          ) : (
            ""
          )
        ) : (
          ""
        )}
        {dailyplanschartsData ? (
          location !== "" ? (
            <DailySummaryCharts plantLocation={location} />
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    );
  }
}
