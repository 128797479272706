import React, { Component } from "react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import Constant from "../common/constants";
import Modal from "react-responsive-modal";
import CountUp from "react-countup";
import "react-responsive-modal/styles.css";
import redirectURL from "../redirectURL";
import Select from "react-select";
// import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
import getToolConfiguration from "./commonFunctions";
import SweetAlert from "react-bootstrap-sweetalert";
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
// import { subDays } from "date-fns";
import GridButton from "./gridButton";
import GridCheckbox from "./gridCheckbox";
import CSVFileValidator from "csv-file-validator";
import { getDDMMYYYYHHMMSS } from "../common/utils";
import { logFormChanges } from "./auditLogfunction";
import $ from "jquery";
var moment = require("moment");

export default class VehiclesAvailabilityMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 100,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        GridButton: GridButton,
        GridCheckbox: GridCheckbox,
      },
      rowData: [],
      tabActive: "all",
      currentDateVehicles: [],
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 100,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      openMasterVehicleAvailabilityModel: false,
      data_type: "",
      location: this.props.plantLocation,
      vehicle_type: "",
      vehicleTypeList: [],
      transporter_name: "",
      transporterList: [],
      vehicle_no: "",
      show: false,
      basicTitle: "",
      basicType: "default",
      row_id: "",
      vehicle_priority: "Owned",
      overlayBlock: "show-n",
      showSlideBlock: "",
      csvfiledata: [],
      openVehiclePriorityConfigurationModel: false,
      vehicle_priority_order_owned: 1,
      vehicle_priority_order_contract: 2,
      vehicle_priority_order_market: 3,
      max_distance_per_day: "",
      monthly_min_planning_distance: 0,
      remaining_distance: "",
      vehicle_next_available_date: "",
      loadshow: "show-m",
      vehicle_breakdown: false,
      clickonEdit: false,
      oldDate: "",
      newDate: "",
      transit_type: "",
      vehiclesData: [],
      previousData: "",
      totalMasterData: [],
      vehicle_product_type: "",
      vehicleTypeConfigs: [],
    };
  }

  async componentDidMount() {
    var curl = window.location.search;
    var ul = curl.split("?");
    // console.log("ul ",ul)
    // let url_type = window.location.href
    let url_type = ul[parseInt(ul.length) - 1];
    // console.log("url_type ", url_type)
    // let path_type = url_type.split('/')
    let path_type = url_type;
    let data_type;
    // let path = path_type[path_type.length-1]
    let path = url_type;

    if (path == "inbound") {
      data_type = 1;
      this.setState({ data_type: data_type });
    } else {
      data_type = 2;
      this.setState({ data_type: data_type });
    }
    let params = {
      data_type: data_type,
      location_code: this.props.plantLocation.value,
    };
    let toolparameters = {
      data_type: data_type,
      plant_code: this.props.plantLocation.value,
    };
    await getToolConfiguration(toolparameters);
    await this.getVehiclesData(params);
    await this.getTransporterDetails(params);
    await this.getMasterAvailabilityVehiclesData(params);
  }

  async componentDidUpdate(oldProps) {
    let data_type = this.state.data_type;
    let params = {
      data_type: data_type,
      location_code: this.props.plantLocation.value,
    };
    if (oldProps.plantLocation !== this.props.plantLocation) {
      this.setState({ location: this.props.plantLocation, tabActive: "all" });
      await this.getVehiclesData(params);
      await this.getTransporterDetails(params);
      await this.getMasterAvailabilityVehiclesData(params);
    }
  }

  getVehiclesData = (params) => {
    let vehicleTypeList = [];
    let dupVehicleArr = [];
    var vehicleTypeConfigs = [];
    // console.log(params,"paramsssss vehicle data")
    redirectURL
      .post("master/getVehiclesData", params)
      .then(async (response) => {
        if (response.data.status === "success") {
          // console.log(
          //   response.data.vehiclesData,
          //   "response.data.vehiclesData first"
          // );
          let vehiclesData = response.data.vehiclesData;
          this.setState({
            vehiclesData,
          });
          if (vehiclesData.length > 0) {
            vehiclesData.map((item) => {
              if (vehicleTypeList.some((e) => e.value === item.vehicle_type)) {
                dupVehicleArr.push(item);
              } else {
                vehicleTypeList.push({
                  value: item.vehicle_type,
                  label: item.vehicle_type,
                });
              }
              return true;
            });
            // console.log(vehicleTypeList,"vehicleTypeList first")
            await this.setState({
              vehicleTypeList: vehicleTypeList,
              vehicle_type: vehicleTypeList[0],
              vehicleTypeConfigs: vehiclesData,
            });
          } else {
            this.setState({
              vehicleTypeList: [],
              vehicle_type: null,
            });
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getVehiclesData",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  vehicleTypeChanged = (vehicle_type) => {
    this.setState({
      vehicle_type: vehicle_type,
    });
  };

  transporterChanged = (transporter_name) => {
    this.setState({
      transporter_name: transporter_name,
    });
  };

  getTransporterDetails = async (param) => {
    let transporterList = [];
    let duplicateTransporterList = [];
    await redirectURL
      .post("master/getTransporterDetails", param)
      .then(async (response) => {
        // console.log("response.data ", response.data)
        if (response.data.status == "success") {
          let transporters = response.data.transporterDetailsData;

          //   const x = transporters.reduce((accumulator, cur) => {
          //     if (!accumulator[cur.location_code]) {
          //         accumulator[cur.location_code] = [cur];
          //     } else {
          //     }
          //     return accumulator
          //   }, []);

          //   const transporter = Object.values(x);
          //   let transporterArr = transporter.flat()

          if (transporters.length > 0) {
            transporters.map((item) => {
              transporterList.push({
                value: item.transporter_code,
                label: item.transporter_name,
              });
            });
            // console.log(transporterList,"transporterList")
            let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
            let transporter_name = "";
            if (toolConfig[0].plant_wise_transporter_configuration == 0) {
              transporter_name = { label: "LSP", value: "LSP" };
            }
            await this.setState({
              transporterList: transporterList,
              transporter_name: transporter_name,
            });
          } else {
            let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
            if (toolConfig[0].plant_wise_transporter_configuration == 0) {
              this.setState({
                transporter_name: { label: "LSP", value: "LSP" },
              });
            } else {
              this.setState({
                transporterList: [],
                transporter_name: null,
              });
            }
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getTransporterDetails",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  getMasterAvailabilityVehiclesData = async (params) => {
    // console.log(params, "first");
    redirectURL
      .post("master/getMasterAvailabilityVehiclesData", params)
      .then(async (response) => {
        if (response.data.status == "success") {
          // console.log(response.data.masterVehicleAvailabilityData,"response.data.masterVehicleAvailabilityData")
          let masterVehicleAvailabilityData =
            response.data.masterVehicleAvailabilityData;
          let vehiclesData = this.state.vehiclesData;
          const mergeDetails = (x, y) => {
            return y.map((itemY) => {
              const matchedItem = x.find(
                (itemX) =>
                  itemX.vehicle_type === itemY.vehicle_type &&
                  itemX.location_code === itemY.location_code &&
                  itemX.data_type === itemY.data_type
              );
              if (matchedItem) {
                return {
                  ...itemY,
                  vehicle_max_weight: matchedItem.vehicle_max_weight,
                  max_volume_m3: matchedItem.max_volume_m3,
                };
              }
              return itemY;
            });
          };

          const updatedMasterVehicleAvailabilityData = mergeDetails(
            vehiclesData,
            masterVehicleAvailabilityData
          );
          //   console.log(
          //     updatedMasterVehicleAvailabilityData,
          //     "first updatedMasterVehicleAvailabilityData",
          //     this.state.location
          //   );
          let todaysDate = new Date();
          var currentDateVehicles = updatedMasterVehicleAvailabilityData.filter(
            (e) => {
              return (
                new Date(e.vehicle_next_available_date).toDateString() ===
                todaysDate.toDateString()
              );
            }
          );
          if (this.state.location.value !== "") {
            currentDateVehicles = currentDateVehicles.filter(
              (veh) => veh.location_code === this.state.location.value
            );
          }
          await this.setState({
            rowData: updatedMasterVehicleAvailabilityData,
            totalMasterData: updatedMasterVehicleAvailabilityData,
            currentDateVehicles: currentDateVehicles,
            loadshow: "show-n",
          });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getMasterAvailabilityVehiclesData",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  openModelForMasterVehicleAvailability = () => {
    // console.log('hit',this.state.vehicle_next_available_date)
    this.setState({ oldDate: this.state.vehicle_next_available_date });
    var clickOn = "";
    if (this.state.clickonEdit) {
      clickOn = "Clicked on Edit button in the aggrid";
    } else {
      clickOn = "clicked on Add vehicle master button";
    }
    let logParams = {
      location_code: !this.state.location.value
        ? this.state.editLocation.value
        : this.state.location.value,
      location_name:
        this.state.location.label === "ALL"
          ? this.state.editLocation.label
          : this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Vehicle Availability configurations",
      activity: clickOn,
      event: "Vehicle master Modal Opened",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    this.setState((prevState) => ({
      openMasterVehicleAvailabilityModel:
        !prevState.openMasterVehicleAvailabilityModel,
    }));
  };

  handelMasterVehicleAvailabilityModelCancel = () => {
    // console.log('ht')
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Vehicle Availability Configurations",
      activity: "clicked on Cancel Button button in the modal",
      event: "Modal is closed",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    let transporter_name = "";
    if (toolConfig[0].plant_wise_transporter_configuration == 0) {
      transporter_name = { label: "LSP", value: "LSP" };
    }

    this.setState((prevState) => ({
      openMasterVehicleAvailabilityModel:
        !prevState.openMasterVehicleAvailabilityModel,
      vehicle_date: "",
      transporter_name: transporter_name,
      vehicle_no: "",
      row_id: "",
      vehicle_priority: "Owned",
      vehicle_type: "",
      max_distance_per_day: "",
      monthly_min_planning_distance: 0,
      remaining_distance: "",
      vehicle_next_available_date: "",
      vehicle_breakdown: false,
      transit_type: "",
      clickonEdit: false,
      vehicle_product_type: "",
    }));
  };

  vehicleAvailabilityInputFields = async (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "vehicle_next_available_date") {
      this.setState({ newDate: value });
    }
    if (name === "vehicle_breakdown") {
      // console.log(value)
      if (value === "false") {
        await this.setState({ [name]: true });
      } else {
        await this.setState({ [name]: false });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  checkNotEqualstoZero = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    var regex = /^(?!0)\d*(\.\d+)?$/;
    var out = regex.test(value);
    if (out) {
      this.setState({ [name]: value });
    }
  };

  saveMasterVehicleAvalabilityData = (params) => {
    let data_type = this.state.data_type;

    let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    let transporter_name = "";
    if (toolConfig[0].plant_wise_transporter_configuration === 0) {
      transporter_name = { label: "SAMSCO", value: "SAMSCO" };
    }

    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Vehicle Configurations",
      activity: "Vehicle Details Entered",
      event: "New Vehicle Details Added",
      data_type: this.state.data_type,
    };
    // console.log(logParams,"new ones added")
    redirectURL
      .post("/master/logUserUpdateOrSaveDataActivities", logParams)
      .then(() => {})
      .catch(function (error) {
        var errDetails = {
          url: "/master/logUserUpdateOrSaveDataActivities",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
    console.log(params, "parasm first");
    redirectURL
      .post("/master/saveMasterVehicleAvailability", params)
      .then((response) => {
        if (response.data.status === "success") {
          let param = {
            data_type: data_type,
          };
          this.getMasterAvailabilityVehiclesData(param);
          this.setState({
            openMasterVehicleAvailabilityModel: false,
            vehicle_type: "",
            vehicle_date: "",
            transporter_name: transporter_name,
            vehicle_no: "",
            row_id: "",
            vehicle_priority: "Owned",
            max_distance_per_day: "",
            monthly_min_planning_distance: 0,
            remaining_distance: "",
            vehicle_next_available_date: "",
            vehicle_breakdown: false,
            transit_type: "",
            show: true,
            basicTitle: "Vehicle Successfully Added",
            basicType: "success",
            vehicle_product_type: "",
          });
        } else {
          if (response.data.status == "failed") {
            this.setState({
              show: true,
              basicTitle: response.data.message,
              basicType: "danger",
            });
          } else {
            this.setState({
              show: true,
              basicTitle: "Data Not Saved successfully",
              basicType: "danger",
            });
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "/master/saveMasterVehicleAvailability",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  updateVehicleUpdateLog = () => {
    if (
      getDDMMYYYYHHMMSS(this.state.oldDate) !==
      getDDMMYYYYHHMMSS(this.state.newDate)
    ) {
      let logParams = [
        {
          vehicle_no: this.state.vehicle_no,
          process: "Vehicle manual update from Vehicle Master screen",
          vehicle_next_available_date: this.state.newDate,
          previous_available_date: this.state.oldDate,
          plant: this.state.location.value,
          updated_by: "user",
        },
      ];
      redirectURL.post("/master/logVehicleTimeEdit", logParams);
      // .then((res)=>{
      //     console.log(res)
      // })
    }
  };

  formMasterVehicleAvailability = (e) => {
    let logParams = {
      location_code: !this.state.location.value
        ? this.state.editLocation.value
        : this.state.location.value,
      location_name:
        this.state.location.label === "ALL"
          ? this.state.editLocation.label
          : this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Vehicle Availability Configurations",
      activity: "clicked on SAVE Button in the Modal",
      event: "Details are saved",
      data_type: this.state.data_type,
    };
    // console.log(getDDMMYYYYHHMMSS(this.state.oldDate),getDDMMYYYYHHMMSS(this.state.newDate))
    // console.log(getDDMMYYYYHHMMSS(this.state.oldDate)!==getDDMMYYYYHHMMSS(this.state.newDate))
    // console.log(logParams,"logParamsssss")
    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    this.updateVehicleUpdateLog();
    this.setState({ loadshow: "show-m", overlayBlock: "show-m" });
    e.preventDefault();
    var openMasterVehicleAvailabilityModel =
      this.state.openMasterVehicleAvailabilityModel;
    var data_type = this.state.data_type;
    var location = !this.state.location.value
      ? this.state.editLocation
      : this.state.location;
    var vehicle_type = this.state.vehicle_type;
    var transporter_name = this.state.transporter_name;
    var vehicle_no = this.state.vehicle_no;
    var row_id = this.state.row_id;
    var vehicle_priority = this.state.vehicle_priority;
    var max_distance_per_day = this.state.max_distance_per_day;
    var monthly_min_planning_distance =
      this.state.monthly_min_planning_distance;
    if (
      parseFloat(max_distance_per_day) >
      parseFloat(monthly_min_planning_distance)
    ) {
      this.setState({
        show: true,
        basicTitle:
          "Max distance per day should be less than monthly min planning distance",
        basicType: "danger",
        loadshow: "show-n",
        overlayBlock: "show-n",
      });
      return;
    }
    let remaining_distance = this.state.remaining_distance;
    let vehicle_next_available_date = moment
      .parseZone(this.state.vehicle_next_available_date)
      .format("YYYY-MM-DD HH:mm");
    let vehicle_breakdown = this.state.vehicle_breakdown === true ? 1 : 0;
    let transit_type = this.state.transit_type;
    var vehicle_product_type = this.state.vehicle_product_type;
    if (
      parseFloat(
        this.state.max_distance_per_day >
          parseFloat(this.state.monthly_min_planning_distance)
      )
    ) {
      this.setState({
        show: true,
        basicTitle:
          "Max distance per plan should be less than  Monthly min distance",
        loadshow: "show-n",
        overlayBlock: "show-n",
        basicType: "danger",
      });
      return;
    }
    // start
    // if (
    //   parseFloat(this.state.remaining_distance) >
    //   parseFloat(this.state.monthly_min_planning_distance)
    // ) {
    //   this.setState({
    //     show: true,
    //     basicTitle:
    //       "Monthly Remaining distance should be less than or equal to Monthly min distance",
    //     loadshow: "show-n",
    //     overlayBlock: "show-n",
    //     basicType: "danger",
    //   });
    //   return;
    // }
    // start
    let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    let params = {
      vehicle_type: vehicle_type.value,
      data_type: data_type,
      location_code: !this.state.location.value
        ? this.state.editLocation.value
        : this.state.location.value,
      location_name: !this.state.location.value
        ? this.state.editLocation.label
        : this.state.location.label,
      vehicle_no: vehicle_no,
      transporter_code: transporter_name.value,
      transporter_name: transporter_name.label,
      vehicle_priority: vehicle_priority,
      max_distance_per_day: parseInt(max_distance_per_day),
      monthly_min_planning_distance: parseInt(monthly_min_planning_distance),
      remaining_distance: parseFloat(remaining_distance),
      vehicle_next_available_date,
      vehicle_breakdown,
      transit_type,
      vehicle_product_type,
    };
    // console.log(params, "formMasterVehicleAvailability first", row_id);
    if (row_id !== "") {
      params.row_id = row_id;
      this.updateMasterVehicleAvailabilityData(params);
    } else {
      this.setState({ clickonEdit: false });
      if (location.value != null && transporter_name.value != null) {
        if (vehicle_type.value != null) {
          this.saveMasterVehicleAvalabilityData(params);
        } else {
          this.setState({
            show: true,
            basicTitle: "Please add Vehicles First",
            basicType: "danger",
            loadshow: "show-n",
            overlayBlock: "show-n",
          });
        }
      } else {
        if (transporter_name.value == null) {
          this.setState({
            show: true,
            basicTitle: "Please Select Transporter",
            basicType: "danger",
            loadshow: "show-n",
            overlayBlock: "show-n",
          });
        } else {
          this.setState({
            show: true,
            basicTitle:
              "Please add " +
              Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION +
              " First",
            basicType: "danger",
            loadshow: "show-n",
            overlayBlock: "show-n",
          });
        }
      }
    }
    this.setState({ loadshow: "show-n", overlayBlock: "show-n" });
  };

  updateMasterVehicleAvailability = async (propdata) => {
    var data = propdata.data;
    var vehicleTypeList = [],
      dupVehicleArr = [];
    var vehicleTypeConfigs = this.state.vehicleTypeConfigs;
    if (vehicleTypeConfigs.length > 0) {
      vehicleTypeConfigs.map((item) => {
        if (
          !dupVehicleArr.includes(item.vehicle_type) &&
          item.location_code === data.location_code
        ) {
          dupVehicleArr.push(item.vehicle_type);
          vehicleTypeList.push({
            value: item.vehicle_type,
            label: item.vehicle_type,
          });
        }
        return true;
      });
      // console.log(vehicleTypeList,"vehicleTypeList first")
      await this.setState({
        vehicleTypeList: vehicleTypeList,
        vehicle_type: vehicleTypeList[0],
        // vehicleTypeConfigs: vehicleTypeConfigs,
      });
    }
    // console.log("inside first", data);
    if (!this.state.location.value) {
      this.setState({
        editLocation: { value: data.location_code, label: data.location_name },
      });
    }
    await this.setState({
      vehicle_no: data.vehicle_no,
      transporter_name: {
        label: data.transporter_name,
        value: data.transporter_code,
      },
      row_id: data._id,
      vehicle_priority: data.vehicle_priority,
      vehicle_type: { label: data.vehicle_type, value: data.vehicle_type },
      max_distance_per_day: data.max_distance_per_day,
      monthly_min_planning_distance: data.monthly_min_planning_distance,
      remaining_distance: data.remaining_distance,
      vehicle_next_available_date: data.vehicle_next_available_date,
      vehicle_breakdown: data.vehicle_breakdown == 1 ? true : false,
      clickonEdit: true,
      transit_type: data.transit_type,
      vehicle_product_type: data.vehicle_product_type,
    });
    var previousData = {
      vehicle_type: data.vehicle_type,
      vehicle_no: data.vehicle_no,
      transporter_code: data.transporter_code,
      transporter_name: data.transporter_name,
      vehicle_priority: data.vehicle_priority,
      max_distance_per_day: data.max_distance_per_day,
      monthly_min_planning_distance: data.monthly_min_planning_distance,
      remaining_distance: data.remaining_distance,
      vehicle_next_available_date: data.vehicle_next_available_date,
      vehicle_breakdown: data.vehicle_breakdown,
      transit_type: data.transit_type,
      vehicle_product_type: data.vehicle_product_type,
    };

    this.setState({
      previousData,
    });

    this.openModelForMasterVehicleAvailability();
  };

  updateMasterVehicleAvailabilityData = (params) => {
    let data_type = this.state.data_type;

    let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    let transporter_name = "";
    if (toolConfig[0].plant_wise_transporter_configuration == 0) {
      transporter_name = { label: "SAMSCO", value: "SAMSCO" };
    }
    // console.log('hit')
    let logParams = {
      location_code: !this.state.location.value
        ? this.state.editLocation.value
        : this.state.location.value,
      location_name: !this.state.location.value
        ? this.state.editLocation.label
        : this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Vehicle Configurations",
      activity: "Vehicle Details Entered",
      event: "Vehicle Type Configurations Edited",
      data_type: this.state.data_type,
    };
    // console.log(logParams,"edit update")
    redirectURL
      .post("/master/logUserUpdateOrSaveDataActivities", logParams)
      .then(() => {})
      .catch(function (error) {
        var errDetails = {
          url: "/master/logUserUpdateOrSaveDataActivities",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });

    // console.log(params,'params')
    redirectURL
      .post("/master/updateMasterVehicleAvailability", params)
      .then((response) => {
        if (response.data.status == "success") {
          let param = {
            data_type: data_type,
          };
          let logParams = {
            user_name: localStorage.getItem("username"),
            useremail: localStorage.getItem("email"),
            client: localStorage.getItem("client"),
            screen: "vehicle availability master",
            location_code: this.state.location.value,
            activity: {},
          };
          let previousData = this.state.previousData;
          logFormChanges(logParams, params, previousData);

          this.getMasterAvailabilityVehiclesData(param);
          this.setState({
            openMasterVehicleAvailabilityModel: false,
            vehicle_type: "",
            transporter_name: transporter_name,
            vehicle_no: "",
            row_id: "",
            vehicle_priority: "Owned",
            max_distance_per_day: "",
            monthly_min_planning_distance: 0,
            remaining_distance: "",
            vehicle_next_available_date: "",
            vehicle_breakdown: false,
            transit_type: "",
            clickonEdit: false,
            show: true,
            basicTitle: "Data Saved successfully",
            basicType: "success",
            previousData: "",
            vehicle_product_type: "",
          });
        } else {
          if (response.data.status == "failed") {
            this.setState({
              show: true,
              basicTitle: response.data.message,
              basicType: "danger",
              clickonEdit: false,
            });
          } else {
            this.setState({
              show: true,
              basicTitle: "Data Not Saved successfully",
              basicType: "danger",
              clickonEdit: false,
            });
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "/master/updateMasterVehicleAvailability",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  closeAlert = async () => {
    await this.setState({
      show: false,
    });
  };
  onClickCounter = (arg) => {
    if (arg === "all") {
      this.setState({
        tabActive: "all",
      });
    }
    if (arg === "current_date_vehicles") {
      this.setState({
        tabActive: "currentDate",
      });
    }
  };

  deleteMasterVehicleAvailabilityDetails = (propData) => {
    let data = propData.data;
    let data_type = this.state.data_type;
    let location = this.state.location;
    let params = {
      delete_id: data._id,
      vehicle_no: data.vehicle_no,
      location_code: location.value,
      data_type: data_type,
    };
    redirectURL
      .post("master/deleteMasterVehicleAvailabilityDetails", params)
      .then((response) => {
        if (response.data.status == "success") {
          let param = {
            data_type: data_type,
          };
          this.getMasterAvailabilityVehiclesData(param);
        } else {
          this.setState({
            show: true,
            basicTitle: "Failed to delete the data",
            basicType: "danger",
          });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/deleteMasterVehicleAvailabilityDetails",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  showVehiclePlans = (propData) => {
    // console.log("propData first", propData);
    // console.log('propData.colDef.field ', propData.colDef.field)

    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Vehicle Availability Configurations",
      activity: "clicked on Current Month Plan Button in the aggrid",
      event: "another aggrid opened below the row",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    let data = propData.data;
    let data_type = this.state.data_type;
    let location = this.state.location;
    let params = {
      vehicle_no: data.vehicle_no,
      data_type: data_type,
      location: location.value,
    };
    // console.log("params first", params);
    redirectURL
      .post("master/showvehicleplans", params)
      .then((response) => {
        // console.log(response.data)
        var records = response.data.records;
        if (records.length > 0) {
          this.setState({
            detailCellRendererParams: {
              detailGridOptions: {
                columnDefs: [
                  {
                    headerName: "Plan ID",
                    field: "transaction_id",
                    editable: false,
                    width: 200,
                  },
                  {
                    headerName: "Planned Distance (Kms)",
                    field: "transaction_id",
                    editable: false,
                    width: 200,
                    valueGetter: function (params) {
                      try {
                        if (params.data.rownode !== undefined) {
                          if (params.data.rownode != "") {
                            return params.data.rownode.distance;
                          }
                        }
                      } catch (error) {}
                    },
                  },
                  {
                    headerName: "No of Customers",
                    field: "no_of_customers",
                    editable: false,
                    width: 200,
                  },
                  {
                    headerName: "Total Weight (Kgs)",
                    field: "total_weight",
                    editable: false,
                    width: 200,
                  },
                  {
                    headerName: "Total Demand Volume (M3)",
                    field: "total_volume",
                    editable: false,
                    width: 200,
                    valueGetter: function (params) {
                      if (params.data.total_volume) {
                        return params.data.total_volume.toFixed(2);
                      }
                    },
                  },
                ],
                defaultColDef: {
                  // flex: 1,
                  minWidth: 100,
                  filter: true,
                  resizable: true,
                  initialWidth: 200,
                  wrapHeaderText: true,
                  autoHeaderHeight: true,
                  sortable: true,
                },
                context: this,
                overlayNoRowsTemplate: "No rows to show",

                // components: { datePicker: getDatePicker() },
              },
              getDetailRowData: async function (param) {
                try {
                  // console.log("param ", param)
                  param.successCallback([]);
                  // console.log("records ", records)
                  var rowitems = records;
                  param.successCallback(rowitems);
                } catch (error) {}
              },
              masterDetail: true,
            },
          });
          if (propData.colDef.field == "viewplans") {
            propData.node.setExpanded(!propData.node.expanded);
          } else {
            propData.node.setExpanded(false);
          }
        } else {
          // console.log("enterer")
          this.setState({
            detailCellRendererParams: {
              detailGridOptions: {
                columnDefs: [
                  {
                    headerName: "Plan ID",
                    field: "transaction_id",
                    editable: false,
                    width: 200,
                  },
                  {
                    headerName: "Planned Distance (Kms)",
                    field: "transaction_id",
                    editable: false,
                    width: 200,
                    valueGetter: function (params) {
                      try {
                        if (params.data.rownode !== undefined) {
                          if (params.data.rownode != "") {
                            return params.data.rownode.distance;
                          }
                        }
                      } catch (error) {}
                    },
                  },
                ],
                overlayNoRowsTemplate: "No rows to show",
                defaultColDef: {
                  // flex: 1,
                  minWidth: 100,
                  filter: true,
                  resizable: true,
                  initialWidth: 200,
                  wrapHeaderText: true,
                  autoHeaderHeight: true,
                  sortable: true,
                },
                context: this,

                // components: { datePicker: getDatePicker() },
              },
              getDetailRowData: async function (param) {
                try {
                  // console.log("param ", param)
                  // param.successCallback([]);
                  var rowitems = records;
                  param.successCallback(rowitems);
                } catch (error) {}
              },
              masterDetail: true,
            },
          });
          if (propData.colDef.field == "viewplans") {
            propData.node.setExpanded(!propData.node.expanded);
          } else {
            propData.node.setExpanded(false);
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/showvehicleplans",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  openUploadOptionForMasterVehicleAvailability = () => {
    document.getElementById("bulkUploadForm").reset();
    document.getElementById("inValidDataInfo").innerHTML = "";
    this.setState({
      showSlideBlock: "slide25",
      overlayBlock: "show-m",
    });
  };

  hideSlideBlock = () => {
    document.getElementById("bulkUploadForm").reset();
    document.getElementById("inValidDataInfo").innerHTML = "";
    this.setState({
      overlayBlock: "show-n",
      showSlideBlock: "",
    });
  };

  formCSVUpload = (event) => {
    this.setState({ loadshow: "show-m", overlayBlock: "show-m" });
    let data_type = this.state.data_type;
    let location = this.state.location;
    event.preventDefault();
    var csvfiledata = this.state.csvfiledata;
    var p = {
      filedata: csvfiledata,
      data_type: data_type,
      location_code: location.value,
      location_name: location.label,
    };
    redirectURL
      .post("/master/uploadMasterVehicleAvailabilityData", p)
      .then(async (resp) => {
        if (resp.data.status == "success") {
          await this.setState({
            show: true,
            basicTitle: resp.data.message,
            basicType: "success",
            overlayBlock: "show-n",
            showSlideBlock: "",
          });
          window.location.reload();
        } else {
          this.setState({
            show: true,
            basicTitle: resp.data.message,
            basicType: "danger",
          });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "/master/uploadMasterVehicleAvailabilityData",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };
  onClickFileUpload() {
    $("#csvfile").val("");
  }

  changeCSVFileHandler = async (e) => {
    document.getElementById("inValidDataInfo").innerHTML = "";
    let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    let transporterConfiguration =
      toolConfig[0].plant_wise_transporter_configuration;
    const config = {
      headers: [
        // {
        //     name: 'Location Code',
        //     inputName: 'location_code',
        //     required: true,
        //     requiredError: function (headerName, rowNumber, columnNumber) {
        //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
        //     }
        // },
        // {
        //     name: 'Location Name',
        //     inputName: 'location_name',
        //     required: true,
        //     requiredError: function (headerName, rowNumber, columnNumber) {
        //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
        //     }
        // },
        {
          name: "Vehicle Type",
          inputName: "vehicle_type",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },

        {
          name: "Vehicle Number",
          inputName: "vehicle_no",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Vehicle Priority",
          inputName: "vehicle_priority",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Max Distance Per Plan",
          inputName: "max_distance_per_day",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Monthly Min Planning Distance",
          inputName: "monthly_min_planning_distance",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Remaining Monthly Distance",
          inputName: "remaining_distance",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Vehicle Next Available Date (YYYY-MM-DD HH:MM)",
          inputName: "vehicle_next_available_date",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Vehicle Break Down",
          inputName: "vehicle_breakdown",
          required: true,
          validate: function (vehicle_breakdown) {
            return validateVehicleBreakdown(vehicle_breakdown);
          },
          validateError: function (headerName) {
            return `${headerName} should be "yes" or "no" only`;
          },
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Transit Type",
          inputName: "transit_type",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Transporter Name",
          inputName: "transporter_name",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Transporter Code",
          inputName: "transporter_code",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        {
          name: "Vehicle Product Type",
          inputName: "vehicle_product_type",
          required: true,
          requiredError: function (headerName, rowNumber, columnNumber) {
            return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
          },
        },
        // {
        //     name: 'From Time',
        //     inputName: 'from_time',
        //     required: false,
        // },
        // {
        //     name: 'To Time',
        //     inputName: 'to_time',
        //     required: false,
        // }
      ],
    };

    // if(transporterConfiguration == 1){
    // config.headers.push({
    //     name: 'Transporter Name',
    //     inputName: 'transporter_name',
    //     required: true,
    //     requiredError: function (headerName, rowNumber, columnNumber) {
    //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    //     }
    // }
    // ,{
    //     name: 'Transporter Code',
    //     inputName: 'transporter_code',
    //     required: true,
    //     requiredError: function (headerName, rowNumber, columnNumber) {
    //         return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
    //     }
    // }

    // )

    // }

    var data = CSVFileValidator(e.target.files[0], config)
      .then(async (csvData) => {
        document.getElementById("inValidDataInfo").innerHTML = "";
        if (csvData.inValidData.length > 0) {
          $("#csvfile").val("");
          document.getElementById("bulkUploadBtn").type = "button";
          document
            .getElementById("bulkUploadBtn")
            .classList.remove("btn-danger");
          document
            .getElementById("bulkUploadBtn")
            .classList.add("btn-secondary");
          let invalidData = csvData.inValidData;
          let element = document.getElementById("inValidDataInfo");
          invalidData.map((item) => {
            let row, column;
            if (item.rowIndex == undefined) {
              row = "NA";
            } else {
              row = item.rowIndex;
            }
            if (item.columnIndex == undefined) {
              column = "NA";
            } else {
              column = item.columnIndex;
            }

            element.innerHTML +=
              "Column : " +
              column +
              "," +
              " Row : " +
              row +
              "--" +
              item.message +
              "<br></br>";
          });
        } else {
          document.getElementById("bulkUploadBtn").type = "submit";
          document
            .getElementById("bulkUploadBtn")
            .classList.remove("btn-secondary");
          document.getElementById("bulkUploadBtn").classList.add("btn-danger");
          await this.setState({
            csvfiledata: csvData.data,
          });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "CSV file validator",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  handelVehiclePriorityConfigurationModel = () => {
    this.setState((prevState) => ({
      openVehiclePriorityConfigurationModel:
        !prevState.openVehiclePriorityConfigurationModel,
    }));
  };
  handelVehiclePriorityConfigurationModelCancel = () => {
    this.setState((prevState) => ({
      openVehiclePriorityConfigurationModel:
        !prevState.openVehiclePriorityConfigurationModel,
    }));
  };

  saveVehiclePriorityOrder = () => {
    // location specific ??
    let vehicle_priority_order_owned = this.state.vehicle_priority_order_owned;
    let vehicle_priority_order_contract =
      this.state.vehicle_priority_order_contract;
    let vehicle_priority_order_market =
      this.state.vehicle_priority_order_market;
    let data_type = this.state.data_type;
    let params = {
      owned: parseInt(vehicle_priority_order_owned),
      contract: parseInt(vehicle_priority_order_contract),
      market: parseInt(vehicle_priority_order_market),
      data_type: data_type,
    };
    redirectURL
      .post("master/saveVehiclePriorityOrder", params)
      .then((response) => {
        if (response.data.status == "success") {
          this.setState({
            openVehiclePriorityConfigurationModel: false,
          });
        } else {
          this.setState({
            show: true,
            basicTitle: "Data Not Saved successfully",
            basicType: "danger",
          });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/saveVehiclePriorityOrder",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  getVehiclePriorityOrder = (params) => {
    let vehicle_priority_order_owned = this.state.vehicle_priority_order_owned;
    let vehicle_priority_order_contract =
      this.state.vehicle_priority_order_contract;
    let vehicle_priority_order_market =
      this.state.vehicle_priority_order_market;
    redirectURL
      .post("master/getVehiclePriorityOrder", params)
      .then((response) => {
        if (response.data.status == "success") {
          let vehiclePriorityData = response.data.vehiclePriorityData;
          vehicle_priority_order_owned = vehiclePriorityData[0].owned;
          vehicle_priority_order_contract = vehiclePriorityData[0].contract;
          vehicle_priority_order_market = vehiclePriorityData[0].market;
          this.setState({
            vehicle_priority_order_owned,
            vehicle_priority_order_contract,
            vehicle_priority_order_market,
          });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getVehiclePriorityOrder",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  handleResetKms = () => {
    var cnf = window.confirm("Do you want to proceed?");
    if (cnf) {
      let data_type = this.state.data_type;
      let location = this.state.location;
      let params = {
        location_code: location.value,
        data_type: data_type,
      };
      redirectURL
        .post(
          "master/resetKmsPlanDistanceMasterVehicleAvailabilityDetails",
          params
        )
        .then((response) => {
          if (response.data.status == "success") {
            let param = {
              data_type: data_type,
            };
            this.getMasterAvailabilityVehiclesData(param);
          } else {
            this.setState({
              show: true,
              basicTitle: "Failed to update the data",
              basicType: "danger",
            });
          }
        })
        .catch(function (error) {
          var errDetails = {
            url: "master/resetKmsPlanDistanceMasterVehicleAvailabilityDetails",
            screen: "Vehicle Availability Configurations",
            error,
          };
          redirectURL.post("/master/logErrorsForApiCalls", errDetails);
        });
    }
  };

  onChangeVehicleType = async (e) => {
    this.setState({ vehicle_type: e });
    var selectedMonPlanDist = 3000;
    var { location, editLocation } = this.state;
    var locCode = "";
    // vehicleTypeList = [],
    // dupVehicleArr = [];
    // var vehicleTypeConfigs = this.state.vehicleTypeConfigs;
    // if (vehicleTypeConfigs.length > 0) {
    //   vehicleTypeConfigs.map((item) => {
    //     if (!dupVehicleArr.includes(item.vehicle_type)) {
    //       dupVehicleArr.push(item.vehicle_type);
    //       vehicleTypeList.push({
    //         value: item.vehicle_type,
    //         label: item.vehicle_type,
    //       });
    //     }
    //     return true;
    //   });
    //   // console.log(vehicleTypeList,"vehicleTypeList first")
    //   await this.setState({
    //     vehicleTypeList: vehicleTypeList,
    //     vehicle_type: vehicleTypeList[0],
    //     // vehicleTypeConfigs: vehicleTypeConfigs,
    //   });
    // }

    if (editLocation) {
      locCode = editLocation.value;
    }
    if (location.value !== "") {
      locCode = location.value;
    }

    var vehicles = this.state.vehicleTypeConfigs.filter(
      (item) => item.vehicle_type === e.value && item.location_code === locCode
    );
    var vehicle = "";
    if (vehicles.length) {
      vehicle = vehicles[0];
    }
    // console.log(
    //   "first3",
    //   this.state.editLocation,
    //   this.state.vehicleTypeConfigs
    // );
    // console.log(
    // vehicle.monthly_min_planning_distance,
    // "vehicle first",
    // this.state.location.value==='',
    // this.state.location,
    // this.state.editLocation.value,
    // e.value,
    //   vehicles
    // );

    if (
      vehicle.monthly_min_planning_distance &&
      vehicle.monthly_min_planning_distance !== ""
    ) {
      selectedMonPlanDist = parseInt(vehicle.monthly_min_planning_distance);
    }
    // this.state.vehiclesData.map((item)=>{
    //     console.log(item,"vehicle_type")
    //     if(item.vehicle_type===e.value){
    //         if (item.monthly_min_planning_distance = ""){
    //             selectedMonPlanDist = 0
    //         }
    //         else{
    //             selectedMonPlanDist=parseInt(item.monthly_min_planning_distance)
    //         }

    //     }
    // })
    if (!this.state.clickonEdit) {
      this.setState({
        monthly_min_planning_distance: selectedMonPlanDist,
        remaining_distance: selectedMonPlanDist,
      });
    }
  };

  onChangeVehicleNumber = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    var regex = /^[a-zA-Z]?[a-zA-Z0-9_]*$/;
    var out = regex.test(value);
    if (name == "vehicle_no") {
      value = value.toUpperCase();
    }
    if (out) {
      this.setState({ [name]: value });
    }
  };

  render() {
    let data = this.state.rowData;
    let locValue = this.state.location.value;
    var filteredData = [];
    if (!locValue) {
      filteredData = this.state.rowData;
    } else {
      filteredData = data.filter((item) => item.location_code === locValue);
    }
    if (this.state.tabActive === "currentDate") {
      if (!locValue) {
        filteredData = this.state.currentDateVehicles;
      } else {
        filteredData = this.state.currentDateVehicles.filter(
          (item) => item.location_code === locValue
        );
      }
    }
    if (this.state.tabActive === "all") {
      if (!locValue) {
        filteredData = this.state.totalMasterData;
      } else {
        filteredData = data.filter((item) => item.location_code === locValue);
      }
    }
    var monthly_distance_preferences = 0;
    let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    let operationalTimesType = 0;
    let transporterConfiguration = 0;
    if (toolConfig !== undefined && toolConfig !== "") {
      if (toolConfig.length > 0) {
        if (toolConfig[0].generic_time_windows === 1) {
          operationalTimesType = 1;
        }
        if (toolConfig[0].individual_truck_time_windows === 1) {
          // operationalTimesType = 2;
        }
        transporterConfiguration =
          toolConfig[0].plant_wise_transporter_configuration;
        monthly_distance_preferences =
          toolConfig[0].monthly_distance_preferences;
      }
    }

    var templatePath = require("../../assets/json/Vehicle_Availability_data_template.csv");

    var colsDefs = [
      {
        headerName: "Actions",
        field: "cluster",
        width: "100",
        pinned: "left",
        filter: false,
        sortable: false,
        params: {
          buttonName: "Edit",
          onClickFunction: this.updateMasterVehicleAvailability,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      },
      // {
      //   headerName: "",
      //   field: "cluster",
      //   width: "80",
      //   pinned: "left",
      //   params: {
      //     buttonName: "Delete",
      //     iconName: "fa fa-trash",
      //     onClickFunction: this.deleteMasterVehicleAvailabilityDetails,
      //   },
      //   cellRendererSelector: function (params) {
      //     var rendComponent = {
      //       component: "GridButton",
      //     };
      //     return rendComponent;
      //   },
      // },
      // {
      //   headerName: "",
      //   field: "viewplans",
      //   width: "250",
      //   pinned: "left",
      //   params: {
      //     buttonName: "Current Month Plan Distance",
      //     iconName: "fa fa-eye",
      //     onClickFunction: this.showVehiclePlans,
      //   },
      //   cellRendererSelector: function (params) {
      //     var rendComponent = {
      //       component: "GridButton",
      //     };
      //     return rendComponent;
      //   },
      // },
      {
        headerName: "Plant Name",
        field: "location_name",
        width: "130",
      },
      {
        headerName: "Vehicle Type",
        field: "vehicle_type",
        width: "190",
        cellClass: function (params) {
          try {
            if (params.data.vehicle_breakdown === 1) {
              return ["colorbg"];
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Vehicle Number",
        field: "vehicle_no",
        width: "100",
        cellClass: function (params) {
          try {
            if (params.data.vehicle_breakdown == 1) {
              return ["colorbg"];
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Transporter Name",
        field: "transporter_name",
        width: "160",
        cellClass: function (params) {
          try {
            if (params.data.vehicle_breakdown == 1) {
              return ["colorbg"];
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Transit Type",
        field: "transit_type",
        width: "100",
        cellClass: function (params) {
          try {
            if (params.data.vehicle_breakdown == 1) {
              return ["colorbg"];
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Vehicle Product Type",
        field: "vehicle_product_type",
        width: "100",
        cellClass: function (params) {
          try {
            if (params.data.vehicle_breakdown == 1) {
              return ["colorbg"];
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Vehicle Priority",
        field: "vehicle_priority",
        width: "130",
        cellClass: function (params) {
          try {
            if (params.data.vehicle_breakdown == 1) {
              return ["colorbg"];
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Max Distance Per Plan",
        field: "max_distance_per_day",
        width: "120",
        cellClass: function (params) {
          try {
            if (params.data.vehicle_breakdown == 1) {
              return ["colorbg"];
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Max Weight",
        field: "vehicle_max_weight",
        width: "120",
        cellClass: function (params) {
          try {
            if (params.data.vehicle_breakdown == 1) {
              return ["colorbg"];
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Max Volume",
        field: "max_volume_m3",
        width: "100",
        cellClass: function (params) {
          try {
            if (params.data.vehicle_breakdown == 1) {
              return ["colorbg"];
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Monthly Min Planning Distance (Kms)",
        field: "monthly_min_planning_distance",
        width: "130",
        cellClass: function (params) {
          try {
            if (params.data.vehicle_breakdown == 1) {
              return ["colorbg"];
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Remaining Monthly Planning Distance (Kms)",
        field: "remaining_distance",
        width: "150",
        valueGetter: function (params) {
          if (
            params.data.remaining_distance !== undefined &&
            params.data.remaining_distance !== "" &&
            params.data.remaining_distance !== "undefined"
          ) {
            return parseFloat(params.data.remaining_distance).toFixed(2);
          } else {
            return "N/A";
          }
        },
        cellClass: function (params) {
          try {
            if (params.data.vehicle_breakdown == 1) {
              return ["colorbg"];
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Next Available Date (YYYY-MM-DD HH:mm)",
        field: "vehicle_next_available_date",
        width: "150",
        valueGetter: function (params) {
          if (params.data.vehicle_next_available_date) {
            // return params.data.remaining_distance.toFixed(2)
            return moment
              .parseZone(params.data.vehicle_next_available_date)
              .format("YYYY-MM-DD HH:mm");
          }
        },
        cellClass: function (params) {
          try {
            if (params.data.vehicle_breakdown == 1) {
              return ["colorbg"];
            }
          } catch (error) {}
        },
      },
      {
        headerName: "Vehicle Break Down",
        field: "vehicle_breakdown",
        width: "90",
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridCheckbox",
          };
          return rendComponent;
        },
      },
    ];

    let vehiclesData = this.state.vehiclesData;
    let selectedVehicleType = this.state.vehicle_type;
    // console.log(selectedVehicleType.value);
    let selectedVehicleTypeData = vehiclesData.filter(
      (each) => each.vehicle_type === selectedVehicleType.value
    );
    let monthly_min_planning_distance;
    if (selectedVehicleTypeData.length > 0) {
      monthly_min_planning_distance =
        selectedVehicleTypeData[0].monthly_min_planning_distance;
    } else {
      monthly_min_planning_distance = 0;
    }
    if (localStorage.getItem("email") === "deletedataaccess@enmovil.in") {
      colsDefs.push({
        headerName: "",
        field: "cluster",
        width: "80",
        pinned: "left",
        params: {
          buttonName: "Delete",
          iconName: "fa fa-trash",
          onClickFunction: this.deleteMasterVehicleAvailabilityDetails,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      });
    }

    var userMailCheck =
      localStorage.getItem("role") === "admin" ? "show-m" : "show-n";
    // console.log('first',this.state.rowData.length)
    return (
      <>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div class="row mb-20p pt-20p">
          <div className="col-sm-12">
            <div className="row" style={{ marginBottom: "20px" }}>
              <div className="col-md-8">
                <div className="row">
                  <div
                    className="col-md-2"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <h5 className="fbold d-flex justify-content-between">
                      <span>
                        {
                          Constant.MASTER_VEHICLE_DATA_AVAILABILITY_MASTER_DETAILS_HEADER_NAME
                        }
                      </span>
                    </h5>
                  </div>
                  <div className="col-md-8" style={{ display: "flex" }}>
                    <div
                      className="col-md-4 cursorPointer customCounter"
                      style={{
                        backgroundColor: "#fff",
                        padding: "10px 0px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                      }}
                      onClick={this.onClickCounter.bind(this, "all")}
                    >
                      <div className="col=md-8">
                        <span className="f12">
                          <i
                            className="fa fa-truck f24 darkgreen"
                            style={{ marginLeft: "25px" }}
                          ></i>
                          <br /> Total Vehicles
                        </span>
                      </div>
                      <div className="col-md-4">
                        <h4 className="darkgreen f20">
                          <span className="counter">
                            <CountUp
                              end={
                                this.state.totalMasterData
                                  ? this.state.totalMasterData.length
                                  : 0
                              }
                            />
                          </span>
                        </h4>
                      </div>
                    </div>
                    <div
                      className="col-md-4 cursorPointer customCounter"
                      style={{
                        backgroundColor: "#fff",
                        padding: "10px 0px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                      onClick={this.onClickCounter.bind(
                        this,
                        "current_date_vehicles"
                      )}
                    >
                      <div className="col-md-8">
                        <span className="f12">
                          <i
                            className="fa fa-truck f24 darkgreen"
                            style={{ marginLeft: "25px" }}
                          ></i>
                          <br />
                          Vehicles For Today
                        </span>
                      </div>
                      <div className="col-md-4">
                        <h4 className="darkgreen f20">
                          <span className="counter">
                            <CountUp
                              end={
                                this.state.currentDateVehicles !== ""
                                  ? this.state.currentDateVehicles.length
                                  : 0
                              }
                            />
                          </span>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex flex-row-reverse col-sm-12 control-padding-right">
                  <span className={userMailCheck}>
                    <a
                      href="javascript:;"
                      onClick={
                        this.openUploadOptionForMasterVehicleAvailability
                      }
                      style={{
                        pointerEvents: !this.state.location.value
                          ? "none"
                          : "auto",
                        backgroundColor: !this.state.location.value
                          ? "#ddd"
                          : "#fff",
                      }}
                      className="btn btn-warning f12 mr-10p"
                    >
                      {
                        Constant.MASTER_VEHICLE_DATA_AVAILABILITY_MASTER_DETAILS_BULK_BUTTON_NAME
                      }
                    </a>
                  </span>
                  <span>
                    {/* <a href="javascript:;"  onClick={this.handelVehiclePriorityConfigurationModel} className="btn btn-warning f12 mr-10p">Set Vehicle Priority</a> */}
                  </span>
                  <span className={userMailCheck}>
                    {/* <a href="javascript:;"  onClick={this.handleResetKms} className="btn btn-warning f12 mr-10p">Reset Kms</a> */}
                  </span>
                  <a
                    href="javascript:;"
                    onClick={this.openModelForMasterVehicleAvailability}
                    style={{
                      pointerEvents: !this.state.location.value
                        ? "none"
                        : "auto",
                      backgroundColor: !this.state.location.value
                        ? "#ddd"
                        : "#fff",
                    }}
                    className="f12 btn btn-default float-right themefont fbold mr-10p mb-10p"
                  >
                    {
                      Constant.MASTER_VEHICLE_DATA_AVAILABILITY_MASTER_DETAILS_BUTTON_NAME
                    }
                  </a>
                </div>
              </div>
            </div>
            {/* <div className="row" style={{padding:"0 12px", marginBottom:"20px"}}>
                        
                    </div> */}

            <div
              id="myGrid"
              style={{ width: "100%", height: "80vh" }}
              className="col-sm-12 ag-theme-balham"
            >
              <AgGridReact
                // modules={this.state.modules}
                rowData={filteredData}
                columnDefs={colsDefs}
                gridOptions={{ context: { componentParent: this } }}
                defaultColDef={this.state.defaultColDef}
                frameworkComponents={this.state.frameworkComponents}
                paginationPageSize={this.state.paginationPageSize}
                pagination={true}
                masterDetail={true}
                detailCellRendererParams={this.state.detailCellRendererParams}
                enableRangeSelection={true}
                enableCellContextMenu={true}
                statusBar={this.state.statusBar}
                sideBar={this.state.sideBar}
              />
            </div>
          </div>
        </div>

        <Modal
          closeOnOverlayClick={false}
          open={this.state.openMasterVehicleAvailabilityModel}
          onClose={this.handelMasterVehicleAvailabilityModelCancel}
          classNames={{ modal: "pickupdrop-modelClass70vh" }}
        >
          <div className="col-sm-12">
            <div>
              <h4>Vehicle Master</h4>
            </div>
            <form
              className="theme-form"
              onSubmit={this.formMasterVehicleAvailability}
            >
              <div className="row number">
                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      Pickup Location
                      <span className="redColor fbold">*</span>
                    </label>
                    <Select
                      isDisabled
                      name="location"
                      value={
                        !this.state.location.value
                          ? this.state.editLocation
                          : this.state.location
                      }
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      Vehicle Type
                      <span className="redColor fbold">*</span>
                    </label>
                    <Select
                      required
                      maxMenuHeight={"170px"}
                      placeholder={"Select"}
                      onChange={this.onChangeVehicleType.bind("vehicle_type")}
                      name="vehicle_type"
                      value={this.state.vehicle_type}
                      options={this.state.vehicleTypeList}
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      Vehicle Number <span className="redColor fbold">*</span>
                    </label>
                    <input
                      type="text"
                      className="forminp form-control"
                      required
                      name="vehicle_no"
                      value={this.state.vehicle_no}
                      onChange={this.onChangeVehicleNumber}
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      Transporter Name <span className="redColor fbold">*</span>
                    </label>
                    {/* start c */}
                    {/* <input
                      type="text"
                      className="forminp form-control"
                      name="transporter_name"
                      value={this.state.transporter_name}
                      onChange={this.vehicleConfigurationInputFields}
                      autoComplete="off"
                    /> */}
                    {/* start c */}
                    <Select
                      required
                      placeholder={"Select"}
                      onChange={this.transporterChanged}
                      name="transporter_name"
                      value={this.state.transporter_name}
                      options={this.state.transporterList}
                      isDisabled={transporterConfiguration ? false : true}
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label
                      htmlFor="vehicle_priority"
                      className="col-form-label f12"
                    >
                      Vehicle Priority
                      <span className="redColor fbold">*</span>
                    </label>

                    <select
                      name="vehicle_priority"
                      id="vehicle_priority"
                      value={this.state.vehicle_priority}
                      required
                      className="forminp form-control"
                      onChange={this.vehicleAvailabilityInputFields}
                    >
                      {/* <option value="" disabled selected>Select</option> */}
                      <option value="Owned">Owned</option>
                      <option value="Contract">Contract</option>
                      <option value="Market">Market</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label
                      htmlFor="transit_type"
                      className="col-form-label f12"
                    >
                      Transit Type <span className="redColor fbold">*</span>
                    </label>

                    <select
                      name="transit_type"
                      id="transit_type"
                      value={this.state.transit_type}
                      required
                      className="forminp form-control"
                      onChange={this.vehicleAvailabilityInputFields}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="Local">Local</option>
                      <option value="UpCountry">UpCountry</option>
                      <option value="Both">Both</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      Max Distance Per Plan(Kms){" "}
                      <span className="redColor fbold">*</span>
                    </label>
                    <input
                      type="text"
                      className="forminp form-control"
                      required
                      name="max_distance_per_day"
                      value={this.state.max_distance_per_day}
                      onChange={this.checkNotEqualstoZero}
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      Monthly Min Planning Distance (Kms){" "}
                      <span className="redColor fbold">*</span>
                    </label>
                    <input
                      type="text"
                      className="forminp form-control"
                      required
                      disabled
                      name="monthly_min_planning_distance"
                      value={this.state.monthly_min_planning_distance}
                      onChange={this.vehicleAvailabilityInputFields}
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      Remaining Monthly Distance (Kms){" "}
                      <span className="redColor fbold">*</span>
                    </label>
                    <input
                      type="text"
                      className="forminp form-control"
                      required
                      disabled
                      name="remaining_distance"
                      value={this.state.remaining_distance}
                      onChange={this.vehicleAvailabilityInputFields}
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      Vehicle Next Available Date{" "}
                      <span className="redColor fbold">*</span>
                    </label>
                    <input
                      type="datetime-local"
                      className="forminp form-control"
                      required
                      name="vehicle_next_available_date"
                      value={this.state.vehicle_next_available_date}
                      onChange={this.vehicleAvailabilityInputFields}
                      placeholder=""
                      autoComplete="off"
                      readonly // Prevents typing
                      // onKeyPress={(e) => e.preventDefault()} // Prevents keypress events
                      onKeyDown={(e) => e.preventDefault()} // Prevents keydown events
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label
                      htmlFor="vehicle_product_type"
                      className="col-form-label f12"
                    >
                      Vehicle Product Type{" "}
                      <span className="redColor fbold">*</span>
                    </label>

                    <select
                      name="vehicle_product_type"
                      id="vehicle_product_type"
                      value={this.state.vehicle_product_type}
                      required
                      className="forminp form-control"
                      onChange={this.vehicleAvailabilityInputFields}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="AMBIENT">AMBIENT</option>
                      <option value="REEFER">REEFER</option>
                      <option value="CHILLED">CHILLED</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12 mt-5 mr-0">
                  <div className="form-group ">
                    <label htmlFor="breakdown" className="mr-1">
                      Vehicle Break Down
                    </label>
                    <input
                      id="breackdown"
                      type="checkbox"
                      name="vehicle_breakdown"
                      value={this.state.vehicle_breakdown}
                      // checked={this.state.vehicle_breakdown==1?true:false}
                      checked={this.state.vehicle_breakdown}
                      onChange={this.vehicleAvailabilityInputFields}
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mt-4">
                <button
                  type="submit"
                  className="btn tbtn float-right save-btn"
                  id="saveGeo"
                >
                  SAVE
                </button>
                <button
                  type="button"
                  className="btn tbtn float-right cancel-btn"
                  onClick={this.handelMasterVehicleAvailabilityModelCancel}
                >
                  CANCEL
                </button>
              </div>
            </form>
          </div>
        </Modal>

        <div
          className={"overlay-block " + this.state.overlayBlock}
          onClick={this.hideSlideBlock}
        ></div>
        <div className={"sliderBlock2 " + this.state.showSlideBlock}>
          <div className="row">
            <h4 className="ml-4 mt-4">Upload Vehicles Input File</h4>
            <div className="col-sm-12">
              <form id="bulkUploadForm" onSubmit={this.formCSVUpload}>
                <div className="row p-20p">
                  <div className="form-group col-sm-12">
                    <a
                      className="btn btn-warning"
                      href={templatePath}
                      target="_blank"
                    >
                      Download Sample Template
                    </a>
                  </div>
                  <div className="form-group col-sm-12 mb-20p">
                    <input
                      type="file"
                      name="csvfile"
                      id="csvfile"
                      className="form-control"
                      onChange={this.changeCSVFileHandler}
                      onClick={this.onClickFileUpload}
                      required
                    />
                  </div>
                  <div className="form-group col-sm-12 mb-20p">
                    <button
                      type="submit"
                      id="bulkUploadBtn"
                      className="btn btn-danger"
                    >
                      SAVE
                    </button>
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={this.hideSlideBlock}
                    >
                      CANCEL
                    </button>
                  </div>
                  <div id="inValidDataInfo" style={{ color: "red" }}></div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <Modal
          closeOnOverlayClick={false}
          open={this.state.openVehiclePriorityConfigurationModel}
          onClose={this.handelVehiclePriorityConfigurationModelCancel}
          classNames={{ modal: "vehiclepriority-modelClass" }}
        >
          <div className="col-sm-12">
            <div className="mb-10p">
              <h4>Set Vehicle Priority</h4>
            </div>
            <div className="col-md-12 col-sm-12 d-flex justify-content-around">
              <h5 className="col-md-6 d-flex justify-content-center align-self-center fw-600">
                Owned
              </h5>
              <div className="form-group col-md-6">
                <select
                  name="vehicle_priority_order_owned"
                  id="vehicle_priority_order_owned"
                  value={this.state.vehicle_priority_order_owned}
                  className="forminp form-control"
                  onChange={this.vehicleAvailabilityInputFields}
                >
                  <option value="1">High</option>
                  <option value="2">Medium</option>
                  <option value="3">Low</option>
                </select>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 ">
              <h5 className="col-md-6 d-flex justify-content-center align-self-center fw-600">
                Contract
              </h5>
              <div className="form-group col-md-6">
                <select
                  name="vehicle_priority_order_contract"
                  id="vehicle_priority_order_contract"
                  value={this.state.vehicle_priority_order_contract}
                  className="forminp form-control"
                  onChange={this.vehicleAvailabilityInputFields}
                >
                  <option value="1">High</option>
                  <option value="2">Medium</option>
                  <option value="3">Low</option>
                </select>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 ">
              <h5 className="col-md-6 d-flex justify-content-center align-self-center fw-600">
                Market
              </h5>
              <div className="form-group col-md-6">
                <select
                  name="vehicle_priority_order_market"
                  id="vehicle_priority_order_market"
                  value={this.state.vehicle_priority_order_market}
                  className="forminp form-control"
                  onChange={this.vehicleAvailabilityInputFields}
                >
                  <option value="1">High</option>
                  <option value="2">Medium</option>
                  <option value="3">Low</option>
                </select>
              </div>
            </div>

            <div className="form-group">
              <button
                type="button"
                onClick={this.saveVehiclePriorityOrder}
                className="btn tbtn float-right save-btn"
              >
                SAVE
              </button>
              <button
                type="button"
                className="btn tbtn float-right cancel-btn"
                onClick={this.handelVehiclePriorityConfigurationModelCancel}
              >
                CANCEL
              </button>
            </div>
          </div>
        </Modal>
        <div className={"loader " + this.state.loadshow}></div>
      </>
    );
  }
}

function validateVehicleBreakdown(vehicle_breakdown) {
  if (
    vehicle_breakdown.toLowerCase() === "yes" ||
    vehicle_breakdown.toLowerCase() === "no"
  ) {
    return true;
  } else {
    return false;
  }
}
