import React, { Component } from "react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
// import Constant from "../common/constants";
import Modal from "react-responsive-modal";
// import CountUp from "react-countup";
import "react-responsive-modal/styles.css";
import redirectURL from "../redirectURL";
// import Select from "react-select";
// import TimeRangePicker from '@wojtekmaj/react-timerange-picker';
// import getToolConfiguration from "../optirun-admin/commonFunctions";
import SweetAlert from "react-bootstrap-sweetalert";
// import DatePicker from 'react-datepicker';
// import "react-datepicker/dist/react-datepicker.css";
// import { subDays } from "date-fns";
// import GridButton from "./gridButton";
import GridButton from "../optirun-admin/gridButton";
// import GridCheckbox from "./gridCheckbox";
import GridCheckbox from "../optirun-admin/gridCheckbox";
// import CSVFileValidator from "csv-file-validator";
// import { getDDMMYYYYHHMMSS } from "../common/utils";
// import { logFormChanges } from "../optirun-admin/userActivityPickupDrop";
// import $ from "jquery";
// var moment = require("moment");

export default class ProductMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 100,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        GridButton: GridButton,
        GridCheckbox: GridCheckbox,
      },
      rowData: [],
      tabActive: "all",
      currentDateVehicles: [],
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 100,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      openMasterProductModal: false,
      data_type: "",
      location: this.props.plantLocation,
      vehicle_type: "",
      vehicleTypeList: [],
      transporter_name: "",
      transporterList: [],
      vehicle_no: "",
      show: false,
      basicTitle: "",
      basicType: "default",
      row_id: "",
      vehicle_priority: "Owned",
      overlayBlock: "show-n",
      showSlideBlock: "",
      csvfiledata: [],
      vehicle_priority_order_owned: 1,
      vehicle_priority_order_contract: 2,
      vehicle_priority_order_market: 3,
      monthly_min_planning_distance: 0,
      remaining_distance: "",
      vehicle_next_available_date: "",
      loadshow: "show-m",
      vehicle_breakdown: false,
      clickonEdit: false,
      oldDate: "",
      newDate: "",
      transit_type: "",
      vehiclesData: [],
      previousData: "",
      totalMasterData: [],
      productData: [],
      product_type: "",
      product_code: "",
    };
  }
  componentDidMount() {
    this.getProductMasterDetails();
  }
  getProductMasterDetails = () => {
    redirectURL
      .post("master/getgetProductMasterDetails")
      .then(async (response) => {
        //   console.log("first", response.data);
        await this.setState({ productData: response.data });
      });
  };
  onClickAddProduct = () => {
    // console.log("first", this.state.product_type);
    this.setState({
      openMasterProductModal: true,
    });
    // this.updateProductDetails()
  };
  updateProductDetails = async (rowData) => {
    // console.log("hit here first", rowData.data);
    var { product_code, product_type } = rowData.data;
    var row_id = rowData.data._id;
    await this.setState({
      openMasterProductModal: true,
      product_code,
      product_type,
      row_id,
    });
  };

  handleProductMasterModal = () => {
    this.setState({
      openMasterProductModal: false,
      row_id: "",
      product_code: "",
      product_type: "",
    });
    // console.log("first on handleProductMasterModal");
  };

  updateMasterProductData = (params) => {
    redirectURL
      .post("/master/updateMasterProductData", params)
      .then(async (response) => {
        // console.log("first res upda", response.data);
        if (response.data.status === "success") {
          await this.setState({
            product_code: "",
            product_type: "",
            show: true,
            basicTitle: "Data Modified Successfully",
            basicType: "success",
            row_id: "",
          });
        } else {
          await this.setState({
            product_code: "",
            product_type: "",
            show: true,
            basicTitle: "Product Code Already Exists",
            basicType: "danger",
            row_id: "",
          });
        }
        await this.getProductMasterDetails();
      });
    // this.setState({ product_code: "", product_type: "" });
  };

  saveMasterProductData = async (params) => {
    redirectURL
      .post("/master/saveMasterProductData", params)
      .then(async (response) => {
        // console.log("first res save", response.data);
        if (response.data.status === "success") {
          await this.setState({
            product_code: "",
            product_type: "",
            show: true,
            basicTitle: "Data Added Successfully",
            basicType: "success",
            row_id: "",
          });
        } else {
          await this.setState({
            product_code: "",
            product_type: "",
            show: true,
            basicTitle: "Product Code Already Exists",
            basicType: "danger",
            row_id: "",
          });
        }
        await this.getProductMasterDetails();
      });
  };

  handleSubmitProductMaster = (event) => {
    event.preventDefault();
    this.setState({ openMasterProductModal: false });
    var { product_code, product_type, row_id } = this.state;
    var params = { product_code, product_type };
    // console.log("first row", row_id, this.state.row_id !== "");
    if (this.state.row_id !== "") {
      params.row_id = row_id;
      this.updateMasterProductData(params);
    } else {
      // console.log("first hit save");
      this.saveMasterProductData(params);
    }
  };

  onChangeProductFields = async (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name === "vehicle_next_available_date") {
      //   this.setState({ newDate: value });
    }
    if (name === "vehicle_breakdown") {
      // console.log(value)
      //   if (value === "false") {
      //     await this.setState({ [name]: true });
      //   } else {
      //     await this.setState({ [name]: false });
      //   }
    } else {
      this.setState({ [name]: value });
    }
  };
  onChangeProductCode = (event) => {
    // console.log("first", event.target.name, event.target.value);
    var name = event.target.name,
      value = event.target.value;
    if (value !== " ") {
      this.setState({ [name]: value });
    }
  };
  closeAlert = async () => {
    await this.setState({
      show: false,
    });
  };
  render() {
    // console.log('first',this.state.rowData.length)
    var colsDefs = [
      {
        headerName: "Actions",
        field: "cluster",
        width: "100",
        pinned: "left",
        filter: false,
        sortable: false,
        params: {
          buttonName: "Edit",
          onClickFunction: this.updateProductDetails,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      },
      //   {
      //     headerName: "Product Name",
      //     field: "product_code",
      //     width: "200",
      //   },
      {
        headerName: "Product Code",
        field: "product_code",
        width: "200",
      },
      {
        headerName: "Product Type",
        field: "product_type",
        width: "200",
      },
    ];
    return (
      <>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        <div class="row mb-20p pt-20p">
          <div className="col-sm-12">
            <div
              className="col-md-12 col-sm-12"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <h5 className="fbold d-flex justify-content-between">
                <span>Product Master</span>
              </h5>
            </div>
            <div className="col-md-12">
              <div className="d-flex flex-row-reverse col-sm-12 control-padding-right">
                <button
                  onClick={this.onClickAddProduct}
                  className="f12 btn btn-default float-right themefont fbold mr-10p mb-10p"
                >
                  +Add Product
                </button>
              </div>
            </div>
            <div
              id="myGrid"
              style={{ width: "100%", height: "80vh" }}
              className="col-sm-12 ag-theme-balham"
            >
              <AgGridReact
                rowData={this.state.productData}
                columnDefs={colsDefs}
                gridOptions={{ context: { componentParent: this } }}
                defaultColDef={this.state.defaultColDef}
                frameworkComponents={this.state.frameworkComponents}
                paginationPageSize={this.state.paginationPageSize}
                pagination={true}
                masterDetail={true}
                detailCellRendererParams={this.state.detailCellRendererParams}
                enableRangeSelection={true}
                enableCellContextMenu={true}
                statusBar={this.state.statusBar}
                sideBar={this.state.sideBar}
              />
            </div>
          </div>
        </div>
        <div className="col-md-12"></div>

        <Modal
          closeOnOverlayClick={false}
          open={this.state.openMasterProductModal}
          onClose={this.handleProductMasterModal}
          classNames={{ modal: "productmaster-modelClass30vh" }}
        >
          <div className="col-sm-12 50vh">
            <div>
              <h4>Product Master</h4>
            </div>{" "}
            <form
              className="theme-form"
              onSubmit={this.handleSubmitProductMaster}
            >
              <div className="row number">
                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      Product Code
                      <span className="redColor fbold">*</span>
                    </label>
                    <input
                      type="text"
                      className="forminp form-control"
                      required
                      name="product_code"
                      value={this.state.product_code}
                      onChange={this.onChangeProductCode}
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label
                      htmlFor="product_type"
                      className="col-form-label f12"
                    >
                      Product Type
                      <span className="redColor fbold">*</span>
                    </label>
                    <select
                      name="product_type"
                      id="product_type"
                      value={this.state.product_type}
                      required
                      className="forminp form-control"
                      onChange={this.onChangeProductFields}
                    >
                      <option value="">Select</option>
                      <option value="AMBIENT">AMBIENT</option>
                      <option value="REEFER">REEFER</option>
                      <option value="CHILLED">CHILLED</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="form-group mt-4">
                <button
                  type="submit"
                  className="btn tbtn float-right save-btn"
                  id="saveGeo"
                >
                  SAVE
                </button>
                <button
                  type="button"
                  className="btn tbtn float-right cancel-btn"
                  onClick={this.handleProductMasterModal}
                >
                  CANCEL
                </button>
              </div>
            </form>
          </div>
        </Modal>
        <div className="col-sm-12"></div>
      </>
    );
  }
}
