import React from "react";

const GridButton = (props) => {
  const handleOnClick = () => {
    props.colDef.params.onClickFunction(props, true);
  };
  var iconName = "fa fa-edit";
  if (
    props.colDef.params.iconName != undefined &&
    props.colDef.params.iconName != ""
  ) {
    iconName = props.colDef.params.iconName;
  }
  var gButton = "btn btn-warning";
  if (
    props.colDef.params.buttonCls != undefined &&
    props.colDef.params.buttonCls != ""
  ) {
    gButton = props.colDef.params.buttonCls;
  }
  var isDisable = false;
  if (props.colDef.params.buttonName === "Update") {
    if (props.data.fo_number !== undefined && props.data.fo_number !== "") {
      isDisable = true;
    }
  }
  if (props.colDef.params.buttonName === "Download") {
    if (
      props.data.execution_status !== undefined &&
      props.data.execution_status !== "" &&
      props.data.execution_status === 0
    ) {
      isDisable = true;
    }
  }
  if (
    props.colDef.params.buttonName === "Edit" &&
    props.data.vehicle_no !== "Not Assigned" &&
    props.data.route_finalized === 1 &&
    props.data.is_available === 0
  ) {
    isDisable = true;
  }
  if (props.colDef.params.page === "adherenceedit") {
    if (
      // props.data.is_transaction_id_missmatch === "" ||
      props.data.transaction_id === ""
    ) {
      isDisable = true;
    }
    // else if(props.data.is_transaction_id_missmatch == 0 && props.data.is_qty_same == 1 && props.data.is_weight_same == 1 &&
    //   props.data.is_volume_same == 1 && props.data.is_vehicle_same == 1 &&  props.data.is_trip_no_missmatch == 0){
    //     isDisable = true;
    // }
  }
  return (
    <div>
      <button
        className={gButton + " grid-btn"}
        disabled={isDisable}
        onClick={handleOnClick}
        style={{ border: "0px", position: "relative" }}
      >
        <i className={iconName}></i> {props.colDef.params.buttonName}
      </button>
    </div>
  );
};

export default GridButton;
