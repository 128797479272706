import React, { Component } from "react";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-balham.css"; // Optional theme CSS
import "ag-grid-enterprise";
import Constant from "../common/constants";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import redirectURL from "../redirectURL";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import GridButton from "./gridButton";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import getToolConfiguration from "./commonFunctions";
import { logFormChanges } from "./auditLogfunction";

export default class VehiclesData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules: AllModules,
      defaultColDef: {
        sortable: true,
        filter: true,
        editable: false,
        resizable: true,
        minWidth: 100,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      frameworkComponents: {
        GridButton: GridButton,
      },
      rowData: [],
      rowSelection: "multiple",
      rowModelhighchartoptionsType: "enterprise",
      rowGroupPanelShow: "always",
      detailCellRendererParams: {},
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      paginationPageSize: 100,
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
      },
      animateRows: true,
      openVehicleConfigurationModel: false,
      vehicle_type: "",
      vehicle_name: "",
      vehicle_priority: "Owned",
      min_volume_cmt: 0,
      max_volume_cmt: 0,
      max_volume_m3: 0,
      min_volume_m3: 0,
      maximum_unloading_time: "",
      unloading_time_per_case: "",
      vehicle_dimensions_width_mm: "",
      vehicle_dimensions_depth_mm: "",
      vehicle_dimensions_height_mm: "",
      vehicle_min_weight: "",
      vehicle_max_weight: "",
      max_no_of_nodes: "",
      setAddVehilceTypeDisable: false,
      variable_vehicle_cost_expandable: [],
      show: false,
      basicTitle: "",
      basicType: "default",
      showAvailablityTable: false,
      data_type: "",
      location: "",
      max_distance: "",
      vehicle_availability_time: ["00:00", "23:59"],
      break_time: ["00:00", "23:59"],
      locationNames: [],
      min_load_per_delivery: "",
      // vehicle_capacity:'',
      vehicle_id: "",
      fuelType: "Petrol",
      average_payload: "",
      mileage: "",
      temperature_controlled: "Yes",
      load_characteristics: "Light",
      glec_vehicle_type: "0.0 - 3.5 t GVW",
      vehicle_type_count: 0,
      openVehiclePriorityConfigurationModel: false,
      vehicle_priority_order_owned: 1,
      vehicle_priority_order_contract: 2,
      vehicle_priority_order_market: 3,
      monthly_min_planning_distance: "",
      clickonEdit: false,
      driver_break_time: "02:00",
      vehicleTypesList: [],
      form_type: "",
      rowId: "",
    };
  }

  async componentDidMount() {
    var curl = window.location.search;
    var ul = curl.split("?");
    // console.log("ul ",ul)
    // let url_type = window.location.href
    let url_type = ul[parseInt(ul.length) - 1];
    // console.log("url_type ", url_type)
    // let path_type = url_type.split('/')
    let path_type = url_type;
    let data_type;
    // let path = path_type[path_type.length-1]
    let path = url_type;

    if (path_type == "inbound") {
      data_type = 1;
      this.setState({ data_type: data_type });
    } else {
      data_type = 2;
      this.setState({ data_type: data_type });
    }
    let params = {
      data_type: data_type,
    };

    // await this.getFuelTypes()
    let toolparameters = {
      data_type: data_type,
    };
    this.setState({
      setAddVehilceTypeDisable: true,
    });
    await getToolConfiguration(toolparameters);
    await this.getVehiclesData(params);
    await this.boundTypeLocations(params);
    await this.getVehiclePriorityOrder(params);
    await this.getGlecVehicleTypes();
  }

  getFuelTypes = () => {
    let fuelTypeList = [];

    redirectURL
      .post("/master/getFuelTypes")
      .then(async (resp) => {
        // console.log(resp.data)

        let fuelData = resp.data;

        if (fuelData.length > 0) {
          fuelData.filter((item) => {
            fuelTypeList.push({ value: item.fuel_code, label: item.fuel_name });
          });
          // console.log(fuelTypeList)
          await this.setState({
            fuelTypeList,
            fuelType: fuelTypeList[0],
          });
        } else {
          this.setState({
            fuelTypeList: [],
            fuelType: null,
          });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getFuelTypes",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  getGlecVehicleTypes = () => {
    let glecVehicleTypesList = [];

    redirectURL
      .post("/master/getGlecVehicleTypes")
      .then(async (resp) => {
        // console.log(resp.data)

        let glecVehicleData = resp.data;

        if (glecVehicleData.length > 0) {
          glecVehicleData.filter((item) => {
            glecVehicleTypesList.push({
              value: item.gross_vehicle_weight_tonnes,
              label: item.glec_vehicle_type,
            });
          });
          // console.log(glecVehicleTypesList)
          await this.setState({
            glecVehicleTypesList,
            glec_vehicle_type: glecVehicleTypesList[0],
          });
        } else {
          this.setState({
            glecVehicleTypesList: [],
            glec_vehicle_type: null,
          });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getGlecVehicleTypes",
          screen: "Vehicle Availability Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  getVehiclesData = (params) => {
    // console.log(params,"params")
    redirectURL
      .post("master/getVehiclesData", params)
      .then(async (response) => {
        if (response.data.status === "success") {
          let vehiclesData = response.data.vehiclesData;
          // console.log(vehiclesData, "vehiclesData first");
          await vehiclesData.map((item) => {
            if (item.vehicle_capacity) {
              //    item.vehicle_capacity= Math.round((item.vehicle_capacity/1000000000)*100)/100
              item.vehicle_capacity = item.vehicle_capacity / 1000;
              item.vehicle_dimensions_width_mm =
                item.vehicle_dimensions_width_mm / 1000;
            }
            return true;
          });
          this.setState({
            rowData: vehiclesData,
          });

          var vehicleTypesList = [];
          vehiclesData.map((each) => vehicleTypesList.push(each.vehicle_type));
          this.setState({ vehicleTypesList });
        }
      });
  };

  fuelTypeChanged = (fuelType) => {
    this.setState({
      fuelType: fuelType,
    });
  };

  glecVehicleTypeChanged = (glec_vehicle_type) => {
    this.setState({
      glec_vehicle_type: glec_vehicle_type,
    });
  };

  openModelForVehicleConfiguration = async (event) => {
    // console.log(event,"eventntntntnt")
    if (this.state.setAddVehilceTypeDisable && !this.state.clickonEdit) {
      event.preventDefault();
    } else {
      var activityMsg = "";
      if (this.state.clickonEdit) {
        activityMsg = "Clicked on Edit button in the aggrid";
        await this.setState({ clickonEdit: false, form_type: 2 });
      } else {
        activityMsg = "clicked on Add Vehicle Button";
        await this.setState({ form_type: 1 });
      }
      let logParams = {
        location_code: this.state.location.value,
        location_name: this.state.location.label,
        user_name: localStorage.getItem("username"),
        useremail: localStorage.getItem("email"),
        client: localStorage.getItem("client"),
        screen: "Vehicle Configurations",
        activity: activityMsg,
        event: "Vehicle Type Configuration Modal Opened",
        data_type: this.state.data_type,
      };

      redirectURL.post("/master/loguserUpdatesorChanges", logParams);
      this.setState((prevState) => ({
        openVehicleConfigurationModel: !prevState.openVehicleConfigurationModel,
      }));
    }
  };

  handelVehiclePriorityConfigurationModel = () => {
    this.setState((prevState) => ({
      openVehiclePriorityConfigurationModel:
        !prevState.openVehiclePriorityConfigurationModel,
    }));
  };
  handelVehiclePriorityConfigurationModelCancel = () => {
    this.setState((prevState) => ({
      openVehiclePriorityConfigurationModel:
        !prevState.openVehiclePriorityConfigurationModel,
    }));
  };

  saveVehiclePriorityOrder = () => {
    // location specific ??
    let vehicle_priority_order_owned = this.state.vehicle_priority_order_owned;
    let vehicle_priority_order_contract =
      this.state.vehicle_priority_order_contract;
    let vehicle_priority_order_market =
      this.state.vehicle_priority_order_market;
    let data_type = this.state.data_type;
    let params = {
      owned: parseInt(vehicle_priority_order_owned),
      contract: parseInt(vehicle_priority_order_contract),
      market: parseInt(vehicle_priority_order_market),
      data_type: data_type,
    };
    redirectURL
      .post("master/saveVehiclePriorityOrder", params)
      .then((response) => {
        if (response.data.status == "success") {
          this.setState({
            openVehiclePriorityConfigurationModel: false,
          });
        } else {
          this.setState({
            show: true,
            basicTitle: "Data Not Saved successfully",
            basicType: "danger",
          });
        }
      });
  };

  getVehiclePriorityOrder = (params) => {
    let vehicle_priority_order_owned = this.state.vehicle_priority_order_owned;
    let vehicle_priority_order_contract =
      this.state.vehicle_priority_order_contract;
    let vehicle_priority_order_market =
      this.state.vehicle_priority_order_market;
    redirectURL
      .post("master/getVehiclePriorityOrder", params)
      .then((response) => {
        if (response.data.status == "success") {
          try {
            let vehiclePriorityData = response.data.vehiclePriorityData;
            vehicle_priority_order_owned = vehiclePriorityData[0].owned;
            vehicle_priority_order_contract = vehiclePriorityData[0].contract;
            vehicle_priority_order_market = vehiclePriorityData[0].market;
            this.setState({
              vehicle_priority_order_owned,
              vehicle_priority_order_contract,
              vehicle_priority_order_market,
            });
          } catch (error) {}
        }
      });
  };

  handelVehicleConfigurationModelCancel = () => {
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Vehicle Configurations",
      activity: "clicked on Cancel Button in the modal",
      event: "Modal is closed",
      data_type: this.state.data_type,
    };

    redirectURL.post("/master/loguserUpdatesorChanges", logParams);
    this.setState((prevState) => ({
      openVehicleConfigurationModel: !prevState.openVehicleConfigurationModel,
      vehicle_type: "",
      vehicle_name: "",
      vehicle_priority: "Owned",
      min_volume_cmt: "",
      max_volume_cmt: "",
      max_volume_m3: "",
      min_volume_m3: "",
      vehicle_dimensions_width_mm: "",
      vehicle_dimensions_depth_mm: "",
      vehicle_dimensions_height_mm: "",
      vehicle_min_weight: "",
      vehicle_max_weight: "",
      max_no_of_nodes: "",
      min_load_per_delivery: "",
      // vehicle_capacity:'',
      vehicle_availability_time: ["00:00", "23:59"],
      break_time: ["00:00", "23:59"],
      max_distance: "",
      vehicle_id: "",
      vehicle_type_count: 0,
      fuelType: "PETROL",
      average_payload: "",
      mileage: "",
      temperature_controlled: "Yes",
      load_characteristics: "Light",
      glec_vehicle_type: "0.0 - 3.5 t GVW",
      monthly_min_planning_distance: "",
      driver_break_time: "02:00",
      unloading_time_per_case: "",
      maximum_unloading_time: "",
      form_type: "",
      rowId: "",
    }));
  };

  // priorityOptions() {
  //     var items = []
  //     items.push({
  //     {
  //         label: "Owned"
  //         value: "owned",
  //     }, {
  //         value: "contract",
  //         label: "Contract"
  //     },{
  //         value: "market",
  //         label: "Market"
  //     }
  //     )
  //     return items;
  // }

  closeAlert = async () => {
    await this.setState({
      show: false,
    });
  };

  handleAvailabilityTime = (val) => {
    this.setState({ vehicle_availability_time: val });
  };

  handleBreakTime = (val) => {
    this.setState({ break_time: val });
  };

  onChangeVehicleType = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    var regex = /^[a-zA-Z]?[a-zA-Z0-9_]*$/;
    var out = regex.test(value);
    if (name == "vehicle_type") {
      value = value.toUpperCase();
    }
    if (value !== "") {
      if (out) {
        this.setState({ [name]: value });
      }
    } else {
      this.setState({ [name]: value });
    }
  };

  onChangeVehicleName = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    var regex = /^[a-zA-Z0-9_ .]*$/;
    // var regex = /^[a-zA-Z]?[a-zA-Z" "]*$/
    var out = regex.test(value);
    if (out) {
      let modifiedValue = value
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      this.setState({ [name]: modifiedValue });
    }
  };

  checkNotEqualstoZero = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    var regex = /^(?!0)\d*(\.\d+)?$/;
    var out = regex.test(value);
    if (out) {
      this.setState({ [name]: value });
    }
  };

  onChangeMinVolumeAndWeight = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    var regex = /^(?:[0-9]+)?(?:\.[0-9]+)?$/;
    var out = regex.test(value);
    if (out) {
      this.setState({ [name]: value });
    }
  };

  onBlurVehicleMinWeight = (e) => {
    let vehicleMaxWeight = this.state.vehicle_max_weight;
    let name = e.target.name;
    let value = e.target.value;
    console.log(typeof value);
    console.log(value, "value", vehicleMaxWeight);
    if (
      parseFloat(vehicleMaxWeight) !== "" &&
      parseFloat(vehicleMaxWeight) < parseFloat(value)
    ) {
      console.log("hit,");
      this.setState({
        show: true,
        basicTitle:
          "Vehicle Max Weight should be greater than Vehicle Min Weight",
        basicType: "danger",
      });
    }
  };

  onBlurVehicleMaxWeight = (e) => {
    let vehicleMinWeight = this.state.vehicle_min_weight;
    let name = e.target.name;
    let value = e.target.value;
    if (parseFloat(vehicleMinWeight) > parseFloat(value)) {
      console.log("hit,");
      this.setState({
        show: true,
        basicTitle:
          "Vehicle Max Weight should be greater than Vehicle Min Weight",
        basicType: "danger",
      });
    }
  };

  onBlurMinVolume = (e) => {
    let vehicleMaxVolume = this.state.max_volume_m3;
    let name = e.target.name;
    let value = e.target.value;
    // console.log(typeof(value));
    if (
      parseFloat(vehicleMaxVolume) < parseFloat(value) &&
      parseFloat(vehicleMaxVolume) !== ""
    ) {
      this.setState({
        show: true,
        basicTitle: "Max volume m3  should be greater than min volume m3",
        basicType: "danger",
      });
    }
  };

  onBlurMaxVolume = (e) => {
    let vehicleMinVolume = this.state.min_volume_m3;
    let name = e.target.name;
    let value = e.target.value;
    // console.log(typeof(value));
    if (parseFloat(vehicleMinVolume) > parseFloat(value)) {
      this.setState({
        show: true,
        basicTitle: "Max volume m3  should be greater than min volume m3",
        basicType: "danger",
      });
    }
  };

  vehicleConfigurationInputFields = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name == "vehicle_type") {
      value = value.toUpperCase();
    }
    this.setState({ [name]: value });
  };

  // vehiclePriority = (vehicle_priority) =>{
  //     console.log(vehicle_priority)
  //     this.setState({
  //         vehicle_priority : vehicle_priority
  //     })
  // }

  locationChanged = async (location) => {
    // console.log(location,"location changed to")
    this.setState(
      {
        location: location,
      },
      () => {
        if (!location.value) {
          this.setState({ setAddVehilceTypeDisable: true });
        } else {
          this.setState({
            setAddVehilceTypeDisable: false,
            editLocation: location,
          });
        }
        getToolConfiguration({ plant_code: location.value });
      }
    );
  };

  boundTypeLocations = async (param) => {
    let location_access = JSON.parse(localStorage.getItem("location_access"));

    let locationNames = [];

    if (location_access == null) {
      locationNames.push({ label: "ALL", value: "" });
    }

    await redirectURL
      .post("master/getBoundTypeLocations", param)
      .then(async (response) => {
        if (response.data.status == "success") {
          let boundLocations = response.data.boundLocations;
          if (boundLocations.length > 0) {
            boundLocations.map((item) => {
              if (location_access != null) {
                if (location_access.includes(item.location_code)) {
                  locationNames.push({
                    value: item.location_code,
                    label: item.location_name,
                  });
                }
              } else {
                locationNames.push({
                  value: item.location_code,
                  label: item.location_name,
                });
              }
            });

            if (locationNames.length > 0) {
              await this.setState({
                locationNames: locationNames,
                location: locationNames[0],
              });
            } else {
              await this.setState({
                locationNames: [{ label: "Please Add Data", value: "no_data" }],
                location: [{ label: "Please Add Data", value: "no_data" }],
              });
            }
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/getBoundTypeLocations",
          screen: "Vehicle Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  validateTimeRange = (from_time, to_time) => {
    let frm_hr = from_time.split(":");
    let to_hr = to_time.split(":");

    if (from_time == to_time) {
      this.setState({
        show: true,
        basicTitle: "Please Input Time Appropriately",
        basicType: "danger",
      });
    } else if (frm_hr[0] > to_hr[0]) {
      this.setState({
        show: true,
        basicTitle: "Please Input Time Appropriately",
        basicType: "danger",
      });
    } else if (frm_hr[0] == to_hr[0]) {
      if (frm_hr[1] > to_hr[1]) {
        this.setState({
          show: true,
          basicTitle: "Please Input Time Appropriately",
          basicType: "danger",
        });
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  saveVehicleConfigurationData = (params) => {
    let data_type = this.state.data_type;
    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Vehicle Configurations",
      activity: "Vehicle Details Entered",
      event: "Vehicle Type Configurations Entered",
      data_type: this.state.data_type,
    };

    redirectURL
      .post("/master/logUserUpdateOrSaveDataActivities", logParams)
      .then(() => {})
      .catch(function (error) {
        var errDetails = {
          url: "/master/logUserUpdateOrSaveDataActivities",
          screen: "Vehicle Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });

    redirectURL
      .post("master/saveVehicleConfiguration", params)
      .then((response) => {
        if (response.data.status == "success") {
          let param = {
            data_type: data_type,
          };
          this.getVehiclesData(param);
          this.setState({
            openVehicleConfigurationModel: false,
            vehicle_type: "",
            vehicle_name: "",
            vehicle_priority: "Owned",
            min_volume_cmt: "",
            max_volume_cmt: "",
            max_volume_m3: "",
            min_volume_m3: "",
            vehicle_dimensions_width_mm: "",
            vehicle_dimensions_depth_mm: "",
            vehicle_dimensions_height_mm: "",
            vehicle_min_weight: "",
            vehicle_max_weight: "",
            min_load_per_delivery: "",
            vehicle_availability_time: ["00:00", "23:59"],
            break_time: ["00:00", "23:59"],
            max_distance: "",
            max_no_of_nodes: "",
            // vehicle_capacity:'',
            vehicle_type_count: 0,
            fuelType: "Petrol",
            average_payload: "",
            mileage: "",
            temperature_controlled: "Yes",
            load_characteristics: "Light",
            glec_vehicle_type: "0.0 - 3.5 t GVW",
            maximum_unloading_time: "",
            unloading_time_per_case: "",
            monthly_min_planning_distance: "",
            driver_break_time: "02:00",
            show: true,
            basicTitle: "New Vehicle Type details added Successfully",
            basicType: "success",
            form_type: "",
          });
        } else {
          if (response.data.status == "failed") {
            this.setState({
              show: true,
              basicTitle: response.data.message,
              basicType: "danger",
            });
          } else {
            this.setState({
              show: true,
              basicTitle: "Data Not Saved successfully",
              basicType: "danger",
            });
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/saveVehicleConfiguration",
          screen: "Vehicle Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  formVehicleConfiguration = (e) => {
    e.preventDefault();
    let vehicleTypesList = this.state.vehicleTypesList;
    let vehicle_type = this.state.vehicle_type;
    let rowData = this.state.rowData;
    let locValue = this.state.location.value;
    let filteredData = rowData.filter(
      (item) => item.location_code === locValue
    );
    var filteredVehicleTypes = [];
    filteredData.map((each) => filteredVehicleTypes.push(each.vehicle_type));
    // console.log(filteredVehicleTypes,"784")
    if (this.state.form_type === 1) {
      if (filteredVehicleTypes.includes(vehicle_type)) {
        this.setState({
          show: true,
          basicTitle: "Vehicle type already exists",
          basicType: "danger",
        });
        return;
      }
    }

    let vehicle_name = this.state.vehicle_name;
    let vehicle_priority = this.state.vehicle_priority;
    let min_volume_cmt = this.state.min_volume_cmt;
    let max_volume_cmt = this.state.max_volume_cmt;
    let max_volume_m3 = this.state.max_volume_m3;
    let min_volume_m3 = this.state.min_volume_m3;
    // console.log(typeof(min_volume_m3), typeof(max_volume_m3))

    if (parseFloat(min_volume_m3) > parseFloat(max_volume_m3)) {
      this.setState({
        show: true,
        basicTitle: "Max Volume(M³) should be greater than Min Volume(M³)",
        basicType: "danger",
      });
      return;
    }
    let vehicle_dimensions_width_mm =
      this.state.vehicle_dimensions_width_mm * 1000;
    let vehicle_dimensions_depth_mm = 1;
    let vehicle_dimensions_height_mm = 1;
    let vehicle_min_weight = this.state.vehicle_min_weight;
    let vehicle_max_weight = this.state.vehicle_max_weight;
    // console.log(typeof(vehicle_max_weight), typeof(vehicle_min_weight))
    if (parseFloat(vehicle_min_weight) > parseFloat(vehicle_max_weight)) {
      this.setState({
        show: true,
        basicTitle:
          "Vehicle Max weight should be greater than vehicle Min weight",
        basicType: "danger",
      });
      return;
    }
    let max_no_of_nodes = 1;
    let min_load_per_delivery = 1;
    let data_type = this.state.data_type;
    let location = this.state.location;
    let max_distance = this.state.max_distance;
    let vehicle_availability_time = this.state.vehicle_availability_time;
    let break_time = this.state.break_time;
    // let vehicle_capacity = this.state.vehicle_capacity;
    var vehicle_id = this.state.vehicle_id;
    let vehicle_type_count = this.state.vehicle_type_count;
    var mileage = this.state.mileage;
    var average_payload = this.state.average_payload;
    let temperature_controlled = this.state.temperature_controlled;
    let load_characteristics = this.state.load_characteristics;
    var rowId = this.state.rowId;
    var monthly_min_planning_distance =
      this.state.monthly_min_planning_distance;

    // console.log(typeof(monthly_min_planning_distance), typeof(max_distance))
    if (parseFloat(max_distance) > parseFloat(monthly_min_planning_distance)) {
      this.setState({
        show: true,
        basicTitle:
          "Max distance per plan should be less than monthly min planning distance",
        basicType: "danger",
      });
      return;
    }
    var maximum_unloading_time = this.state.maximum_unloading_time;
    var unloading_time_per_case = this.state.unloading_time_per_case;
    if (
      parseFloat(unloading_time_per_case) > parseFloat(maximum_unloading_time)
    ) {
      this.setState({
        show: true,
        basicTitle:
          "Unloading time per case should be less than Maximum Unloading Time",
        basicType: "danger",
      });
      return;
    }
    if (parseFloat(maximum_unloading_time) > 500) {
      this.setState({
        show: true,
        basicTitle:
          "Maximum Unloading time should be less than or equal to 500 mins",
        basicType: "danger",
      });
      return;
    }
    if (parseFloat(unloading_time_per_case) > 20) {
      this.setState({
        show: true,
        basicTitle:
          "Unloading time per case should be less than or equal to 20 mins",
        basicType: "danger",
      });
      return;
    }

    let vehicle_capacity = vehicle_dimensions_width_mm * 1 * 1;

    let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    let break_time_from,
      break_time_to,
      vehicle_availability_from,
      vehicle_availability_to;
    var driver_break_time = this.state.driver_break_time;

    if (max_distance === "" || toolConfig[0].distance_preferences === 0) {
      max_distance = 100000;
    }
    if (max_no_of_nodes == "" || toolConfig[0].clubbing_limits == 0) {
      max_no_of_nodes = 1;
    }
    if (
      min_load_per_delivery == "" ||
      toolConfig[0].min_load_preferences == 0
    ) {
      min_load_per_delivery = 1;
    }
    if (
      max_volume_cmt == "" ||
      toolConfig[0].truck_min_max_configuration_preferences == 0
    ) {
      // let metricValue = Math.round((vehicle_capacity/1000000000))
      max_volume_cmt = parseInt(max_volume_cmt);
    }
    if (
      min_volume_cmt == "" ||
      toolConfig[0].truck_min_max_configuration_preferences == 0
    ) {
      min_volume_cmt = 1;
    }
    if (break_time[0] == null) {
      break_time_from = "00:00";
      break_time_to = "23:59";
    } else {
      break_time_from = break_time[0];
      break_time_to = break_time[1];
    }
    if (vehicle_availability_time[0] == null) {
      vehicle_availability_from = "00:00";
      vehicle_availability_to = "23:59";
    } else {
      vehicle_availability_from = vehicle_availability_time[0];
      vehicle_availability_to = vehicle_availability_time[1];
    }

    let params = {
      vehicle_type: vehicle_type,
      vehicle_name: vehicle_name,
      vehicle_priority: vehicle_priority,
      min_volume_cmt: parseFloat(min_volume_cmt),
      max_volume_cmt: parseFloat(max_volume_cmt),
      max_volume_m3: parseFloat(max_volume_m3),
      min_volume_m3: parseFloat(min_volume_m3),
      vehicle_dimensions_width_mm: parseFloat(vehicle_dimensions_width_mm),
      vehicle_dimensions_depth_mm: parseFloat(vehicle_dimensions_depth_mm),
      vehicle_dimensions_height_mm: parseFloat(vehicle_dimensions_height_mm),
      vehicle_min_weight: parseFloat(vehicle_min_weight),
      vehicle_max_weight: parseFloat(vehicle_max_weight),
      max_no_of_nodes: parseInt(max_no_of_nodes),
      data_type: data_type,
      location_code: this.state.editLocation.value,
      location_name: this.state.editLocation.label,
      max_distance: parseInt(max_distance),
      min_load_per_delivery: parseInt(min_load_per_delivery),
      break_time_from: break_time_from,
      break_time_to: break_time_to,
      driver_break_time: driver_break_time,
      vehicle_availability_from: vehicle_availability_from,
      vehicle_availability_to: vehicle_availability_to,
      vehicle_capacity: parseFloat(vehicle_capacity),
      vehicle_type_count: parseInt(vehicle_type_count),
      monthly_min_planning_distance: monthly_min_planning_distance,
      // fuelType: this.state.fuelType.value,
      // mileage: parseFloat(mileage),
      // average_payload : parseFloat(average_payload),
      // temperature_controlled: temperature_controlled,
      // load_characteristics : load_characteristics,
      // glec_vehicle_type: this.state.glec_vehicle_type.label,
      maximum_unloading_time: maximum_unloading_time,
      unloading_time_per_case: unloading_time_per_case,
    };
    // console.log("params first", params, vehicle_id !== "", vehicle_id !== "");
    if (vehicle_id !== "") {
      params.vehicle_id = vehicle_id;
      params.rowId = rowId;
      if (
        toolConfig[0].generic_time_windows == 1 &&
        toolConfig[0].vehicle_operational_times == 1
      ) {
        let vehicle_time = this.validateTimeRange(
          vehicle_availability_time[0],
          vehicle_availability_time[1]
        );
        let break_times = this.validateTimeRange(break_time[0], break_time[1]);

        if (vehicle_time && break_times) {
          this.updateVehicleConfigurationData(params);
        }
      } else {
        this.updateVehicleConfigurationData(params);
      }
    } else {
      if (location.value != null) {
        if (
          toolConfig[0].generic_time_windows == 1 &&
          toolConfig[0].vehicle_operational_times == 1
        ) {
          let vehicle_time = this.validateTimeRange(
            vehicle_availability_time[0],
            vehicle_availability_time[1]
          );
          let break_times = this.validateTimeRange(
            break_time[0],
            break_time[1]
          );

          if (vehicle_time && break_times) {
            this.saveVehicleConfigurationData(params);
          }
        } else {
          this.saveVehicleConfigurationData(params);
        }
      } else {
        this.setState({
          show: true,
          basicTitle:
            "Please add " +
            Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION +
            " First",
          basicType: "danger",
        });
      }
    }
  };

  updateVehicleConfiguration = async (propdata) => {
    console.log(propdata.data, "propdata first");
    let data = propdata.data;
    // console.log(data)
    // console.log(this.state.setAddVehilceTypeDisable,"valueeeeeeeee")
    await this.setState({
      editLocation: this.state.setAddVehilceTypeDisable
        ? { value: data.location_code, label: data.location_name }
        : this.state.location,
      form_type: 2,
      vehicle_type: data.vehicle_type,
      vehicle_name: data.vehicle_name,
      vehicle_priority: data.vehicle_priority,
      min_volume_cmt: data.min_volume_cmt,
      max_volume_cmt: data.max_volume_cmt,
      max_volume_m3: data.max_volume_m3,
      min_volume_m3: data.min_volume_m3,
      vehicle_dimensions_width_mm: data.vehicle_dimensions_width_mm,
      vehicle_dimensions_depth_mm: data.vehicle_dimensions_depth_mm,
      vehicle_dimensions_height_mm: data.vehicle_dimensions_height_mm,
      vehicle_min_weight: data.vehicle_min_weight,
      vehicle_max_weight: data.vehicle_max_weight,
      max_no_of_nodes: data.max_no_of_nodes,
      min_load_per_delivery: data.min_load_per_delivery,
      vehicle_availability_time: [
        data.vehicle_availability_from,
        data.vehicle_availability_to,
      ],
      break_time: [data.break_time_from, data.break_time_to],
      driver_break_time: data.driver_break_time,
      max_distance: data.max_distance,
      // vehicle_capacity:data.vehicle_capacity,
      monthly_min_planning_distance: data.monthly_min_planning_distance,
      vehicle_id: data.vehicle_id,
      vehicle_type_count: data.vehicle_type_count,
      // fuelType:{label:data.fuelType,value:data.fuelType},
      // average_payload:data.average_payload,
      // mileage:data.mileage,
      // temperature_controlled:data.temperature_controlled == null ? 'Yes' : data.temperature_controlled,
      // load_characteristics:data.load_characteristics == null ? 'Light' : data.load_characteristics,
      glec_vehicle_type: {
        label: data.glec_vehicle_type,
        value: data.gross_vehicle_weight_tonnes,
      },
      unloading_time_per_case: data.unloading_time_per_case,
      maximum_unloading_time: data.maximum_unloading_time,
      clickonEdit: true,
      rowId: data._id,
    });
    var previousData = {
      vehicle_type: data.vehicle_type,
      vehicle_name: data.vehicle_name,
      vehicle_priority: data.vehicle_priority,
      min_volume_cmt: data.min_volume_cmt,
      max_volume_cmt: data.max_volume_cmt,
      max_volume_m3: data.max_volume_m3,
      min_volume_m3: data.min_volume_m3,
      vehicle_dimensions_width_mm: data.vehicle_dimensions_width_mm,
      vehicle_dimensions_depth_mm: data.vehicle_dimensions_depth_mm,
      vehicle_dimensions_height_mm: data.vehicle_dimensions_height_mm,
      vehicle_min_weight: data.vehicle_min_weight,
      vehicle_max_weight: data.vehicle_max_weight,
      max_no_of_nodes: data.max_no_of_nodes,
      min_load_per_delivery: data.min_load_per_delivery,
      vehicle_availability_time_from: data.vehicle_availability_from,
      vehicle_availability_to: data.vehicle_availability_to,
      break_time_from: data.break_time_from,
      break_time_to: data.break_time_to,
      driver_break_time: data.driver_break_time,
      max_distance: data.max_distance,
      vehicle_capacity: data.vehicle_capacity,
      monthly_min_planning_distance: data.monthly_min_planning_distance,
      vehicle_id: data.vehicle_id,
      vehicle_type_count: data.vehicle_type_count,
      unloading_time_per_case: data.unloading_time_per_case,
      maximum_unloading_time: data.maximum_unloading_time,
    };
    await this.setState({
      previousData: previousData,
    });
    this.openModelForVehicleConfiguration("edit");
  };

  updateVehicleConfigurationData = (params) => {
    // console.log("params ", params)
    let data_type = this.state.data_type;
    // console.log(this.state.location)

    let logParams = {
      location_code: this.state.location.value,
      location_name: this.state.location.label,
      user_name: localStorage.getItem("username"),
      useremail: localStorage.getItem("email"),
      client: localStorage.getItem("client"),
      screen: "Vehicle Configurations",
      activity: "Vehicle Details Entered",
      event: "Vehicle Type Configurations Edited",
      data_type: this.state.data_type,
    };

    redirectURL
      .post("/master/logUserUpdateOrSaveDataActivities", logParams)
      .then(() => {})
      .catch(function (error) {
        var errDetails = {
          url: "/master/logUserUpdateOrSaveDataActivities",
          screen: "Vehicle Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });

    redirectURL
      .post("master/updateVehicleConfigurationData", params)
      .then((response) => {
        if (response.data.status == "success") {
          let param = {
            data_type: data_type,
          };
          let logParams = {
            user_name: localStorage.getItem("username"),
            useremail: localStorage.getItem("email"),
            client: localStorage.getItem("client"),
            screen: "vehicle configurations",
            location_code: this.state.location.value,
            activity: {},
          };
          let previousData = this.state.previousData;
          logFormChanges(logParams, params, previousData);

          this.getVehiclesData(param);
          this.setState({
            openVehicleConfigurationModel: false,
            vehicle_type: "",
            vehicle_name: "",
            vehicle_priority: "Owned",
            min_volume_cmt: "",
            max_volume_cmt: "",
            max_volume_m3: "",
            min_volume_m3: "",
            vehicle_dimensions_width_mm: "",
            vehicle_dimensions_depth_mm: "",
            vehicle_dimensions_height_mm: "",
            vehicle_min_weight: "",
            vehicle_max_weight: "",
            max_no_of_nodes: "",
            min_load_per_delivery: "",
            vehicle_availability_time: ["00:00", "23:59"],
            break_time: ["00:00", "23:59"],
            max_distance: "",
            // vehicle_capacity:'',
            vehicle_id: "",
            vehicle_type_count: 0,
            fuelType: "Petrol",
            average_payload: "",
            mileage: "",
            temperature_controlled: "Yes",
            load_characteristics: "Light",
            glec_vehicle_type: "0.0 - 3.5 t GVW",
            maximum_unloading_time: "",
            unloading_time_per_case: "",
            monthly_min_planning_distance: "",
            driver_break_time: "02:00",
            show: "true",
            basicTitle:
              "Vehicle Type configuration details Updated Successfully",
            // show: true,
            // basicTitle: "Vehicle Type details Updated Successfully",
            basicType: "success",
            form_type: "",
            previousData: "",
            rowId: "",
          });
        } else {
          if (response.data.status === "failed") {
            this.setState({
              show: true,
              basicTitle: response.data.message,
              basicType: "danger",
            });
          } else {
            this.setState({
              show: true,
              basicTitle: "Data Not Saved successfully",
              basicType: "danger",
            });
          }
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/updateVehicleConfigurationData",
          screen: "Vehicle Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  deleteVehicleConfiguration = (propData) => {
    let data = propData.data;
    let data_type = this.state.data_type;

    let params = {
      delete_id: data._id,
      vehicle_type: data.vehicle_type,
      data_type: data_type,
    };

    redirectURL
      .post("master/deleteVehicleConfiguration", params)
      .then((response) => {
        if (response.data.status == "success") {
          let param = {
            data_type: data_type,
          };
          this.getVehiclesData(param);
        } else {
          this.setState({
            show: true,
            basicTitle: "Failed to delete the data",
            basicType: "danger",
          });
        }
      })
      .catch(function (error) {
        var errDetails = {
          url: "master/deleteVehicleConfiguration",
          screen: "Vehicle Configurations",
          error,
        };
        redirectURL.post("/master/logErrorsForApiCalls", errDetails);
      });
  };

  onChangeDriverBreakTime(event) {
    var value = event.target.value;
    const isValidInput = /^[\d:]{0,2}(:[\d:]*)?$/.test(value);
    if (isValidInput === true) {
      if (value.length <= 5) {
        this.setState({
          driver_break_time: value,
          isValidInput: isValidInput,
        });
      }
    }
  }
  render() {
    console.log("first row", this.state.rowId);
    let data = this.state.rowData;
    let locValue = this.state.location.value;
    let filteredData;
    if (!locValue) {
      filteredData = this.state.rowData;
    } else {
      filteredData = data.filter((item) => item.location_code == locValue);
    }

    let vehicleTypeCnt = 4;
    let truck_min_max = 0;
    let truckPriorities = 0;
    let distancePreferences = 0;
    let operationalTimes = 0;
    let operationalTimesType = 0;
    let clubbingLimits = 0;
    let minLoad = 0;
    let vehicleAvailability = 0;
    let vehicleMinMaxWeight = 0;
    let vehicleAvailabilityType = 0;
    var monthly_distance_preferences = 0;
    let plant_wise_sustainbility_integration = 0;

    let toolConfig = JSON.parse(localStorage.getItem("tlcfg"));
    if (toolConfig != undefined && toolConfig != "") {
      if (toolConfig.length > 0) {
        vehicleTypeCnt = toolConfig[0].max_no_of_trucks;
        truck_min_max = toolConfig[0].truck_min_max_configuration_preferences;
        truckPriorities = toolConfig[0].truck_priorities;
        distancePreferences = toolConfig[0].distance_preferences;
        operationalTimes = toolConfig[0].vehicle_operational_times;

        if (toolConfig[0].generic_time_windows === 1) {
          operationalTimesType = 1;
        }
        if (toolConfig[0].individual_truck_time_windows === 1) {
          operationalTimesType = 2;
        }

        clubbingLimits = toolConfig[0].clubbing_limits;
        minLoad = toolConfig[0].min_load_preferences;
        vehicleAvailability = toolConfig[0].vehicle_availability;

        if (toolConfig[0].vehicle_availability_by_vehicle_type === 1) {
          vehicleAvailabilityType = 1;
        }
        if (toolConfig[0].vehicle_availability_by_individual_vehicle === 1) {
          vehicleAvailabilityType = 2;
        }
        monthly_distance_preferences =
          toolConfig[0].monthly_distance_preferences;
        vehicleMinMaxWeight = toolConfig[0].truck_weight_min_max_preferences;

        plant_wise_sustainbility_integration =
          toolConfig[0].sustainbility_integration;
      }
    }

    var colsDefs = [
      {
        headerName: "Plant Name",
        field: "location_name",
        width: "200",
      },
      {
        headerName: "Vehicle Type",
        field: "vehicle_type",
        width: "200",
      },
      {
        headerName: "Vehicle Name",
        field: "vehicle_name",
        width: "100",
      },
      // {
      //     headerName:"No of Vehicles",
      //     field:"no_of_vehicles",
      //     width:"80",
      // initialWidth:"80",
      // editable:true,
      // },
      // {
      //     headerName:"Vehicle Operational(from)",
      //     field:"vehicle_availability_from",
      //     width:"150",
      //     // initialWidth:"80",
      //     // editable:true,
      //     // cellEditorPopup: true,
      //     // cellEditor: 'agSelectCellEditor',
      //     // cellEditorParams: {
      //     // values: Constant.TIMER_VALUES,
      //     // }
      // },
      // {
      //     headerName:"Vehicle Operational(to)",
      //     field:"vehicle_availability_to",
      //     width:"140"
      //     // initialWidth:"80",
      //     // editable:true,
      //     // cellEditorPopup: true,
      //     // cellEditor: 'agSelectCellEditor',
      //     // cellEditorParams: {
      //     // values: Constant.TIMER_VALUES,
      //     // }
      // },
      // {
      //     headerName:"Driver Break Time(from)",
      //     field:"driver_break_time",
      //     width:"110"
      // },
      // {
      //     headerName:"Driver Break Time(from)",
      //     field:"break_time_from",
      //     width:"110",
      //     // initialWidth:"80",
      //     // editable:true,
      //     // cellEditorPopup: true,
      //     // cellEditor: 'agSelectCellEditor',
      //     // cellEditorParams: {
      //     // values: Constant.TIMER_VALUES,
      //     // }
      // },
      // {
      //     headerName:"Driver Break Time(to) ",
      //     field:"break_time_to",
      //     width:"100",
      //     // initialWidth:"80",
      //     // role:"listbox",
      //     // editable:true,
      //     // cellEditorPopup: true,
      //     // cellEditor: 'agSelectCellEditor',
      //     // cellEditorParams: {
      //     // values: Constant.TIMER_VALUES,
      //     // }
      // },
      // {
      //     headerName:"Min Seating Capacity",
      //     field:"min_volume_cmt",
      //     width:"120"
      // },
      // {
      //     headerName:"Max Seating Capacity",
      //     field:"max_volume_cmt",
      //     width:"120"
      // },
      // {
      //     headerName:"Vehicle Seating Capacity",
      //     field:"vehicle_dimensions_width_mm",
      //     width:"160"
      // },
      // {
      //     headerName:"Vehicle Height (mm)",
      //     field:"vehicle_dimensions_height_mm",
      //     width:"125"
      // },
      // {
      //     headerName:"Vehicle Depth (mm)",
      //     field:"vehicle_dimensions_depth_mm",
      //     width:"120"
      // },
      {
        headerName: "Vehicle Min Weight (Kgs)",
        field: "vehicle_min_weight",
        width: "120",
      },
      {
        headerName: "Vehicle Max Weight (Kgs)",
        field: "vehicle_max_weight",
        width: "120",
      },
      {
        headerName: "Vehicle Min Volume (M3)",
        field: "min_volume_m3",
        width: "130",
      },
      {
        headerName: "Vehicle Max Volume (M3)",
        field: "max_volume_m3",
        width: "130",
      },
      // {
      //     headerName:"Vehicle Capacity (CMT)",
      //     field:"vehicle_capacity",
      //     width:"135"
      // },
      // {
      //     headerName:"Min Load Per Delivery",
      //     field:"min_load_per_delivery",
      //     width:"140"
      // },
      {
        headerName: "Max Distance (Kms)",
        field: "max_distance",
        width: "120",
      },
      {
        headerName: "Monthly Min Planning Distance (Kms)",
        field: "monthly_min_planning_distance",
        hide: true,
        // hide: monthly_distance_preferences === 1 ? false : true,
        width: 150,
      },

      {
        headerName: "Vehicle Priority",
        field: "vehicle_priority",
        width: "100",
      },
      // {
      //     headerName:"Variable Vehicle Cost",
      //     field:"variable_vehicle_cost_expandable",
      //     width:"120"
      // },
      // {
      //     headerName:"Variable Vehicle Cost",
      //     field:"cluster",
      //     width:"120",
      //     pinned:'left',
      //     params:{buttonName:'Variable Cost',
      //             onClickFunction:this.variableCostFunction},
      //     cellRendererSelector:function(params){

      //         var rendComponent = {
      //             component: "GridButton"
      //         };
      //         return rendComponent

      //     },
      // },
      {
        headerName: "Actions",
        field: "cluster",
        width: "20",
        pinned: "left",
        params: {
          buttonName: "Edit",
          onClickFunction: this.updateVehicleConfiguration,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      },
      // {
      //     headerName:"",
      //     field:"cluster",
      //     width:"80",
      //     pinned:'left',
      //     params:{buttonName:'Delete',iconName:"fa fa-trash",
      //             onClickFunction:this.deleteVehicleConfiguration},
      //     cellRendererSelector:function(params){

      //         var rendComponent = {
      //             component: "GridButton"
      //         };
      //         return rendComponent

      //     },
      // },
      {
        headerName: "Unloading Time Per Case (Mins)",
        field: "unloading_time_per_case",
        // hide: monthly_distance_preferences === 1 ? false : true,
        hide: true,
        width: 150,
      },
      {
        headerName: "Maximum Unloading Time (Mins)",
        field: "maximum_unloading_time",
        // hide: monthly_distance_preferences === 1 ? false : true,
        hide: true,
        width: 150,
      },
    ];

    if (plant_wise_sustainbility_integration == 1) {
      colsDefs.push(
        {
          headerName: "Average Payload (Tonnes)",
          field: "average_payload",
          width: "150",
        },

        {
          headerName: "Mileage (Km/l)",
          field: "mileage",
          width: "150",
        },
        {
          headerName: "Fuel Type",
          field: "fuelType",
          width: "140",
        },
        {
          headerName: "Temperature Controlled",
          field: "temperature_controlled",
          width: "100",
        },
        {
          headerName: "Load Characteristics",
          field: "load_characteristics",
          width: "100",
        },
        {
          headerName: "GLEC Vehicle Type",
          field: "glec_vehicle_type",
          width: "100",
        }
      );
    }
    // if(this.state.data_type == 1){
    //     colsDefs.push({
    //         headerName:"Suppliers / Vendor Clubbing Limits" ,
    //         field:"max_no_of_nodes",
    //         width:"300"
    //     });
    // }else{
    //     colsDefs.push({
    //         headerName:"Consignees / Dealers Clubbing Limits" ,
    //         field:"max_no_of_nodes",
    //         width:"300"
    //     });
    // }
    if (localStorage.getItem("email") === "deletedataaccess@enmovil.in") {
      colsDefs.push({
        headerName: "",
        field: "cluster",
        width: "80",
        pinned: "left",
        params: {
          buttonName: "Delete",
          iconName: "fa fa-trash",
          onClickFunction: this.deleteVehicleConfiguration,
        },
        cellRendererSelector: function (params) {
          var rendComponent = {
            component: "GridButton",
          };
          return rendComponent;
        },
      });
    }

    return (
      <div className="">
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          onConfirm={this.closeAlert}
        ></SweetAlert>
        {/* start */}
        {/* <div class="row mb-20p">
          <div className="col-sm-12">
            <h5 className="fbold">
              <span>{Constant.MASTER_VEHICLE_DATA_HEADER_NAME}</span>
              <div className="float-right col-sm-4">
                <a
                  href="javascript:;"
                  onClick={this.openModelForPickupDrop}
                  className="f18 float-right themefont fbold mr-10p mb-10p"
                >
                  + Add pickup/drop locations
                </a>

                <span className="float-right">
                  <a
                    href="javascript:;"
                    onClick={this.openUploadOptionForPickupDrop}
                    className="btn btn-warning f12 mr-10p"
                  >
                    + Upload pickup/drop Locations Data
                  </a>
                </span>
              </div>
            </h5>
          </div>
        </div> */}
        {/* start */}
        <div class="row mb-20p pt-2p">
          <div className="col-sm-12 d-flex">
            <div
              className={
                this.state.showAvailablityTable
                  ? "col-sm-7 "
                  : "col-sm-12 " + "  d-inline"
              }
            >
              <h5 className="fbold d-flex justify-content-between">
                <span>
                  {Constant.MASTER_VEHICLE_DATA_CONFIGURATION_HEADER_NAME}
                </span>
                {/* {vehicleTypeCnt > this.state.rowData.length ? */}
                <div className="float-right">
                  <a
                    href="javascript:;"
                    onClick={this.openModelForVehicleConfiguration}
                    style={{
                      pointerEvents: this.state.setAddVehilceTypeDisable
                        ? "none"
                        : "auto",
                      backgroundColor: this.state.setAddVehilceTypeDisable
                        ? "#ddd"
                        : "#fff",
                    }}
                    className="f12 btn btn-default float-right themefont fbold mr-10p mb-10p"
                  >
                    {Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}
                  </a>
                  {/* start */}
                  {/* <span className="float-right">
                    <a
                      href="javascript:;"
                      onClick={this.openUploadOptionForPickupDrop}
                      className="btn btn-warning f12 mr-10p"
                    >
                      {Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}
                    </a>
                  </span>
                </div>
                :{" "}
                <div className="float-right">
                  <a
                    href="javascript:;"
                    data-toggle="tooltip"
                    title="Max-Limit Reached"
                    className="f12 btn btn-default float-right themefont fbold mr-10p mb-10p"
                  >
                    {Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}
                  </a>

                  <span className="float-right">
                    <a
                      href="javascript:;"
                      onClick={this.openUploadOptionForPickupDrop}
                      className="btn btn-warning f12 mr-10p"
                    >
                      {Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BUTTON_NAME}
                    </a>
                  </span> */}
                  {/* start */}
                </div>
              </h5>
              {truckPriorities ? (
                <span className="float-right">
                  <a
                    href="javascript:;"
                    onClick={this.handelVehiclePriorityConfigurationModel}
                    className="btn btn-warning f12 mr-10p"
                  >
                    Set Vehicle Priority
                  </a>
                </span>
              ) : (
                <div />
              )}
              <div className="col-md-2 col-sm-12">
                <div className="form-group ">
                  <label className="col-form-label f12">
                    {Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION}
                  </label>
                  <Select
                    placeholder={"ALL"}
                    onChange={this.locationChanged}
                    name="location"
                    value={this.state.location}
                    options={this.state.locationNames}
                  />
                </div>
              </div>

              <div
                id="myGrid"
                style={{ width: "100%", height: "70vh" }}
                className="col-sm-12 ag-theme-balham dropdown"
              >
                <AgGridReact
                  // modules={this.state.modules}
                  rowData={filteredData}
                  columnDefs={colsDefs}
                  gridOptions={{ context: { componentParent: this } }}
                  defaultColDef={this.state.defaultColDef}
                  frameworkComponents={this.state.frameworkComponents}
                  paginationPageSize={this.state.paginationPageSize}
                  pagination={true}
                  enableRangeSelection={true}
                  enableCellContextMenu={true}
                  statusBar={this.state.statusBar}
                  sideBar={this.state.sideBar}
                />
              </div>
            </div>
          </div>
        </div>

        <Modal
          closeOnOverlayClick={false}
          open={this.state.openVehicleConfigurationModel}
          onClose={this.handelVehicleConfigurationModelCancel}
          classNames={{ modal: "pickupdrop-modelClass" }}
        >
          <div className="col-sm-12">
            <div>
              <h4>Vehicle Type Configuration</h4>
            </div>
            <form
              className="theme-form"
              onSubmit={this.formVehicleConfiguration}
            >
              <div className="row number">
                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      {
                        Constant.MASTER_VEHICLE_DATA_CONFIGURATION_BOUND_LOCATION
                      }
                    </label>
                    <Select
                      isDisabled
                      name="location"
                      value={this.state.editLocation}
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      Vehicle Type
                      <span className="redColor fbold">*</span>
                    </label>
                    <input
                      required
                      type="text"
                      className="forminp form-control"
                      id="vehicle_type"
                      name="vehicle_type"
                      value={this.state.vehicle_type}
                      onChange={this.onChangeVehicleType}
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      Vehicle Type Name
                      <span className="redColor fbold">*</span>
                    </label>
                    <input
                      required
                      type="text"
                      className="forminp form-control"
                      name="vehicle_name"
                      value={this.state.vehicle_name}
                      onChange={this.onChangeVehicleName}
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label
                      htmlFor="vehicle_priority"
                      className="col-form-label f12"
                    >
                      {
                        Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_PRIORITY
                      }{" "}
                      <span className="redColor fbold">*</span>
                    </label>
                    {/* <Select
                                        placeholder={"Select"}
                                        onChange={this.vehiclePriority}
                                        className={""}
                                        name="vehicle_priority"
                                        value={this.state.vehicle_priority}
                                        options={this.priorityOptions()} 
                                        isDisabled={toolConfig[0].truck_priorities ? false : true}
                                        
                                        /> */}

                    <select
                      name="vehicle_priority"
                      id="vehicle_priority"
                      value={this.state.vehicle_priority}
                      required={truckPriorities ? true : false}
                      disabled={truckPriorities ? false : true}
                      className={
                        truckPriorities
                          ? "forminp form-control"
                          : "forminp form-control disable-field"
                      }
                      onChange={this.vehicleConfigurationInputFields}
                    >
                      {/* <option value="" disabled selected>Select</option> */}
                      <option value="Owned">Owned</option>
                      <option value="Contract">Contract</option>
                      <option value="Market">Market</option>
                    </select>
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      {
                        Constant.MASTER_VEHICLE_DATA_CONFIGURATION_CMT_MIN_VOLUME
                      }{" "}
                      <span className="redColor fbold">*</span>
                    </label>
                    <input
                      type="number"
                      name="min_volume_m3"
                      id="min_volume_m3"
                      value={this.state.min_volume_m3}
                      onChange={this.onChangeMinVolumeAndWeight}
                      onBlur={this.onBlurMinVolume}
                      placeholder=""
                      autoComplete="off"
                      disabled={truck_min_max === 0}
                      className="forminp form-control"
                      required={truck_min_max ? true : false}
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      {
                        Constant.MASTER_VEHICLE_DATA_CONFIGURATION_CMT_MAX_VOLUME
                      }{" "}
                      <span className="redColor fbold">*</span>
                    </label>
                    <input
                      type="number"
                      name="max_volume_m3"
                      value={this.state.max_volume_m3}
                      onChange={this.checkNotEqualstoZero}
                      autoComplete="off"
                      required={truck_min_max ? true : false}
                      disabled={truck_min_max === 0}
                      onBlur={this.onBlurMaxVolume}
                      className="forminp form-control"
                    />
                  </div>
                </div>

                {/* start */}
                {/* <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      {
                        Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_DIMENSIONS
                      }
                    </label>
                    <div className="d-flex justify-content-between number">
                      /*{" "}
                      <div className="col-md-4 col-sm-4 d-flex control-padding">
                        {" "}
                        // /*{" "}
                        <label className="col-form-label f14 d-inline font-bold">
                          W
                        </label>{" "}
                        //
                        <input
                          required
                          type="number"
                          min="0"
                          className="forminp form-control d-inline control-padding-right"
                          id="width"
                          name="vehicle_dimensions_width_mm"
                          value={this.state.vehicle_dimensions_width_mm}
                          onChange={this.vehicleConfigurationInputFields}
                          placeholder="width"
                          autoComplete="off"
                        />
                        /*{" "}
                      </div>{" "}
                      // /*{" "}
                      <div className="col-md-4 col-sm-4 d-flex control-padding">
                        <label className="col-form-label f14 d-inline font-bold">
                          H
                        </label>
                        <input
                          required
                          type="number"
                          min="0"
                          className="forminp form-control d-inline control-padding-right"
                          id="height"
                          name="vehicle_dimensions_height_mm"
                          value={this.state.vehicle_dimensions_height_mm}
                          onChange={this.vehicleConfigurationInputFields}
                          placeholder="height"
                          autoComplete="off"
                        />
                      </div>
                      <div className="col-md-3 col-sm-4 d-flex control-padding">
                        <label className="col-form-label f14 d-inline font-bold">
                          D
                        </label>
                        <input
                          required
                          type="number"
                          min="0"
                          className="forminp form-control d-inline control-padding-right"
                          id="depth"
                          name="vehicle_dimensions_depth_mm"
                          value={this.state.vehicle_dimensions_depth_mm}
                          onChange={this.vehicleConfigurationInputFields}
                          placeholder="depth"
                          autoComplete="off"
                        />
                      </div>{" "}
                      //
                    </div>
                  </div>
                </div> */}

                {/* start */}
                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      {
                        Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MIN_WEIGHT
                      }{" "}
                      <span className="redColor fbold">*</span>{" "}
                    </label>
                    <input
                      type="number"
                      id="vehicle_min_weight"
                      name="vehicle_min_weight"
                      value={this.state.vehicle_min_weight}
                      onChange={this.onChangeMinVolumeAndWeight}
                      onBlur={this.onBlurVehicleMinWeight}
                      required={vehicleMinMaxWeight ? true : false}
                      className={
                        vehicleMinMaxWeight
                          ? "forminp form-control"
                          : "forminp form-control disable-field"
                      }
                      disabled={vehicleMinMaxWeight ? false : true}
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      {
                        Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MAX_WEIGHT
                      }{" "}
                      <span className="redColor fbold">*</span>{" "}
                    </label>
                    <input
                      type="number"
                      id="vehicle_max_weight"
                      name="vehicle_max_weight"
                      value={this.state.vehicle_max_weight}
                      onChange={this.checkNotEqualstoZero}
                      onBlur={this.onBlurVehicleMaxWeight}
                      required={vehicleMinMaxWeight ? true : false}
                      className={
                        vehicleMinMaxWeight
                          ? "forminp form-control"
                          : "forminp form-control disable-field"
                      }
                      disabled={vehicleMinMaxWeight ? false : true}
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                </div>

                {/* start */}
                {/* <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      {
                        Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_CAPACITY
                      }{" "}
                    </label>
                    <input
                      required
                      type="number"
                      min="0"
                      className="forminp form-control"
                      id="vehicle_capacity"
                      name="vehicle_capacity"
                      value={this.state.vehicle_capacity}
                      onChange={this.vehicleConfigurationInputFields}
                      placeholder=""
                      autoComplete="off"
                    />
                  </div>
                </div> */}

                {/* start */}
                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      {
                        Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MAX_DISTANCE
                      }{" "}
                      <span className="redColor fbold">*</span>
                    </label>
                    <input
                      type="number"
                      min="1"
                      required={distancePreferences ? true : false}
                      className={
                        distancePreferences
                          ? "forminp form-control"
                          : "forminp form-control disable-field"
                      }
                      placeholder={distancePreferences ? "Kms" : "Maximum"}
                      disabled={distancePreferences ? false : true}
                      id="max_distance"
                      name="max_distance"
                      value={this.state.max_distance}
                      onChange={this.vehicleConfigurationInputFields}
                      autoComplete="off"
                    />
                  </div>
                </div>

                {/* <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      {
                        Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_AVAILABILITY_TIME
                      }
                    </label>
                    {operationalTimes ? (
                      operationalTimesType == 1 ? (
                        <TimeRangePicker
                          onChange={this.handleAvailabilityTime}
                          value={this.state.vehicle_availability_time}
                          rangeDivider={"to"}
                          required={true}
                          className="forminp form-control vehicle-time"
                          clearIcon={null}
                          clockIcon={null}
                          openClockOnFocus={false}
                        />
                      ) : operationalTimesType == 2 ? (
                        <TimeRangePicker
                          rangeDivider={"to"}
                          value={this.state.vehicle_availability_time}
                          className="forminp form-control vehicle-time disable-field-time"
                          disabled={true}
                          clearIcon={null}
                          clockIcon={null}
                          openClockOnFocus={false}
                        />
                      ) : (
                        <TimeRangePicker
                          rangeDivider={"to"}
                          value={this.state.vehicle_availability_time}
                          className="forminp form-control vehicle-time disable-field-time"
                          disabled={true}
                          clearIcon={null}
                          clockIcon={null}
                          openClockOnFocus={false}
                        />
                      )
                    ) : (
                      <TimeRangePicker
                        rangeDivider={"to"}
                        value={this.state.vehicle_availability_time}
                        className="forminp form-control vehicle-time disable-field-time"
                        disabled={true}
                        clearIcon={null}
                        clockIcon={null}
                        openClockOnFocus={false}
                      />
                    )}
                  </div>
                </div> */}

                {/* <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    {this.state.data_type == 1 ? (
                      <label className="col-form-label f12">
                        {
                          Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MAX_DELIVERY_INBOUND
                        }
                      </label>
                    ) : (
                      <label className="col-form-label f12">
                        {
                          Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MAX_DELIVERY_OUTBOUND
                        }
                      </label>
                    )}
                    <input
                      type="number"
                      min="1"
                      id="max_no_of_nodes"
                      name="max_no_of_nodes"
                      value={this.state.max_no_of_nodes}
                      onChange={this.vehicleConfigurationInputFields}
                      placeholder={clubbingLimits ? "" : "Maximum"}
                      autoComplete="off"
                      required={clubbingLimits ? true : false}
                      className={
                        clubbingLimits
                          ? "forminp form-control"
                          : "forminp form-control disable-field"
                      }
                      disabled={clubbingLimits ? false : true}
                    />
                  </div>
                </div> */}

                {/* <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      {
                        Constant.MASTER_VEHICLE_DATA_CONFIGURATION_VEHICLE_MIN_LOAD
                      }
                    </label>
                    <input
                      type="text"
                      id="min_load_per_delivery"
                      name="min_load_per_delivery"
                      value={this.state.min_load_per_delivery}
                      onChange={this.vehicleConfigurationInputFields}
                      placeholder=""
                      autoComplete="off"
                      required={minLoad ? true : false}
                      className={
                        minLoad
                          ? "forminp form-control"
                          : "forminp form-control disable-field"
                      }
                      disabled={minLoad ? false : true}
                    />
                  </div>
                </div> */}
                {/* {vehicleAvailability == 0 ? (
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group ">
                      <label className="col-form-label f12">
                        Vehicle Type Count
                      </label>
                      <input
                        type="number"
                        min="1"
                        id="vehicle_type_count"
                        name="vehicle_type_count"
                        value={this.state.vehicle_type_count}
                        onChange={this.vehicleConfigurationInputFields}
                        placeholder=""
                        autoComplete="off"
                        required={true}
                        className="forminp form-control"
                      />
                    </div>
                  </div>
                ) : (
                  <div />
                )} */}
                {/* start */}

                {vehicleAvailability == 1 && vehicleAvailabilityType == 1 ? (
                  <div className="col-md-4 col-sm-12">
                    <div className="form-group ">
                      <label className="col-form-label f12">
                        Vehicle Type Count
                      </label>
                      <input
                        type="number"
                        min="1"
                        id="vehicle_type_count"
                        name="vehicle_type_count"
                        value={this.state.vehicle_type_count}
                        onChange={this.vehicleConfigurationInputFields}
                        placeholder=""
                        autoComplete="off"
                        required={true}
                        className="forminp form-control"
                      />
                    </div>
                  </div>
                ) : (
                  <div />
                )}

                <div className="col-md-4 col-sm-12">
                  <div className="form-group ">
                    <label className="col-form-label f12">
                      Monthly Min Planning Distance (KMs){" "}
                      <span className="redColor fbold">*</span>
                    </label>
                    <input
                      type="number"
                      min="1"
                      id="monthly_min_planning_distance"
                      name="monthly_min_planning_distance"
                      value={this.state.monthly_min_planning_distance}
                      onChange={this.checkNotEqualstoZero}
                      placeholder=""
                      autoComplete="off"
                      required={
                        monthly_distance_preferences === 1 ? true : false
                      }
                      //   disabled={
                      //     monthly_distance_preferences === 1 ? false : true
                      //   }
                      className="forminp form-control"
                    />
                  </div>
                </div>

                {/* start */}
                {/* <div className="col-md-4 col-sm-12">
                      <div className="form-group ">
                        <label className="col-form-label f12">
                          Driver Break Time Per Day (HH:MM){" "}
                          <span className="redColor fbold">*</span>
                        </label>
                        <input
                          type="text"
                          name="driver_break_time"
                          id="driver_break_time"
                          value={this.state.driver_break_time}
                          onChange={this.onChangeDriverBreakTime.bind(this)}
                          required
                          className="forminp form-control"
                        />
                      </div>
                    </div> */}

                {/* <div className="col-md-4 col-sm-12">
                      <div className="form-group ">
                        <label className="col-form-label f12">
                          Unloading Time Per Case (Mins){" "}
                          <span className="redColor fbold">*</span>
                        </label>
                        <input
                          type="number"
                          name="unloading_time_per_case"
                          id="unloading_time_per_case"
                          value={this.state.unloading_time_per_case}
                          onChange={this.vehicleConfigurationInputFields}
                          placeholder=""
                          autoComplete="off"
                          className={"forminp form-control"}
                          required
                        />
                      </div>
                    </div> */}

                {/* <div className="col-md-4 col-sm-12">
                      <div className="form-group ">
                        <label className="col-form-label f12">
                          Maximum Unloading Time (Mins){" "}
                          <span className="redColor fbold">*</span>
                        </label>
                        <input
                          type="number"
                          name="maximum_unloading_time"
                          id="maximum_unloading_time"
                          value={this.state.maximum_unloading_time}
                          onChange={this.vehicleConfigurationInputFields}
                          placeholder=""
                          autoComplete="off"
                          className={"forminp form-control"}
                          required
                        />
                      </div>
                    </div> */}

                {/* { plant_wise_sustainbility_integration === 1? 
                                <>
                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group ">
                                    <label className="col-form-label f12">GLEC Vehicle Type</label>
                                    <Select
                                        maxMenuHeight={'170px'}
                                        placeholder={"Select"}
                                        onChange={this.glecVehicleTypeChanged}
                                        name="glec_vehicle_type"
                                        value={this.state.glec_vehicle_type}
                                        options={this.state.glecVehicleTypesList} 
                                        />

                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group ">
                                        <label className="col-form-label f12">Fuel Type</label>
                                        <Select
                                            maxMenuHeight={'170px'}
                                            placeholder={"Select"}
                                            onChange={this.fuelTypeChanged}
                                            name="fuelType"
                                            value={this.state.fuelType}
                                            options={this.state.fuelTypeList} 
                                            />

                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group ">
                                        <label className="col-form-label f12">Average Payload (Tonnes)</label>
                                        <input type="text" 
                                            className= "forminp form-control"
                                            required 
                                            name="average_payload" 
                                            id="average_payload"
                                            value={this.state.average_payload}
                                            onChange={this.vehicleConfigurationInputFields} 
                                            placeholder="" autoComplete="off" />
                                                                            
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                    <div className="form-group ">
                                        <label className="col-form-label f12">Mileage (Km/L)</label>
                                        <input type="text" 
                                            className= "forminp form-control"
                                            required 
                                            name="mileage" 
                                            id="mileage"
                                            value={this.state.mileage}
                                            onChange={this.vehicleConfigurationInputFields} 
                                            placeholder="" autoComplete="off" />
                                                                            
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label htmlFor="temperature_controlled" className="col-form-label f12">Temperature Controlled</label>
                                    <select name="temperature_controlled" id="temperature_controlled" value = {this.state.temperature_controlled}
                                        required={true}
                                        className="forminp form-control"
                                        onChange={this.vehicleConfigurationInputFields}>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="form-group ">
                                    <label htmlFor="load_characteristics" className="col-form-label f12">Load Characteristics</label>
                                    <select name="load_characteristics" id="load_characteristics" value = {this.state.load_characteristics}
                                        required={true}
                                        className="forminp form-control"
                                        onChange={this.vehicleConfigurationInputFields}>
                                        <option value="Light">Light</option>
                                        <option value="Average/Mixed">Average/Mixed</option>
                                        <option value="Heavy">Heavy</option>
                                        <option value="Container">Container</option>
                                    </select>
                                </div>
                            </div>
                                </>

                            : "" } */}
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  className="btn tbtn float-right save-btn"
                  id="saveGeo"
                >
                  SAVE
                </button>
                <button
                  type="button"
                  className="btn tbtn float-right cancel-btn"
                  onClick={this.handelVehicleConfigurationModelCancel}
                >
                  CANCEL
                </button>
              </div>
            </form>
          </div>
        </Modal>

        <Modal
          closeOnOverlayClick={false}
          open={this.state.openVehiclePriorityConfigurationModel}
          onClose={this.handelVehiclePriorityConfigurationModelCancel}
          classNames={{ modal: "vehiclepriority-modelClass" }}
        >
          <div className="col-sm-12">
            <div className="mb-10p">
              <h4>Set Vehicle Priority</h4>
            </div>
            <div className="col-md-12 col-sm-12 d-flex justify-content-around">
              <h5 className="col-md-6 d-flex justify-content-center align-self-center fw-600">
                Owned
              </h5>
              <div className="form-group col-md-6">
                <select
                  name="vehicle_priority_order_owned"
                  id="vehicle_priority_order_owned"
                  value={this.state.vehicle_priority_order_owned}
                  className="forminp form-control"
                  onChange={this.vehicleConfigurationInputFields}
                >
                  <option value="1">High</option>
                  <option value="2">Medium</option>
                  <option value="3">Low</option>
                </select>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 ">
              <h5 className="col-md-6 d-flex justify-content-center align-self-center fw-600">
                Contract
              </h5>
              <div className="form-group col-md-6">
                <select
                  name="vehicle_priority_order_contract"
                  id="vehicle_priority_order_contract"
                  value={this.state.vehicle_priority_order_contract}
                  className="forminp form-control"
                  onChange={this.vehicleConfigurationInputFields}
                >
                  <option value="1">High</option>
                  <option value="2">Medium</option>
                  <option value="3">Low</option>
                </select>
              </div>
            </div>
            <div className="col-md-12 col-sm-12 ">
              <h5 className="col-md-6 d-flex justify-content-center align-self-center fw-600">
                Market
              </h5>
              <div className="form-group col-md-6">
                <select
                  name="vehicle_priority_order_market"
                  id="vehicle_priority_order_market"
                  value={this.state.vehicle_priority_order_market}
                  className="forminp form-control"
                  onChange={this.vehicleConfigurationInputFields}
                >
                  <option value="1">High</option>
                  <option value="2">Medium</option>
                  <option value="3">Low</option>
                </select>
              </div>
            </div>

            <div className="form-group">
              <button
                type="button"
                onClick={this.saveVehiclePriorityOrder}
                className="btn tbtn float-right save-btn"
              >
                SAVE
              </button>
              <button
                type="button"
                className="btn tbtn float-right cancel-btn"
                onClick={this.handelVehiclePriorityConfigurationModelCancel}
              >
                CANCEL
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
